import { useToast } from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { createEvent } from 'contexts/redux/event/eventSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IEvent } from 'services/@types';
import { eventTypes } from 'variables/eventTypes';
import { importFromExcel } from 'utils/excelImport';

interface EventData {
  // English fields
  created_at?: number;
  lead_source?: string;
  event_date?: number;
  event_name?: string;
  event_type?: string;
  status?: string;
  venue?: string;
  resources?: string;

  // Hebrew fields
  'נוצר ב'?: number;
  'מקור פניה'?: string;
  'תאריך האירוע'?: number;
  'שם האירוע'?: string;
  'סוג אירוע'?: string;
  סטטוס?: string;
  'מקום לאירועים'?: string;
  משאבים?: string;
}

type UseEventImportProps = {
  onClose: () => void;
};

const useEventImport = ({ onClose }: UseEventImportProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [importedEvents, setImportedEvents] = useState<Partial<IEvent>[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const user = useMyUser();

  const transformToEvent = useCallback((data: EventData): Partial<IEvent> => {
    // Get event name from either English or Hebrew field
    const eventName = data.event_name || data['שם האירוע'] || 'Unnamed Event';

    // Get event type from either English or Hebrew field
    const eventType = data.event_type || data['סוג אירוע'] || '';

    // Convert Excel date number to JavaScript Date
    const excelDate = data.event_date || data['תאריך האירוע'];
    const dateAndTime = excelDate
      ? new Date(Math.round((excelDate - 25569) * 86400 * 1000))
      : undefined;

    // Get location from either English or Hebrew field
    const locationName = data.venue || data['מקום לאירועים'] || '';

    // Get resources from either English or Hebrew field
    const resources = data.resources || data['משאבים'] || '';

    // Get status from either English or Hebrew field
    // const status = data.status || data["סטטוס"] || 'new';

    // Map status to EventStatus enum values
    // const mappedStatus = status.toLowerCase() === 'closed' || status.toLowerCase() === 'סגור'
    //   ? 'completed'
    //   : status.toLowerCase() === 'potential' || status.toLowerCase() === 'פוטנציאלי'
    //     ? 'new'
    //     : 'active';

    return {
      name: eventName,
      type:
        eventTypes.find((type) =>
          eventType.toLowerCase().replaceAll(' ', '_').includes(type),
        ) || 'other',
      dateAndTime,
      location: locationName
        ? {
            label: locationName,
            value: {
              description: locationName,
              formatted_address: locationName,
              place_id: '',
              plus_code: '',
              geometry: {
                location: {
                  lat: 0,
                  lng: 0,
                },
              },
              types: [],
            },
          }
        : undefined,
      eventDetails: resources ? `Resources: ${resources}` : undefined,
    };
  }, []);

  const exportJsonFromExcel = useCallback(
    async (file: File): Promise<Partial<IEvent>[]> => {
      return importFromExcel<EventData, Partial<IEvent>>(
        file,
        transformToEvent,
        {
          setIsLoading,
          setError,
          setFileName,
          setImportedData: setImportedEvents,
        },
      );
    },
    [transformToEvent, setIsLoading, setError, setFileName, setImportedEvents],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) return;

      try {
        await exportJsonFromExcel(file);
      } catch (error) {
        console.error('Error processing Excel file:', error);
        toast({
          title: t('dialogs.import_data.errors.processing_excel_file'),
          status: 'error',
          variant: 'error',
        });
      }
    },
    [exportJsonFromExcel, toast, t],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls',
    multiple: false,
  });

  const createMultipleEvents = useCallback(async () => {
    console.log('Importing events:', importedEvents);
    try {
      const results = await Promise.all(
        importedEvents.map(async (event) => {
          try {
            const payload: any = {
              name: event.name,
              type: event.type || 'corporate',
              dateAndTime: event.dateAndTime,
              eventDetails: event.eventDetails,
              clients: event.clients,
            };
            return await dispatch(
              createEvent({
                ...payload,
                curator: user?.id,
              }),
            );
          } catch (error) {
            console.error(`Failed to create event: ${event.name}`, error);
            return { error };
          }
        }),
      );

      const errors = results.filter((result) => 'error' in result);
      if (errors.length > 0) {
        throw new Error(`Failed to create ${errors.length} events`);
      }
      toast({
        title: t('dialogs.import_data.event_import_success'),
        status: 'success',
        variant: 'main',
      });
      setFileName('');
      setImportedEvents([]);
      setError(null);
      onClose();
    } catch (error) {
      toast({
        title: t('dialogs.import_data.errors.event_import_error'),
        status: 'error',
        variant: 'error',
      });
    }
  }, [importedEvents, dispatch, user?.id, toast, t, onClose]);

  return {
    exportJsonFromExcel,
    isLoading,
    error,
    getRootProps,
    getInputProps,
    isDragActive,
    importedEvents,
    fileName,
    handleImport: createMultipleEvents,
  };
};

export default useEventImport;
