import { useQuery } from '@tanstack/react-query';
import {
  getActiveTasks,
  getCompletedTasks,
} from 'contexts/redux/task/taskSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useGetTasks } from 'queries/task';
import { useDispatch } from 'react-redux';

const useGeneralTaskHistory = () => {
  const dispatch = useDispatch<any>();
  const { appNavigate: navigate } = useAppNavigate();
  const { data: activeTasks } = useGetTasks({
    status: 'pending',
    sortBy: 'priority',
  });
  const { data: completedTasks } = useGetTasks({
    status: 'completed',
    sortBy: 'priority',
  });

  useQuery({
    queryKey: [`getEventActiveTasks-general`],
    queryFn: () => dispatch(getActiveTasks({ status: 'pending' })),
    enabled: true,
  });

  useQuery({
    queryKey: [`getEventCompletedTasks-general`],
    queryFn: () => dispatch(getCompletedTasks({ status: 'completed' })),
    enabled: true,
  });

  const goToTaskCreate = () => {
    navigate(`/main/tasks/create`);
  };

  return { activeTasks, completedTasks, goToTaskCreate };
};

export default useGeneralTaskHistory;
