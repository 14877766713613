import { Flex, Hide, Link, Text, useToast } from '@chakra-ui/react';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  AppDialogProps,
  setUploadQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { format } from 'date-fns';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Business, IQuote, QuoteStatus } from 'services/@types';
import QuoteListItemEventCard from './QuoteListItemEventCard';
import RenderQuoteStatus from './QuoteStatus';
import { QuoteThreeDotsMenu } from './QuoteThreeDotsMenu';
import { t } from 'i18next';
import UploadService from 'services/upload.api';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useGetSupplier } from 'queries/supplier';
import { useGetEntity } from 'queries/entity';

export default function QuoteListItem({
  quote,
  activeTab,
  fetchQuotes,
}: {
  quote: IQuote;
  activeTab: 'sent' | 'received' | 'templates';
  fetchQuotes: any;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { appNavigate: navigate } = useAppNavigate();
  const myBusiness = useMyBusiness();
  const toast = useToast();
  const totalPayment = useMemo(() => {
    const total =
      quote?.totalAmount || quote?.generalPaymentDetails?.totalPayment;
    if (!total) return '';
    const currency = quote?.currency === 'ILS' ? '₪' : '$';
    return `${currency} ${total.toLocaleString()}`;
  }, [
    quote?.totalAmount,
    quote?.generalPaymentDetails?.totalPayment,
    quote?.currency,
  ]);

  const { data: quoteSupplier } = useGetSupplier(quote?.supplierId);
  const { data: quoteBusiness } = useGetEntity<Business>(
    'business',
    quote?.businessId,
  );

  const customers = useMemo(() => {
    const customers = quote?.clients
      .map(
        (c) =>
          `${c?.client?.firstName || ''} ${
            c?.client?.lastName || c?.client?.company || ''
          }`,
      )
      .join(', ');
    return customers;
  }, [quote?.clients]);

  const supplier = useMemo(() => {
    const supplier = quoteSupplier?.name || quoteBusiness?.businessName || '';
    return supplier;
  }, [quoteSupplier, quoteBusiness]);

  const handleViewQuote = useCallback(() => {
    // if the quote is sent by the user, navigate to the quote for editing
    if (quote?.isManuallyUploaded) {
      dispatch(
        setUploadQuoteDialog({
          item: {
            quoteId: quote.id,
          },
        } as AppDialogProps),
      );
    } else {
      navigate(`/main/finance/quotes/preview/${quote.id}`);
    }
  }, [quote.totalAmount, quote.id, dispatch, navigate, myBusiness?.id]);

  const handleAppendixDownload = useCallback(
    async (e: any) => {
      e.stopPropagation();
      try {
        const mediaFiles = quote?.isManuallyUploaded
          ? quote.uploadedQuote
          : quote.media;
        if (mediaFiles[0].displayName.endsWith('.pdf')) {
          handleOpenPdf(mediaFiles[0].url, mediaFiles[0].displayName);
          return;
        }
        console.log('media: ', mediaFiles);
        const response = await UploadService.getUpload(
          mediaFiles[0].url,
          'original',
        );
        console.log('response', response);
        const imageData = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = imageData;
        a.download = mediaFiles[0].displayName;
        a.click();
        toast({
          title: t('quote_list.download_quote_attachment_success'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
      } catch (error) {
        console.error('handleDownloadMedia error: ', error);
      }
    },
    [quote, toast, t],
  );

  return (
    <Flex
      flexDirection="column"
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}>
      <Flex
        key={quote.id}
        justify="space-between"
        onClick={handleViewQuote}
        py={4}
        px={6}
        gap={2}
        fontSize="sm">
        <Hide below="md">
          <Flex
            flex="1"
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'flex-start', md: 'space-between' }}
            gap={2}
            fontWeight="500">
            <Flex flex={1} align="center">
              {quote?.createdAt && format(quote.createdAt, 'dd.MM.yy')}
            </Flex>
            <Flex flex={1} align="center">
              {activeTab === 'sent' ? customers : supplier}
            </Flex>
            <Flex
              flex={2}
              fontWeight={{ base: '700', md: '500' }}
              align="center"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <QuoteListItemEventCard
                eventId={quote?.eventId}
                quoteName={quote?.documentTitle}
              />
              {/* <EventCard
                        event={file.event}
                        onClick={() => {}}
                        isQuoteEvent
                        maxW={{ base: '100%', md: '320px' }}
                        flex="1"
                      /> */}
            </Flex>
            <Flex flex={1} align="center">
              {totalPayment}
            </Flex>
            <Flex flex={1} align="center">
              <RenderQuoteStatus
                status={quote.status as QuoteStatus}
                isSender={quote?.businessId === myBusiness?.id}
              />
            </Flex>
            <Flex flex={1} align="center">
              {quote?.quoteNumber || ''}
            </Flex>

            <Flex flex={1} w={{ base: 'auto', md: '100px' }} align="center">
              {(quote?.media?.[0]?.displayName ||
                quote?.uploadedQuote?.[0]?.displayName) && (
                <Text
                  noOfLines={1}
                  w="full"
                  overflow="hidden"
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={handleAppendixDownload}
                  color="#B59767">
                  {quote?.isManuallyUploaded
                    ? quote?.uploadedQuote?.[0]?.displayName
                    : quote?.media?.[0]?.displayName}
                </Text>
              )}
            </Flex>
            <Flex flexShrink={0} align="center" minW="80px" justify="center">
              {/* TODO: add version */}
              {1}
            </Flex>
            <Flex flex="0 0 60px" justify="center" align="center">
              <QuoteThreeDotsMenu
                id={quote.id}
                status={quote.status as QuoteStatus}
                media={quote?.media}
                businessId={quote?.businessId}
                activeTab={activeTab}
                quote={quote}
                fetchQuotes={fetchQuotes}
              />
            </Flex>
          </Flex>
        </Hide>
        <Hide above="md">
          <Flex
            flex="1"
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'flex-start', md: 'space-between' }}
            gap={2}
            fontWeight="500">
            <Flex flex={1} w={{ base: 'auto', md: '100px' }} align="center">
              {(quote?.media?.[0]?.displayName ||
                quote?.uploadedQuote?.[0]?.displayName) && (
                <Text
                  noOfLines={1}
                  w="full"
                  overflow="hidden"
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={handleAppendixDownload}
                  color="#B59767">
                  {quote?.isManuallyUploaded
                    ? quote?.uploadedQuote?.[0]?.displayName
                    : quote?.media?.[0]?.displayName}
                </Text>
              )}
            </Flex>
            <Flex flex={1} align="center">
              {totalPayment}
            </Flex>
            <Flex flex={1} align="center">
              {quote?.createdAt && format(quote.createdAt, 'dd.MM.yy')}
            </Flex>
            <Flex flex={1} align="center">
              {quote?.documentTitle || ''}
            </Flex>
          </Flex>
        </Hide>
        <Hide above="md">
          <Flex
            flex="0 1 100px"
            justify={{ base: 'space-between', md: 'flex-start' }}
            flexDirection="column">
            <Flex justify="flex-end">
              <QuoteThreeDotsMenu
                id={quote.id}
                status={quote.status as QuoteStatus}
                media={quote?.media}
                businessId={quote?.businessId}
                activeTab={activeTab}
                quote={quote}
                fetchQuotes={fetchQuotes}
              />
            </Flex>
            <Flex justify="flex-end" align="center">
              <RenderQuoteStatus
                status={quote.status as QuoteStatus}
                isSender={quote?.businessId === myBusiness?.id}
              />
            </Flex>
            {/* <Flex>
              {quote?.createdAt && format(quote.createdAt, 'dd MMM, yyyy')}
            </Flex> */}
          </Flex>
        </Hide>
      </Flex>
      <Hide below="md">
        {activeTab === 'received' && (
          <Flex px={6} pb={2} fontSize="14px">
            <Text color="#999EA9">{t(`quote_list.description`)}: </Text>
            <Text px={1}>{quote?.comment || quote?.description || ''}</Text>
          </Flex>
        )}
      </Hide>
    </Flex>
  );
}
