import {
  Checkbox,
  Flex,
  Hide,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { IInvoice } from 'services/@types';
import { currency } from 'variables/currency';
import Product from './Product';

interface IProductsProps {
  products: IInvoice['products'];
  handleSelectedProduct: (e: any, index: number) => void;
  currencyIcon: string;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
  error: {
    products: boolean;
    totalPayment: boolean;
  };
  handleChangeProductDescription: (e: any, index: number) => void;
  handleChangeProductAmount: (e: any, index: number) => void;
  handleChangeProductPrice: (e: any, index: number) => void;
  handleRemoveProduct: (index: number) => void;
  handleAddProduct: () => void;
  updateProductByCurrencyChange: (
    productData: any,
    productIndex: number,
  ) => void;
  invoice: Partial<IInvoice>;
  handleChange: (path: string, value: any) => void;
  isAllProductsVatIncluded: boolean;
  handleChangeAllProductsVatIncluded: (e: any) => void;
}

export default function Products(props: IProductsProps) {
  const { t } = useTranslation();

  const {
    products,
    handleSelectedProduct,
    currencyIcon,
    handleChangeProductVatIncluded,
    handleChangeProductDescription,
    error,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    handleAddProduct,
    updateProductByCurrencyChange,
    invoice,
    handleChange,
    isAllProductsVatIncluded,
    handleChangeAllProductsVatIncluded,
  } = props;

  const { data: businessPreferences } = useGetBusinessPreferences();

  const selectedCurrency = useMemo(() => {
    const selected = currency.find((c) => c.value === invoice?.currency);
    return selected ? [selected] : [''];
  }, [invoice?.currency]);

  const showedOptions = useMemo(() => {
    const selected = currency.find((c) => c.value === invoice?.currency);
    return selected ? t(selected.label) + ' - ' + selected.symbol : '';
  }, [invoice?.currency, t]);

  return (
    <Flex flexDirection="column" gap="12px">
      <Text variant="lineUpFormDialogFileName">
        {t('create_invoice.items')}
      </Text>
      <Hide below="md">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          gap={4}
          width="100%">
          <DropdownField
            closeOnSelect
            showedOptions={showedOptions}
            label={t('create_quote.currency')}
            options={currency}
            placeholder={t('create_quote.currency')}
            selectedOptions={selectedCurrency}
            onSelected={(e: any) => {
              handleChange('currency', e.value);
            }}
            menuButton={{
              w: {
                base: '100%',
                md: '239px',
              },
              bg: '#FFFFFF',
            }}
          />
          <VStack alignItems="start" width="144px">
            <Text variant="createQuoteCheckboxText">
              {t('create_quote.vat')}
            </Text>
            <Checkbox
              marginTop={'0px'}
              variant="roundBrand"
              isChecked={isAllProductsVatIncluded}
              onChange={handleChangeAllProductsVatIncluded}
              h="50px"
              alignItems="center">
              <Text variant="createQuoteCheckboxText">
                {t('quote_preview.vat', {
                  percentage: businessPreferences?.vatPercent,
                })}
              </Text>
            </Checkbox>
          </VStack>
        </Flex>
      </Hide>
      <Flex flexDirection="column" gap="12px">
        {products?.map((product, index) => (
          <Product
            product={product}
            index={index}
            handleSelectedProduct={handleSelectedProduct}
            currencyIcon={currencyIcon}
            key={index}
            error={error}
            handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            handleChangeProductDescription={handleChangeProductDescription}
            handleChangeProductAmount={handleChangeProductAmount}
            handleChangeProductPrice={handleChangeProductPrice}
            handleRemoveProduct={handleRemoveProduct}
            updateProductByCurrencyChange={updateProductByCurrencyChange}
          />
        ))}
      </Flex>
      <HStack>
        <IconButton
          borderRadius="10px"
          icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
          onClick={handleAddProduct}
          aria-label="add"
          w="33px"
          h="33px"
          minW="33px"
          bg="#93C3B3"
        />
        <Text variant="addRowText">{t('create_invoice.add_row')}</Text>
      </HStack>
    </Flex>
  );
}
