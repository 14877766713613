import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  AppDialogProps,
  closeImportDataDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Contacts from './Contacts';
import Events from './Events';

const ImportDataDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const { importDataDialog } = useSelector((state: RootState) => ({
    importDataDialog: state.dialogs.importDataDialog,
  }));
  const dialog = importDataDialog as AppDialogProps;

  const handleClose = useCallback(() => {
    dispatch(closeImportDataDialog());
  }, [dispatch]);

  useEffect(() => {
    setTabIndex(dialog?.item?.activeTab || 0);
    dialog?.item?.activeTab === 1 ? setTabIndex(1) : setTabIndex(0);
  }, [dialog?.item?.activeTab]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle" fontSize={'md'} fontWeight="600">
                {t('dialogs.import_data.title')}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <AnimatePresence mode="wait">
              {tabIndex === 0 && (
                <motion.div
                  key="events"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{
                    type: 'spring',
                    stiffness: 200,
                    damping: 25,
                  }}>
                  <Events onClose={handleClose} />
                </motion.div>
              )}
              {tabIndex === 1 && (
                <motion.div
                  key="contacts"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{
                    type: 'spring',
                    stiffness: 200,
                    damping: 25,
                  }}>
                  <Contacts onClose={handleClose} />
                </motion.div>
              )}
            </AnimatePresence>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImportDataDialog;
