import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import _notificationService from 'services/notification.api';

interface UnreadNotificationsCount {
  count: number;
}

interface UseGetUnreadNotificationsCountOptions {
  enabled?: boolean;
}

export const getUnreadNotificationsCountQueryKey = (): QueryKey => [
  'unreadNotificationsCount',
];

const getUnreadNotificationsCountQueryOptions = () => ({
  queryKey: getUnreadNotificationsCountQueryKey(),
  queryFn: () => _notificationService.getUnreadNotifications(),
  staleTime: Infinity, // make it unstale
});

const useGetUnreadNotificationsCount = (
  options: UseGetUnreadNotificationsCountOptions = {},
): UseQueryResult<UnreadNotificationsCount, Error> => {
  return useQuery({
    ...getUnreadNotificationsCountQueryOptions(),
  });
};

useGetUnreadNotificationsCount.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  await queryClient.prefetchQuery(getUnreadNotificationsCountQueryOptions());
};

useGetUnreadNotificationsCount.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  queryClient.invalidateQueries({
    queryKey: getUnreadNotificationsCountQueryKey(),
  });
};

interface UseGetNotificationsOptions {
  enabled?: boolean;
  readAt?: boolean;
  limit?: number;
  offset?: number;
  businessId?: string;
  updatedAt?: boolean;
}

export const getNotificationsQueryKey = (readAt?: boolean): QueryKey => [
  'notifications',
  { readAt },
];

export const getNotificationsQueryOptions = (
  options: UseGetNotificationsOptions = {},
) => ({
  queryKey: getNotificationsQueryKey(options.readAt),
  queryFn: () =>
    _notificationService.getNotifications({
      readAt: options.readAt,
      limit: options.limit,
      offset: options.offset,
      sortBy: 'createdAt:desc',
      projectBy: options.updatedAt ? '-updatedAt' : '-createdAt',
    }),
  staleTime: 1000 * 60 * 5, // 5 minutes
});

const useGetNotifications = (options: UseGetNotificationsOptions = {}) => {
  return useQuery({
    ...getNotificationsQueryOptions(options),
    enabled: options.enabled !== false,
  });
};

useGetNotifications.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  options: UseGetNotificationsOptions = {},
) => {
  await queryClient.prefetchQuery(getNotificationsQueryOptions(options));
};

useGetNotifications.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  options: UseGetNotificationsOptions = {},
) => {
  queryClient.invalidateQueries({
    queryKey: getNotificationsQueryKey(options.readAt),
  });
};

// Specific hook for unread notifications
export const useGetUnreadNotifications = (
  options: Omit<UseGetNotificationsOptions, 'readAt'> = {},
) => {
  return useGetNotifications({ ...options, readAt: false });
};

// Specific hook for read notifications
export const useGetReadNotifications = (
  options: Omit<UseGetNotificationsOptions, 'readAt'> = {},
) => {
  return useGetNotifications({ ...options, readAt: true });
};
// Hook for marking a notification as read
export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: string) =>
      _notificationService.markNotificationAsRead(notificationId),
    onSuccess: () => {
      // Invalidate both read and unread notifications queries to refresh the data
      queryClient.invalidateQueries({
        queryKey: getNotificationsQueryKey(true),
      });
      queryClient.invalidateQueries({
        queryKey: getNotificationsQueryKey(false),
      });
      // Also invalidate the unread count query
      queryClient.invalidateQueries({
        queryKey: getUnreadNotificationsCountQueryKey(),
      });
    },
  });
};

export default useGetUnreadNotificationsCount;
