import { Checkbox, MenuList } from '@chakra-ui/react';

import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IQuote } from 'services/@types';

export const ProductVatCheckbox = memo(
  ({
    product,
    index,
    setNewQuote,
    handleChangeProductVatIncluded,
    dir,
    language,
  }: {
    product: any;
    index: number;
    setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
    handleChangeProductVatIncluded: (e: any, index: number) => void;
    dir: string;
    language: string;
  }) => {
    const { data: businessPreferences } = useGetBusinessPreferences();
    const { t } = useTranslation('', {
      lng: language,
    });
    return (
      <Flex
        alignItems="center"
        gap="4px"
        w={{ base: '100%', md: 'fit-content' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}>
        {/* <Checkbox
          variant="roundBrand"
          isChecked={product?.isVatIncluded}
          onChange={(e) => handleChangeProductVatIncluded(e, index)}
          h="50px"
          dir={dir}
          alignItems="center">
          <Text variant="createQuoteCheckboxText">
            {t('quote_preview.vat', {
              percentage: businessPreferences?.vatPercent,
            })}
          </Text>
        </Checkbox> */}
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label=""
            icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
            variant="ghost"
            h="50px"
            dir={dir}
            lang={language}
          />
          <MenuList>
            <MenuItem
              onClick={() => {
                setNewQuote((prev) => ({
                  ...prev,
                  products: prev.products.filter((p, key) => key !== index),
                }));
              }}>
              {t('create_quote.remove_product')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    );
  },
);
