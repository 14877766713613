import { useDisclosure } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchClients,
  setCurrentClient,
  setEditClient,
} from 'contexts/redux/client/clientSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IClient, IClientContact } from 'services/@types';

export default function useClients({
  setClient,
}: {
  setClient?: (v: { client: IClient; contacts: IClientContact[] }) => void;
}) {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<string>('');
  const queryClient = useQueryClient();
  const { clients, loading, editClient } = useSelector((state: RootState) => ({
    clients: state.clients.clients,
    loading: state.clients.loading,
    editClient: state.clients.editClient,
  }));
  const {
    isOpen: isAddClientOpen,
    onOpen: onOpenAddClient,
    onClose: onAddClientClosed,
  } = useDisclosure();

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      queryClient.invalidateQueries({ queryKey: ['clients', e.target.value] });
    },
    [queryClient],
  );

  const handleCloseClientModal = useCallback(
    async (params?: {
      newClientData: IClient;
      contacts?: IClientContact[] | null;
    }) => {
      if (params?.newClientData && setClient) {
        await dispatch(fetchClients({ limit: 1000 }));
        setClient({
          client: params.newClientData,
          contacts: params.newClientData.contacts || [],
        });
      }
      dispatch(setEditClient(null));
      onAddClientClosed();
    },
    [dispatch, onAddClientClosed, setClient],
  );

  const handleOpenAddClient = useCallback(() => {
    dispatch(setEditClient(null));
    onOpenAddClient();
  }, [dispatch, onOpenAddClient]);

  useQuery({
    queryKey: ['clients', search],
    queryFn: () => {
      const params: { page: number; limit: number; name?: string } = {
        page: 1,
        limit: 1000,
      };

      if (search.length > 0) {
        params.name = search;
        // reset current client
        dispatch(setCurrentClient(null));
      }

      return dispatch(fetchClients(params));
    },
  });

  // open modal for edit client
  useEffect(() => {
    if (editClient) {
      onOpenAddClient();
    }
  }, [editClient, onOpenAddClient]);

  return {
    clients,
    isAddClientOpen,
    onOpenAddClient: handleOpenAddClient,
    onAddClientClosed: handleCloseClientModal,
    search,
    handleSearch,
    loading,
  };
}
