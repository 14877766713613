import { AxiosInstance, AxiosResponse } from 'axios';
import {
  PaginatedResults,
  IPayment,
  IPaymentUpdate,
  IPaymentCreateData,
} from './@types';
import { createAxiosInstance } from './axios.util';

class PaymentService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createPayment(
    payment: Partial<IPaymentCreateData & { id?: string }>,
  ): Promise<IPayment> {
    delete payment.id;
    const response: AxiosResponse<IPayment> = await this.api.post(
      '/payments',
      payment,
    );
    return response.data;
  }

  public async getPayments(
    queryParams: Record<string, any> = { limit: 100 },
  ): Promise<PaginatedResults<IPayment>> {
    const response: AxiosResponse<PaginatedResults<IPayment>> =
      await this.api.get('/payments', {
        params: queryParams,
      });
    return response.data;
  }

  public async getPayment(paymentId: string): Promise<IPayment> {
    const response: AxiosResponse<IPayment> = await this.api.get(
      `/payments/${paymentId}`,
    );
    return response.data;
  }

  public async updatePayment(
    paymentId: string,
    updates: IPaymentUpdate,
  ): Promise<IPayment> {
    delete updates.eventId;
    delete updates.sentEmailAt;
    const response: AxiosResponse<IPayment> = await this.api.patch(
      `/payments/${paymentId}`,
      updates,
    );
    return response.data;
  }

  public async deletePayment(paymentId: string): Promise<void> {
    await this.api.delete(`/payments/${paymentId}`);
  }
  public async getExpenses(
    queryParams?: Record<string, any>,
  ): Promise<IPayment[]> {
    const response: AxiosResponse<IPayment[]> = await this.api.get(
      `/payments/expenses`,
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getIncomes(
    queryParams?: Record<string, any>,
  ): Promise<IPayment[]> {
    const response: AxiosResponse<IPayment[]> = await this.api.get(
      `/payments/incomes`,
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getPaymentsOverviewReports(
    queryParams?: Record<string, any>,
  ): Promise<{
    payments: IPayment[];
    totalPaidAmount: number;
    numberOfEvents: number;
  }> {
    const response: AxiosResponse<{
      payments: IPayment[];
      totalPaidAmount: number;
      numberOfEvents: number;
    }> = await this.api.get(`/payments/overview/reports`, {
      params: queryParams,
    });
    return response.data;
  }
}
const _paymentService = new PaymentService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _paymentService;
