import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import {
  AppDialogProps,
  closeSelectClientDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { fetchSuppliers } from 'contexts/redux/supplier/supplierSlice';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChosenClient from './ChosenClient';

const trimAndLowerCase = (str: string) => str.toLowerCase().trim();

function SelectClientDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const { selectClientDialog, clients } = useSelector((state: RootState) => ({
    selectClientDialog: state.dialogs.selectClientDialog,
    clients: state.clients.clients,
  }));
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 700);

  const filteredClients = useMemo(() => {
    if (!debouncedSearch) return clients;
    return clients.filter((c) =>
      trimAndLowerCase(
        `${c.firstName}${c.email}${c.address || ''}${c.businessID || ''}`,
      ).includes(debouncedSearch.toLowerCase().trim()),
    );
  }, [clients, debouncedSearch]);

  const dialog = selectClientDialog as AppDialogProps;

  const handleClose = useCallback(() => {
    dispatch(closeSelectClientDialog());
    setSelectedClients([]);
  }, [dispatch]);

  const handleSubmitSuppliersSelection = useCallback(() => {
    if (dialog?.onConfirm) {
      dialog.onConfirm(clients.filter((s) => selectedClients.includes(s.id)));
    }
    handleClose();
  }, [handleClose, dialog, clients, selectedClients]);

  const handleSelectClient = useCallback(
    (clientId: string) => {
      if (selectedClients.includes(clientId)) {
        setSelectedClients((prev) => prev.filter((id) => id !== clientId));
      } else {
        setSelectedClients((prev) => [...prev, clientId]);
      }
    },
    [selectedClients],
  );

  useEffect(() => {
    const getParticipants = async () => {
      if (!!dialog) {
        await dispatch(fetchSuppliers({}));
        await dispatch(fetchClients({ limit: 1000 }));
      }
    };

    getParticipants();
  }, [dispatch, dialog]);

  useEffect(() => {
    if (dialog?.item?.chosenClients) {
      setSelectedClients(dialog.item.chosenClients.map((c: any) => c.id));
    }
  }, [clients, dialog?.item]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'sm'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        minH={{ base: '100%', md: '50dvh' }}>
        <ModalBody
          p="24px 16px"
          w="full"
          h="100%"
          display="flex"
          flexDir="column">
          <VStack w="100%" gap={4} spacing={0} h={'full'}>
            <HStack w="100%" justifyContent="space-between" spacing={0}>
              <Text
                variant="cardSectionTitle"
                fontSize={'base'}
                fontWeight={700}
                color="#141718">
                {t('dialogs.select_client.title')}
              </Text>
              <ModalCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                justifyContent={'center'}
                alignItems="center"
                display={'flex'}
                boxShadow="none !important"
              />
            </HStack>
            <SearchBar
              value={search}
              placeholder={t('dialogs.select_client.search')}
              margin={'0 !important'}
              inputProps={{
                onChange: (e: any) => setSearch(e.target.value),
              }}
            />
            <VStack
              w="100%"
              maxH="500px"
              overflowY="auto"
              gap={2}
              display="flex"
              flexDirection="column"
              h="100%"
              flex={1}>
              {filteredClients.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.select_client.no_clients')}
                </Text>
              )}
              {filteredClients.map((client) => (
                <ChosenClient
                  key={client.id}
                  client={client}
                  wrapperProps={{
                    w: 'full',
                    borderColor: selectedClients.includes(client.id)
                      ? '#939393'
                      : '#E9E3FF',
                  }}
                  onRemove={
                    selectedClients.includes(client.id)
                      ? () => handleSelectClient(client.id)
                      : undefined
                  }
                  onClick={() => handleSelectClient(client.id)}
                />
              ))}
            </VStack>
          </VStack>
          <Spacer />
          <Button
            variant="h1cta"
            w="full"
            onClick={handleSubmitSuppliersSelection}>
            {t('dialogs.select_client.add')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SelectClientDialog;
