import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import QuoteDeadlineIcon from 'assets/svg/notification/quote-deadline-icon.svg';
import SupplierDetailsRequestIcon from 'assets/svg/notification/supplier-details-request-icon.svg';
import { createClient } from 'contexts/redux/client/clientSlice';
import {
  setCreateContractPreviewDialog,
  setExternalSupplierDialog,
  setNotificationsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  fetchNotifications,
  updateNotification,
} from 'contexts/redux/notifications/notificationsSlice';
import { setNewQuoteProp } from 'contexts/redux/quote/quoteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import 'moment/locale/he'; // Add Hebrew locale support
import { useGetEntity } from 'queries/entity';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { INotification, IQuote } from 'services/@types';
import { AppNotificationEventName } from 'services/@types/notificationEventNames';
import _userService from 'services/user.api';
import { getNotificationMomentFormat } from './NotificationComponent';
export default function PlainNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();

  const { appNavigate: navigate } = useAppNavigate();
  const clients = useSelector((state: RootState) => state.clients.clients);
  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  const { data: entity } = useGetEntity(
    notification?.type,
    notification?.entityId,
  );

  const icon = useMemo(() => {
    if (notification?.type === 'quote') {
      if (
        notification?.notificationEventName ===
        AppNotificationEventName.QuoteDeadlineReminder
      ) {
        return QuoteDeadlineIcon;
      }
      return null;
    }
    if (
      [
        AppNotificationEventName.SupplierDetailsRequest,
        AppNotificationEventName.QuoteRejected,
      ].includes(notification?.notificationEventName)
    ) {
      return SupplierDetailsRequestIcon;
    }
    return null;
  }, [notification?.notificationEventName, notification?.type]);

  const titleMessage = useMemo(() => {
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.SupplierDetailsRequest
    ) {
      return t(notification.title, {
        ...notification?.variables,
        ...{
          name: notification?.variables?.name,
        },
      });
    }
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteRequest
    ) {
      return notification.variables.serviceDescription;
    }
    return '';
  }, [
    notification?.notificationEventName,
    notification.title,
    notification?.variables,
    t,
  ]);

  const message = useMemo(() => {
    return t(notification.message, {
      ...notification?.variables,
      ...{
        date: moment(notification?.variables?.date).format('D MMM, YYYY'),
      },
    });
  }, [notification.message, notification?.variables, t]);

  const button = useMemo(() => {
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteApproved
    ) {
      return {
        text: t('notifications.quote_approved.button'),
        handler: () => {
          // navigate(`/main/finance/quotes/preview/${notification?.entityId}`);
          dispatch(
            setCreateContractPreviewDialog({
              item: {
                quoteId: notification?.entityId,
                eventId: (entity as IQuote)?.eventId,
              },
            }),
          );
        },
      };
    }

    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteRejected
    ) {
      return {
        text: t('notifications.quote_declined.button'),
        handler: () => {
          navigate(`/main/finance/quotes/preview/${notification?.entityId}`);
        },
      };
    }

    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteSigned
    ) {
      return {
        text: t('notifications.quote_signed.button'),
      };
    }
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteCreated
    ) {
      return {
        text: t('notifications.quote_created.button'),
        handler: () => {
          navigate(
            `/main/invite-quote?token=${notification?.variables?.token}`,
          );
        },
      };
    }

    if (
      notification?.notificationEventName ===
      AppNotificationEventName?.SupplierDetailsRequest
    ) {
      return {
        text: t('notifications.supplier_details_request.view_add'),
        handler: () => {
          dispatch(
            setExternalSupplierDialog({
              item: {
                supplierId: notification?.variables?.supplierId,
              },
              onConfirm: async () => {
                await dispatch(
                  updateNotification({
                    notificationId: notification?.id,
                    updates: {
                      visible: false,
                    },
                  }),
                );
                dispatch(
                  fetchNotifications({
                    limit: 1000,
                    sortBy: 'createdAt:desc',
                  }),
                );
              },
            }),
          );
        },
      };
    }
    if (
      notification.type === 'quote' &&
      notification.notificationEventName ===
        AppNotificationEventName.QuoteRequest
    ) {
      return {
        text: t('notifications.quote_request.button'),
        handler: async () => {
          navigate(`/main/finance/quotes/create`);
          // check if the user id is any of the existing clients
          const user = await _userService.getUser(
            notification?.variables?.userId,
          );
          const existingClient = clients.find(
            (client) => client.businessID === user?.businessID,
          );
          dispatch(
            setNewQuoteProp({
              key: 'description',
              value: notification?.variables?.description,
            }),
          );
          if (existingClient) {
            dispatch(
              setNewQuoteProp({
                key: 'clientId',
                value: existingClient.id,
              }),
            );
          } else {
            // create a new client...
            const { payload } = await dispatch(
              createClient({
                client: {
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  email: user?.email,
                  phone: user?.phone,
                  type: 'private',
                  businessID: user?.businessID,
                },
              }),
            );
            console.log('created #client payload', payload);
            dispatch(
              setNewQuoteProp({
                key: 'clientId',
                value: (payload as any).id,
              }),
            );
          }
          if (notification?.variables?.eventId) {
            dispatch(
              setNewQuoteProp({
                key: 'eventId',
                value: notification?.variables?.eventId,
              }),
            );
          } else if (notification?.variables?.dateAndTime) {
            dispatch(
              setNewQuoteProp({
                key: 'eventDate',
                value: new Date(notification.variables?.dateAndTime),
              }),
            );
          }
        },
      };
    }
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.EventAddSupplier
    ) {
      return {
        text: t('notifications.event_add_supplier.button_text'),
        handler: () => {
          navigate(`/main/event/${notification?.variables?.eventId}`);
        },
      };
    }
    return null;
  }, [
    notification.notificationEventName,
    notification.type,
    notification?.entityId,
    notification.variables?.eventId,
    notification.variables?.token,
    notification.variables?.supplierId,
    notification.variables?.userId,
    notification.variables?.dateAndTime,
    notification?.id,
    t,
    dispatch,
    navigate,
    clients,
  ]);

  const onButtonClicked = useCallback(() => {
    if (button?.handler) {
      button.handler();
      dispatch(setNotificationsDialog(false));
    }
  }, [button, dispatch]);

  const description = useMemo(() => {
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteRequest
    ) {
      return notification.variables.description;
    }
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.QuoteRejected
    ) {
      return notification.variables.comment;
    }
    return '';
  }, [notification?.notificationEventName, notification?.variables]);
  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <VStack
        border="1px solid #F9FAFB"
        p="8px"
        borderRadius={'8px'}
        bgColor={'#93C3B31A'}
        spacing={0}>
        <HStack alignItems={'center'} gap="12px">
          {icon && <Image src={icon} alt="" w="24px" h="24px" />}
          <VStack spacing={0} alignItems={'flex-start'} gap={3}>
            <VStack spacing={0}>
              {titleMessage && (
                <>
                  <Text
                    variant="cardSubTitle"
                    color="#1B2037"
                    lineHeight={'20px'}>
                    {titleMessage}
                  </Text>
                  <br />
                </>
              )}
              <Text variant="cardSubTitle" color="#1B2037" lineHeight={'20px'}>
                {message}
              </Text>
              {description && (
                <HStack justify={'space-between'} w="full">
                  <Icon as={RiDoubleQuotesR} color="brand.900" />
                  <Text
                    w="full"
                    textAlign={'center'}
                    fontWeight={500}
                    pt={2}
                    color="brand.300"
                    lineHeight={'20px'}>
                    {description}
                  </Text>
                </HStack>
              )}
            </VStack>
            {button && (
              <Button variant="h1cta" onClick={onButtonClicked} w="full">
                {button.text}
              </Button>
            )}
          </VStack>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification?.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
