import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ICreateDesign, GetDesignsParams, UpdateDesign } from 'services/@types';
import _designService from 'services/design.api';

// Query keys
export const designKeys = {
  all: ['designs'] as const,
  lists: () => [...designKeys.all, 'list'] as const,
  list: (filters: GetDesignsParams) =>
    [...designKeys.lists(), filters] as const,
  details: () => [...designKeys.all, 'detail'] as const,
  detail: (id: string) => [...designKeys.details(), id] as const,
};

// Get Designs
export const useGetDesigns = (params?: GetDesignsParams) => {
  return useQuery({
    queryKey: designKeys.list(params || {}),
    queryFn: () => _designService.getDesigns(params),
  });
};

// Get Single Design
export const useGetDesign = (designId: string) => {
  return useQuery({
    queryKey: designKeys.detail(designId),
    queryFn: () => _designService.getDesign(designId),
    enabled: !!designId,
  });
};

// Create Design
export const useCreateDesign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newDesign: ICreateDesign) =>
      _designService.createDesign(newDesign),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: designKeys.lists() });
    },
  });
};

interface UpdateDesignParams {
  designId: string;
  updates: UpdateDesign;
}

// Update Design
export const useUpdateDesign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ designId, updates }: UpdateDesignParams) =>
      _designService.updateDesign(designId, updates),
    onSuccess: (_, { designId }) => {
      queryClient.invalidateQueries({
        queryKey: designKeys.detail(designId),
      });
      queryClient.invalidateQueries({ queryKey: designKeys.lists() });
    },
  });
};

// Delete Design
export const useDeleteDesign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (designId: string) => _designService.deleteDesign(designId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: designKeys.lists() });
    },
  });
};
