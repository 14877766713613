import {
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import NoClientsImg from 'assets/img/quote/NoQuotesCreated.png';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';

import { FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import { MdFileUpload } from 'react-icons/md';
interface NoClientsProps {
  onOpenAddClient: () => void;
  onOpenImportDialog: () => void;
}
export default function NoClients(props: NoClientsProps) {
  const { t } = useTranslation();
  const { onOpenAddClient, onOpenImportDialog } = props;
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      gap="4"
      flexDirection={{ base: 'column', md: 'row' }}>
      <Card
        flex="1"
        h="552px"
        borderRadius={{ base: '0px', md: '30px' }}
        minH="552px">
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="previewQuoteContactTitle">
            {t('clients.clients_list')}
          </Text>
          <Flex alignItems="center" gap="2">
            <Tooltip label={t('clients.import_clients')} placement="top">
              <IconButton
                borderRadius="10px"
                icon={<Icon as={MdFileUpload} color="white" w="24px" h="24px" />}
                onClick={onOpenImportDialog}
                aria-label="import"
                w="32px"
                h="32px"
                minW="32px"
                bg="#6B9AE3"
              />
            </Tooltip>
            <IconButton
              borderRadius="10px"
              icon={<Icon as={FiPlus} color="white" w="29px" h="29px" />}
              onClick={onOpenAddClient}
              aria-label="add"
              w="32px"
              h="32px"
              minW="32px"
              bg="#93C3B3"
            />
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          h="100%"
          w="100%"
          textAlign="center">
          <Flex flexDirection="column" gap="24px" alignItems="center">
            <Image p="22px" h="240px" src={NoClientsImg} alt="" />
            <Text variant="noClientsDescription">
              {t('clients.no_clients')}
            </Text>
            <Button variant="h1cta" w="320px" onClick={onOpenAddClient}>
              {t('clients.add_new_client')}
            </Button>
            <Button variant="outline" w="320px" onClick={onOpenImportDialog}>
              {t('clients.import_clients')}
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card
        flex="1"
        h="552px"
        minH="552px"
        borderRadius={{ base: '0px', md: '30px' }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="previewQuoteContactTitle">
            {t('clients.client_detail')}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label=""
              icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
              variant="ghost"
              w="32px"
              h="32px"
              minW="32px"
            />
            <MenuList>
              <MenuItem></MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          h="100%"
          minH="100%"
          w="100%">
          <Flex
            flexDirection="column"
            gap="24px"
            alignItems="center"
            textAlign="center"
            maxW="321px">
            <Image p="20px" w="240px" h="240px" src={NoEventsImg} alt="" />
            <Text variant="noClientsDescription">
              {t('clients.no_clients_description')}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
