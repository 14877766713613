import { useDisclosure, useToast } from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import {
  createContract,
  fetchContract,
  setContractPreview,
  updateContract,
} from 'contexts/redux/contract/contractSlice';
import {
  setContactsPickerDialog,
  setEventsDialog,
  setQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { getEvent } from 'contexts/redux/event/eventSlice';
import { getQuotes, setSelectedQuote } from 'contexts/redux/quote/quoteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  IClient,
  IClientContact,
  IContract,
  IEvent,
  IQuote,
  Upload,
  UrlItem,
} from 'services/@types';

import UploadService from 'services/upload.api';
import { isValidEmail } from 'utils/isValidEmail';
import { currency } from 'variables/currency';
import { languages } from 'variables/languages';

const initialRecipient: IContract['recipients'][0] = {
  firstName: '',
  lastName: '',
};

export default function useContractForm() {
  const params = useParams();
  const toast = useToast();
  const { appNavigate: navigate } = useAppNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const {
    onOpen: onOpenSaveAsTemplate,
    onClose: onCloseSaveAsTemplate,
    isOpen: isOpenSaveAsTemplate,
  } = useDisclosure();
  const myBusiness = useMyBusiness();
  const myUser = useMyUser();

  const { qoutes, loading, selectedQuote, contractPreview } = useSelector(
    (state: RootState) => ({
      qoutes: state.quote.qoutes,
      loading: state.contracts.loading,
      selectedQuote: state.quote.selectedQuote,
      contractPreview: state.contracts.contractPreview,
    }),
  );
  const [currentSentTo, setCurrentSentTo] = useState<string>('');
  // auto complete logic for multiple sentTo
  // const [displayingSentTo, setDisplayingSentTo] = useState<
  //   {
  //     avatar: UrlItem | undefined;
  //     email: string;
  //     label: string;
  //     value: string;
  //   }[]
  // >([]);
  const [event, setEvent] = useState<IEvent>(null);
  const [contract, setContract] = useState<IContract>({
    contractDate: new Date(),
    recipients: [{}],
    clients: [],
    paragraphs: [],
  });
  const [radioValue, setRadioValue] = useState<'assign' | 'attach'>('assign');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    const selected = languages.find((l) => l.value === contract?.language);
    return selected ? [selected] : [];
  }, [contract?.language]);

  const currentEventType = useMemo(
    () => contract?.eventType || '',
    [contract?.eventType],
  );

  const showedEventType = useMemo(
    () => (currentEventType ? t(`event_types.${currentEventType}`) : ''),
    [currentEventType, t],
  );

  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );

  const showedLanguage: string = useMemo(() => {
    const selected = languages.find((l) => l.value === contract?.language);
    return selected ? t(selected.label) : '';
  }, [contract?.language, t]);

  const displayedQoutes = useMemo(() => {
    return qoutes.map((qoute) => {
      let label = '';
      const values = [
        qoute?.quoteNumber,
        qoute?.eventName,
        ...qoute?.clients?.map(
          (c) => `${c?.client?.firstName || ''} ${c?.client?.lastName || ''}`,
        ),
      ];
      for (const key in values) {
        if (!!values[key]) {
          label += `${values[key]} `;
        }
      }
      return {
        value: qoute.id,
        label,
      };
    });
  }, [qoutes]);

  const currencySymbol = useMemo(() => {
    const selected = currency.find((c) => c.value === contract?.currency);
    return selected ? selected.symbol : '';
  }, [contract?.currency]);

  const userId = useMemo(() => myUser?.id, [myUser?.id]);

  const handleChange = useCallback((path: string, value: any) => {
    setContract((prev) => ({ ...prev, [path]: value }));
  }, []);

  const handleSelectedQuote = useCallback(
    async (id: string) => {
      const quote = qoutes.find((q) => q.id === id);

      if (quote) {
        dispatch(setSelectedQuote(quote));
        setContract((prev) => ({
          ...prev,
          quoteId: quote.id,
          currency: quote?.currency || '',
        }));
      }
    },
    [dispatch, qoutes],
  );

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('eventType', '');
        return;
      }
      handleChange('eventType', formattedSelected);
    },
    [selectedEventType, handleChange],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (event: IEvent) => {
          setEvent(event);
          setContract((prev) => ({
            ...prev,
            eventId: event.id,
            eventType: event.type,
            clients: event.clients,
            recipients: event.clients.map((c: any) => ({
              firstName: c.client.firstName,
              lastName: c.client.lastName,
              idNumber: c.client.idNumber,
            })),
            sentTo: event.clients?.[0]?.client?.email,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleOpenClientDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: contract?.clients || [],
          type: 'clients',
        },
        onConfirm: (
          selection: { client: IClient; contacts: IClientContact[] }[],
        ) => {
          setContract((prev) => ({
            ...prev,
            clients: selection,
          }));
          if (contract?.sentTo !== selection?.[0]?.client?.email) {
            setContract((prev) => ({
              ...prev,
              sentTo: selection?.[0]?.client?.email,
            }));
          }
        },
      }),
    );
  }, [dispatch, contract?.clients, contract?.sentTo]);

  const handleOpenQuoteDialog = useCallback(() => {
    dispatch(
      setQuoteDialog({
        onConfirm: (quote: IQuote) => {
          handleSelectedQuote(quote.id);
        },
      }),
    );
  }, [dispatch, handleSelectedQuote]);

  const setClient = useCallback(
    (client: { client: IClient; contacts: IClientContact[] }) => {
      setContract((prev) => ({
        ...prev,
        clients: [...(prev?.clients || []), client],
      }));
    },
    [],
  );

  // const handleSelectSentTo = useCallback((value) => {
  //   setContract((prev) => ({
  //     ...prev,
  //     sentTo: [...(prev?.sentTo || []), value],
  //   }));
  //   setCurrentSentTo('');
  // }, []);

  const handleChangeCurrentSentTo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentSentTo(e.target.value);
    },
    [],
  );

  // const handleRemoveSentToItem = useCallback((value) => {
  //   setContract((prev) => ({
  //     ...prev,
  //     sentTo: prev.sentTo.filter((guest: string) => guest !== value),
  //   }));
  // }, []);

  const addRecipient = useCallback(() => {
    setContract((prev) => ({
      ...prev,
      recipients: [...(prev?.recipients || []), initialRecipient],
    }));
  }, []);

  const handleChangeRecipient = useCallback(
    (payload: { name: string; value: string; index: number }) => {
      setContract((prev) => ({
        ...prev,
        recipients: prev.recipients.map((r, i) =>
          i === payload.index ? { ...r, [payload.name]: payload.value } : r,
        ),
      }));
    },
    [],
  );

  const handleRemoveRecipient = useCallback((index: number) => {
    setContract((prev) => ({
      ...prev,
      recipients: prev.recipients.filter((_, i) => i !== index),
    }));
  }, []);

  const handleUploadFile = useCallback(
    async (event: any) => {
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newFile: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        return newFile;
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  // add file near comment
  const handleAddFile = useCallback(
    async (event: any) => {
      event.stopPropagation();
      try {
        const newFile = await handleUploadFile(event);
        if (newFile) {
          setContract((prev) => ({
            ...prev,
            media: [
              ...(prev?.media || []),
              {
                url: newFile.filePath,
                type: 'document',
                displayName: newFile.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [handleUploadFile],
  );

  // add quote file
  const handleAddQuoteFile = useCallback(async (urlItem: UrlItem) => {
    if (!urlItem) {
      console.error('use add quote file error -> urlItem is required');
      return;
    }
    setContract((prev) => ({
      ...prev,
      media: [
        ...(prev?.media || []),
        {
          url: urlItem.url,
          type: 'quote',
          displayName: urlItem.displayName,
        },
      ],
    }));
  }, []);

  const clearFile = useCallback(
    async (type: 'document' | 'quote') => {
      if (!type) {
        console.error('use clear file error -> type is required');
        return;
      }
      try {
        const [target, targetId, access, fileId] = contract?.media
          ?.find((m) => m.type === type)
          ?.url.split('/');
        await UploadService.deleteUpload({
          target,
          targetId,
          access,
          fileId,
        });

        setContract((prev) => ({
          ...prev,
          media: prev.media.filter((m) => m.type !== type),
        }));
      } catch (error) {
        console.error('use clear file error ->', error);
      }
    },
    [contract?.media],
  );
  const handleGoToPreview = useCallback(() => {
    dispatch(setContractPreview(contract));
    navigate(`/main/finance/contracts/preview`);
  }, [navigate, dispatch, contract]);

  const handleCreateContract = useCallback(
    async (filter?: Partial<IContract>) => {
      const payload: any = filter
        ? {
            ...contract,
            ...filter,
          }
        : {
            ...contract,
          };

      if (payload?.clients) {
        payload.clients = contract.clients.map(
          (c: { client: IClient; contacts: IClientContact[] | string[] }) => ({
            client: c.client?.id,
            contacts: !c.contacts
              ? []
              : (c.contacts as IClientContact[]).map((contact) => contact.id),
          }),
        );
      }

      let response: any;

      // if this is a template and we are trying to generate a draft or a contract from it, we shouldn't overwrite this template, but instead create a new draft/contract
      if (payload?.id && contract?.isTemplate) {
        delete payload.id;
      }

      if (payload?.id) {
        response = await dispatch(
          updateContract({
            contractId: payload.id,
            updates: payload,
          }),
        );

        if (response.type?.includes('rejected')) {
          toast({
            title: (response?.payload as any)?.response?.data?.message,
            status: 'error',
            position: 'top-right',
          });
          return;
        }
        toast({
          title: t('create_contract.contract_updated'),
          variant: 'main',
          position: 'top-right',
        });
      } else {
        // check if sentTo is valid
        if (payload?.status === 'sent' && !payload?.sentTo) {
          setError((prev) => [...prev, 'sentTo']);
          return;
        }
        if (payload?.sentTo && !isValidEmail(payload?.sentTo)) {
          setError((prev) => [...prev, 'sentTo']);
          return;
        }
        response = await dispatch(createContract(payload));

        if (response.type?.includes('rejected')) {
          toast({
            title: (response?.payload as any)?.response?.data?.message,
            status: 'error',
            position: 'top-right',
          });
          return;
        }

        toast({
          title: filter.isDraft
            ? t('create_contract.contract_drafted')
            : filter.isTemplate
            ? t('create_contract.contract_template_created')
            : t('create_contract.contract_sent'),
          variant: 'main',
          position: 'top-right',
        });
      }

      // send contract to sentTo if it was sent to someone
      const createdContract = response.payload as IContract;
      if (createdContract?.status === 'sent') {
        dispatch(setContractPreview(createdContract));
        navigate(`/main/finance/contracts/preview?sent=true`);
      } else if (filter?.isTemplate) {
        navigate(
          `/main/finance/contracts/create?templateId=${response.payload.id}`,
        );
      } else if (filter.isDraft) {
        navigate(`/main/finance/contracts/${response.payload.id}`);
      } else {
        navigate('/main/finance/contracts');
      }
    },
    [contract, dispatch, navigate, t, toast],
  );

  const handleSaveAsTemplate = useCallback(() => {
    onOpenSaveAsTemplate();
  }, [onOpenSaveAsTemplate]);

  useEffect(() => {
    dispatch(getQuotes({ businessId: myBusiness?.id }));
    dispatch(
      fetchClients({
        limit: 1000,
      }),
    );
  }, [dispatch, myBusiness?.id]);
  // generate main paragraph
  useEffect(() => {
    if (!contract || !myBusiness) return;

    const generatedMainParagraphContent = `
        <div style="padding: 12px; border-radius: 12px; background-color: #f1f1f1; width: 100%; max-width: 821px; position: relative; margin-bottom: 12px; ${
          i18n.dir() === 'rtl' ? 'padding-left: 48px' : 'padding-right: 48px'
        }">
          <p style="padding: 4px 0; margin-bottom: 12px"><span><strong>${t(
            'mainparagraph.the_parties',
          )}</strong> ${t(
      'mainparagraph.service_contract_agreement',
    )}</span><strong className="date">
              ${new Date(
                contract?.contractDate
                  ? contract.contractDate
                  : new Date().toString(),
              )
                .toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
                .split('/')
                .join('.')}
            </strong> 
            <span> ${t('mainparagraph.effective_date')}</span>
          </p>
          <div style="margin-bottom: 12px">
            <p style="padding: 0 44px; margin-bottom: 8px">
              <span>${t('mainparagraph.supplier')}</span> <strong>${
      myBusiness?.businessName || t('mainparagraph.empty_data')
    }</strong> 
              <strong>${t('mainparagraph.id_no')} ${
      myBusiness?.businessVATId || t('mainparagraph.empty_data')
    }</strong> 
              <span>
                ${t('mainparagraph.registered_address_of')} 
                <strong>${
                  myBusiness?.address || t('mainparagraph.empty_data')
                }</strong>
              </span> 
              <span>${t('mainparagraph.the_service_provider')}</span> 
            </p>${contract?.clients
              ?.map(
                ({ client }) =>
                  `<p style="padding:  0 44px;">
                <span>${t('mainparagraph.client')}</span> 
                <strong>
                  ${
                    client?.type === 'business'
                      ? client?.company || t('mainparagraph.empty_data')
                      : `${
                          client?.firstName || t('mainparagraph.empty_data')
                        } ${client?.lastName || t('mainparagraph.empty_data')}`
                  }
                </strong> 
                <strong>${t('mainparagraph.id')} ${
                    client?.idNumber || t('mainparagraph.empty_data')
                  }</strong>
                <span>${t('mainparagraph.address_of')}</span> <strong>${
                    client?.address || t('mainparagraph.empty_data')
                  }</strong> 
                <span>${t('mainparagraph.the_client')}</span>
              </p>`,
              )
              .join('\n')}<p>
              ${t('mainparagraph.parties')}
            </p>
          </div>
        </div>
        <div style="padding: 0 24px; max-width: 531px">
          ${t('mainparagraph.consideration')}
        </div>
    `;
    setContract((prev) => ({
      ...prev,
      paragraphs: prev.paragraphs.map((p, index) =>
        index > 0
          ? p
          : {
              ...p,
              content: generatedMainParagraphContent,
            },
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBusiness, t, contract?.clients, contract?.contractDate]);

  // autocomplete for sent to
  // useEffect(() => {
  //   if (currentSentTo.length > 0 && myBusiness?.id) {
  //     const fetchGuests = async () => {
  //       const guests = await UserService.getUsers({
  //         businessID: myBusiness?.id,
  //         name: currentSentTo,
  //         limit: 5,
  //       });

  //       if (guests?.results) {
  //         setDisplayingSentTo(
  //           guests.results
  //             ?.filter((guest: User) => !contract?.sentTo?.includes(guest?.id))
  //             .map((guest: User) => ({
  //               avatar: guest.media?.find((media) => media.type === 'avatar'),
  //               email: guest.email,
  //               label: `${guest.firstName} ${guest.lastName}`,
  //               value: guest.id,
  //             })),
  //         );
  //       }
  //     };
  //     fetchGuests();
  //   }
  // }, [currentSentTo, myBusiness?.id, dispatch, contract?.sentTo]);

  useEffect(() => {
    const getContract = async (id: string, isTemplate?: boolean) => {
      const response = await dispatch(fetchContract(id));
      if (response.type?.includes('fulfilled')) {
        let contract: IContract = response.payload as IContract;

        // if template, clear template fields
        contract = {
          ...contract,
        };
        delete contract?.createdAt;
        delete contract?.updatedAt;
        if (isTemplate) {
          delete contract?.id;
          contract.isDraft = false;
          contract.isTemplate = false;
          contract.templateDescription = '';
          contract.templateTitle = '';
          delete contract?.quoteId;
          delete contract?.eventId;
          delete contract?.isDraft;
          delete contract?.createdAt;
          delete contract?.updatedAt;
          delete contract?.creatorBusinessId;
          contract.recipients = [{}];
          contract.clients = [];
          delete contract.sentTo;
          dispatch(setSelectedQuote(null));
        }
        setContract(contract);
        if (contract?.eventId) {
          const event = await dispatch(getEvent(contract?.eventId));
          if (event.type?.includes('fulfilled')) {
            setEvent(event.payload as IEvent);
          }
        }
        if (contract?.quoteId) {
          handleSelectedQuote(contract?.quoteId);
        }
      }
    };

    // get contract from params
    if (params?.contractId) {
      getContract(params?.contractId, false);
    }
    // get contract from templateId
    const templateId = searchParams.get('templateId');
    if (templateId) {
      getContract(templateId, true);
    }

    const quoteId = searchParams.get('quoteId');
    if (quoteId) {
      handleSelectedQuote(quoteId);
    }
    const eventId = searchParams.get('eventId');
    if (eventId) {
      dispatch(getEvent(eventId)).then((event) => {
        if (event.type?.includes('fulfilled') && event.payload) {
          setEvent(event.payload as IEvent);
        }
      });
    }

    if (!params?.contractId && !templateId) {
      const setEventAndQuote = async () => {
        const eventId = searchParams.get('eventId');
        const quoteId = searchParams.get('quoteId');
        if (eventId || quoteId) {
          if (eventId) {
            const event = await dispatch(getEvent(eventId));
            if (event.type?.includes('fulfilled') && event.payload) {
              setContract((prev) => ({
                ...prev,
                eventId: (event.payload as IEvent).id,
                eventType: (event.payload as IEvent).type,
              }));
              setEvent(event.payload as IEvent);
            }
          }
          if (quoteId) {
            handleSelectedQuote(quoteId);
          }
        } else if (contractPreview) {
          setContract(contractPreview as IContract);
          if (contractPreview?.eventId) {
            const event = await dispatch(getEvent(contractPreview?.eventId));
            if (event.type?.includes('fulfilled') && event.payload) {
              setEvent(event.payload as IEvent);
            }
          }
          if (contractPreview?.quoteId) {
            handleSelectedQuote(contractPreview?.quoteId);
          }
          if (contractPreview?.media?.find((i) => i.type === 'quote')) {
            setRadioValue('attach');
          }
          dispatch(setContractPreview(null));
        }
      };
      setEventAndQuote();
    }
  }, [
    params?.contractId,
    params?.quoteId,
    params?.eventId,
    dispatch,
    searchParams,
    handleSelectedQuote,
    contract?.eventId,
    contractPreview,
  ]);

  useEffect(() => {
    return () => {
      // clear selected quote when unmount
      dispatch(setSelectedQuote(null));
    };
  }, [dispatch]);
  return {
    event,
    handleOpenEventDialog,
    handleChange,
    contract,
    selectedLanguage,
    showedLanguage,
    radioValue,
    setRadioValue,
    qoutes: displayedQoutes,
    handleSelectedQuote,
    selectedEventType,
    showedEventType,
    handleChangeEventType,
    currencySymbol,
    // displayingSentTo,
    // handleSelectSentTo,
    currentSentTo,
    handleChangeCurrentSentTo,
    // handleRemoveSentToItem,
    addRecipient,
    handleChangeRecipient,
    handleRemoveRecipient,
    fileInputRef,
    handleAddFile,
    clearFile,
    handleCreateContract,
    loading,
    handleAddQuoteFile,
    userId,
    handleSaveAsTemplate,
    isOpenSaveAsTemplate,
    onCloseSaveAsTemplate,
    selectedQuote,
    handleOpenQuoteDialog,
    handleOpenClientDialog,
    setClient,
    handleGoToPreview,
    error,
    setError,
  };
}
