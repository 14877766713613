import {
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import PreviewIcon from 'assets/img/quote/QuotePreview.png';
import Dropzone from 'components/dropzone';
import EventCard from 'components/eventsPickerDialog/eventCard';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import QuoteCard from 'components/quotePickerDialog/quoteCard';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useGetBusinessEventPlans } from 'queries/businessEventPlan';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { IContract, IEvent, IQuote, UrlItem } from 'services/@types';
import { eventTypes } from 'variables/eventTypes';
import { languages } from 'variables/languages';
interface ICreateContractHeaderProps {
  event?: IEvent | null;
  handleOpenEventDialog: () => void;
  contract: IContract | null;
  selectedLanguage: { label: string; value: string }[];
  handleChange: (path: string, value: any) => void;
  showedLanguage: string;
  radioValue: 'assign' | 'attach';
  setRadioValue: (value: 'assign' | 'attach') => void;
  handleAddQuoteFile: (urlItem: UrlItem) => void;
  clearFile: (type: 'document' | 'quote') => void;
  userId: string;
  handleOpenQuoteDialog: () => void;
  selectedQuote: IQuote | null;
  selectedEventType: string[];
  showedEventType: string;
  handleChangeEventType: (e: string) => void;
  handleGoToPreview: () => void;
}
export default function CreateContractHeader(
  props: ICreateContractHeaderProps,
) {
  const {
    event = null,
    handleOpenEventDialog,
    contract,
    selectedLanguage,
    handleChange,
    showedLanguage,
    radioValue,
    setRadioValue,
    handleAddQuoteFile,
    clearFile,
    userId,
    handleOpenQuoteDialog,
    selectedQuote,
    selectedEventType,
    showedEventType,
    handleChangeEventType,
    handleGoToPreview,
  } = props;
  const { t } = useTranslation();
  const business = useMyBusiness();
  const { data: businessEventPlans } = useGetBusinessEventPlans({
    eventId: event?.id,
    businessId: business?.id,
  });

  const eventName = useMemo(() => {
    return businessEventPlans?.results[0]?.eventName || event?.name;
  }, [businessEventPlans?.results, event?.name]);
  return (
    <Flex flexDirection="column" gap="36px">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        columnGap={3}
        rowGap={4}>
        <Text fontSize="24px" fontWeight="600">
          {t('create_contract.title')}
        </Text>
        {/* assign to event */}
        <Flex
          flex={{ base: 'unset', md: 1 }}
          justifyContent="flex-end"
          order={{ base: 1, md: 0 }}
          flexShrink={{ base: 0, md: 1 }}
          w="100%">
          {!event ? (
            <Button
              variant="h1cta"
              maxW={{ base: '100%', md: '270px' }}
              flex="1"
              onClick={handleOpenEventDialog}>
              {t('create_contract.assign_event')}
            </Button>
          ) : (
            <EventCard
              event={event}
              eventName={eventName}
              isQuoteEvent
              onClick={handleOpenEventDialog}
              maxW={{ base: '100%', md: '270px' }}
              flex="1"
            />
          )}
        </Flex>
        <Flex
          cursor="pointer"
          bg="#0000004D"
          borderRadius="10px"
          w="32px"
          h="32px"
          onClick={handleGoToPreview}
          alignItems="center"
          justifyContent="center">
          <Image src={PreviewIcon} alt="preview" />
        </Flex>
      </Flex>
      <Flex wrap="wrap" gap="44.5px" alignItems="end">
        <InputDateField
          label={t(`create_contract.contract_date`)}
          value={contract?.contractDate || new Date()}
          justifyContent="end"
          containerStyles={{
            flex: '1',
            w: { base: '100%', md: '320px' },
          }}
          buttonStyles={{
            w: { base: '100%', md: '320px' },
            maxW: { base: '100%', md: '320px' },
            borderColor: '#0000001A',
            justifyContent: 'space-between',
          }}
          imgSrc={CalendarIcon}
          onChange={(e: Date) => {
            handleChange('contractDate', e);
          }}
          imgStyles={{
            bg: 'transparent',
          }}
        />
        <InputField
          label={t('create_contract.description')}
          w={{ base: '100%', md: '320px' }}
          value={contract?.documentTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange('documentTitle', e.target.value);
          }}
        />
        <DropdownField
          closeOnSelect
          showedOptions={showedLanguage}
          label={t('create_contract.language')}
          options={languages}
          minW={{ base: '100%', md: '320px' }}
          placeholder={t('create_contract.language')}
          selectedOptions={selectedLanguage}
          onSelected={(e: any) => {
            handleChange('language', e.value);
          }}
          formLabelStyles={{
            mb: '4px',
          }}
          menuButton={{
            w: {
              base: '100%',
              md: '320px',
            },
            bg: '#FFFFFF',
          }}
        />
        <DropdownField
          closeOnSelect
          isDisabled={!!event}
          showedOptions={showedEventType}
          minW={{ base: '100%', md: '320px' }}
          label={t('create_contract.event_type')}
          options={eventTypes.map((type) => `event_types.${type}`)}
          placeholder={t('create_contract.event_type')}
          onSelected={handleChangeEventType}
          selectedOptions={selectedEventType}
          formLabelStyles={{
            mb: '4px',
          }}
          menuButton={{
            w: {
              base: '100%',
              md: '320px',
            },
            bg: '#FFFFFF',
          }}
        />
      </Flex>
      <RadioGroup
        value={radioValue}
        onChange={(e: string) => setRadioValue(e as 'assign' | 'attach')}>
        <Flex gap="44px" flexDirection={{ base: 'column', md: 'row' }}>
          <Flex flexDirection="column" gap="12px">
            <Radio
              variant="brandOutline"
              borderColor="brand.900"
              value="assign">
              <Text variant="createQuoteTitle">
                {t('create_contract.assign_to_existing_quote')}
              </Text>
            </Radio>
            {/* assign to quote */}
            {radioValue === 'assign' && selectedQuote?.id ? (
              <QuoteCard
                quote={selectedQuote}
                hideEventName
                onClick={handleOpenQuoteDialog}
              />
            ) : (
              <Button
                variant="h1cta"
                onClick={handleOpenQuoteDialog}
                disabled={radioValue !== 'assign'}>
                {t('create_contract.assign_to_quote')}
              </Button>
            )}
          </Flex>
          <Flex flexDirection="column" gap="16px">
            <Radio
              variant="brandOutline"
              borderColor="brand.900"
              color="brand.900"
              value="attach">
              <Text variant="createQuoteTitle">
                {t('create_contract.attach_existing_quote')}
              </Text>
            </Radio>
            {!contract?.media?.find((i) => i.type === 'quote') && (
              <Dropzone
                disabled={radioValue !== 'attach'}
                uploadKey="quote"
                target="user"
                uploadLabel={t('create_contract.add_file_or_drag_and_drop')}
                labelSize="xs"
                targetId={userId}
                access="private"
                currentImage={''}
                dropzoneOptions={{
                  multiple: false,
                }}
                onUploadCompleted={handleAddQuoteFile}
                initialProgress={
                  !!contract?.media?.find((i) => i.type === 'quote') ? 100 : 0
                }
                h="74px"
                w="206px"
                minH="74px"
                minW="206px"
                transition="all 0.3s ease"
              />
            )}
            {contract?.media?.find((i) => i.type === 'quote') && (
              <Flex alignItems="center" gap="8px">
                <Text
                  variant="contractFileText"
                  onClick={() => {
                    handleOpenPdf(
                      contract?.media?.find((m) => m.type === 'quote')?.url,
                      contract?.media?.find((m) => m.type === 'quote')
                        ?.displayName,
                    );
                  }}>
                  {
                    contract?.media?.find((m) => m.type === 'quote')
                      ?.displayName
                  }
                </Text>
                <IconButton
                  icon={<Icon as={FiX} color="#939393" />}
                  aria-label="delete"
                  onClick={() => {
                    clearFile('quote');
                  }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </RadioGroup>
    </Flex>
  );
}
