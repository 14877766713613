import { Button, Flex, Text } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useGetEntity } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Business, IClient } from 'services/@types';
import useInviteClient from '../hooks/useInviteClient';

export default function ClientsListItem(props: {
  client: IClient;
  onClick: () => void;
}) {
  const { t } = useTranslation();
  const { client, onClick } = props;
  const { handleInviteToBrilliant, isInvited, loading } = useInviteClient({
    client,
  });

  const { data: clientBusiness } = useGetEntity('business', client.businessID);
  const displayImage = useMemo(() => {
    const business = clientBusiness as Business;
    if (business?.media?.[0]?.url) {
      return business?.media?.[0]?.url;
    }
    return client?.media?.[0]?.url;
  }, [client?.media, clientBusiness]);
  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      _hover={{ bg: '#F5F5F5' }}
      transition="all 0.2s ease-in-out"
      justifyContent="space-between"
      borderBottom="1px solid #EEEEEE"
      p="20px 16px"
      onClick={onClick}>
      <Flex gap="12px" alignItems="center">
        <AuthImage
          path={displayImage || ''}
          isAvatar
          imageProps={{ overflow: 'hidden', borderRadius: '50%' }}
          borderRadius="50%"
          name={
            client.company
              ? client.company
              : `${client.firstName || ''} ${client.lastName || ''}`
          }
          w="60px"
          h="60px"
        />
        <Flex flexDirection="column" maxW="175px" minW="100px">
          <Text variant="clientName">
            {client.company
              ? client.company
              : `${client.firstName || ''} ${client.lastName || ''}`}
          </Text>
          <Text variant="clientEmail">{client.email}</Text>
        </Flex>
      </Flex>
      {!isInvited && !client.businessID && (
        <Button
          minW="120px"
          isLoading={loading}
          variant="h2outlined"
          onClick={handleInviteToBrilliant}>
          {t('clients.invite_to_brilliant')}
        </Button>
      )}
    </Flex>
  );
}
