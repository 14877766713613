import { Collapse, Flex, Hide, HStack, Text } from '@chakra-ui/react';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Business, CreateQuoteError, IQuote, Product } from 'services/@types';
import { AddProductModal } from 'views/main/managment/products/components/AddProductModal';
import useProductItem from '../hooks/useProductItem';
import PackageIcon from './PackageIcon';
import PackageProductInfo from './PackageProductInfo';
import { ProductVatCheckbox } from './ProductVatCheckbox';

export default function ProductItem({
  product,
  index,
  setNewQuote,
  products,
  handleSelectedProduct,
  currencyIcon,
  error,
  currency,
  handleChangeProductVatIncluded,
  newQuoteLanguage,
  dir,
  setIsUserInteraction,
}: {
  product: any;
  index: number;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  products: { value: string; label: string }[];
  handleSelectedProduct: (e: any, index: number) => void;
  currencyIcon: string;
  error: CreateQuoteError;
  currency: string;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
  newQuoteLanguage?: 'en' | 'he';
  dir: string;
  setIsUserInteraction: (isUserInteraction: boolean) => void;
}) {
  const { t } = useTranslation('', {
    lng: newQuoteLanguage || 'en',
  });
  const {
    showOptions,
    isPackage,
    handleChangeDescription,
    onOptionsRendered,
    isAddProductOpen,
    onOpenAddProduct,
    currentEditableProduct,
    handleAddProductClosed,
    isPackageInfoVisible,
    handlePackageInfoVisibility,
    productData,
    handleChangeProductQuantity,
    handleChangeProductAmount,
  } = useProductItem({
    product,
    setNewQuote,
    index,
    currency,
    setIsUserInteraction,
  });

  const handleAddProduct = useCallback(
    (product: Product) => {
      setNewQuote((prev) => {
        const updatedProducts = [...prev.products];
        updatedProducts[index] = {
          productId: product.id,
          description: product.name || '',
          amount: product.price || 0,
          quantity: 1,
          net: product.price || 0,
          vat: (product.price || 0) * 0.17,
          gross: (product.price || 0) * 1.17,
          isVatIncluded: true,
        };
        return {
          ...prev,
          products: updatedProducts,
        };
      });
    },
    [setNewQuote, index],
  );

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex wrap="wrap" gap="12px" width="100%" alignItems="end">
        {showOptions && (
          <Hide above="md">
            <ProductVatCheckbox
              product={product}
              index={index}
              dir={dir}
              language={newQuoteLanguage}
              setNewQuote={setNewQuote}
              handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            />
          </Hide>
        )}
        <HStack w={"100%"}>

          <InputAutocompleteField
            label={t('create_quote.description')}
            placeholder={t('create_quote.description')}
            maxWidth={{ base: '100%', lg: '256px' }}
            w={{ base: '100%', sm: '256px' }}
            value={product.description}
            options={products}
            onSelected={(e: any) => {
              handleSelectedProduct(e, index);
            }}
            dir={dir}
            lang={newQuoteLanguage}
            isIconWidthAuto={isPackage}
            borderColor={error?.products ? 'error.100' : 'gray.200'}
            onChange={handleChangeDescription}
            onOptionsRendered={onOptionsRendered}
            iconLeft={isPackage ? <PackageIcon isPackage={isPackage} /> : null}
          />
        </HStack>
        <Flex
          w={"full"}
          gap="12px"
          alignItems={"end"}
          wrap="wrap"
        >

          {showOptions && (
            <Flex minWidth={{ base: '115px' }} grow={1}>

              <InputField
                display={"flex"}
                flexGrow={1}
                label={t('create_quote.quantity')}
                placeholder={t('create_quote.quantity')}
                // maxWidth={{ base: '100%'}}
                minWidth={{ base: '115px'}}
                w={{ base: '100%' }}
                // maxWidth={{ base: '100%', md: '115px' }}
                // w={{ base: '100%', md: '115px' }}
                type="number"
                min={1}
                value={product?.quantity}
                onChange={(e) => handleChangeProductQuantity(e, index)}
                dir={dir}
              />
            </Flex>
          )}
          {showOptions && (
            <Flex minWidth={{ base: '115px' }} grow={1}>

              <InputField
                label={t('create_quote.amount')}
                placeholder={t('create_quote.price')}
                //  maxWidth={{ base: '100%'}}
                 minWidth={{ base: '115px'}}
                w={{ base: '100%' }}
                // maxWidth={{ base: '100%', md: '115px' }}
                // w={{ base: '100%', md: '115px' }}
                type="number"
                value={product?.amount}
                onChange={(e) => handleChangeProductAmount(e, index)}
                iconLeft={<Text variant="currency">{currencyIcon}</Text>}
                dir={dir}
              />
            </Flex>
          )}
          {showOptions && (
            <Flex minWidth={{ base: '115px' }} grow={1}>

              <InputField
                label={t('create_quote.net')}
                placeholder={t('create_quote.net')}
                //  maxWidth={{ base: '100%'}}
                w={{ base: '100%' }}
                 minWidth={{ base: '125px'}}
                // maxWidth={{ base: '100%', md: '125px' }}
                // w={{ base: '100%', md: '125px' }}
                bg="#F6F6F6"
                asNumber={true}
                format="0,0.00"
                borderColor="#F6F6F6"
                value={product?.net}
                dir={dir}
              />
            </Flex>
          )}
          {showOptions && (
            <Flex minWidth={'115px'} grow={1}>

              <InputField
                label={t('create_quote.vat')}
                placeholder={t('create_quote.vat')}
                //  maxWidth={{ base: '100%'}}
                w={{ base: '100%' }}
                 minWidth={{ base: '125px'}}
                // maxWidth={{ base: '100%', md: '125px' }}
                // w={{ base: '100%', md: '125px' }}
                bg="#F6F6F6"
                asNumber={true}
                format="0,0.00"
                borderColor="#F6F6F6"
                value={product?.vat}
                dir={dir}
              />
            </Flex>
          )}
          {showOptions && (
            <Flex minWidth={{ base: '115px' }} flexGrow={1}>

              <InputField  
                label={t('create_quote.gross')}
                placeholder={t('create_quote.gross')}
                //  maxWidth={{ base: '100%'}}
                w={{ base: '100%' }}
                 minWidth={{ base: '125px'}}
                // maxWidth={{ base: '100%', md: '125px' }}
                // w={{ base: '100%', md: '125px' }}
                bg="#F6F6F6"
                asNumber={true}
                format="0,0.00"
                borderColor="#F6F6F6"
                value={product?.gross}
                dir={dir}
              />
            </Flex>
          )}
          {(showOptions && (index != 0)) && (
            <Hide below="md">
              <ProductVatCheckbox
                product={product}
                index={index}
                dir={dir}
                language={newQuoteLanguage}
                setNewQuote={setNewQuote}
                handleChangeProductVatIncluded={handleChangeProductVatIncluded}
              />
            </Hide>
          )}
          {!showOptions && (
            <Flex alignItems="center" h="50px">
              <Text variant="createQuoteCheckboxText">
                {t('create_quote.no_product')}
              </Text>
              <Text
                variant="addNewProduct"
                cursor="pointer"
                onClick={onOpenAddProduct}>
                {t('create_quote.add_new_product')}
              </Text>
            </Flex>
          )}
        </Flex>
        <AddProductModal
          isOpen={isAddProductOpen}
          isNewProductInQuote={true}
          editableProduct={currentEditableProduct}
          onFoundExistingProduct={(business: Business) => {
            console.log('found business ->', business);
          }}
          onCreatedProduct={handleAddProduct}
          onClose={handleAddProductClosed}
        />
      </Flex>
      {isPackage && (
        <Collapse in={isPackageInfoVisible} animateOpacity>
          <Flex flexDirection="column" gap="16px">
            {productData?.subItems?.map((subItem, key) => (
              <PackageProductInfo productId={subItem as string} key={key} />
            ))}
          </Flex>
        </Collapse>
      )}
      {isPackage && (
        <Flex alignItems="center" justifyContent="center">
          <Text
            variant="addNewProduct"
            cursor="pointer"
            onClick={handlePackageInfoVisibility}>
            {t(
              isPackageInfoVisible
                ? 'create_quote.hide_package'
                : 'create_quote.show_package',
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
