import { Text, useMediaQuery } from '@chakra-ui/react';
import EventCard from 'components/eventsPickerDialog/eventCard';
import { useGetEntity } from 'queries/entity';
import { useEffect } from 'react';
import { IEvent } from 'services/@types';

type QuoteListItemEventCardProps = {
  eventId: string;
  quoteName: string;
};
const QuoteListItemEventCard = ({
  eventId,
  quoteName,
}: QuoteListItemEventCardProps) => {
  const { data: event } = useGetEntity<IEvent>('event', eventId);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return event && !isMobile ? (
    <EventCard
      event={event}
      isQuoteEvent
      maxW={{ base: '100%', md: '320px' }}
      flex="1"
    />
  ) : (
    <Text
      color="#000000CC"
      fontSize="md"
      textTransform="capitalize"
      noOfLines={1}
      wordBreak="break-all">
      {event?.name || quoteName}
    </Text>
  );
};

export default QuoteListItemEventCard;
