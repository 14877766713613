import { toast, useToast } from '@chakra-ui/react';
import { User } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  getNavigateTo,
  selectSelectedDate,
  setNavigateTo,
  setSelectedDate,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createMeeting } from 'contexts/redux/meeting/meetingSlice';
import { RootState } from 'contexts/redux/store';
import { fetchUser, fetchUsers } from 'contexts/redux/user/userSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import useGetMeeting, {
  useUpdateMeeting,
  useCreateMeeting,
} from 'queries/meeting';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IEvent, IMeeting, UrlItem } from 'services/@types';
import { isValidEmail } from 'utils/isValidEmail';

export const requiredFields = ['name', 'location', 'startDate', 'endDate'];

interface UseCreateMeetingResult {
  errorList: string[];
  meeting: Partial<IMeeting>;
  handleChangeMeeting: (e: any) => void;
  handleCreateUpdateMeeting: (navigateTo?: string) => void;
  handleChangeRemind: (e: any) => void;
  selectedRemind: string[];
  event: IEvent;
  handleOpenEventDialog: () => void;
  displayingGuest: {
    avatar: UrlItem | undefined;
    email: string;
    label: string;
    value: string;
    isExternal?: boolean;
    businessName?: string;
  }[];
  currentGuestInput: string;
  selectedGuests: string[];
  handleSelectGuest: (value: string) => void;
  handleSelectExternalGuest: (value: string) => void;
  handleRemoveGuest: (value: string) => void;
  setCurrentGuestInput: (value: string) => void;
  setEvent: (event: IEvent) => void;
  isUpdatingMeeting: boolean;
  isCreatingMeeting: boolean;
}

export default function useCreateUpdateMeeting(): UseCreateMeetingResult {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const { triggerChangesDetected, preventChangesDetected } = useAppNavigate();
  const navigate = useNavigate();
  const myUser = useMyUser();

  const myBusiness = useMyBusiness();
  const selectedDate = useSelector(selectSelectedDate);

  const [event, setEvent] = useState<IEvent>(null);
  const [meeting, setMeeting] = useState<Partial<IMeeting>>({
    startDate: selectedDate.start,
    endDate: selectedDate.end,
    guests: [],
  });

  const params = useParams();
  const id = useMemo(() => params?.id, [params?.id]);
  const { data: meetingData } = useGetMeeting(id);
  const { mutateAsync: updateMeeting, isPending: isUpdatingMeeting } =
    useUpdateMeeting();
  const { mutateAsync: createMeeting, isPending: isCreatingMeeting } =
    useCreateMeeting();
  const [errorList, setErrorList] = useState<string[]>([]);
  const selectedRemind = useMemo(
    () => [`create_meeeting.remind_${meeting?.reminder}`] || [],
    [meeting?.reminder],
  );
  const queryClient = useQueryClient();
  const { calendars } = useSelector((state: RootState) => state.calendars);

  const navigateTo = useSelector(getNavigateTo);
  const [currentGuestInput, setCurrentGuestInput] = useState<string>('');
  const [displayingGuest, setDisplayingGuest] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
      isExternal?: boolean;
      businessName?: string;
    }[]
  >([]);

  const selectedGuests = useMemo(
    () => [...(meeting?.guests || []), ...(meeting?.externalGuests || [])],
    [meeting?.guests, meeting?.externalGuests],
  );

  const handleChangeMeeting = useCallback(
    (e: any) => {
      console.log('handle change meeting', e);
      triggerChangesDetected();
      if (errorList.includes(e.target.name)) {
        setErrorList((prev) => prev.filter((field) => field !== e.target.name));
      }

      if (e.target.name === 'startTime' || e.target.name === 'endTime') {
        const [hours, minutes] = e.target.value.split(':');
        const key = e.target.name === 'startTime' ? 'startDate' : 'endDate';
        const baseDate = meeting[key];
        const newDate = moment(baseDate)
          .set('hour', parseInt(hours))
          .set('minute', parseInt(minutes))
          .toDate();

        setMeeting((prev) => {
          if (e.target.name === 'startTime') {
            return {
              ...prev,
              [key]: newDate,
              endDate: moment(newDate).isAfter(prev.endDate)
                ? moment(newDate).add(30, 'minutes').toDate()
                : prev.endDate,
            };
          }
          return {
            ...prev,
            [key]: newDate,
            startDate: moment(newDate).isBefore(prev.startDate)
              ? moment(newDate).subtract(30, 'minutes').toDate()
              : prev.startDate,
          };
        });
        return;
      } else if (e.target.name === 'startDate') {
        dispatch(
          setSelectedDate({
            start: e.target.value,
            end: moment(e.target.value).add(30, 'minutes').toDate(),
          }),
        );
      }

      setMeeting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [dispatch, errorList, meeting, triggerChangesDetected],
  );

  const validateTask = useCallback(() => {
    let result = requiredFields.filter((field) => {
      const meetingField = meeting[field as keyof IMeeting];
      const ress =
        !meeting || field === 'location'
          ? !(meetingField as any)?.value
          : !meetingField;
      return ress;
    });

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [meeting]);

  const handleCreateUpdateMeeting = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }
    preventChangesDetected();
    let newMeetingData: any = { ...meeting };
    if (event) {
      newMeetingData.eventId = event.id;
    }

    if (meeting?.id) {
      const updatedMeeting = await updateMeeting({
        id: meeting.id,
        updates: newMeetingData,
      });
      if ((updatedMeeting as any)?.error) {
        toast({
          title: t('create_meeting.update_meeting_error'),
          variant: 'main',
        });
        return;
      }
      toast({
        title: t('create_meeting.update_meeting_success'),
        variant: 'main',
      });
      navigate(navigateTo || '/');
      if (navigateTo) {
        dispatch(setNavigateTo(null));
        // invalidate query
        queryClient.invalidateQueries({
          queryKey: [`getMeetings-${meeting.eventId}`],
        });
      }
      return;
    }
    const newMeeting = await createMeeting(newMeetingData);
    if ((newMeeting as any)?.error) {
      return;
    }
    toast({
      title: t('create_meeting.success'),
      variant: 'main',
    });
    if (newMeeting?.eventId) {
      await queryClient.invalidateQueries({
        queryKey: ['todayMeetings', newMeeting?.eventId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['anchorMeetings', newMeeting?.eventId],
      });
      await queryClient.invalidateQueries({
        queryKey: [`getMeetings-${newMeeting?.eventId}`],
      });
      await queryClient.invalidateQueries({
        queryKey: ['meetings', newMeeting?.eventId],
      });
    }
    setEvent(null);
    setMeeting(null);
    navigate(navigateTo || '/');
    if (navigateTo) {
      dispatch(setNavigateTo(null));
    }
  }, [
    validateTask,
    preventChangesDetected,
    meeting,
    event,
    createMeeting,
    toast,
    t,
    navigate,
    navigateTo,
    updateMeeting,
    dispatch,
    queryClient,
  ]);
  const handleChangeRemind = useCallback(
    (e: string) => {
      const split = e.split('_');
      const event = {
        target: {
          value: split[split.length - 1],
          name: 'reminder',
        },
      };
      handleChangeMeeting(event);
    },
    [handleChangeMeeting],
  );
  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (errorList.includes('event')) {
            setErrorList((prev) => prev.filter((field) => field !== 'event'));
          }
          setEvent(e);
        },
      }),
    );
  }, [dispatch, errorList]);

  const handleSelectGuest = useCallback(
    async (value: string) => {
      const { payload: user } = await dispatch(fetchUser(value));
      setMeeting((prev) => ({
        ...prev,
        guests: [...prev.guests, user.id],
      }));
      setCurrentGuestInput('');
    },
    [dispatch],
  );

  const handleSelectExternalGuest = useCallback((value: string) => {
    setMeeting((prev) => ({
      ...prev,
      externalGuests: [...(prev?.externalGuests || []), value],
    }));
    setCurrentGuestInput('');
  }, []);

  // id is the email or the user id
  const handleRemoveGuest = (id: string) => {
    if (isValidEmail(id)) {
      setMeeting((prev) => ({
        ...prev,
        externalGuests: prev.externalGuests.filter((item) => item !== id),
      }));
    } else {
      setMeeting((prev) => ({
        ...prev,
        guests: prev.guests.filter((item) => item !== id),
      }));
    }
  };

  useEffect(() => {
    if (currentGuestInput.length < 2 || !currentGuestInput) {
      setDisplayingGuest([]);
      return;
    }
    const fetchSeats = async () => {
      const users = await dispatch(
        fetchUsers({
          name: currentGuestInput,
          email: currentGuestInput,
          limit: 5,
          populate: 'businessID',
        }),
      );

      if (users?.payload?.results?.length > 0) {
        setDisplayingGuest(
          users.payload.results
            .filter(
              (user: User) => !meeting.guests.find((a: any) => a === user?.id),
            )
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
              businessName: user?.businessID?.businessName || '',
            })),
        );
      }

      // posibility of adding email from outside of the system
      if (
        isValidEmail(currentGuestInput) &&
        users?.payload?.results?.length === 0
      ) {
        setDisplayingGuest([
          {
            value: currentGuestInput,
            avatar: undefined,
            label: currentGuestInput,
            isExternal: true,
            businessName: '',
            email: t('create_meeting.external_guest'),
          },
        ]);
      }
    };
    fetchSeats();
  }, [
    currentGuestInput,
    dispatch,
    myBusiness?.id,
    meeting?.guests,
    myUser?.id,
    t,
  ]);

  // set default calendar to meetings if it's create meeting
  useEffect(() => {
    if (!id) {
      // find external calendar
      let calendarId = calendars.find(
        (calendar) => calendar.type === 'external',
      )?.id;

      // if not found, find meetings calendar
      if (!calendarId) {
        calendarId = calendars.find(
          (calendar) => calendar.name === 'meetings',
        )?.id;
      }

      setMeeting((prev) => ({
        ...prev,
        calendarId: calendarId,
      }));
    }
  }, [calendars, id]);

  useEffect(() => {
    if (meetingData) {
      console.log('starting meetingData', meetingData);
      setMeeting(meetingData);
      // we need to set the selected date globally for other components(agenda) to use it
      dispatch(
        setSelectedDate({
          start: meetingData.startDate,
          end: meetingData.endDate,
        }),
      );
    }
  }, [meetingData, dispatch]);

  useEffect(() => {
    // update selectedDate to meeting
    setMeeting((prev) => ({
      ...prev,
      startDate: selectedDate.start,
      endDate: selectedDate.end,
    }));
  }, [selectedDate]);
  return {
    errorList,
    meeting,
    handleChangeMeeting,
    handleCreateUpdateMeeting,
    handleChangeRemind,
    selectedRemind,
    event,
    handleOpenEventDialog,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleSelectExternalGuest,
    handleRemoveGuest,
    setCurrentGuestInput,
    setEvent,
    selectedGuests,
    isUpdatingMeeting,
    isCreatingMeeting,
  };
}
