import { Divider, Flex, VStack, Text } from '@chakra-ui/react';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import LocationSearchInput from 'components/placesAutocomplete';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuote } from 'services/@types';
import { eventTypes } from 'variables/eventTypes';
import { getLocalizedDir } from 'helpers/getLocalizedDir';

interface QuoteInformationProps {
  handleChange: (key: string, value: any) => void;
  newQuote: IQuote;
  handleChangeEventType: (selected: string) => void;
  selectedEventType: string[];
  eventDateSwitch: boolean;
  setEventDateSwitch: (value: boolean) => void;
}

export default function QuoteInformation({
  handleChange,
  newQuote,
  handleChangeEventType,
  selectedEventType,
  eventDateSwitch,
  setEventDateSwitch,
}: QuoteInformationProps) {
  const { t } = useTranslation('', {
    lng: newQuote?.language,
  });

  const handleLocationChange = useCallback(
    (e: string) => {
      handleChange('location', { label: e, value: e });
    },
    [handleChange],
  );

  const handleLocationSelect = useCallback(
    (e: any) => {
      handleChange('location', e);
    },
    [handleChange],
  );

  const handleEventDateChange = useCallback(
    (e: Date) => {
      handleChange('eventDate', e.toString());
    },
    [handleChange],
  );

  const handleEventDateSwitch = useCallback(
    (event: any) => {
      setEventDateSwitch(event.target.checked);
      handleChange(
        'eventDate',
        !event.target.checked ? null : new Date().toString(),
      );
    },
    [handleChange],
  );

  useEffect(() => {
    if(newQuote?.eventDate){
      setEventDateSwitch(true);
    }
  },[newQuote])

  return (
    <VStack alignItems="start" gap="16px" flex="1">
      <Text variant="createQuoteTitle">{t('create_quote.event_details')}</Text>
      <Flex wrap="wrap" gap="12px" width="100%">
        <DropdownField
          label={t('create_quote.type')}
          options={eventTypes.map((type) => `event_types.${type}`)}
          placeholder={t('create_quote.type')}
          onSelected={handleChangeEventType}
          showedOptions={t(selectedEventType[0])}
          selectedOptions={selectedEventType}
          closeOnSelect
          menuButton={{
            w: {
              base: '100%',
              md: '259px',
            },
            borderColor: '#0000001A',
            bg: '#FFFFFF',
          }}
          flex={{ base: '1', md: '0' }}
          dir={getLocalizedDir(newQuote?.language)}
        />
        <LocationSearchInput
          value={newQuote?.location}
          sxWidth={'260px'}
          label={t('create_event.placeholder.location')}
          placeholder={t('create_event.placeholder.location')}
          onChange={handleLocationChange}
          onSelect={handleLocationSelect}
        />
        <InputDateField
          label={t(`create_quote.event_date`)}
          justifyContent="end"
          dir={getLocalizedDir(newQuote?.language)}
          isDisabled={!eventDateSwitch}
          containerStyles={{ flex: '1', w: { base: '100%', md: '260px' } }}
          buttonStyles={{
            w: { base: '100%', md: '260px' },
            maxW: { base: '100%', md: '280px' },
            borderColor: '#0000001A',
            justifyContent: 'space-between',
          }}
          switchProps={{
            id: 'eventDateSwitch',
            size: 'sm',
            isChecked: eventDateSwitch,
            onChange: handleEventDateSwitch,
            label: '',
            dir: getLocalizedDir(newQuote?.language),
          }}
          value={newQuote?.eventDate}
          imgSrc={CalendarIcon}
          onChange={handleEventDateChange}
          imgStyles={{
            bg: 'transparent',
          }}
          lang={newQuote?.language}
        />
        <InputField
          label={t(`create_quote.number_of_guests`)}
          value={newQuote?.numberOfGuests}
          onChange={(e) => handleChange('numberOfGuests', e.target.value)}
          flex="1"
          lang={newQuote?.language}
          dir={getLocalizedDir(newQuote?.language)}
          minWidth={{ base: '100%', md: '260px' }}
          w={{ base: '100%', md: '280px' }}
          placeholder={t(`create_quote.number_of_guests`)}
        />

        <Divider
          orientation="horizontal"
          my="36px"
          color="#E8E8E8"
          borderWidth={'1px'}
        />
        <TextField
          label={t(`create_quote.quote_includes`)}
          value={newQuote?.description}
          onChange={(e: any) => handleChange('description', e.target.value)}
          flex="1"
          minWidth={{ base: '100%' }}
          minH={'140px'}
          w={{ base: '100%' }}
          placeholder={t(`create_quote.description`)}
        />
      </Flex>
    </VStack>
  );
}
