import { Divider, Flex, Image, Text, VStack } from '@chakra-ui/react';
import EmailIcon from 'assets/svg/supplierInfo/email-icon.svg';
import PhoneIcon from 'assets/svg/supplierInfo/phone-icon.svg';
import AuthImage from 'components/authImage/AuthImage';
import { useGetEntity } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Business, IClient } from 'services/@types';
import { DetailInfoItem } from '../../suppliers/components/DetailInfoItem';

import ClientInfo from './ClientInfo';
interface SelectedClientDetailsProps {
  client: IClient;
}

export default function SelectedClientDetails({
  client,
}: SelectedClientDetailsProps) {
  const { t } = useTranslation();
  const { data: clientBusiness } = useGetEntity('business', client.businessID);
  const displayImage = useMemo(() => {
    const business = clientBusiness as Business;
    if (business?.media?.[0]?.url) {
      return business?.media?.[0]?.url;
    }
    return client?.media?.[0]?.url;
  }, [clientBusiness, client?.media]);
  return (
    <Flex flexDirection="column" gap="36px" h="full" overflow={{ md: 'auto' }}>
      <Flex alignItems="center" gap="24px">
        <AuthImage
          path={displayImage || ''}
          isAvatar
          imageProps={{ overflow: 'hidden', borderRadius: '50%' }}
          borderRadius="50%"
          name={
            client.company
              ? client.company
              : `${client.firstName || ''} ${client.lastName || ''}`
          }
          w="60px"
          h="60px"
        />
        <Flex flexDirection="column">
          <Text variant="clientName">
            {client?.firstName} {client?.lastName}
          </Text>
          <Text variant="clientDetailUnderline">{client?.email}</Text>
        </Flex>
      </Flex>
      <ClientInfo client={client} />
      <Divider />
      <Flex
        justify="start"
        align="start"
        w="full"
        flexDirection="column"
        pb={{ base: '24px', md: '0px' }}
        gap="24px">
        {client.contacts?.map((clientContact, index) => (
          <VStack align="start" key={index} w="full" gap={3}>
            <Text variant="eventCardTitle">
              {t('suppliers.details', {
                fullName: `${clientContact?.firstName} ${clientContact?.lastName}`,
              })}
            </Text>
            <DetailInfoItem
              icon={<Image src={EmailIcon} />}
              value={clientContact?.email}
            />
            <DetailInfoItem
              icon={<Image src={PhoneIcon} />}
              value={clientContact?.phone}
            />
          </VStack>
        ))}
      </Flex>
    </Flex>
  );
}
