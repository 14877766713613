import {
  Button,
  Checkbox,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import EventCard from 'components/eventsPickerDialog/eventCard';
import DropDownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setEditClient } from 'contexts/redux/client/clientSlice';
import {
  closeAddPaymentDialog,
  setContactsPickerDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { formatCurrency } from 'helpers/formatNumber';
import { PAYMENT_KEYS, usePaymentMutations } from 'hooks/usePayment';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import {
  IClient,
  IClientContact,
  IEvent,
  IPaymentCreateData,
  Supplier,
} from 'services/@types';
import AddClientModal from 'views/main/managment/clients/components/AddClientModal';
import ChosenClient from 'views/main/managment/events/createUpdateEvent/ChosenClient';
import ChosenSupplier from 'views/main/managment/events/createUpdateEvent/ChosenSupplier';
import {
  expenseOptions,
  incomeOptions,
  initialPayment,
  IPaymentDialogState,
} from './constants';
import {
  calculateAmountToPay,
  calculateGross,
  calculateTotalAmount,
  calculateTotalVat,
  calculateVat,
} from './utils';

// dialog for adding income or expense in finance overview
export default function AddPaymentDialog() {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const queryClient = useQueryClient();
  const myBusiness = useMyBusiness();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addPaymentDialog,
  );

  const [newPayment, setNewPayment] =
    useState<IPaymentDialogState>(initialPayment);

  const {
    createPayment: { mutateAsync: createPayment, isPending },
  } = usePaymentMutations();

  const {
    isOpen: isAddClientOpen,
    onOpen: onOpenAddClient,
    onClose: onAddClientClosed,
  } = useDisclosure();

  const type: 'income' | 'expense' = useMemo(() => {
    return dialog?.item?.type || 'income';
  }, [dialog?.item?.type]);

  const isDisabled = useMemo(() => {
    if (type === 'income') {
      return newPayment.clients.length === 0;
    }
    return !newPayment.supplier;
  }, [newPayment, type]);

  const clientsList = useMemo(
    () => [
      ...(newPayment.clients
        ?.filter((c) => c.client?.type === 'private')
        ?.map((c) => c.client) ?? []),
      ...(newPayment.clients
        ?.filter((c) => c.client?.type === 'business')
        ?.flatMap(
          (c: { contacts: IClientContact[] | string[] }) =>
            c.contacts as IClientContact[],
        ) ?? []),
    ],
    [newPayment.clients],
  );

  const typeOptions = useMemo(() => {
    const options = type === 'income' ? incomeOptions : expenseOptions;
    return options.map((o) => ({
      label: t(`add_payment_dialog.${o}`),
      value: o,
    }));
  }, [type, t]);

  const selectedOptions = useMemo(() => {
    const selectedOption = typeOptions.find(
      (o) => o.value === newPayment.typeOfTransaction,
    );
    return selectedOption ? [selectedOption] : [];
  }, [newPayment.typeOfTransaction, typeOptions]);

  const showedOptions = useMemo(() => {
    const selectedOption = typeOptions.find(
      (o) => o.value === newPayment.typeOfTransaction,
    );
    return selectedOption ? selectedOption.label : '';
  }, [typeOptions, newPayment.typeOfTransaction]);

  const handleCloseClientModal = useCallback(
    (newClientData?: IClient) => {
      dispatch(setEditClient(null));
      onAddClientClosed();
      if (newClientData) {
        setNewPayment((prev) => ({
          ...prev,
          clients: [...prev.clients, { client: newClientData, contacts: [] }],
        }));
      }
    },
    [dispatch, onAddClientClosed],
  );

  const handleSelectClient = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: newPayment.clients || [],
          type: 'clients',
        },
        onConfirm: (
          selection: { client: IClient; contacts: IClientContact[] }[],
        ) => {
          setNewPayment((prev) => ({
            ...prev,
            clients: selection,
          }));
        },
      }),
    );
  }, [newPayment.clients, dispatch]);

  const handleSelectSupplier = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenSuppliers: newPayment.supplier ? [newPayment.supplier] : [],
          type: 'suppliers',
        },
        onConfirm: (
          v: {
            supplier: Supplier;
          }[],
        ) => {
          setNewPayment((prev) => ({
            ...prev,
            supplier: v[0]?.supplier || null,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleSelectEvent = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: IEvent) => {
          setNewPayment((prev) => ({
            ...prev,
            event: e,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleRemoveEvent = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setNewPayment((prev) => ({
        ...prev,
        event: null,
      }));
    },
    [],
  );

  const onClose = useCallback(() => {
    dispatch(closeAddPaymentDialog());
    setNewPayment(initialPayment);
  }, [dispatch]);

  const handleRemoveSupplier = useCallback(() => {
    setNewPayment((prev) => ({
      ...prev,
      supplier: null,
    }));
  }, []);

  const handleChangeVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewPayment((prev) => ({
        ...prev,
        isVatIncluded: e.target.checked,
      }));
    },
    [],
  );

  const removeClient = useCallback(
    (clientId, contactId) => {
      if (contactId) {
        const contactClient = newPayment.clients.find((ic) =>
          ic.contacts.some((c) => (c as IClientContact).id === contactId),
        );
        const newClients = newPayment.clients.map((ic) => {
          if (ic.client.id === contactClient?.client.id) {
            return {
              ...ic,
              contacts: (ic.contacts as IClientContact[]).filter(
                (c) => c.id !== contactId,
              ),
            };
          }
          return ic;
        });
        setNewPayment((prev) => ({
          ...prev,
          clients: newClients,
        }));
      } else {
        setNewPayment((prev) => ({
          ...prev,
          clients: prev.clients.filter((c) => c.client.id !== clientId),
        }));
      }
    },
    [newPayment.clients],
  );

  const handleChangeDate = (date: Date) => {
    setNewPayment((prev) => ({
      ...prev,
      data: date,
    }));
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPayment((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSelectedTypeOfTransaction = useCallback((e: any) => {
    setNewPayment((prev) => ({
      ...prev,
      typeOfTransaction: e.value,
    }));
  }, []);

  const handleChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewPayment((prev) => ({
        ...prev,
        description: e.target.value,
      }));
    },
    [],
  );

  const handleAddPayment = useCallback(async () => {
    const data: Partial<IPaymentCreateData> = {
      amount: Number(newPayment?.amount),
      dueDate: newPayment?.data,
      comment: newPayment?.description,
      businessId: myBusiness?.id,
      eventId: newPayment?.event?.id,
      isVatIncluded: newPayment?.isVatIncluded,
      vat: Number(newPayment?.vat),
      totalVat: Number(newPayment?.totalVat),
      gross: Number(newPayment?.gross),
      discount: Number(newPayment?.discount),
      totalAmount: Number(newPayment?.totalAmount),
      amountToPay: Number(newPayment?.amountToPay),
      typeOfTransaction: newPayment?.typeOfTransaction,
    };

    if (type === 'income') {
      data.clients = newPayment?.clients?.map((c) => ({
        client: c?.client?.id,
        contacts: c?.contacts?.map((contact) => contact?.id),
      }));
    } else {
      data.supplierId = newPayment?.supplier?.id;
    }

    try {
      const payment = await createPayment(data);
      console.log('payment: ', payment);
      if (payment) {
        queryClient.invalidateQueries({
          queryKey: PAYMENT_KEYS.list({
            eventId: newPayment?.event?.id,
            type,
          }),
        });
        onClose();
        toast({
          title: t('add_payment_dialog.payment_added'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
      }
    } catch (error) {
      toast({
        title: (error as any)?.response?.data?.message,
        status: 'error',
        position: 'top-right',
      });
    }
  }, [newPayment, myBusiness?.id, type, toast, t, onClose, queryClient, type]);

  useEffect(() => {
    if (dialog?.item?.event) {
      setNewPayment((prev) => ({
        ...prev,
        event: dialog?.item?.event,
      }));
    }
  }, [dialog?.item?.event]);

  // calculate gross
  useEffect(() => {
    setNewPayment((prev) => ({
      ...prev,
      gross: calculateGross({
        amount: prev.amount,
        vat: businessPreferences?.vatPercent,
        isVatIncluded: prev.isVatIncluded,
      }),
    }));
  }, [
    newPayment.amount,
    newPayment.vat,
    newPayment.isVatIncluded,
    businessPreferences?.vatPercent,
  ]);

  // calculate vat
  useEffect(() => {
    setNewPayment((prev) => ({
      ...prev,
      vat: calculateVat({
        amount: prev.amount,
        vat: businessPreferences?.vatPercent,
        isVatIncluded: prev.isVatIncluded,
      }),
    }));
  }, [
    newPayment.amount,
    newPayment.isVatIncluded,
    businessPreferences?.vatPercent,
  ]);

  // calculate total amount
  useEffect(() => {
    setNewPayment((prev) => ({
      ...prev,
      totalAmount: calculateTotalAmount({
        gross: prev.amount,
        discount: prev.discount,
      }),
    }));
  }, [newPayment.amount, newPayment.discount]);

  // calculate total vat and amount to pay
  useEffect(() => {
    setNewPayment((prev) => ({
      ...prev,
      totalVat: calculateTotalVat({
        vat: businessPreferences?.vatPercent,
        totalAmount: prev.totalAmount,
        isVatIncluded: prev.isVatIncluded,
      }),
      amountToPay: calculateAmountToPay({
        vat: businessPreferences?.vatPercent,
        totalAmount: prev.totalAmount,
        isVatIncluded: prev.isVatIncluded,
      }),
    }));
  }, [newPayment.gross, newPayment.discount, newPayment.totalAmount]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        maxH={'90%'}
        minW={{ base: '100%', md: '686px' }}
        p="24px"
        gap="24px"
        borderRadius="30px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="previewQuoteContactTitle">
            {t(`add_payment_dialog.title_${type}`)}
          </Text>
          <IconButton
            icon={<LuX />}
            onClick={onClose}
            aria-label="close"
            color="#00000066"
            w="20px"
            h="20px"
            minW="20px"
            maxW="20px"
            cursor="pointer"
          />
        </Flex>
        <VStack
          overflow={'scroll'}
          w="100%"
          flex="1"
          justify={'stretch'}
          align="stretch">
          <Flex alignItems="center" gap="12px" wrap="wrap">
            {type === 'income' && (
              <Button
                margin={'0 !important'}
                onClick={onOpenAddClient}
                variant="h2outlined">
                {t('create_event.label.create_new_client')}
              </Button>
            )}
            {type === 'income' && (
              <Button
                variant="h1cta"
                w="100%"
                flex="1"
                onClick={handleSelectClient}>
                {t('add_payment_dialog.choose_client')}
              </Button>
            )}

            {type === 'expense' && (
              <Button
                variant="h1cta"
                w="100%"
                flex="1"
                onClick={handleSelectSupplier}>
                {t('add_payment_dialog.choose_supplier')}
              </Button>
            )}

            {newPayment.event && (
              <EventCard
                event={newPayment.event}
                onClick={handleSelectEvent}
                isQuoteEvent
                maxW={{ base: '100%', md: '320px' }}
                flex="1"
                handleClickXIcon={handleRemoveEvent}
              />
            )}

            {!newPayment.event && (
              <Button
                variant="h1cta"
                w="100%"
                flex="1"
                onClick={handleSelectEvent}>
                {t('add_payment_dialog.assigned_event')}
              </Button>
            )}
          </Flex>
          <Flex wrap="wrap" gap="12px">
            {type === 'income' &&
              clientsList.map((c, index) => (
                <ChosenClient
                  key={index}
                  client={'type' in c ? c : undefined}
                  contact={'type' in c ? undefined : (c as IClientContact)}
                  removeClient={removeClient}
                />
              ))}
            {type === 'expense' && newPayment.supplier && (
              <ChosenSupplier
                supplier={newPayment.supplier}
                onRemove={handleRemoveSupplier}
              />
            )}
          </Flex>
          <DropDownField
            closeOnSelect
            options={typeOptions}
            selectedOptions={selectedOptions}
            showedOptions={showedOptions}
            onSelected={onSelectedTypeOfTransaction}
            placeholder={t('add_payment_dialog.type_of_transaction')}
          />
          <Flex alignItems="end" gap="12px" wrap="wrap">
            <InputField
              w="155px"
              label={t('add_payment_dialog.amount')}
              placeholder={'0.00'}
              type="number"
              min={0}
              iconLeft={<Text variant="currency">₪</Text>}
              value={newPayment.amount}
              name="amount"
              onChange={handleChange}
            />
            <InputField
              w="155px"
              label={t('add_payment_dialog.vat', {
                percentage: businessPreferences?.vatPercent,
              })}
              placeholder={'0.00'}
              type="number"
              min={0}
              iconLeft={<Text variant="currency">₪</Text>}
              value={newPayment.vat}
              name="vat"
              borderColor="#F6F6F6"
              bg="#F6F6F6"
            />
            <InputField
              w="155px"
              label={t('add_payment_dialog.gross')}
              placeholder={'0.00'}
              type="number"
              min={0}
              iconLeft={<Text variant="currency">₪</Text>}
              value={newPayment.gross}
              name="gross"
              borderColor="#F6F6F6"
              bg="#F6F6F6"
            />
            <Checkbox
              variant="roundBrand"
              isChecked={newPayment.isVatIncluded}
              onChange={handleChangeVatIncluded}
              w={{ base: '100%', md: 'auto' }}
              h="50px"
              alignItems="center">
              <Text variant="createQuoteCheckboxText">
                {t('add_payment_dialog.vat', {
                  percentage: businessPreferences?.vatPercent,
                })}
              </Text>
            </Checkbox>
            <InputField
              w="155px"
              label={t('add_payment_dialog.discount')}
              placeholder={'0.00'}
              type="number"
              min={0}
              iconLeft={<Text variant="currency">₪</Text>}
              value={newPayment.discount}
              name="discount"
              onChange={handleChange}
            />
          </Flex>
          <InputDateField
            label={t('add_payment_dialog.due_date')}
            placeholder={t('add_payment_dialog.due_date')}
            value={newPayment.data}
            onChange={handleChangeDate}
            buttonStyles={{
              borderColor: '#0000001A',
              justifyContent: 'space-between',
              w: '100%',
            }}
            imgStyles={{
              bg: 'transparent',
            }}
            imgSrc={CalendarIcon}
          />
          <TextField
            label={t('add_payment_dialog.description')}
            placeholder={t('add_payment_dialog.description_placeholder')}
            value={newPayment.description}
            onChange={handleChangeDescription}
          />
          {!isDisabled && (
            <Flex flexDirection="column" gap="16px">
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="previewQuoteSummaryLabel">
                  {t('add_payment_dialog.total')}
                </Text>
                <Text variant="previewQuoteProductItemName">
                  {formatCurrency(newPayment.amount, '₪')}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="previewQuoteSummaryLabel">
                  {t('add_payment_dialog.discount')}
                </Text>
                <Text variant="previewQuoteProductItemName">
                  {formatCurrency(newPayment.discount, '₪')}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="previewQuoteSummaryLabel">
                  {t('add_payment_dialog.total_payment')}
                </Text>
                <Text variant="previewQuoteProductItemName">
                  {formatCurrency(newPayment.totalAmount, '₪')}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="previewQuoteSummaryLabel">
                  {t('add_payment_dialog.vat', {
                    percentage: businessPreferences?.vatPercent,
                  })}
                </Text>
                <Text variant="previewQuoteProductItemName">
                  {formatCurrency(newPayment.totalVat, '₪')}
                </Text>
              </Flex>
              <Divider />
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="previewQuoteSummaryLabel">
                  {t('add_payment_dialog.amount_to_pay')}
                </Text>
                <Text variant="previewQuoteProductItemName">
                  {formatCurrency(newPayment.amountToPay, '₪')}
                </Text>
              </Flex>
            </Flex>
          )}
        </VStack>
        <Button
          variant="h1cta"
          isLoading={isPending}
          isDisabled={isDisabled}
          w={{ base: '100%', md: '320px' }}
          alignSelf="center"
          onClick={handleAddPayment}>
          {t('add_payment_dialog.add')}
        </Button>
      </ModalContent>
      <AddClientModal
        isOpen={isAddClientOpen}
        onClose={(params?: {
          newClientData: IClient;
          contacts?: IClientContact[];
        }) => {
          if (params) {
            handleCloseClientModal(params.newClientData);
          } else {
            handleCloseClientModal();
          }
        }}
      />
    </Modal>
  );
}
