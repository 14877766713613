import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  AppDialogProps,
  closeAlertDialog,
  openAlertDialog,
  setQuoteSharingDialog,
  setUploadQuoteDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { deleteQuote, updateQuote } from 'contexts/redux/quote/quoteSlice';
import { AppDispatch } from 'contexts/redux/store';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import UploadService from 'services/upload.api';
import { IQuote, QuoteStatus } from 'services/@types';

interface IQuoteThreeDotsMenuProps {
  id: string;
  status: QuoteStatus;
  media: IQuote['media'];
  businessId: string;
  activeTab: 'sent' | 'received' | 'templates';
  quote: IQuote;
  fetchQuotes: any;
}

export const QuoteThreeDotsMenu = memo((props: IQuoteThreeDotsMenuProps) => {
  const { id, status, media, businessId, activeTab, quote, fetchQuotes } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { appNavigate: navigate } = useAppNavigate();
  const myBusiness = useMyBusiness();

  const handleDownloadPdf = useCallback(
    (e: any) => {
      e.stopPropagation();
      dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));
      setTimeout(() => {
        navigate(`/main/finance/quotes/preview/${id}?download=true`);
      }, 400);
    },
    [navigate, id, dispatch],
  );

  const handleNotifySenderAndConfirmQuote = useCallback(
    (notify?: boolean, fetchQuotes?: any) => {
      dispatch(
        updateQuote({
          id: id,
          updates: { status: 'signed' },
          query: {
            notify: notify ? true : undefined,
          },
        }),
      ).then(() => {
        toast({
          title: t('quote_list.quote_confirmed'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
        fetchQuotes();
      });
    },
    [dispatch, id, t, toast],
  );

  const handleConfirmQuote = useCallback(
    (e: any, fetchQuotes?: any) => {
      e.stopPropagation();
      // if the quote is from the same business, we don't need to notify the sender
      if (businessId && businessId === myBusiness?.id) {
        handleNotifySenderAndConfirmQuote(false, fetchQuotes);
        return;
      }

      dispatch(
        openAlertDialog({
          title: t('quote_list.notify_sender_that_quote_is_signed'),
          content: t(
            'quote_list.notify_sender_that_quote_is_signed_description',
          ),
          onConfirm: () => {
            handleNotifySenderAndConfirmQuote(true, fetchQuotes);
            dispatch(closeAlertDialog());
          },
          onClose: (index: number) => {
            dispatch(closeAlertDialog({ index }));
            handleNotifySenderAndConfirmQuote(false, fetchQuotes);
          },
        }),
      );
    },
    [
      handleNotifySenderAndConfirmQuote,
      dispatch,
      t,
      myBusiness?.id,
      businessId,
    ],
  );

  const handleDownloadMedia = useCallback(
    async (e: any) => {
      e.stopPropagation();
      try {
        const mediaFiles = quote?.isManuallyUploaded
          ? quote.uploadedQuote
          : media;
        if (mediaFiles[0].displayName.endsWith('.pdf')) {
          handleOpenPdf(mediaFiles[0].url, mediaFiles[0].displayName);
          return;
        }
        console.log('media: ', mediaFiles);
        const response = await UploadService.getUpload(
          mediaFiles[0].url,
          'original',
        );
        console.log('response', response);
        const imageData = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = imageData;
        a.download = mediaFiles[0].displayName;
        a.click();
        toast({
          title: t('quote_list.download_quote_attachment_success'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
      } catch (error) {
        console.error('handleDownloadMedia error: ', error);
      }
    },
    [media, quote, toast, t],
  );

  const handleDeleteQuote = useCallback(
    async (e: any, fetchQuotes?: any) => {
      e.stopPropagation();
      await dispatch(deleteQuote(id)).unwrap();
      toast({
        title: t('quote_list.quote_deleted'),
        status: 'success',
        variant: 'main',
        position: 'top-right',
      });

      fetchQuotes();
    },
    [dispatch, id, t, toast],
  );

  const openUploadQuoteDialog = useCallback(() => {
    dispatch(
      setUploadQuoteDialog({ item: { quoteId: quote?.id } } as AppDialogProps),
    );
  }, [dispatch]);

  return (
    <Menu>
      <MenuButton onClick={(e) => e.stopPropagation()} w="max-content">
        <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
      </MenuButton>
      <MenuList>
        {!quote.isManuallyUploaded && (
          <MenuItem
            h="44px"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/main/finance/quotes/preview/${id}`);
            }}>
            <Text variant="eventTabButtonTitle">{t('quote_list.view')}</Text>
          </MenuItem>
        )}
        {!quote.isManuallyUploaded && (
          <MenuItem
            h="44px"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setQuoteSharingDialog({
                  item: {
                    quoteId: id,
                  },
                }),
              );
            }}>
            <Text variant="eventTabButtonTitle">{t('quote_list.share')}</Text>
          </MenuItem>
        )}
        {!quote.isManuallyUploaded && (
          <MenuItem h="44px" onClick={handleDownloadPdf}>
            <Text variant="eventTabButtonTitle">
              {t('quote_list.download')}
            </Text>
          </MenuItem>
        )}

        {myBusiness?.id === businessId &&
          quote.isManuallyUploaded &&
          activeTab === 'received' && (
            <MenuItem
              h="44px"
              onClick={(e) => {
                e.stopPropagation();
                openUploadQuoteDialog();
              }}>
              <Text variant="eventTabButtonTitle">
                {t('quote_list.editUploaded')}
              </Text>
            </MenuItem>
          )}
        {myBusiness?.id === businessId && activeTab !== 'received' && (
          <MenuItem
            h="44px"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/main/finance/quotes/${id}`);
            }}>
            <Text variant="eventTabButtonTitle">{t('quote_list.edit')}</Text>
          </MenuItem>
        )}
        {(status === 'pendingContract' && activeTab === 'sent') ||
          (status !== 'signed' && activeTab === 'received' && (
            <MenuItem
              h="44px"
              onClick={(e) => {
                handleConfirmQuote(e, fetchQuotes);
              }}>
              <Text variant="eventTabButtonTitle">
                {t('quote_list.confirm')}
              </Text>
            </MenuItem>
          ))}
        {(activeTab !== 'received' || quote.isManuallyUploaded) && (
          <MenuItem
            h="44px"
            onClick={(e) => {
              handleDeleteQuote(e, fetchQuotes);
            }}>
            <Text variant="eventTabButtonTitle">{t('quote_list.delete')}</Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
});
