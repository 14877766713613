import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import BankNoteIconSvg from 'assets/svg/previewQuote/bankNoteIcon.svg';
import moment from 'moment';
import { formatNumber } from 'helpers/formatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePieChart } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import { IPaymentInstallment } from 'services/@types';

interface IPaymentItemProps {
  installment: IPaymentInstallment;
  formattedCurrency: string;
  index: number;
  length: number;
  dir?: string;
  lang?: string;
}

export default function PaymentItem(props: IPaymentItemProps) {
  const { installment, formattedCurrency, index, length } = props;
  const lang = props.lang;
  const { t } = useTranslation('', {
    lng: lang,
  });

  const formattedDate = useMemo(() => {
    if (!installment.dueDate) return '-';
    return moment(installment.dueDate).locale(lang || 'he').format('DD MMM, YYYY');
  }, [installment.dueDate, lang]);

  const formattedAmount = useMemo(() => {
    if (!installment.amount) return '-';
    return (
      formattedCurrency + formatNumber(installment.amount).toLocaleString()
    );
  }, [installment.amount, formattedCurrency]);

  return (
    <Flex
      bg="#F6F6F6"
      borderRadius="16px"
      justify="space-between"
      p="10px 12px">
      <Flex alignItems="center" gap="8px">
        <Icon as={AiOutlinePieChart} color="#060518" w="15px" h="15px" />
        <Text variant="previewQuotePaymentItem">
          {index + 1}\{length}
        </Text>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Icon as={FiCalendar} color="#060518" w="15px" h="15px" />
        <Text variant="previewQuotePaymentItem">{formattedDate}</Text>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Image src={BankNoteIconSvg} alt="bankNoteIcon" />
        <Text variant="previewQuotePaymentItem">{formattedAmount}</Text>
      </Flex>
    </Flex>
  );
}
