import { closeAlertDialog } from 'contexts/redux/dialog/dialogsSlice';

import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { openAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { updateQuote } from 'contexts/redux/quote/quoteSlice';
import { AppDispatch } from 'contexts/redux/store';
import { format } from 'date-fns';
import { useGetEntity } from 'queries/entity';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IClient, IPayment, Supplier, Business } from 'services/@types';
import QuoteService from 'services/quote.api';

interface IuseHandlePaymentItemProps {
  quoteId: string;
  payment: IPayment;
  paymentIndex: number;
  isPaid: boolean;
  date: string | Date;
}

export default function useHandlePaymentItem(
  props: IuseHandlePaymentItemProps,
) {
  const { quoteId, payment, paymentIndex, isPaid, date } = props;
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { t } = useTranslation();
  const formattedDate = useMemo(() => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  }, [date]);
  const { data: quoteData } = useQuery({
    queryKey: ['quote', quoteId],
    queryFn: () => QuoteService.getQuote(quoteId),
    enabled: !!quoteId,
  });
  const { data: supplier } = useGetEntity<Supplier>(
    'supplier',
    quoteData?.supplierId || payment?.supplierId,
  );
  const { data: business } = useGetEntity<Business>('business', quoteData?.businessId);
  const { data: client } = useGetEntity<IClient>(
    'client',
    quoteData?.clients[0]?.client?.id,
  );

  const handlePay = useCallback(() => {
    if (!isPaid) {
      dispatch(
        openAlertDialog({
          title: t('event_finance.mark_as_paid'),
          content: t('event_finance.mark_as_paid_description'),
          onConfirm: () => {
            dispatch(
              updateQuote({
                id: quoteData?.id,
                updates: {
                  paymentInInstallments: {
                    ...quoteData?.paymentInInstallments,
                    installments:
                      quoteData?.paymentInInstallments.installments.map(
                        (payment, index) =>
                          index === paymentIndex
                            ? {
                              ...payment,
                              isPaid: true,
                              dueDate: new Date(),
                            }
                            : payment,
                      ),
                  },
                },
              }),
            );
            toast({
              title: t('event_finance.paid_successfully'),
              variant: 'main',
              position: 'top-right',
            });
            dispatch(closeAlertDialog());
          },
        }),
      );
    }
  }, [
    isPaid,
    quoteData?.id,
    quoteData?.paymentInInstallments,

    paymentIndex,
    dispatch,
    t,
    toast,
  ]);

  return {
    handlePay,
    formattedDate,
    quote: quoteData,
    supplier,
    client,
    business
  };
}
