import { Collapse, Flex, Hide, Text } from '@chakra-ui/react';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PackageIcon from 'views/main/finance/quote/createQuote/components/PackageIcon';
import PackageProductInfo from 'views/main/finance/quote/createQuote/components/PackageProductInfo';
import { AddProductModal } from 'views/main/managment/products/components/AddProductModal';
import useInvoiceProduct from '../hooks/useInvoiceProduct';
import ProductActions from './ProductActions';

interface IInvoiceProductProps {
  product: any;
  index: number;
  handleSelectedProduct: (e: any, index: number) => void;
  currencyIcon: string;
  error: {
    products: boolean;
    totalPayment: boolean;
  };
  handleChangeProductVatIncluded: (e: any, index: number) => void;
  handleChangeProductDescription: (e: any, index: number) => void;
  handleChangeProductAmount: (e: any, index: number) => void;
  handleChangeProductPrice: (e: any, index: number) => void;
  handleRemoveProduct: (index: number) => void;
  updateProductByCurrencyChange: (productData: any, index: number) => void;
}
function InvoiceProduct(props: IInvoiceProductProps) {
  const { t } = useTranslation();
  const {
    product,
    index,
    handleSelectedProduct,
    currencyIcon,
    error,
    handleChangeProductVatIncluded,
    handleChangeProductDescription,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    updateProductByCurrencyChange,
  } = props;
  const { data: businessPreferences } = useGetBusinessPreferences();

  const {
    memoProducts,
    isPackage,
    onOptionsRendered,
    isAddProductOpen,
    onOpenAddProduct,
    currentEditableProduct,
    handleAddProductClosed,
    isPackageInfoVisible,
    handlePackageInfoVisibility,
    productData,
    showOptions,
  } = useInvoiceProduct({
    product,
    updateProductByCurrencyChange,
    index,
  });

  return (
    <Flex
      gap="12px"
      alignItems="end"
      wrap="wrap"
      flexDirection={{ base: 'column', md: 'row' }}>
      <Hide above="md">
        <ProductActions
          product={product}
          index={index}
          handleChangeProductVatIncluded={handleChangeProductVatIncluded}
          businessPreferences={businessPreferences}
          handleRemoveProduct={handleRemoveProduct}
          Parent={Flex}
          ParentProps={{
            justifyContent: 'space-between',
            alignItems: 'center',
            // w: '100%',
          }}
        />
      </Hide>
      <InputAutocompleteField
        label={t('create_invoice.description')}
        placeholder={t('create_invoice.description')}
        maxWidth={{ base: '100%', md: '256px' }}
        w={{ base: '100%', md: '256px' }}
        value={product.description}
        options={memoProducts}
        onSelected={(e: any) => {
          handleSelectedProduct(e, index);
        }}
        isIconWidthAuto={isPackage}
        // borderColor={error.products ? 'error.100' : 'gray.200'}
        onChange={(e) => handleChangeProductDescription(e, index)}
        onOptionsRendered={onOptionsRendered}
        iconLeft={<PackageIcon isPackage={isPackage} />}
      />
      {!!showOptions && (
        <>
          <InputField
            label={t('create_invoice.quantity')}
            placeholder={t('create_invoice.quantity')}
            maxWidth={{ base: '100%', md: '115px' }}
            w={{ base: '100%', md: '115px' }}
            type="number"
            value={product?.quantity}
            min={1}
            onChange={(e) => handleChangeProductAmount(e, index)}
          />
          <InputField
            label={t('create_invoice.amount')}
            placeholder={t('create_invoice.amount')}
            maxWidth={{ base: '100%', md: '115px' }}
            w={{ base: '100%', md: '115px' }}
            type="number"
            min={1}
            value={product?.amount}
            onChange={(e) => handleChangeProductPrice(e, index)}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
          <InputField
            label={t('create_invoice.net')}
            placeholder={t('create_invoice.net')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            borderColor="#F6F6F6"
            type="number"
            value={product?.net}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
          <InputField
            label={t('create_invoice.vat_title')}
            placeholder={t('create_invoice.vat_title')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            borderColor="#F6F6F6"
            type="number"
            value={product?.vat}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
          <InputField
            label={t('create_invoice.gross')}
            placeholder={t('create_invoice.gross')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            borderColor="#F6F6F6"
            type="number"
            value={product?.gross}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
          <Hide below="md">
            <ProductActions
              product={product}
              index={index}
              handleChangeProductVatIncluded={handleChangeProductVatIncluded}
              businessPreferences={businessPreferences}
              handleRemoveProduct={handleRemoveProduct}
              Parent={React.Fragment}
            />
          </Hide>
        </>
      )}
      {!showOptions && (
        <>
          <Flex alignItems="center" h="50px">
            <Text variant="createQuoteCheckboxText">
              {t('create_quote.no_product')}
            </Text>
            <Text
              variant="addNewProduct"
              cursor="pointer"
              onClick={onOpenAddProduct}>
              {t('create_quote.add_new_product')}
            </Text>
          </Flex>
          <AddProductModal
            isOpen={isAddProductOpen}
            editableProduct={currentEditableProduct}
            onClose={handleAddProductClosed}
            onFoundExistingProduct={() => {}}
          />
        </>
      )}
      {isPackage && (
        <Collapse in={isPackageInfoVisible} animateOpacity>
          <Flex flexDirection="column" gap="16px">
            {productData?.subItems?.map((subItem, key) => (
              <PackageProductInfo productId={subItem as string} key={key} />
            ))}
          </Flex>
        </Collapse>
      )}
      {isPackage && (
        <Flex alignItems="center" justifyContent="center">
          <Text
            variant="addNewProduct"
            cursor="pointer"
            onClick={handlePackageInfoVisibility}>
            {t(
              isPackageInfoVisible
                ? 'create_quote.hide_package'
                : 'create_quote.show_package',
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

export default memo(InvoiceProduct);
