import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { setImportDataDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppNavigate } from 'hooks/useAppNavigate';
import {
  animatedContainerItemStyles,
  animatedContainerStyles,
} from 'motionVariants';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IEvent } from 'services/@types';
import eventsService from 'services/event.api';
import GridEventCard from 'views/main/managment/events/eventsOverview/components/GridEventCard';
import NoEventsCreated from 'views/main/managment/events/eventsOverview/components/NoEventsCreated';

interface UpcomingEventsProps {
  cardStyles?: any;
}
export default function UpcomingEvents(props: UpcomingEventsProps) {
  const { cardStyles = {} } = props;
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { data: events, isLoading } = useQuery({
    queryKey: ['dashboardUpcomingEvents'],
    queryFn: () =>
      eventsService.getEvents({
        upcomingDate: new Date(),
        limit: 6,
        sortBy: 'dateAndTime',
      }),
  });

  const handleOpenImportDialog = () => {
    dispatch(setImportDataDialog({ item: { activeTab: 0 } }));
  };

  return (
    <Card
      flex={1}
      minWidth={{ base: '100%', md: '200px' }}
      maxH="580px"
      minH="580px"
      gap="20px"
      borderRadius={{ base: '0px', md: '30px' }}
      {...cardStyles}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="cardTitle">{t('upcoming_events.title')}</Text>
        <Text
          variant="eventSubTitle"
          textDecoration="underline"
          onClick={() => navigate('/main/events')}
          cursor="pointer">
          {t('upcoming_events.see_all')}
        </Text>
      </Flex>
      <AnimatePresence mode="wait">
        <motion.div
          variants={animatedContainerStyles}
          initial="hidden"
          style={{
            width: 'inherit',
            height: 'inherit',
            overflow: 'auto',
          }}
          animate="visible">
          <motion.div
            variants={animatedContainerItemStyles}
            style={{ width: 'inherit', height: 'inherit' }}>
            <Flex
              display={
                !isLoading && events?.results?.length > 0 ? 'flex' : 'none'
              }
              wrap="wrap"
              overflowY="auto"
              gap="12px">
              {events?.results?.map((event: IEvent, index: number) => (
                <GridEventCard key={index} event={event} />
              ))}
            </Flex>
            {isLoading && (
              <Flex
                flex={1}
                justifyContent="center"
                alignItems="center"
                minH="200px">
                <Spinner />
              </Flex>
            )}
            {!isLoading && events?.results?.length === 0 && (
              <NoEventsCreated onOpenImportDialog={handleOpenImportDialog} />
            )}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </Card>
  );
}
