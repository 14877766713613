import { BellIcon } from '@chakra-ui/icons';
import {
  HStack,
  Icon,
  RenderProps,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { onNewMessageReceived } from 'contexts/redux/messenger/messengerSlice';
import {
  fetchNotifications,
  onNewNotificationReceived,
} from 'contexts/redux/notifications/notificationsSlice';
import { sendPushNotificationToken } from 'contexts/redux/pushNotificationToken/pushNotificationTokenSlice';
import { RootState } from 'contexts/redux/store';
import moment from 'moment';
import { createEntityHook } from 'queries/entity';
import useGetUnreadNotificationsCount, {
  getNotificationsQueryKey,
  useGetReadNotifications,
  useGetUnreadNotifications,
} from 'queries/notifications';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, messaging, onMessage } from '../../config/firebase';

export const NotifyAlertDialog: React.FC<
  {
    title: string;
    body: string;
    data: {
      type: string;
      threadId?: string;
      messageId?: string;
      senderName?: string;
    };
  } & RenderProps
> = ({ title, body, data, ...props }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    toast.close(props.id);
    window.location.href = `/main/messenger/${data.threadId}`;

    toast.close(props.id);
  }, [data.threadId, props.id, toast]);
  return (
    <VStack
      onClick={handleClick}
      p={2}
      w="100%"
      cursor="pointer"
      pe={4}
      maxW="300px"
      spacing={0}
      borderRadius="lg"
      align="flex-start"
      justify="flex-start"
      bgGradient={{
        base: 'linear(to-b, brand.400, brand.600)',
        sm: 'linear(to-r, brand.400, brand.600)',
        md: 'linear(to-l, brand.400, brand.600)',
        lg: 'linear(to-l, brand.400, brand.600)',
        xl: 'linear(to-l, brand.400, brand.600)',
      }}
      color="primary.100">
      <HStack spacing={2} justify="flex-start" align="flex-start" w="100%">
        <Icon as={BellIcon} fontSize="2xl" />
        <VStack spacing={0} align="flex-start" justify="flex-start">
          <Text fontWeight="bold" noOfLines={2}>
            {t(title)}
          </Text>
          <Text fontSize="sm" fontWeight="medium" noOfLines={3}>
            {t(body, { name: data.senderName || '' })}
          </Text>
        </VStack>
        <Spacer />
        <Icon cursor="pointer" as={LuX} onClick={() => toast.close(props.id)} />
      </HStack>
    </VStack>
  );
};

export const PushNotificationAlert = () => {
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const { t } = useTranslation();

  const myUser = useMyUser();
  const { refetch: refetchUnreadNotificationsCount } =
    useGetUnreadNotificationsCount();
  const { refetch: refetchNotificationsUnread } = useGetUnreadNotifications();
  const { refetch: refetchNotificationsRead } = useGetReadNotifications();

  const { currentThread } = useSelector((state: RootState) => state.messenger);
  const queryClient = useQueryClient();

  // Handle notification payload
  const handleNotificationPayload = useCallback(
    (payload: any) => {
      console.log('@@ payload', payload);
      const { notification, data } = payload;
      const title = notification?.title || t('notifications.new_notification');
      const body = notification?.body || t('notifications.new_notification');

      // Handle quote type notifications
      if (data?.type === 'quote' && data.entityId) {
        const entity = createEntityHook(data?.type);
        entity.invalidate(queryClient, data?.entityId);
      }
      refetchUnreadNotificationsCount();

      refetchNotificationsRead();
      refetchNotificationsUnread();

      // Handle message notifications
      if (data?.messageId) {
        // Skip notification if user is already in the thread
        if (currentThread?.id === data?.threadId) {
          return;
        }

        toast({
          title: title,
          position: 'top-right',
          variant: 'main',
          render: (props: RenderProps) => (
            <NotifyAlertDialog
              {...props}
              title={title}
              body={body}
              data={data}
            />
          ),
          description: body,
          status: 'info',
        });

        dispatch(onNewMessageReceived(data.messageId));
      }

      // Handle system notifications
      if (data?.notificationId) {
        const formattedDueDate = notification?.variables?.dueDate
          ? moment(notification.variables.dueDate).format('D MMM, YYYY')
          : undefined;

        const translationVariables = {
          ...(data || {}),
          ...(formattedDueDate ? { dueDate: formattedDueDate } : {}),
        };

        toast({
          title: t(title, translationVariables),
          position: 'top-right',
          variant: 'main',
          description: t(body, translationVariables),
        });

        dispatch(onNewNotificationReceived(data.notificationId));
      }
    },
    [currentThread?.id, dispatch, queryClient, t, toast],
  );

  // Setup push notification token
  const setupPushNotification = useCallback(async () => {
    if (!messaging || !myUser?.id) return;

    try {
      const permission = await Notification.requestPermission();
      console.log(`@@ Permission : ${permission}`);

      if (permission === 'granted') {
        try {
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY,
          });
          console.log('@@ Token generated : ', token);

          if (token) {
            await dispatch(
              sendPushNotificationToken({
                device: navigator.userAgent,
                token,
                userId: myUser.id,
              }),
            );
          }
        } catch (tokenError) {
          console.error('Error getting notification token:', tokenError);
        }
      }
    } catch (permissionError) {
      console.error(
        'Error requesting notification permission:',
        permissionError,
      );
    }
  }, [dispatch, myUser?.id]);

  // Invalidate notifications on window focus
  const invalidateNotifications = useCallback(() => {
    console.log('Invalidating notifications on window focus');
    refetchUnreadNotificationsCount();

    refetchNotificationsRead();
    refetchNotificationsUnread();
  }, [
    refetchNotificationsRead,
    refetchNotificationsUnread,
    refetchUnreadNotificationsCount,
  ]);

  // Setup push notification effect
  useEffect(() => {
    setupPushNotification();

    // Setup message listener
    let unsubscribe: (() => void) | undefined;

    if (messaging) {
      unsubscribe = onMessage(messaging, handleNotificationPayload);
    }

    // Cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [setupPushNotification, handleNotificationPayload]);

  // Setup focus listener effect
  useEffect(() => {
    // Add focus listener to invalidate notifications
    window.addEventListener('focus', invalidateNotifications);

    // Cleanup
    return () => {
      window.removeEventListener('focus', invalidateNotifications);
    };
  }, [invalidateNotifications]);

  return <></>;
};

export default PushNotificationAlert;
