import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAlertDialog,
  openAlertDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { fetchUsers } from 'contexts/redux/user/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppNavigate } from 'hooks/useAppNavigate';
import {
  useCreateTask as useCreateTaskMutation,
  useDeleteTask,
  useGetTask,
  useUpdateTask,
} from 'queries/task';
import { IEvent, ITask, User } from 'services/@types';
import EventService from 'services/event.api';
import { AppDispatch } from 'contexts/redux/store';

const requiredFields = ['name'];

interface UseCreateTaskResult {
  task: Partial<ITask>;
  event: IEvent;
  handleChangeTask: (e: any) => void;
  handleCreateTask: () => void;
  handleOpenEventDialog: () => void;
  errorList: string[];
  isUpdatePage: boolean;
  handleDeleteTask: () => void;
  handleUpdateTask: () => void;
  currentAssigned: string;
  setCurrentAssigned: (value: string) => void;
  displayingAssigned: any[];
  handleSelectAssigned: (value: string) => void;
  handleRemoveAssigned: (value: string) => void;
  isDisabled: boolean;
}

export default function useCreateTask(): UseCreateTaskResult {
  const business = useMyBusiness();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { triggerChangesDetected, preventChangesDetected } = useAppNavigate();
  const [event, setEvent] = useState<IEvent>(null);

  // State management
  const [task, setTask] = useState<Partial<ITask>>({
    assigned: [],
    dateAndTime: new Date(),
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [currentAssigned, setCurrentAssigned] = useState<string>('');
  const [displayingAssigned, setDisplayingAssigned] = useState<any[]>([]);

  // Derived values
  const isUpdatePage = useMemo(() => !!params?.id, [params?.id]);

  // React Query hooks
  const { data: taskData } = useGetTask(params?.id || '', {
    enabled: isUpdatePage,
  });
  const { mutateAsync: createTask } = useCreateTaskMutation();
  const { mutateAsync: updateTask } = useUpdateTask();
  const { mutateAsync: deleteTask } = useDeleteTask();

  // Handlers
  const handleChangeTask = useCallback(
    (e: any) => {
      triggerChangesDetected();
      if (isDisabled) {
        setIsDisabled(false);
      }
      if (errorList.includes(e.target.name)) {
        setErrorList((prev) => prev.filter((field) => field !== e.target.name));
      }
      if (e.target.value === '') {
        setTask((prev) => {
          const newTask: Partial<ITask> = { ...prev };
          const key = e.target.name as keyof ITask;
          if (key in newTask) {
            delete newTask[key];
          }
          return newTask;
        });
        return;
      }
      setTask((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [errorList, isDisabled, triggerChangesDetected],
  );

  const validateTask = useCallback(() => {
    let result = requiredFields.filter(
      (field) => !task || !task[field as keyof ITask],
    );

    if (event) {
      result = result.filter((field) => field !== 'event');
    }

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [task, event]);

  const handleCreateTask = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }

    const newTaskBody = { ...task, event: event?.id };

    try {
      await createTask(newTaskBody as ITask);
      preventChangesDetected();

      setTask(null);
      setEvent(null);

      toast({
        title: t('create_task.task_created'),
        variant: 'main',
      });

      const eventId = searchParams.get('eventId');
      if (eventId) {
        navigate(`/main/event/${eventId}`);
      } else {
        if (window?.history?.length > 1) {
          navigate(-1);
        } else {
          navigate('/main/dashboard');
        }
      }
    } catch (error) {
      console.error('Error creating task:', error);
      toast({
        title: t('create_task.error'),
        status: 'error',
        variant: 'main',
      });
    }
  }, [
    toast,
    t,
    task,
    event?.id,
    preventChangesDetected,
    createTask,
    validateTask,
    navigate,
    searchParams,
  ]);

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (errorList.includes('event')) {
            setErrorList((prev) => prev.filter((field) => field !== 'event'));
          }
          setEvent(e);
        },
      }),
    );
  }, [dispatch, errorList]);

  const handleDeleteTask = useCallback(async () => {
    if (!params?.id) {
      return;
    }

    dispatch(
      openAlertDialog({
        title: 'create_task.dialogs.delete_task.title',
        onConfirm: async () => {
          try {
            await deleteTask(params?.id);
            navigate(-1);
          } catch (error) {
            console.error('Error deleting task:', error);
            toast({
              title: t('create_task.delete_error'),
              status: 'error',
              variant: 'main',
            });
          } finally {
            dispatch(closeAlertDialog());
          }
        },
      }),
    );
  }, [dispatch, params?.id, navigate, deleteTask, toast, t]);

  const handleUpdateTask = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }

    try {
      await updateTask({
        id: params.id,
        updates: { ...task, event: event?.id },
      });

      setTask(null);
      setEvent(null);

      toast({
        title: t('create_task.task_updated'),
        variant: 'main',
        position: 'top-right',
      });

      navigate(-1);
    } catch (error) {
      console.error('Error updating task:', error);
      toast({
        title: t('create_task.update_error'),
        status: 'error',
        variant: 'main',
      });
    }
  }, [
    task,
    event?.id,
    updateTask,
    params.id,
    toast,
    t,
    navigate,
    validateTask,
  ]);

  const handleSelectAssigned = useCallback((value) => {
    setTask((prev) => ({ ...prev, assigned: [...prev.assigned, value] }));
    setCurrentAssigned('');
  }, []);

  const handleRemoveAssigned = useCallback((value) => {
    setTask((prev) => ({
      ...prev,
      assigned: prev.assigned.filter((guest: string) => guest !== value),
    }));
  }, []);

  // Effects
  useEffect(() => {
    if (taskData) {
      setTask(taskData);

      if (taskData.event) {
        const fetchEvent = async () => {
          try {
            const event = await EventService.getEvent(taskData.event);
            if (event) {
              setEvent(event);
              setTask((prev) => ({ ...prev, dateAndTime: event.dateAndTime }));
            }
          } catch (error) {
            console.error('Error fetching event:', error);
          }
        };
        fetchEvent();
      }
    }
  }, [taskData]);

  useEffect(() => {
    if (currentAssigned.length > 0 && business?.id) {
      const fetchGuests = async () => {
        const guests: any = await dispatch(
          fetchUsers({
            businessID: business?.id,
            name: currentAssigned,
            limit: 5,
          }),
        );
        if (guests?.payload?.results) {
          setDisplayingAssigned(
            guests.payload.results
              ?.filter((guest: User) => !task?.assigned?.includes(guest?.id))
              .map((guest: User) => ({
                avatar: guest.media?.find((media) => media.type === 'avatar'),
                email: guest.email,
                label: `${guest.firstName} ${guest.lastName}`,
                value: guest.id,
              })),
          );
        }
      };
      fetchGuests();
    }
  }, [currentAssigned, business?.id, dispatch, task?.assigned]);

  useEffect(() => {
    const eventId = searchParams.get('eventId');
    if (eventId) {
      const fetchEvent = async () => {
        try {
          const event = await EventService.getEvent(eventId);
          if (event) {
            setEvent(event);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchEvent();
    }
  }, [searchParams]);

  return {
    task,
    event,
    handleChangeTask,
    handleCreateTask,
    handleOpenEventDialog,
    errorList,
    handleDeleteTask,
    handleUpdateTask,
    currentAssigned,
    setCurrentAssigned,
    displayingAssigned,
    handleSelectAssigned,
    handleRemoveAssigned,
    isDisabled,
    isUpdatePage,
  };
}
