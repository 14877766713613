import { useQueryClient } from '@tanstack/react-query';
import { AppDispatch } from 'contexts/redux/store';
import {
  getUnreadNotificationsCountQueryKey,
  useMarkNotificationAsRead,
} from 'queries/notifications';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { INotification } from 'services/@types';
import ButtonNotification from './ButtonNotification';
import PlainIconNotification from './PlainIconNotification';
import PlainNotification from './PlainNotification';
import RequestNotification from './RequestNotification';
import SystemNotification from './SystemNotification';
import TaskNotification from './TaskNotification';

export interface INotificationComponentProps {
  notification: INotification;
  notificationReceivedId?: string;
}

export const getNotificationMomentFormat = (language: string) => {
  return language.startsWith('he') ? 'DD MMM' : 'MMM DD';
};

const NotificationComponent = ({
  notification,
  notificationReceivedId,
}: INotificationComponentProps) => {
  const { category } = notification;
  const queryClient = useQueryClient();
  const [isInViewport, setIsInViewport] = useState<boolean>(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead();
  useEffect(() => {
    const checkVisibility = () => {
      if (notificationRef.current) {
        const rect = notificationRef.current.getBoundingClientRect();
        const isIntersecting =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);

        if (isIntersecting) {
          const style = window.getComputedStyle(notificationRef.current);
          const isActuallyVisible =
            style.display !== 'none' &&
            style.visibility !== 'hidden' &&
            style.opacity !== '0';
          setIsInViewport(isActuallyVisible);
        } else {
          setIsInViewport(false);
        }
      }
    };

    // Initial check
    checkVisibility();

    // Set up scroll and resize listeners
    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);

    // Optional: You can also use a setInterval for periodic checks
    const intervalId = setInterval(checkVisibility, 1000); // Check every second

    return () => {
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isInViewport && !notification.readAt) {
      const updateNotification = async () => {
        await markNotificationAsRead(notification.id);
        await queryClient.invalidateQueries({
          queryKey: [
            getUnreadNotificationsCountQueryKey(),
            notificationReceivedId,
          ],
        });
      };
      updateNotification();
    }
  }, [
    isInViewport,
    notification.id,
    notification.readAt,
    queryClient,
    notificationReceivedId,
    markNotificationAsRead,
  ]);

  switch (category) {
    case 'plainIcon':
      return (
        <PlainIconNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
    case 'request':
      return (
        <RequestNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
    case 'plain':
      return (
        <PlainNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
    case 'button':
      return (
        <ButtonNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
    case 'task':
      return (
        <TaskNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
    case 'system':
      return (
        <SystemNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
    default:
      return (
        <SystemNotification
          notification={notification}
          notificationRef={notificationRef}
        />
      );
  }
};

export default NotificationComponent;
