import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Product } from 'services/@types';
import ProductService from 'services/product.api';
import {
  getUpdatedProductByAmount,
  getUpdatedProductByIls,
  getUpdatedProductByQuantity,
  getUpdatedProductByUsd,
} from '../helpers/calculations';

interface UseProductItemProps {
  product: any;
  setNewQuote: (newQuote: any) => void;
  index: number;
  currency: string;
  setIsUserInteraction: (value: boolean) => void;
}
export default function useProductItem(props: UseProductItemProps) {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const { product, setNewQuote, index, currency, setIsUserInteraction } = props;
  const [showOptions, setShowOptions] = useState(true);
  const [isPackageInfoVisible, setIsPackageInfoVisible] = useState(false);
  const [currentEditableProduct, setCurrentEditableProduct] =
    useState<Partial<Product>>(null);
  const {
    isOpen: isAddProductOpen,
    onOpen: onOpenAddProduct,
    onClose: onAddProductClosed,
  } = useDisclosure();

  const { data: productData } = useQuery({
    queryKey: ['product', product.productId],
    queryFn: () => ProductService.getProduct(product.productId),
    enabled: !!product.productId,
  });

  const isPackage = useMemo(() => {
    return (
      Array.isArray(productData?.subItems) && productData?.subItems?.length > 0
    );
  }, [productData?.subItems]);

  const onOptionsRendered = useCallback(
    (options) => {
      const showOptions =
        options?.length === 0 && product?.description?.length > 0;
      setShowOptions(!showOptions);
    },
    [product?.description?.length],
  );

  const handleAddProductClosed = useCallback(() => {
    onAddProductClosed();
    setCurrentEditableProduct(null);
  }, [onAddProductClosed]);

  const handleChangeDescription = useCallback(
    (e: any) => {
      setIsUserInteraction(true);
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index ? { ...p, description: e.target.value } : p,
        ),
      }));
    },
    [index, setNewQuote, setIsUserInteraction],
  );

  const handlePackageInfoVisibility = useCallback(() => {
    setIsPackageInfoVisible((prev) => !prev);
  }, []);

  const handleChangeProductQuantity = useCallback(
    (e: any, index: number) => {
      setIsUserInteraction(true);
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index
            ? getUpdatedProductByQuantity(
              p,
              e.target.value,
              businessPreferences?.vatValue,
            )
            : p,
        ),
      }));
    },
    [businessPreferences?.vatValue, setNewQuote, setIsUserInteraction],
  );

  const handleChangeProductAmount = useCallback(
    (e: any, index: number) => {
      setIsUserInteraction(true);
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index
            ? getUpdatedProductByAmount(
              p,
              e.target.value,
              businessPreferences?.vatValue,
            )
            : p,
        ),
      }));
    },
    [businessPreferences?.vatValue, setNewQuote, setIsUserInteraction],
  );

  // handle currency change
  useEffect(() => {
    if (currency === 'ILS') {
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any) =>
          p?.productId === productData?.id
            ? getUpdatedProductByIls(
              p,
              productData?.price,
              businessPreferences?.vatValue,
            )
            : { ...p },
        ),
      }));
    }
    if (currency === 'USD') {
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any) =>
          p?.productId === productData?.id
            ? getUpdatedProductByUsd(
              p,
              productData?.price,
              businessPreferences?.vatValue,
            )
            : { ...p },
        ),
      }));
    }
  }, [
    businessPreferences?.vatValue,
    currency,
    productData?.price,
    productData?.id,
    setNewQuote
  ]);
  return {
    showOptions,
    isPackage,
    handleChangeDescription,
    onOptionsRendered,
    isAddProductOpen,
    onOpenAddProduct,
    currentEditableProduct,
    handleAddProductClosed,
    isPackageInfoVisible,
    handlePackageInfoVisibility,
    productData,
    handleChangeProductQuantity,
    handleChangeProductAmount,
  };
}
