import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import NoEventsImg from 'assets/img/eventsOverview/noEventsCreated.png';
import { useAppNavigate } from 'hooks/useAppNavigate';

interface NoEventsCreatedProps {
  onOpenImportDialog: () => void;
}

export default function NoEventsCreated({ onOpenImportDialog }: NoEventsCreatedProps) {
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="320px" h="280px" src={NoEventsImg} alt="" />
      <Box gap="16px" textAlign="center">
        <Text variant="noEventsTitle">
          {t('events_overview.no_events_created_yet')}
        </Text>
        <Text variant="noEventsSubTitle">
          {t('events_overview.no_events_created_yet_description')}
        </Text>
      </Box>
      <Flex direction="column" gap="3">
        <Button
          w="355px"
          variant="h1cta"
          onClick={() => navigate('/main/events/create')}>
          {t('events_overview.create_event')}
        </Button>
        <Button
          w="355px"
          variant="outline"
          onClick={onOpenImportDialog}>
          {t('events_overview.import_events')}
        </Button>
      </Flex>
    </Flex>
  );
}
