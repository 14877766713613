import { Button, Flex, Image, Text, useToast, VStack } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Icon from 'assets/svg/notification/askingForQuote.svg';
import EventDueIcon from 'assets/svg/notification/event-due-icon.svg';
import QuoteDeadlineIcon from 'assets/svg/notification/quote-deadline-icon.svg';
import axios from 'axios';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { updateNotification } from 'contexts/redux/notifications/notificationsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import 'moment/locale/he';
import { createEntityHook } from 'queries/entity';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BusinessEventPlan, INotification } from 'services/@types';
import { AppNotificationEventName } from 'services/@types/notificationEventNames';
import _businessEventPlanService from 'services/businessEventPlan.api';
import { getNotificationMomentFormat } from './NotificationComponent';

export default function ButtonNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { appNavigate: navigate } = useAppNavigate();
  const { t, i18n } = useTranslation();
  const myBusiness = useMyBusiness();
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [customBtnText, setCustomBtnText] = useState<string>(
    notification.buttonText,
  );
  const icon = useMemo(() => {
    if (
      notification.notificationEventName ===
      AppNotificationEventName.EventDuePassed
    ) {
      return EventDueIcon;
    }
    if (
      notification.notificationEventName ===
      AppNotificationEventName.QuoteDeadlineReminder
    ) {
      return QuoteDeadlineIcon;
    }
    return Icon;
  }, [notification.notificationEventName]);

  const buttonText = useMemo(() => {
    if (customBtnText) {
      return customBtnText;
    }
    return notification.buttonText;
  }, [customBtnText, notification.buttonText]);

  const entity = createEntityHook(notification?.type);

  const { data: businessEventPlan } = useQuery<BusinessEventPlan>({
    queryKey: [
      `getEventParticipants-${notification?.entityId}-${myBusiness?.id}`,
    ],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId: notification?.entityId,
      });

      const data = result.results[0];
      return data || null;
    },
    enabled: !!notification?.entityId && !!myBusiness?.id,
  });

  // Handle EventDuePassed specific logic
  const handleEventDuePassedClick = useCallback(async () => {
    if (!myBusiness?.id || !businessEventPlan) return;

    const businessEventPlanQueryKey = `getEventParticipants-${notification?.entityId}-${myBusiness?.id}`;
    const res = await _businessEventPlanService.updateBusinessEventPlan(
      businessEventPlan?.id,
      {
        status: 'post',
      },
    );

    if (res) {
      queryClient.invalidateQueries({
        queryKey: [businessEventPlanQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: ['dashboardTrackedEvents'],
      });
      toast({
        title: t('event.status_change.toast_success'),
        variant: 'main',
        status: 'success',
      });
    }
  }, [
    businessEventPlan,
    myBusiness?.id,
    notification?.entityId,
    queryClient,
    toast,
    t,
  ]);

  // Handle general notification button click
  const handleGeneralButtonClick = useCallback(async () => {
    if (!notification.buttonLink) return;

    if (
      notification.notificationEventName ===
      AppNotificationEventName.CalendarInvite
    ) {
      navigate(notification?.buttonLink);
      return;
    }

    try {
      const result = await axios.get(notification?.buttonLink);

      if (result.status === 200) {
        entity.invalidate(queryClient, notification?.entityId);
        toast({
          title: t('toast.title.operation_succeeded'),
          variant: 'green',
          status: 'success',
        });
      }
    } catch (e) {
      console.warn(`failed to click button: ${e}`);
    }
  }, [
    notification?.buttonLink,
    notification?.notificationEventName,
    notification?.entityId,
    entity,
    queryClient,
    toast,
    t,
    navigate,
  ]);

  // Main button click handler that routes to the appropriate specific handler
  const handleButtonClick = useCallback(async () => {
    if (!notification.buttonLink) return;

    if (
      notification.notificationEventName ===
      AppNotificationEventName.EventDuePassed
    ) {
      await handleEventDuePassedClick();
    } else {
      await handleGeneralButtonClick();
    }

    // Mark notification as not visible regardless of type
    await dispatch(
      updateNotification({
        notificationId: notification?.id,
        updates: { visible: false },
      }),
    );
  }, [
    notification?.buttonLink,
    notification?.notificationEventName,
    notification?.id,
    dispatch,
    handleEventDuePassedClick,
    handleGeneralButtonClick,
  ]);

  const handleButtonUnderlineClick = useCallback(async () => {
    if (!notification.buttonUnderlineLink) return;

    await dispatch(
      updateNotification({
        notificationId: notification?.id,
        updates: { visible: false },
      }),
    );
    axios
      .get(notification.buttonUnderlineLink)
      .then(() => {
        entity.invalidate(queryClient, notification?.entityId);
        toast({
          title: t('toast.title.operation_succeeded'),
          variant: 'green',
          status: 'success',
        });
      })
      .catch((e) => {
        console.warn(`failed to click button underline: ${e}`);
      });
  }, [
    notification?.buttonUnderlineLink,
    notification?.id,
    notification?.entityId,
    dispatch,
    entity,
    queryClient,
    toast,
    t,
  ]);

  useEffect(() => {
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.CalendarInvite
    ) {
      setCustomBtnText('notifications.calendar_invite.button_text');
    }
  }, [notification?.notificationEventName]);

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px 8px"
      flexDirection="column"
      gap="36px">
      <Flex alignItems="start" gap="12px" p="0 5px">
        <Image src={icon} alt="" w="24px" h="24px" />
        <Text variant="cardSubTitle" color="#1B2037" fontSize="sm">
          {t(notification.message, {
            name: notification?.variables?.name,
            ...notification?.variables,
          })}
        </Text>
      </Flex>
      <VStack gap="12px">
        <VStack spacing={2} w="100%">
          <Button variant="h1cta" w="100%" onClick={handleButtonClick}>
            {t(buttonText)}
          </Button>
          <Button
            variant="h4"
            textDecorationLine={'underline'}
            fontWeight="500"
            display={notification.buttonUnderlineLink ? 'block' : 'none'}
            w="100%"
            onClick={handleButtonUnderlineClick}>
            {t(notification.buttonUnderlineText)}
          </Button>
        </VStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
