export const checkboxStyles = {
  components: {
    Checkbox: {
      baseStyle: {
        fontWeight: '500',
        // borderRadius: '16px',
        boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
        transition: '.25s all ease',
        boxSizing: 'border-box',
        borderColor: 'brand.900',
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
        control: {
          _checked: {
            bg: 'brand.900',
            borderColor: 'brand.900',
          },
        },
        label: {
          marginTop: '0px',
        },
      },
      variants: {
        brand: {
          color: 'brand.900',
        },
        blue: {
          color: 'brand.400',
        },
        roundBrand: {
          control: {
            borderRadius: '50%',
            _focus: {
              boxShadow: 'none',
            },
          },
          label: {
            marginTop: '0px',
          },
        },
        secondary: {
          control: {
            borderRadius: '50%',
            _focus: {
              boxShadow: 'none',
            },
          },
        },
      },
    },
  },
};
