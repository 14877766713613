import {
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TotalQuotePayment from '../components/TotalQuotePayment';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import InputDateField from 'components/fields/InputDateField';
import SwitchField from 'components/fields/SwitchField';
import { AppTooltip } from 'components/tooltip';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { AnimatePresence, motion } from 'framer-motion';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPaperclip, FiPlus, FiSave, FiTrash, FiTrash2, FiX } from 'react-icons/fi';
import { CreateQuoteError, IQuote } from 'services/@types';
import { AiOutlinePieChart } from 'react-icons/ai';

const contentVariants = {
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.2 },
    },
  },
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.2, delay: 0.1 },
    },
  },
};

export default function PaymentDetails({
  newQuote,
  handleIncreasePaymentQuantity,
  handleDecreasePaymentQuantity,
  setNewQuote,
  currencyIcon,
  error,
  handleChangePaymentAmount,
  handleChangeFile,
  handleChangePaymentDescription,
  handleRemovePaymentIndex,
  clearFile,
  handleChangeAllProductsVatIncluded,
  isAllProductsVatIncluded,
  dir,
  setIsUserInteraction,
  handleChangeNextAmount
}: {
  newQuote: IQuote;
  handleIncreasePaymentQuantity: () => void;
  handleDecreasePaymentQuantity: () => void;
  handleRemovePaymentIndex: (index: number) => void;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  currencyIcon: string;
  error: CreateQuoteError;
  handleChangeAllProductsVatIncluded: (e: any) => void;
  isAllProductsVatIncluded: boolean;
  handleChangeFile: (e: any) => void;
  handleChangePaymentAmount: (e: any, index: number) => void;
  handleChangeNextAmount: (e: any, index: number) => void;
  handleChangePaymentDescription: (e: any, index: number) => void;
  clearFile: () => void;
  setIsUserInteraction?: (isUserInteraction: boolean) => void;
  dir: string;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { data: businessPreferences } = useGetBusinessPreferences();

  const myBusiness = useMyBusiness();
  const { t, i18n } = useTranslation('', {
    lng: newQuote?.language,
  });
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const [isEnabled, setIsEnabled] = useState(true);
  const [disableCancelationTerms, setDisableCancelationTerms] = useState(false);

  return (
    <Flex direction="column" gap="12px">
      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
        style={{ display: 'none' }}
        onChange={handleChangeFile}
      />
      {/* <SwitchField
        dir={dir}
        align="start"
        reversed
        justify="flex-start"
        wrapperProps={{
          gap: 2,
          alignSelf: 'flex-start',
        }}
        id="general-payment-details"
        label={t('create_quote.general_payment_details')}
        isChecked={isEnabled}
        onChange={(e) => {
          setIsEnabled(e.target.checked);
        }}
      /> */}

      <AnimatePresence>
        {isEnabled && (
          <motion.div
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden">
            <VStack align="stretch" spacing={3}>
              <Flex
                w="100%"
                flexDirection={{ base: 'column', md: 'row' }}
                gap="16px">
                <VStack flexGrow={2} align="stretch" spacing={3}>
                  {newQuote.paymentInInstallments?.installments?.map(
                    (payment, index) => (
                      <HStack align="stretch" key={index} alignItems="start">
                        <VStack w="100%" alignItems={"start"}>
                          <HStack color={"brand.900"}>
                            <Icon as={AiOutlinePieChart} w="16px" h="16px" />
                            <Text>{index+1}{`\\`}{newQuote?.paymentInInstallments?.installments.length + " "}{t('create_quote.payment_description_label')}</Text>
                          </HStack>
                          <InputField
                            mt={"-6px"}
                            dir={dir}
                            w="100%"
                            type="text"
                            name="description"
                            min={0}
                            // label={
                              // index === 0 &&
                              //   newQuote.paymentInInstallments.installments.length > 1
                              //   ? t('create_quote.payment_description_advance')
                              //   : t('create_quote.payment_description')
                            // }
                            placeholder={t(
                              'create_quote.payment_description_placeholder',
                            )}
                            value={payment.description}
                            onChange={(e) => handleChangePaymentDescription(e, index)}
                          />
                        </VStack>

                        <InputField
                          dir={dir}
                          w="100%"
                          type="number"
                          name="amount"
                          min={0}
                          label={t('create_quote.amount')}
                          placeholder={t('create_quote.amount')}
                          value={payment.amount}
                          onBlur={(e) => handleChangeNextAmount(e, index)}
                          onChange={(e) => handleChangePaymentAmount(e, index)}
                          iconLeft={
                            <Text variant="currency">{currencyIcon}</Text>
                          }
                        />
                        <InputDateField
                          lang={newQuote?.language}
                          containerStyles={{ flex: '1', w: { base: '100%' } }}
                          label={t('create_quote.date')}
                          dir={dir}
                          buttonStyles={{
                            w: { base: '100%' },
                            maxW: { base: '100%' },
                            borderColor: payment.dueDate
                              ? '#0000001A'
                              : 'error.100',
                            justifyContent: 'space-between',
                          }}
                          value={payment.dueDate}
                          imgSrc={CalendarIcon}
                          onChange={(date: Date) =>
                            setNewQuote((prev) => ({
                              ...prev,
                              paymentInInstallments: {
                                ...prev.paymentInInstallments,
                                installments:
                                  prev.paymentInInstallments.installments.map(
                                    (p, i) =>
                                      i === index ? { ...p, dueDate: date } : p,
                                  ),
                              },
                            }))
                          }
                          imgStyles={{
                            bg: 'transparent',
                          }}
                        />
                        {index != 0 && (
                          <Flex alignSelf="flex-end" py={2}>
                            <IconButton
                              icon={<Icon as={FiTrash2} color="red" />}
                              aria-label="delete"
                              alignSelf="flex-end"
                              onClick={() => handleRemovePaymentIndex(index)}
                              // variant="actionIconRed"
                            />
                          </Flex>
                        )}
                      </HStack>
                    ),
                  )}
                  <HStack>
                    <IconButton
                      borderRadius="8px"
                      icon={
                        <Icon as={FiPlus} color="white" w="15px" h="15px" />
                      }
                      onClick={handleIncreasePaymentQuantity}
                      aria-label="add"
                      w="24px"
                      h="24px"
                      minW="24px"
                      bg="rgba(112, 142, 133, 1)"
                    />
                    <Text variant="addRowText">
                      {t('create_quote.add_payment')}
                    </Text>
                  </HStack>
                </VStack>
                <Divider
                  opacity={1}
                  orientation="vertical"
                  borderColor="rgba(153, 158, 169, 1)"
                  h="auto"
                  borderWidth={'1px'}
                  display={{ base: 'none', md: 'block' }}
                />

                <TotalQuotePayment
                  isAllProductsVatIncluded={isAllProductsVatIncluded}
                  handleChangeAllProductsVatIncluded={
                    handleChangeAllProductsVatIncluded
                  }
                  newQuote={newQuote}
                  setNewQuote={setNewQuote}
                  currencyIcon={currencyIcon}
                  error={error}
                  setIsUserInteraction={setIsUserInteraction}
                />
              </Flex>

              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />
              <InputField
                iconPointerEvents={"all"}
                label={t('create_quote.cancellation_terms')}
                value={newQuote?.cancellationTerms}
                minW={{ base: '100%', md: '510px' }}
                isReadOnly={disableCancelationTerms}
                onChange={(e) =>
                  setNewQuote((prev) => ({
                    ...prev,
                    cancellationTerms: e.target.value,
                  }))
                }
                placeholder={t('create_quote.cancellation_terms_placeholder')}
                iconRight={<Icon
                cursor={"pointer"}
                onClick={() => setDisableCancelationTerms(prev => !prev)}
                as={FiSave} color={"rgba(158, 158, 158, 1)"} />}
              />
              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />
              <HStack justify="flex-start">
                {/* attach file... */}
                <IconButton
                  icon={<Icon as={FiPaperclip} p={'1px'} />}
                  aria-label="add"
                  display={newQuote?.media?.length > 0 ? 'none' : 'flex'}
                  onClick={() => fileInputRef.current?.click()}
                  variant="actionIconGrey"
                />
                <Text
                  variant="addRowText"
                  display={newQuote?.media?.length > 0 ? 'none' : 'block'}>
                  {t('create_quote.attach_file')}
                </Text>
                {newQuote?.media?.length > 0 && (
                  <Flex alignItems="center" gap="4px">
                    <Text
                      variant="eventGridCardDesc"
                      onClick={() => {
                        handleOpenPdf(
                          newQuote?.media?.[0]?.url,
                          newQuote?.media?.[0]?.displayName,
                        );
                      }}>
                      {newQuote.media[0]?.displayName}
                    </Text>
                    <Icon
                      aria-label=""
                      as={FiX}
                      cursor="pointer"
                      onClick={() => clearFile()}
                    />
                  </Flex>
                )}
              </HStack>
              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />
              <HStack align="end" justify="start" gap={0} w="fit-content">
                {/* make input date field here */}
                <InputDateField
                  lang={newQuote?.language}
                  label={t('create_quote.quote_valid_until')}
                  value={
                    newQuote?.validUntil
                      ? new Date(newQuote.validUntil)
                      : new Date()
                  }
                  onChange={(date: Date) =>
                    setNewQuote((prev) => ({
                      ...prev,
                      validUntil: date.toISOString(),
                    }))
                  }
                />
                <SwitchField
                  dir={dir}
                  wrapperProps={{ gap: 4, mb: 2 }}
                  id="quote-valid-until"
                  reversed
                  label={t('create_quote.quote_reminder')}
                  checked={newQuote?.shouldNotify}
                  onChange={(e) =>
                    setNewQuote((prev) => ({
                      ...prev,
                      shouldNotify: e.target.checked,
                    }))
                  }
                />
              </HStack>
              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />
              <VStack align="flex-start">
                {/* business details here */}
                <Text fontSize="14px" fontWeight="500" color="primary.100">
                  {t('create_quote.business_details')}
                </Text>
                <HStack bg="#efefef" p={4} borderRadius="16px" w="100%">
                  <Text fontSize="14px" fontWeight="500">
                    {myBusiness?.businessName}
                    {myBusiness?.address && `, ${myBusiness.address}`}
                    {myBusiness?.phone && ` ${myBusiness.phone}`}
                  </Text>
                </HStack>
              </VStack>
              <HStack align="center" gap={4}>
                <SwitchField
                  dir={dir}
                  id="is-contract"
                  wrapperProps={{ gap: 4 }}
                  label={t('create_quote.send_as_contract')}
                  checked={newQuote?.isContract}
                  reversed
                  onChange={(e) =>
                    setNewQuote((prev) => ({
                      ...prev,
                      isContract: e.target.checked,
                    }))
                  }
                />
                <AppTooltip
                  label={t('create_quote.send_as_contract_tooltip')}
                />
              </HStack>
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
}
