import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { fetchBusinessEventPlans } from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import {
  AppDialogProps,
  closeEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessEventPlan } from 'services/@types';
import EventCard from './eventCard';

function EventsPickerDialog() {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const business = useMyBusiness();
  const { eventsDialog, businessEventPlans } = useSelector(
    (state: RootState) => ({
      eventsDialog: state.dialogs.eventsDialog,
      businessEventPlans: state.businessEventPlan.businessEventPlans,
    }),
  );
  const dialog = eventsDialog as AppDialogProps;

  const events = useMemo(() => {
    return (
      businessEventPlans?.map(
        (businessEventPlan: BusinessEventPlan) => ({
          event: businessEventPlan.eventId,
          eventName: businessEventPlan.eventName,
        }),
      ) || []
    );
  }, [businessEventPlans]);

  const handleChange = useCallback(
    async (value) =>
      await dispatch(
        fetchBusinessEventPlans({ name: value, businessId: business?.id }),
      ),
    [dispatch, business?.id],
  );

  const handleClose = useCallback(() => {
    dispatch(closeEventsDialog());
  }, [dispatch]);

  const handleSelectEvent = useCallback(
    (selectedEvent) => {
      if (eventsDialog?.onConfirm) {
        eventsDialog.onConfirm(selectedEvent);
      }
      handleClose();
    },
    [handleClose, eventsDialog],
  );
  useEffect(() => {
    (async () => {
      if (!!dialog) {
        await dispatch(fetchBusinessEventPlans({ businessId: business?.id }));
      }
    })();
  }, [dispatch, dialog, business?.id]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px">
        <ModalBody p="2xl">
          <VStack w="100%" gap={2}>
            <HStack w="100%" justifyContent="space-between">
              <Text variant="cardSectionTitle" ml="30px">
                {t('dialogs.events.title')}
              </Text>
              <ModalCloseButton position="relative" top="0" />
            </HStack>
            <SearchBar
              w="100%"
              autoFocus
              onChange={(e: any) => handleChange(e.target.value)}
            />
            <VStack w="100%" h="500px" overflowY="auto" gap={2}>
              {events.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.events.no_events')}
                </Text>
              )}
              {events.map(({ event, eventName }) => (
                <EventCard
                  key={event.id}
                  event={event}
                  eventName={eventName}
                  onClick={handleSelectEvent}
                  h="115px"
                />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EventsPickerDialog;
