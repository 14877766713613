import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Business, UrlItem } from 'services/@types';
import { updateBusiness as update } from '../../../../contexts/redux/business/businessSlice';

const businessNameUpdateErrors: string[] = [
  'business_older_than_7_days',
  'business_has_financial_documents',
  'business_has_external_interactions',
];

function useUpdateBusiness(myBusiness: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] =
    useState<Record<string, any>>(myBusiness);
  const dispatch = useDispatch<any>();
  const toast = useToast();

  useEffect(() => {
    setInputValues(myBusiness);
  }, [myBusiness]);
  const handleChange = useCallback(
    (event: { target: { name: string; value: string | any } }) => {
      const { name, value } = event.target;
      console.log('# handleChange ->', name, value, inputValues);
      if (name === 'media') {
        //media is an array of objects, if it has already an object, which is different type of media, then we need to update the url of that object, else just add a new object to the array
        const media = [...inputValues?.media] || [];
        const mediaIndex = media.findIndex(
          (m: UrlItem) => m.type === value.type,
        );
        console.log('# mediaIndex ->', mediaIndex, value);
        if (mediaIndex >= 0) {
          media[mediaIndex] = value;
        } else {
          media.push(value);
        }
        setInputValues((prevState) => ({ ...prevState, media }));
        return;
      }
      setInputValues((prevState) => ({ ...prevState, [name]: value }));
    },
    [inputValues],
  );

  const updateBusiness = useCallback(async () => {
    setLoading(true);
    console.log('# update business ->', inputValues);
    try {
      let payload: Partial<Business> = {};
      Object.keys(inputValues).map((key) => {
        if (myBusiness[key] !== inputValues[key]) {
          payload = { ...payload, [key]: inputValues[key] };
        }
      });
      // detect if the business has changed
      if (Object.keys(payload).length === 0) {
        setLoading(false);
        return true;
      }
      const response = await dispatch(
        update({ businessId: inputValues?.id, updates: payload }),
      );
      if (!response.error) {
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.business_updated'),
          status: 'success',
          duration: 2000,
          variant: 'main',
        });
        setLoading(false);
        return true;
      } else {
        setError(response.error.message);
        let errorDetails = response.payload.response.data.message;
        let errorTitle = t('toast.title.error');
        if (businessNameUpdateErrors.includes(errorDetails)) {
          errorDetails = t(
            `business_settings.business_name_update_errors.${errorDetails}`,
          );
          errorTitle = t('business_settings.business_name_update_errors.title');
        }
        toast({
          title: errorTitle,
          description: errorDetails,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, dispatch, myBusiness, toast, t]);

  return {
    inputValues,
    handleChange,
    updateBusiness,
    setInputValues,
    loading,
    setLoading,
    error,
  };
}

export default useUpdateBusiness;
