import { useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react';
import {
  deleteContract,
  fetchContracts,
  updateContract,
} from 'contexts/redux/contract/contractSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { setCreateContractPreviewDialog } from 'contexts/redux/dialog/dialogsSlice';
interface IParams {
  search?: string;
  sortBy?: string;
  projectBy?: string;
  limit?: number;
  isDraft?: boolean;
  isTemplate?: boolean;
  status?: 'sent' | 'signed' | 'partiallySigned';
}

// const defaultHeaders = [
//   'customer',
//   'date',
//   'event',
//   'quote_number',
//   'status',
//   'signatures',
//   '',
// ];

const defaultHeaders = [
  'customer',
  'date',
  'event',
  'quote_number',
  'status',
  '',
];

// const defaultHeadersMobile = defaultHeaders.filter(
//   (_, index) => index !== 4 && index !== 5 && index !== 3,
// );

const defaultHeadersMobile = defaultHeaders.filter(
  (_, index) => index !== 4 && index !== 3,
);

// const draftHeaders = defaultHeaders.filter(
//   (_, index) => index !== 5 && index !== 4,
// );

const draftHeaders = defaultHeaders.filter((_, index) => index !== 4);

const draftHeadersMobile = draftHeaders.filter((_, index) => index !== 3);

const templateHeaders = ['title', 'date', 'description', 'state', ''];

const templateHeadersMobile = templateHeaders.filter((_, index) => index !== 3);

// const headersMobile = headers.filter((_, index) => index !== 3 && index !== 4);

export const tabs = ['contract', 'template', 'draft'];

export type ActiveTab = (typeof tabs)[number];

const getSortBy = (header: string) => {
  switch (header) {
    case 'customer':
      return '-clients.client.name';
    case 'date':
      return '-contractDate';
    case 'event':
      return 'eventId.name';
    case 'quote_number':
      return 'quoteId.quoteNumber';
    case 'status':
      return '-status';
    case 'title': // for templates tab
      return 'templateTitle';
    case 'description': // for templates tab
      return 'templateDescription';
    case 'state': // for templates tab
      return '-isDefaultTemplate';
  }
  return '';
};

export default function useContracts() {
  const { contracts } = useSelector((state: RootState) => state.contracts);
  const [activeTab, setActiveTab] = useState<ActiveTab>(tabs[0]);
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { appNavigate: navigate } = useAppNavigate();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const onOpenCreateContractPreview = useCallback(() => {
    dispatch(setCreateContractPreviewDialog({}));
  }, [dispatch]);

  const headerFields = useMemo(() => {
    if (activeTab === tabs[1]) {
      return isMobile ? templateHeadersMobile : templateHeaders;
    }
    if (activeTab === tabs[2]) {
      return isMobile ? draftHeadersMobile : draftHeaders;
    }
    return isMobile ? defaultHeadersMobile : defaultHeaders;
  }, [isMobile, activeTab]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleDeleteContract = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, id: string) => {
      e.stopPropagation();
      dispatch(deleteContract(id));
      toast({
        title: t('contracts.contract_deleted'),
        status: 'success',
        position: 'top-right',
      });
    },
    [dispatch, toast, t],
  );

  const handleTemplateState = useCallback(
    (id: string, defaultTemplate: boolean) => {
      dispatch(
        updateContract({
          contractId: id,
          updates: { isDefaultTemplate: defaultTemplate },
        }),
      );
      toast({
        title: t('contracts.contract_state_updated'),
        status: 'success',
        variant: 'main',
        position: 'top-right',
      });
    },
    [dispatch, toast, t],
  );

  const handleSort = useCallback(
    (header: 'customer' | 'date' | 'event' | 'quote_number') => {
      if (sortBy === header) {
        setSortBy('');
        return;
      }
      setSortBy(header);
    },
    [sortBy],
  );

  const handleTabChange = useCallback((tab: ActiveTab) => {
    setActiveTab(tab);
  }, []);

  const handleNavigateToCreateContract = useCallback(
    (templateId?: string) => {
      let path = '/main/finance/contracts/create';
      if (templateId) {
        path += `?templateId=${templateId}`;
      }
      navigate(path);
    },
    [navigate],
  );

  useEffect(() => {
    const params: IParams = {
      limit: 20,
      projectBy: '-updatedAt',
    };
    if (search) {
      params.search = search;
    }
    if (sortBy) {
      const sortByValue = getSortBy(sortBy);
      if (sortByValue) {
        params.sortBy = sortByValue;
      }
    }
    if (activeTab === tabs[0]) {
      params.isDraft = false;
      params.isTemplate = false;
    }
    if (activeTab === tabs[1]) {
      params.isTemplate = true;
    }
    if (activeTab === tabs[2]) {
      params.isDraft = true;
    }
    dispatch(fetchContracts(params));
  }, [dispatch, search, sortBy, activeTab]);

  return {
    contracts,
    search,
    handleDeleteContract,
    handleSearch,
    isMobile,
    headerFields,
    handleSort,
    sortBy,
    activeTab,
    handleTabChange,
    handleTemplateState,
    onOpenCreateContractPreview,
    handleNavigateToCreateContract,
  };
}
