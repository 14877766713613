import {
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import NotificationComponent from 'components/notifications/NotificationComponent';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import useGetUnreadNotificationsCount, {
  useGetReadNotifications,
  useGetUnreadNotifications,
} from 'queries/notifications';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { MdNotificationsNone } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsEmptySvg from '../../assets/svg/notification/notifications-empty.svg';
import { RootState } from 'contexts/redux/store';
import { setNotificationsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useCallback } from 'react';

const NotificationsMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myUser = useMyUser();
  const { isNotificationsDialogOpen } = useSelector(
    (state: RootState) => state.dialogs,
  );

  const {
    data: notifications,
    error,
    isLoading,
  } = useGetReadNotifications({
    businessId: myUser?.businessID,
  });
  const { data: unreadNotifications, error: unreadError } =
    useGetUnreadNotifications({
      businessId: myUser?.businessID,
    });

  const unreadNotificationsCount = useGetUnreadNotificationsCount();

  // Chakra Color Mode
  const menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const onClose = useCallback(() => {
    dispatch(setNotificationsDialog(false));
  }, [dispatch]);

  const onToggle = useCallback(() => {
    if (!!isNotificationsDialogOpen) {
      onClose();
    } else dispatch(setNotificationsDialog({}));
  }, [dispatch, isNotificationsDialogOpen, onClose]);

  return (
    <Menu isOpen={!!isNotificationsDialogOpen} onClose={onClose}>
      <MenuButton p="0px" position="relative" onClick={onToggle}>
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          display={unreadNotificationsCount.data?.count > 0 ? 'flex' : 'none'}
          w="12px"
          h="12px"
          top="7px"
          right="8px"
          borderRadius="50%"
          bg="#AA915D">
          <Text variant="missedMessageCounter">
            {unreadNotificationsCount.data?.count || 10}
          </Text>
        </Flex>
        <Icon
          mt="6px"
          as={MdNotificationsNone}
          color={'brand.400'}
          w="24px"
          p={'1px'}
          h="24px"
          me="10px"
        />
      </MenuButton>
      <MenuList
        boxShadow={shadow}
        p="20px"
        borderRadius="20px"
        bg={menuBg}
        border="none"
        mt="22px"
        me={{ base: '30px', md: 'unset' }}
        height="100%"
        maxH="85vh"
        overflowY="auto"
        w="328px">
        <Flex
          w="100%"
          mb="20px"
          alignItems="center"
          justifyContent="space-between">
          <Text variant="cardTitle">{t('notifications.title')}</Text>
          <Flex alignItems="center" gap="34px">
            <IconButton
              variant="actionIconTransparent"
              aria-label="close-notifications"
              icon={<LuX fontSize="24px" />}
              onClick={onClose}
              color="#8E7A70"
              w="18px"
              h="18px"
              cursor="pointer"
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" py={1}>
          {unreadNotifications?.results.length > 0 && (
            <>
              <Text fontSize="xl" fontWeight={'bold'} my={5}>
                {t('notifications.new')}
              </Text>
              <Flex flexDirection="column" gap={4}>
                {unreadNotifications?.results.map((notification) => (
                  <NotificationComponent
                    notification={notification}
                    notificationReceivedId={notification?.id}
                  />
                ))}
              </Flex>
            </>
          )}
          {notifications?.results.length > 0 && (
            <>
              <Text fontSize="xl" fontWeight={'bold'} my={5}>
                {t('notifications.read')}
              </Text>
              <Flex flexDirection="column" gap={4}>
                {notifications?.results.map((notification) => (
                  <NotificationComponent
                    key={notification.id}
                    notification={notification}
                    notificationReceivedId={notification?.id}
                  />
                ))}
              </Flex>
            </>
          )}
          {(unreadError || error) && (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              pt={8}
              gap={4}>
              <Text
                textAlign="center"
                color="red.500"
                fontSize="md"
                fontWeight="medium">
                {t('notifications.error_loading')}
              </Text>
              <Text textAlign="center" color="gray.500" fontSize="sm">
                {t('notifications.try_again_later')}
              </Text>
            </Flex>
          )}
          {notifications?.results.length === 0 &&
            unreadNotifications?.results.length === 0 && (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                pt={8}
                gap={4}>
                <Image src={NotificationsEmptySvg} alt="" w="140px" h="128px" />
                <Text textAlign="center" color="gray.500" fontSize="sm">
                  {t('notifications.no_notifications')}
                </Text>
              </Flex>
            )}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default NotificationsMenu;
