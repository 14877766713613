import { useToast } from '@chakra-ui/react';
import {
  AppDialogProps,
  closeAskForQuoteDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface UseQuoteResult {
  handleOpenEventDialog: () => void;
  handleClose: () => void;
  isOpenned: boolean;
  quote: Record<string, any>;
  sendQuote: () => void;
  quoteRequestType: 'event' | 'date_and_time';
  setQuoteRequestType: (type: 'event' | 'date_and_time') => void;
  handleChange: (key: string, value: any) => void;
  loading: boolean;
  isSendDisabled: boolean;
  handleDateAndTime: (date: string, type: 'date' | 'time') => void;
}
export default function useQuote(): UseQuoteResult {
  const toast = useToast();
  const { t } = useTranslation();
  const [quoteRequestType, setQuoteRequestType] = useState<
    'event' | 'date_and_time'
  >('event');
  const dispatch = useDispatch<any>();
  const { askForQuoteDialog, currentSupplier, loading } = useSelector(
    (state: RootState) => ({
      askForQuoteDialog: state.dialogs.askForQuoteDialog,
      currentSupplier: state.suppliers.currentSupplier,
      loading: state.quote.loading,
    }),
  );
  const dialog = askForQuoteDialog as AppDialogProps;
  const [quote, setQuote] = useState<Record<string, any>>({});

  const isSendDisabled = useMemo(() => {
    if (quoteRequestType === 'date_and_time') {
      return !quote.serviceDescription || !quote.date || !quote.time;
    }
    if (quoteRequestType === 'event') {
      return !quote.event || !quote.serviceDescription;
    }
    return false;
  }, [quote, quoteRequestType]);

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          setQuote((prev) => ({ ...prev, event: e }));
        },
      }),
    );
  }, [dispatch]);

  const handleChange = useCallback((key, value) => {
    setQuote((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleClose = useCallback(() => {
    setQuote({});
    dispatch(closeAskForQuoteDialog());
  }, [dispatch]);

  const handleDateAndTime = useCallback(
    (date: string, type: 'date' | 'time') => {
      console.log(date, type);
      setQuote((prev) => ({ ...prev, [type]: date }));
    },
    [],
  );

  const sendQuote = useCallback(async () => {
    // Create invite instead of directly creating quote
    const inviteData = {
      email: currentSupplier?.email || '',
      fullName: currentSupplier?.name || '',
      type: 'quote_request' as 'quote',
      businessID: currentSupplier?.creatorBusinessID || '',
      // Pass additional data needed for quote creation
      metadata: {
        serviceDescription: quote.serviceDescription,
        supplierId: currentSupplier?.id,
        eventId: quoteRequestType === 'event' ? quote.event?.id : undefined,
        dateAndTime:
          quoteRequestType === 'date_and_time'
            ? new Date(`${quote.date} ${quote.time}`)
            : undefined,
      },
    };

    // Import and use createInvite from inviteSlice
    const { createInvite } = await import('contexts/redux/invite/inviteSlice');
    const res = await dispatch(createInvite(inviteData));

    if (res.payload) {
      toast({
        variant: 'main',
        title: t('dialogs.ask_for_quote.toast.quote_sent'),
      });
    }
    handleClose();
  }, [
    currentSupplier?.email,
    currentSupplier?.name,
    currentSupplier?.creatorBusinessID,
    currentSupplier?.id,
    quote.serviceDescription,
    quote.event?.id,
    quote.date,
    quote.time,
    quoteRequestType,
    dispatch,
    handleClose,
    toast,
    t,
  ]);
  return {
    quoteRequestType,
    setQuoteRequestType,
    handleOpenEventDialog,
    handleClose,
    quote,
    isOpenned: !!dialog,
    sendQuote,
    handleChange,
    loading,
    isSendDisabled,
    handleDateAndTime,
  };
}
