import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { ITask, PaginatedResults } from 'services/@types';
import _taskService from 'services/task.api';

// Query keys
const getTaskQueryKey = (taskId: string): QueryKey => ['task', taskId];
const getTasksQueryKey = (params?: Record<string, any>): QueryKey => [
  'tasks',
  params,
];
const getEventTasksQueryKey = (eventId: string): QueryKey => ['tasks', eventId];

// Query options
const getTaskQueryOptions = (taskId: string) => ({
  queryKey: getTaskQueryKey(taskId),
  queryFn: () => _taskService.getTask(taskId),
  staleTime: 0, // disable stale time
  gcTime: 0, // disable gc time
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const getTasksQueryOptions = <T = ITask>(params?: Record<string, any>) => ({
  queryKey: getTasksQueryKey(params),
  queryFn: () => _taskService.getTasks(params) as Promise<PaginatedResults<T>>,
  staleTime: 0, // disable stale time
  gcTime: 0, // disable gc time
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

// Interface for useGetTask options
interface UseGetTaskOptions {
  enabled?: boolean;
}

/**
 * Hook for fetching a task by ID
 */
export const useGetTask = (
  taskId: string,
  options: UseGetTaskOptions = {},
): UseQueryResult<ITask, Error> => {
  return useQuery({
    ...getTaskQueryOptions(taskId),
    enabled: options.enabled !== false && !!taskId,
  });
};

/**
 * Hook for fetching tasks with optional parameters
 */
export const useGetTasks = <T = ITask>(params?: Record<string, any>) => {
  return useQuery<PaginatedResults<T>, Error>({
    ...getTasksQueryOptions<T>(params),
    enabled: true,
  });
};

/**
 * Hook for getting tasks by event ID
 */
export const useGetEventTasks = (
  eventId: string,
  options: UseGetTaskOptions = {},
) => {
  return useQuery<PaginatedResults<ITask>, Error>({
    queryKey: getEventTasksQueryKey(eventId),
    queryFn: () => _taskService.getTasks({ event: eventId }),
    enabled: options.enabled !== false && !!eventId,
    staleTime: 0,
    gcTime: 0,
  });
};

/**
 * Hook for updating a task
 */
export const useUpdateTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, updates }: { id: string; updates: Partial<ITask> }) =>
      _taskService.updateTask(id, updates),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getTaskQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      // If the task has an event, invalidate that event's tasks queries
      if (data.event) {
        queryClient.invalidateQueries({
          queryKey: getEventTasksQueryKey(data.event),
        });
        queryClient.invalidateQueries({
          queryKey: [`getEventActiveTasks-general`],
        });
      }

      // Invalidate dashboard queries
      queryClient.invalidateQueries({ queryKey: ['dashboard'] });
    },
  });
};

/**
 * Hook for deleting a task
 */
export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => _taskService.deleteTask(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: getTaskQueryKey(id) });
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      // Invalidate event tasks and dashboard queries
      queryClient.invalidateQueries({
        queryKey: [`getEventActiveTasks-general`],
      });
      queryClient.invalidateQueries({ queryKey: ['dashboard'] });
    },
  });
};

/**
 * Hook for creating a task
 */
export const useCreateTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (task: ITask) => _taskService.createTask(task),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getTaskQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      // If the task has an event, invalidate that event's tasks queries
      if (data.event) {
        queryClient.invalidateQueries({
          queryKey: getEventTasksQueryKey(data.event),
        });
        queryClient.invalidateQueries({
          queryKey: [`getEventActiveTasks-general`],
        });
      }

      // Invalidate dashboard queries
      queryClient.invalidateQueries({ queryKey: ['dashboard'] });
    },
  });
};

// Helper methods for prefetching and invalidating
useGetTask.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  taskId: string,
) => {
  await queryClient.prefetchQuery(getTaskQueryOptions(taskId));
};

useGetTask.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  taskId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getTaskQueryKey(taskId) });
};

useGetTasks.prefetch = async <T = ITask>(
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  await queryClient.prefetchQuery(getTasksQueryOptions<T>(params));
};

useGetTasks.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  queryClient.invalidateQueries({ queryKey: getTasksQueryKey(params) });
};

export default useGetTask;
