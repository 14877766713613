import {
  Badge,
  Button,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import CalendarsDropDown from 'components/calendarsDropDown';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import QuoteCard from 'components/quotePickerDialog/quoteCard';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { setSelectedQuote } from 'contexts/redux/quote/quoteSlice';
import { updateUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IQuote } from 'services/@types';
import { eventTypes } from 'variables/eventTypes';
interface CreateEventHeaderProps {
  handleChange: (key: string, value: any) => void;
  event: Record<string, any>;
  errorList: string[];
  handleOpenQuoteDialog: () => void;
  selectedQuote: IQuote | null; 
  readOnly?: boolean;
}
export default function CreateEventHeader(props: CreateEventHeaderProps) {
  const {
    handleChange,
    event,
    errorList,
    handleOpenQuoteDialog,
    selectedQuote,
    readOnly,
  } = props;
  const dispatch = useDispatch<any>();
  const myUser = useMyUser();
  const { t } = useTranslation();

  const params = useParams();
  const { appNavigate: navigate } = useAppNavigate();
  const currentEventType = useMemo(() => event?.type || '', [event?.type]);
  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );
  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('type', '');
        return;
      }
      handleChange('type', formattedSelected);
    },
    [selectedEventType, handleChange],
  );
  return (
    <Stack>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justify={{ base: 'start', md: 'space-between' }}
        gap={{ base: '16px', md: '0px' }}
        alignItems={{ base: 'start', md: 'center' }}>
        <HStack
          flexDirection={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}>
          <Text variant="cardTitle">
            {params?.id
              ? t('create_event.edit_event')
              : t('create_event.title')}
          </Text>
          <Badge bg={`eventStatus.${event?.status || 'new'}`} variant="tag">
            {t(`event.status.${event?.status || 'new'}`)}
          </Badge>
        </HStack>
        <VStack
          spacing={0}
          w="full"
          maxW={{ base: '236px', md: '336px', lg: '376px' }}
          alignSelf={{ base: 'end', md: 'stretch' }}>
          <HStack spacing={0} w="full" gap={4}>
            {/* assign to quote */}
            <Flex w="full" maxW={{ base: '180px', md: '280px', lg: '320px' }}>
              {selectedQuote?.id ? (
                <QuoteCard
                  quote={selectedQuote}
                  hideEventName
                  py={'3px'}
                  borderColor={'brand.200'}
                  onClick={handleOpenQuoteDialog}
                  onClose={() => dispatch(setSelectedQuote(null))}
                />
              ) : (
                <Button
                  w="full"
                  variant="h1cta"
                  height={'44px'}
                  fontSize={'sm'}
                  isDisabled={readOnly}
                  onClick={handleOpenQuoteDialog}>
                  {t('create_contract.assign_to_quote')}
                </Button>
              )}
            </Flex>
            <IconButton
              aria-label="back"
              variant="ghost"
              opacity={0.6}
              icon={<FiX fontSize={'24px'} />}
              onClick={() => navigate(-1)}
              cursor="pointer"
            />
          </HStack>
        </VStack>
      </Flex>
      <Flex align="end" wrap="wrap" gap="40px">
        <DropdownField
          isDisabled={readOnly}
          options={eventTypes.map((type) => `event_types.${type}`)}
          onSelected={handleChangeEventType}
          selectedOptions={selectedEventType}
          placeholder={t('create_event.placeholder.select_event_type')}
          w={{ base: '100%', lg: '318px' }}
          menuButton={{
            borderColor: errorList.includes('type') ? 'error.100' : 'gray.200',
          }}
          showedOptions={t(selectedEventType[0])}
          closeOnSelect
        />
        <InputField
          label={t('create_event.label.event_title')}
          placeholder={t('create_event.placeholder.override_title_if_needed')}
          w={{ base: '100%', lg: '318px' }}
          name="name"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          borderColor={errorList.includes('name') ? 'error.100' : 'gray.200'}
          value={event?.name || ''}
        />
        <CalendarsDropDown
          w={{ base: '100%', lg: '318px' }}
          value={event?.calendarId}
          isDisabled={readOnly}
          isEvent
          onChange={(value: string) => {
            dispatch(
              updateUserPreferences({
                id: myUser?.id,
                updates: {
                  defaultEventCalendarId: value,
                },
              }),
            );
            // handleChange(
            //   'calendarId',
            //   value === event?.calendarId ? '' : value,
            // );
          }}
        />
      </Flex>
    </Stack>
  );
}
