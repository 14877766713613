import { formatNumber } from 'helpers/formatNumber';
import { IInvoice, IInvoiceProductListItem, Product } from 'services/@types';
import { EXCHANGE_RATE_FROM_ILS_TO_USD } from 'variables/currency';

// add selected product to products
export const addSelectedProduct = ({
  products,
  selectedProduct,
  index,
  vatValue,
}: {
  products: IInvoiceProductListItem[];
  selectedProduct: Product;
  index: number;
  vatValue: number;
}) => {
  return products.map((p, key) =>
    key === index
      ? {
          ...p,
          description: selectedProduct.name,
          productId: selectedProduct.id,
          amount: selectedProduct.price,
          quantity: 1,
          net: selectedProduct.price,
          vat: formatNumber(selectedProduct.price * vatValue),
          gross: formatNumber(
            selectedProduct.price * vatValue + selectedProduct.price,
          ),
          isVatIncluded: true,
        }
      : p,
  );
};

// get updated product with vat included or not
export const getUpdatedProduct = (
  product: IInvoiceProductListItem,
  isVatIncluded: boolean,
  vatValue: number,
) => {
  if (isVatIncluded) {
    return {
      ...product,
      isVatIncluded,
      vat: formatNumber(product.net * vatValue),
      gross: formatNumber(product.net + product.net * vatValue),
    };
  }

  return {
    ...product,
    isVatIncluded,
    vat: 0,
    gross: product.net,
  };
};

// calculate products vat included or not by index
export const calculateProductsVatByIndex = (
  products: IInvoiceProductListItem[],
  index: number,
  isVatIncluded: boolean,
  vatValue: number,
) => {
  return products.map((p, key) =>
    key === index ? getUpdatedProduct(p, isVatIncluded, vatValue) : p,
  );
};

// calculate products vat included or not
export const calculateProductsVat = (
  products: IInvoiceProductListItem[],
  isVatIncluded: boolean,
  vatValue: number,
) => {
  return products.map((p) => getUpdatedProduct(p, isVatIncluded, vatValue));
};

// get updated product by amount
export const getUpdatedProductByAmount = (
  product: IInvoiceProductListItem,
  amount: number,
  vatValue: number,
) => {
  return {
    ...product,
    quantity: amount,
    net: formatNumber(product.amount * amount),
    vat: product.isVatIncluded
      ? formatNumber(formatNumber(product.amount * amount) * vatValue)
      : 0,
    gross: product.isVatIncluded
      ? formatNumber(
          formatNumber(product.amount * amount) +
            formatNumber(product.amount * amount) * vatValue,
        )
      : formatNumber(product.amount * amount),
  };
};

// get updated product by quantity
export const getUpdatedProductByPrice = (
  product: IInvoiceProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount: price,
    net: formatNumber(product.quantity * price),
    vat: product.isVatIncluded
      ? formatNumber(formatNumber(product.quantity * price) * vatValue)
      : formatNumber(product.quantity * price),
    gross: product.isVatIncluded
      ? formatNumber(
          formatNumber(product.quantity * price) +
            formatNumber(product.quantity * price) * vatValue,
        )
      : formatNumber(product.quantity * price),
  };
};

// get updated product by usd
export const getUpdatedProductByUsd = (
  product: IInvoiceProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount: formatNumber(price * EXCHANGE_RATE_FROM_ILS_TO_USD),
    net: formatNumber(product.quantity * price * EXCHANGE_RATE_FROM_ILS_TO_USD),
    vat: product?.isVatIncluded
      ? formatNumber(
          formatNumber(
            product.quantity * price * EXCHANGE_RATE_FROM_ILS_TO_USD,
          ) * vatValue,
        )
      : formatNumber(product.quantity * price * EXCHANGE_RATE_FROM_ILS_TO_USD),
    gross: product?.isVatIncluded
      ? formatNumber(
          formatNumber(
            product.quantity * price * EXCHANGE_RATE_FROM_ILS_TO_USD,
          ) +
            formatNumber(
              product.quantity * price * EXCHANGE_RATE_FROM_ILS_TO_USD,
            ) *
              vatValue,
        )
      : formatNumber(product.quantity * price * EXCHANGE_RATE_FROM_ILS_TO_USD),
  };
};

// get updated product by ils
export const getUpdatedProductByIls = (
  product: IInvoiceProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount: price,
    net: formatNumber(product.quantity * price),
    vat: product?.isVatIncluded
      ? formatNumber(formatNumber(product.quantity * price) * vatValue)
      : formatNumber(product.quantity * price),
    gross: product?.isVatIncluded
      ? formatNumber(
          formatNumber(product.quantity * price) +
            formatNumber(product.quantity * price) * vatValue,
        )
      : formatNumber(product.quantity * price),
  };
};

export const calculateTotal = (products: IInvoiceProductListItem[]) => {
  return products.reduce((acc, curr) => {
    if (curr.net) {
      return formatNumber(acc + curr.net);
    }
    return acc;
  }, 0);
};

export const calculateTotalIncludingDiscount = (
  discountType: IInvoice['generalPaymentDetails']['discountType'],
  discount: IInvoice['generalPaymentDetails']['discount'],
  total: IInvoice['generalPaymentDetails']['total'],
) => {
  if (discountType === 'fixed') {
    return formatNumber(total - discount);
  }
  return formatNumber(total - (total * discount) / 100);
};

export const calculateTotalVat = (
  products: IInvoiceProductListItem[],
  generalPaymentDetails: IInvoice['generalPaymentDetails'],
  vatValue: number,
) => {
  if (generalPaymentDetails?.discount > 0) {
    return formatNumber(
      generalPaymentDetails?.totalIncludingDiscount * vatValue,
    );
  }
  const totalVat = products.reduce((acc, curr) => {
    if (curr.vat) {
      return formatNumber(acc + curr.vat);
    }
    return acc;
  }, 0);

  return totalVat;
};

export const calculateTotalPayment = (
  products: IInvoiceProductListItem[],
  generalPaymentDetails: IInvoice['generalPaymentDetails'],
  vatValue: number,
) => {
  const totalPayment = products.reduce((acc, curr) => {
    if (curr.gross) {
      return formatNumber(acc + curr.gross);
    }
    return acc;
  }, 0);

  if (generalPaymentDetails?.totalIncludingDiscount) {
    const totalVat = calculateTotalVat(
      products,
      generalPaymentDetails,
      vatValue,
    );
    return formatNumber(
      generalPaymentDetails?.totalIncludingDiscount + totalVat,
    );
  }

  return totalPayment;
};
