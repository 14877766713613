import { Flex, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuote, Product } from 'services/@types';
import ProductListItem from './ProductListItem';

export const headers = ['item', 'quantity', 'rate', 'amount'];

export type QuoteProduct = IQuote['products'];

interface IProductsListProps {
  products: QuoteProduct;
  currency: string;
  dir?: string;
  lang?: string;
  vatPercent: number;
}

export default function ProductsList({
  products,
  currency,
  dir,
  lang,
  vatPercent,
}: IProductsListProps) {
  const { t } = useTranslation('', {
    lng: lang,
  });

  const formattedCurrency = useMemo(() => {
    if (currency === 'USD') return '$';
    return '₪';
  }, [currency]);

  return (
    <VStack w="100%" spacing={0} align="stretch">
      <Flex
        borderBottom="1px solid #E9EDF7"
        py={4}
        px={6}
        display={{ base: 'none', md: 'flex' }}>
        {headers.map((header, index) => (
          <Flex key={header} flex={index === 0 ? 2 : 1} align="center">
            <Text variant="previewQuoteProductHeader">
              {t(`quote_preview.${header}`)}
            </Text>
          </Flex>
        ))}
      </Flex>

      <VStack spacing={0} align="stretch">
        {products?.map((product, index) => (
          <ProductListItem
            product={product}
            key={index}
            dir={dir}
            lang={lang}
            productData={
              typeof product.productId === 'object'
                ? (product.productId as Product)
                : undefined
            }
            isLast={index === products?.length - 1}
            formattedCurrency={formattedCurrency}
            vatPercent={vatPercent}
          />
        ))}
      </VStack>
    </VStack>
  );
}
