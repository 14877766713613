import { Checkbox, Flex, Icon, Text } from '@chakra-ui/react';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import Dropzone from 'components/dropzone';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { IEvent, IInvoice, UrlItem } from 'services/@types';
import { currency } from 'variables/currency';

import AssignEvent from './AssignEvent';
import AssignQuote from './AssignQuote';

interface IFormHeaderProps {
  handleOpenEventDialog: () => void;
  documentType: string;
  event?: IEvent | null;
  handleRemoveEvent: () => void;
  handleChange: (name: string, value: any) => void;
  invoice: Partial<IInvoice>;
  removeFile: (type: 'invoiceAttachment') => void;
  handleUploadCompleted: (file: UrlItem) => void;
  isAllProductsVatIncluded: boolean;
  handleChangeAllProductsVatIncluded: (e: any) => void;
  handleOpenQuoteDialog: () => void;
}

export default function FormHeader(props: IFormHeaderProps) {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const { t } = useTranslation();
  const {
    handleOpenEventDialog,
    documentType,
    handleRemoveEvent,
    handleChange,
    invoice,
    removeFile,
    handleUploadCompleted,
    isAllProductsVatIncluded,
    handleChangeAllProductsVatIncluded,
    handleOpenQuoteDialog,
  } = props;
  const myUser = useMyUser();

  const selectedCurrency = useMemo(() => {
    const selected = currency.find((c) => c.value === invoice?.currency);
    return selected ? [selected] : [''];
  }, [invoice?.currency]);

  const showedCurrency = useMemo(() => {
    const selected = currency.find((c) => c.value === invoice?.currency);
    return selected ? t(selected.label) + ' - ' + selected.symbol : '';
  }, [invoice?.currency, t]);

  return (
    <Flex flexDirection="column" gap="24px">
      <Flex wrap="wrap" gap="36px" alignItems="end">
        {documentType !== 'tax_invoice' && (
          <InputField
            label={t('create_invoice.document_title')}
            placeholder={t('create_invoice.document_title_placeholder')}
            value={invoice?.title}
            w={{ base: '100%', md: '511px' }}
            onChange={(e: any) => {
              handleChange('title', e.target.value);
            }}
          />
        )}
        {documentType === 'tax_invoice' && (
          <AssignEvent
            handleOpenEventDialog={handleOpenEventDialog}
            event={props.event}
            handleRemoveEvent={handleRemoveEvent}
            w={{ base: '100%', md: '320px' }}
            maxW={{ base: '100%', md: '320px' }}
          />
        )}
        {documentType !== 'tax_invoice' && (
          <AssignQuote
            handleOpenQuoteDialog={handleOpenQuoteDialog}
            quoteID={invoice?.quoteID}
          />
        )}
        {/* <InputDateField
          label={t('create_invoice.contract_date')}
          placeholder={t('create_invoice.contract_date')}
          value={
            invoice?.contractDate ? new Date(invoice.contractDate) : new Date()
          }
          onChange={(e: any) => {
            handleChange('contractDate', e);
          }}
          buttonStyles={{
            borderColor: '#0000001A',
            justifyContent: 'space-between',
            w: { base: '100%', md: '320px' },
          }}
          imgStyles={{
            bg: 'transparent',
          }}
          imgSrc={CalendarIcon}
        />
        {documentType === 'tax_invoice' && (
          <InputField
            label={t('create_invoice.description')}
            placeholder={t('create_invoice.description')}
            value={invoice?.description}
            w={{ base: '100%', md: '320px' }}
            onChange={(e: any) => {
              handleChange('description', e.target.value);
            }}
          />
        )} */}

        {/* <DropdownField
          closeOnSelect
          label={t('create_invoice.currency')}
          placeholder={t('create_invoice.currency')}
          options={currency}
          selectedOptions={selectedCurrency}
          onSelected={(e: any) => {
            handleChange('currency', e.value);
          }}
          showedOptions={showedCurrency}
          w={{ base: '100%', md: '320px' }}
        />
        {documentType === 'tax_invoice' && (
          <Checkbox
            variant="roundBrand"
            isChecked={isAllProductsVatIncluded}
            onChange={handleChangeAllProductsVatIncluded}
            w={{ base: '100%', md: 'auto' }}
            h="50px"
            alignItems="center">
            <Text variant="createQuoteCheckboxText">
              {t('create_invoice.vat', {
                percentage: businessPreferences?.vatPercent,
              })}
            </Text>
          </Checkbox>
        )}
        {documentType !== 'tax_invoice' && (
          <DropdownField
            closeOnSelect
            label={t('create_invoice.event_type')}
            placeholder={t('create_invoice.event_type')}
            options={eventTypes.map((type) => `event_types.${type}`)}
            selectedOptions={selectedEventType}
            onSelected={handleSelectEventType}
            showedOptions={showedEventType}
            w={{ base: '100%', md: '320px' }}
          />
        )} */}
      </Flex>
      {/* upload file */}
      {/* <Flex flexDirection="column" gap="16px">
        <Text variant="lineUpFormDialogFileName">
          {t('create_invoice.upload_file')}
        </Text>
        {!invoice?.media?.find((m) => m.type === 'invoiceAttachment') && (
          <Dropzone
            labelSize="14px"
            uploadIcon={
              <Icon
                as={RiDownloadCloud2Line}
                color="gray.500"
                w="25px"
                h="25px"
              />
            }
            uploadLabel={t('create_invoice.add_file_or_drag_and_drop')}
            w="322px"
            h="72px"
            maxH="72px"
            minH="72px"
            onUploadCompleted={handleUploadCompleted}
            uploadKey="invoiceAttachment"
            access="private"
            target="user"
            targetId={myUser?.id}
          />
        )}
        {invoice?.media?.find((m) => m.type === 'invoiceAttachment') && (
          <Flex alignItems="center" gap="4px">
            <Text
              variant="eventGridCardDesc"
              onClick={() => {
                handleOpenPdf(
                  invoice?.media?.find((m) => m.type === 'invoiceAttachment')
                    ?.url,
                  invoice?.media?.find((m) => m.type === 'invoiceAttachment')
                    ?.displayName,
                );
              }}>
              {
                invoice?.media?.find((m) => m.type === 'invoiceAttachment')
                  ?.displayName
              }
            </Text>
            <Icon
              aria-label=""
              as={FiX}
              cursor="pointer"
              onClick={() => removeFile('invoiceAttachment')}
            />
          </Flex>
        )}
      </Flex> */}
    </Flex>
  );
}
