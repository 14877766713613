import { Flex, Hide, Spinner } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { setBusinessEventPlanFilter } from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { setImportDataDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useMyUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { AnimatePresence, motion } from 'framer-motion';
import {
  animatedContainerItemStyles,
  animatedContainerStyles,
} from 'motionVariants';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import EventsOverviewHeader from './components/EventsOverviewHeader';
import GridEventCard from './components/GridEventCard';
import ListEventCard from './components/ListEventCard';
import NoEvents from './components/NoEvents';
import NoEventsCreated from './components/NoEventsCreated';
import useEvents from './hooks/useEvents';

export default function EventsOverview({
  isArchive = false,
}: {
  isArchive?: boolean;
}) {
  const { result, isFilterEmpty, isLoading } = useEvents();
  const dispatch = useDispatch<AppDispatch>();
  const userPreferences = useMyUserPreferences();

  const handleOpenImportDialog = useCallback(() => {
    dispatch(setImportDataDialog({ item: { activeTab: 0 } }));
  }, [dispatch]);

  useEffect(() => {
    if (isArchive) {
      dispatch(
        setBusinessEventPlanFilter({ status: ['completed', 'cancelled'] }),
      );
    } else {
      dispatch(
        setBusinessEventPlanFilter({ status: ['new', 'active', 'post'] }),
      );
    }
  }, [dispatch, isArchive]);

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      flexDirection={{ base: 'column', md: 'row' }}
      gap={4}>
      <Card
        w="100%"
        h="calc(100vh - 170px)"
        gap="28px"
        borderRadius={{ base: '0px', md: '18px' }}>
        <EventsOverviewHeader
          isArchive={isArchive}
          onOpenImportDialog={handleOpenImportDialog}
        />
        <AnimatePresence mode="wait">
          {isLoading ? (
            <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <motion.div
              variants={animatedContainerStyles}
              initial="hidden"
              style={{
                width: 'inherit',
                height: 'inherit',
                overflow: 'auto',
              }}
              animate="visible">
              {result.length === 0 && !isFilterEmpty && <NoEvents />}
              {result.length === 0 && isFilterEmpty && (
                <NoEventsCreated onOpenImportDialog={handleOpenImportDialog} />
              )}
              {result.length > 0 && (
                <motion.div
                  variants={animatedContainerItemStyles}
                  style={{ width: 'inherit', height: 'inherit' }}>
                  <Flex
                    wrap={
                      userPreferences.eventsOverviewLayout === 'list'
                        ? 'nowrap'
                        : 'wrap'
                    }
                    flexDirection={
                      userPreferences.eventsOverviewLayout === 'list'
                        ? 'column'
                        : 'row'
                    }
                    overflowY="auto"
                    gap="12px">
                    {result.map((businessEventPlan, index) =>
                      businessEventPlan.eventId?.id ||
                      businessEventPlan.event?.id ? (
                        userPreferences.eventsOverviewLayout === 'list' ? (
                          <Flex w="full">
                            <Hide below="md">
                              <ListEventCard
                                key={index}
                                eventName={businessEventPlan?.eventName}
                                event={
                                  businessEventPlan.event ||
                                  businessEventPlan.eventId
                                }
                              />
                            </Hide>
                            <Hide above="md">
                              <GridEventCard
                                key={index}
                                eventName={businessEventPlan?.eventName}
                                event={
                                  businessEventPlan.event ||
                                  businessEventPlan.eventId
                                }
                              />
                            </Hide>
                          </Flex>
                        ) : (
                          <GridEventCard
                            key={index}
                            eventName={businessEventPlan?.eventName}
                            event={
                              businessEventPlan.event ||
                              businessEventPlan.eventId
                            }
                          />
                        )
                      ) : null,
                    )}
                  </Flex>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Card>
    </Flex>
  );
}
