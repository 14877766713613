import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import AuthImage from 'components/authImage/AuthImage';
import Card from 'components/card/Card';
import { setContactsPickerDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import {
  useCreateBusinessEventPlan,
  useDeleteBusinessEventPlan,
} from 'queries/businessEventPlan';
import { useGetSuppliers } from 'queries/supplier';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdOutlineMoreHoriz } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Supplier } from 'services/@types';
import businessEventPlanService from 'services/businessEventPlan.api';

export default function Suppliers() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { style, ref } = useHighlightAnimation('event_suppliers');
  const params = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const eventId = useMemo(() => params.id, [params.id]);
  const result = useGetSuppliers({ eventId });
  const createBusinessEventPlan = useCreateBusinessEventPlan();
  const deleteBusinessEventPlan = useDeleteBusinessEventPlan();

  const suppliers = useMemo(
    () => result?.data as unknown as Supplier[],
    [result.data],
  );

  const handleRemoveSupplierFromEvent = useCallback(
    async (supplier: Supplier) => {
      try {
        const data = await businessEventPlanService.getBusinessEventPlans({
          eventId,
          businessId: supplier.businessID,
        });

        if (data?.results?.[0]?.id) {
          deleteBusinessEventPlan.mutateAsync(data.results[0].id).then(() => {
            queryClient.invalidateQueries({
              queryKey: ['suppliers', { eventId }],
            });
            toast({
              title: t('event.supplier_removed_from_event'),
              status: 'success',
              variant: 'main',
              position: 'top-right',
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [deleteBusinessEventPlan, eventId, queryClient],
  );

  const handleAddSupplierToEvent = useCallback(
    async (
      v: {
        supplier: Supplier;
      }[],
    ) => {
      console.log('supplier selected', v);
      // if no suppliers selected, remove all suppliers from event
      if (suppliers?.length > 0 && v?.length === 0) {
        suppliers.forEach((supplier) => {
          handleRemoveSupplierFromEvent(supplier);
        });
        return;
      }
      // remove suppliers which was unselected
      suppliers
        .filter((supplier) => !v.find((v) => v.supplier.id === supplier.id))
        .forEach((supplier) => {
          handleRemoveSupplierFromEvent(supplier);
        });

      for (const supplier of v) {
        const businessId = supplier.supplier.businessID;
        if (!businessId) {
          console.error('this supplier has no business id');
          continue;
        }

        createBusinessEventPlan
          .mutateAsync({
            planRequest: {
              eventId,
              businessId,
              participants: [],
            },
            notify: true,
          })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: ['suppliers', { eventId }],
            });
            toast({
              title: t('event.supplier_added_to_event'),
              status: 'success',
              variant: 'main',
              position: 'top-right',
            });
          });
      }
    },
    [
      suppliers,
      handleRemoveSupplierFromEvent,
      createBusinessEventPlan,
      eventId,
      queryClient,
      toast,
      t,
    ],
  );

  const handleOpenSelectSupplierDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenSuppliers: suppliers,
          eventId,
          type: 'suppliers',
        },
        onConfirm: handleAddSupplierToEvent,
      }),
    );
  }, [dispatch, suppliers, eventId, handleAddSupplierToEvent]);

  return (
    <Card
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      style={{ gridColumn: 'span 2' }}
      maxHeight="535px"
      h="100%"
      gap="20px"
      minH="535px"
      overflowX="hidden"
      overflowY="auto"
      minW={{ base: '100%', lg: 'auto' }}
      {...style}
      ref={ref}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.event_suppliers')}</Text>
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={handleOpenSelectSupplierDialog}
        />
      </Flex>
      <Flex flexDirection="column" gap="20px">
        {suppliers?.map((supplier) => (
          <Flex
            key={supplier.id}
            alignItems="center"
            w="100%"
            flexDirection="column">
            <Flex
              alignItems="center"
              gap="10px"
              justifyContent="space-between"
              w="100%">
              <Flex alignItems="center" gap="10px">
                <AuthImage
                  path={supplier.media?.[0]?.url || ''}
                  name={supplier.name}
                  w="34px"
                  h="34px"
                  isAvatar
                />
                <Flex flexDirection="column" maxW="140px">
                  <Text variant="eventSupplierName" noOfLines={1}>
                    {supplier.name}
                  </Text>
                  <Text variant="createQuoteCheckboxText" noOfLines={1}>
                    {supplier.email}
                  </Text>
                </Flex>
              </Flex>
              <Menu>
                <MenuButton aria-label="more">
                  <Icon
                    as={MdOutlineMoreHoriz}
                    color="#93C3B3"
                    w="20px"
                    h="20px"
                  />
                </MenuButton>
                <MenuList p="0" overflow="hidden">
                  <MenuItem
                    gap="13px"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => handleRemoveSupplierFromEvent(supplier)}
                    h="100%">
                    <Text variant="eventTabButtonTitle">
                      {t('event.remove_from_event')}
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Divider />
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}
