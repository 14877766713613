import {
  Drawer,
  DrawerContent,
  Flex,
  Icon,
  IconButton,
  Show,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { CiImport } from 'react-icons/ci';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { MdFileUpload } from 'react-icons/md';
import { IClient } from 'services/@types';
import useClientView from '../hooks/useClientView';
import ClientDetailsCard from './ClientDetailsCard';
import ClientsListItem from './ClientsListItem';
import NoSearchResult from './NoSearchResult';
interface ClientsViewProps {
  onOpenAddClient: () => void;
  onOpenImportDialog: () => void;
  clients: IClient[];
  search: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}
export default function ClientsView(props: ClientsViewProps) {
  const { t } = useTranslation();
  const {
    onOpenAddClient,
    clients,
    search,
    handleSearch,
    loading,
    onOpenImportDialog,
  } = props;
  const { isOpen, onClose, handleSelectClient } = useClientView();

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      gap="4"
      flexDirection={{ base: 'column', md: 'row' }}>
      <Card
        p={0}
        flex="1"
        gap="24px"
        borderRadius={{ base: '0px', md: '30px' }}
        h="calc(100vh - 170px)">
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          p="20px 20px 0">
          <Text variant="previewQuoteContactTitle">
            {t('clients.clients_list')}
          </Text>
          <Flex alignItems="center" gap="2" w={{ base: '100%', md: 'auto' }}>
            <InputField
              w={{ base: '100%', md: 'auto' }}
              placeholder={t('clients.search')}
              iconRight={<Icon as={FiSearch} w="20px" h="20px" />}
              onChange={handleSearch}
              value={search}
            />
            <Tooltip label={t('clients.import_clients')} placement="top" variant="business">
              <IconButton
                aria-label="import"
                size="md"
                icon={<Icon as={CiImport} />}
                onClick={props.onOpenImportDialog}
                variant="actionIconBlue"
              />
            </Tooltip>
            <IconButton
              borderRadius="10px"
              icon={<Icon as={FiPlus} color="white" w="29px" h="29px" />}
              onClick={onOpenAddClient}
              aria-label="add"
              w="32px"
              h="32px"
              minW="32px"
              bg="#93C3B3"
            />
          </Flex>
        </Flex>
        <Flex
          display={
            loading || (clients.length === 0 && search.length > 0)
              ? 'none'
              : 'flex'
          }
          flexDirection="column"
          overflowY="auto"
          h="calc(100vh - 270px)">
          {clients.map((client) => (
            <ClientsListItem
              key={client.id}
              onClick={() => handleSelectClient(client)}
              client={client}
            />
          ))}
        </Flex>
        {loading && (
          <Flex justifyContent="center" alignItems="center" h="100%">
            <Spinner />
          </Flex>
        )}
        {clients.length === 0 && search.length > 0 && (
          <NoSearchResult search={search} />
        )}
      </Card>
      <Show above="md">
        <ClientDetailsCard />
      </Show>
      <Show below="md">
        <Drawer isOpen={isOpen} onClose={onClose}>
          <DrawerContent
            maxW="full"
            mt={{
              sm: 240,
            }}
            padding={0}
            borderRadius="0px">
            <ClientDetailsCard />
          </DrawerContent>
        </Drawer>
      </Show>
    </Flex>
  );
}
