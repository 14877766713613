import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import AuthImage from 'components/authImage/AuthImage';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { useMemo } from 'react';
import businessService from 'services/business.api';

interface IBusinessDetailsProps {
  senderBusinessID?: string;
}

export default function BusinessDetails(props: IBusinessDetailsProps) {
  const { senderBusinessID = null } = props;
  const myBusiness = useMyBusiness();

  const { data: senderBusiness } = useQuery({
    queryKey: ['business', senderBusinessID],
    queryFn: () => businessService.getBusiness(senderBusinessID),
    enabled: !!senderBusinessID,
  });

  const businessName = useMemo(() => {
    return senderBusiness?.businessName || myBusiness?.businessName;
  }, [senderBusiness?.businessName, myBusiness?.businessName]);

  const businessLogo = useMemo(() => {
    const data = senderBusiness || myBusiness;
    return data?.media?.find((m) => m.type === 'profileImage')?.url;
  }, [senderBusiness?.media, myBusiness?.media]);

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <AuthImage
        path={businessLogo}
        alt={businessName}
        isAvatar
        borderRadius="0px"
        maxW="150px"
      />
      <Text
        fontSize="18px"
        fontWeight="600"
        color="#000000"
        lineHeight="28px"
        letterSpacing="-2%">
        {businessName}
      </Text>
    </Flex>
  );
}
