import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import Avatars from 'components/avatars';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import { LuBookmark } from 'react-icons/lu';
import { IMeeting } from 'services/@types';
import { useMyUser } from 'contexts/redux/auth/authSlice';
export const AnchorMeeting = ({
  meeting,
  openCancelMeetingDialog,
}: {
  meeting: IMeeting;
  openCancelMeetingDialog: ({
    participant,
    meetingId,
    eventId,
  }: {
    participant: string[];
    meetingId: string;
    eventId: string;
  }) => void;
}) => {
  const { t } = useTranslation();
  const myUser = useMyUser();

  const startTime = useMemo(() => {
    return meeting?.startDate
      ? format(new Date(meeting.startDate), 'MMM dd, HH:mm')
      : '';
  }, [meeting?.startDate]);

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      w="100%"
      border="1px solid #AA915D"
      borderRadius="20px"
      p="16px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="anchorMeetingName">{meeting?.name}</Text>
        <Flex alignItems="center" gap="16px">
          <Icon as={LuBookmark} color="#AA915D" w="24px" h="24px" />
          {meeting?.creatorUserId === myUser?.id && (
            <Menu>
              <MenuButton onClick={(e) => e.stopPropagation()}>
                <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
              </MenuButton>
              <MenuList>
                <MenuItem
                  h="44px"
                  onClick={(e) => {
                    e.stopPropagation();
                    openCancelMeetingDialog({
                      participant: meeting?.guests,
                      meetingId: meeting?.id,
                      eventId: meeting?.eventId,
                    });
                  }}>
                  <Text variant="eventTabButtonTitle">
                    {t('event.anchor_meetings.cancel_meeting')}
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Text variant="dashboardEventCardTime">{startTime}</Text>
        <Flex
          bg="#0050EF1A"
          w="82px"
          h="26px"
          borderRadius="4px"
          alignItems="center"
          justifyContent="center">
          <Text color="#60A0CB" fontSize="14px" fontWeight="500">
            Upcoming
          </Text>
        </Flex>
      </Flex>
      <Text variant="emptyAnchorMeetingTitle">
        {t(`event.anchor_meetings.${meeting?.anchorMeetingType}`)}
      </Text>
      <Flex alignItems="center" gap="8px">
        <Icon as={IoLocationOutline} color="#AA915D" h="22px" />
        <Text variant="anchorMeetingLocation">{meeting?.location?.label}</Text>
      </Flex>
      <Flex alignItems="center" gap="8px" justifyContent="space-between">
        <Text variant="anchorMeetingDescription">{meeting?.description}</Text>
        <Avatars users={meeting?.guests} />
      </Flex>
    </Flex>
  );
};
