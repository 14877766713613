import {
  Box,
  Flex,
  Text,
  VStack,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useContactImport from './hooks/useContactImport';

type ContactsProps = {
  onClose: () => void;
};

const Contacts = ({ onClose }: ContactsProps) => {
  const { t } = useTranslation();
  const {
    isLoading,
    error,
    getRootProps,
    getInputProps,
    isDragActive,
    importedClients,
    fileName,
    handleImport,
  } = useContactImport({
    onClose: () => {
      onClose();
    },
  });

  const bg = useColorModeValue('white', 'navy.700');
  const borderColor = useColorModeValue('gray.500', 'whiteAlpha.100');

  return (
    <Flex
      content="space-between"
      align="center"
      flexDir="column"
      gap={{ base: '8px', md: '24px' }}>
      <VStack w="full" spacing={4} align="stretch" overflowY="auto" maxH="50vh">
        <Box
          {...getRootProps()}
          p={6}
          cursor="pointer"
          transition="all 0.2s"
          minH="70px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position={'relative'}
          borderColor={isDragActive ? 'brand:300 ' : borderColor}
          borderRadius="16px"
          _hover={{
            border: '1px dashed',
          }}
          bg={bg}
          border={'1px dashed'}>
          <input {...getInputProps()} />
          <VStack spacing={2}>
            {fileName ? (
              <>
                <Text fontSize="sm" color="gray.500">
                  {t('dialogs.import_data.current_file')}: {fileName}
                </Text>
                <Text fontSize="xs" color="blue.500">
                  {t('dialogs.import_data.drop_to_replace')}
                </Text>
              </>
            ) : (
              <>
                <Text textAlign="center">
                  {isDragActive
                    ? t('dialogs.import_data.drop_file_here')
                    : t('dialogs.import_data.drag_drop_prompt')}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {t('dialogs.import_data.supported_formats')}: .xlsx, .xls
                </Text>
              </>
            )}
          </VStack>
        </Box>
        {isLoading && (
          <Text textAlign="center" color="gray.500">
            {t('dialogs.import_data.processing_file')}
          </Text>
        )}
        {error && (
          <Text textAlign="center" color="red.500">
            {t('dialogs.import_data.error_processing_file')}: {error.message}
          </Text>
        )}
      </VStack>

      {importedClients.length > 0 && (
        <>
          <Box w="full" overflowX="auto" maxH="50vh" overflowY="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t('dialogs.import_data.table.name')}</Th>
                  <Th>{t('dialogs.import_data.table.type')}</Th>
                  <Th>{t('dialogs.import_data.table.email')}</Th>
                  <Th>{t('dialogs.import_data.table.phone')}</Th>
                  <Th>{t('dialogs.import_data.table.address')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {importedClients.map((client, index) => (
                  <Tr key={index}>
                    <Td>
                      {client.company ||
                        `${client.firstName} ${client.lastName}`}
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={
                          client.type === 'private' ? 'green' : 'blue'
                        }>
                        {t(`dialogs.import_data.client_types.${client.type}`)}
                      </Badge>
                    </Td>
                    <Td>{client.email || '-'}</Td>
                    <Td>{client.phone || '-'}</Td>
                    <Td>{client.address || '-'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Button onClick={handleImport} variant="h1cta" minW={150}>
            {t('dialogs.import_data.import')}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default Contacts;
