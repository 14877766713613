import { Button, Flex, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { IClient, IClientContact } from 'services/@types';
import AddClientModal from 'views/main/managment/clients/components/AddClientModal';
import useClients from 'views/main/managment/clients/hooks/useClients';
import ChosenClient from 'views/main/managment/events/createUpdateEvent/ChosenClient';

type IClientProps = {
  setClient?: (client: { client: IClient; contacts: IClientContact[] }) => void;
  clientsList: (IClient | IClientContact)[];
  removeChosenClient: (clientId: string, contactId?: string) => void;
  handleOpenClientDialog: () => void;
};

export default function Clients(props: IClientProps) {
  const { t } = useTranslation();
  const { clientsList, setClient, removeChosenClient, handleOpenClientDialog } =
    props;
  const { onOpenAddClient, isAddClientOpen, onAddClientClosed } = useClients({
    setClient,
  });

  return (
    <Flex flexDirection="column" gap="12px">
      <Text variant="lineUpFormDialogFileName">
        {t('create_invoice.client_details')}
      </Text>
      <Flex alignItems="center" gap="12px">
        <Button
          margin={'0 !important'}
          onClick={handleOpenClientDialog}
          variant="h1cta"
          w="full"
          maxW={{ base: 'unset', md: '320px' }}
          style={{
            outline: false ? '1px solid #FF554A' : 'none',
            outlineOffset: '4px',
          }}>
          {t('create_invoice.select_clients')}
        </Button>
        <Button
          margin={'0 !important'}
          onClick={onOpenAddClient}
          variant="h2outlined"
          w="full"
          maxW={{ base: 'unset', md: '320px' }}>
          {t('create_invoice.create_new_client')}
        </Button>
      </Flex>
      <Flex gap="12px" wrap="wrap" w="100%">
        <AnimatePresence>
          {clientsList.map((c) => (
            <motion.div
              layout
              key={c.id}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{
                type: 'spring',
                stiffness: 200,
                damping: 25,
              }}>
              <ChosenClient
                client={'type' in c ? c : undefined}
                contact={'type' in c ? undefined : (c as IClientContact)}
                removeClient={removeChosenClient}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </Flex>
      <AddClientModal isOpen={isAddClientOpen} onClose={onAddClientClosed} />
    </Flex>
  );
}
