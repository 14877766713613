import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useGetEntity } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Business, UrlItem } from 'services/@types';
import { Engfonts, Hebrfonts } from 'theme/foundations/fonts';
import { getLocalizedDir } from 'helpers/getLocalizedDir';

import ContactDetails from './components/ContactDetails';
import Header from './components/Header';
import NoteAndTerms from './components/NoteAndTerms';
import ProductsList from './components/ProductsList';
import Summary from './components/Summary';
import usePreviewQuote from './hooks/usePreviewQuote';
import DeclineQuoteDialog from 'views/auth/approveQuote/components/DeclineQuoteDialog';

export default function PreviewQuote() {
  const params = useParams();
  const myUser = useMyUser();

  const {
    memoizedQuote,
    memoizedIsDownloading,
    handleUpdateQuote,
    forcedDesktopView,
    componentRef,
    setForcedDesktopView,
    setIsDownloading,
    handleDownloadPdf,
    isContract,
    memoDiscount,
    handleSendQuote,
    handleApprove,
    handleDecline,
    isDeclineQuoteDialogOpen,
    onDeclineQuoteDialogClose,
    onDeclineQuoteDialogOpen,
    comment,
    setComment,
  } = usePreviewQuote();

  const { data: business } = useGetEntity<Business>(
    'business',
    params?.businessId || memoizedQuote?.businessId || myUser?.businessID,
  );

  const { t } = useTranslation('', {
    lng: memoizedQuote?.language,
  });

  const dir = useMemo(() => {
    return getLocalizedDir(memoizedQuote?.language || 'en');
  }, [memoizedQuote?.language]);

  const fonts = memoizedQuote?.language === 'he' ? Hebrfonts : Engfonts;

  return (
    <Box
      w="full"
      sx={{
        '& *': {
          direction: dir,
          fontFamily: fonts.body,
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          direction: dir,
          fontFamily: fonts.heading,
        },
        '& button': {
          direction: dir,
          fontFamily: fonts.button || fonts.body,
        },
      }}>
      <Flex
        w={'100%'}
        pt={{
          base: '190px',
          md: '80px',
          xl: '80px',
        }}
        pb={{
          base: '100px',
          md: '0',
        }}
        flexDirection="column"
        gap="8px">
        <Card
          w={forcedDesktopView.state ? '1016px' : '100%'}
          ref={componentRef}
          gap={{
            base: forcedDesktopView.state ? 5 : '24px',
            md: 5,
          }}
          p="24px"
          borderRadius={{
            base: forcedDesktopView.state ? '30px' : 0,
            md: '30px',
          }}>
          <Header business={business} />
          <Divider borderColor="#00000033" />
          <ContactDetails
            setIsDownloading={setIsDownloading}
            isDownloading={memoizedIsDownloading}
            dir={dir}
            lang={memoizedQuote?.language}
            handleDownload={() => {
              setForcedDesktopView({
                state: true,
                executor: handleDownloadPdf,
              });
            }}
            createdAt={memoizedQuote?.createdAt}
            quoteNumber={memoizedQuote?.quoteNumber}
            clientName={memoizedQuote?.clients
              .map(
                (client) =>
                  `${client?.client?.firstName || ''} ${
                    client?.client?.lastName || ''
                  }`,
              )
              .join(' ')}
            numberOfGuests={memoizedQuote?.numberOfGuests}
            location={memoizedQuote?.location}
            totalAmount={memoizedQuote?.generalPaymentDetails?.totalPayment}
            currency={memoizedQuote?.currency}
            eventId={memoizedQuote?.eventId}
            businessId={memoizedQuote?.businessId}
          />
          <ProductsList
            dir={dir}
            lang={memoizedQuote?.language}
            products={memoizedQuote?.products}
            currency={memoizedQuote?.currency}
            vatPercent={memoizedQuote?.vatPercent}
          />
          <Divider borderColor="#00000033" />
          <Flex
            justify="space-between"
            gap="24px"
            flexDirection={{
              base: forcedDesktopView.state ? 'row' : 'column',
              md: 'row',
            }}>
            <NoteAndTerms
              dir={dir}
              lang={memoizedQuote?.language}
              comment={memoizedQuote?.comment}
              paymentTerms={memoizedQuote?.paymentTerms}
              legalText={memoizedQuote?.legalText}
              media={memoizedQuote?.media?.[0] as UrlItem}
            />
            <Summary
              dir={dir}
              lang={memoizedQuote?.language}
              currency={memoizedQuote?.currency}
              total={memoizedQuote?.generalPaymentDetails?.total}
              discount={memoDiscount}
              totalPayment={
                memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount
              }
              vat={memoizedQuote?.generalPaymentDetails?.vat}
              amountToPay={memoizedQuote?.generalPaymentDetails?.totalPayment}
              paymentInInstallments={memoizedQuote?.paymentInInstallments}
              vatPercent={memoizedQuote?.vatPercent}
            />
          </Flex>
          {!memoizedIsDownloading && (
            <Flex
              justify={{
                base: forcedDesktopView.state ? 'flex-end' : 'center',
                md: 'flex-end',
              }}>
              <Flex flexDirection="column" gap="12px">
                {myUser?.businessID === memoizedQuote?.businessId && (
                  <Flex alignItems="center" gap="8px" justifyContent="end">
                    <Switch
                      dir={dir}
                      variant="brand"
                      isChecked={isContract}
                      onChange={handleUpdateQuote}
                    />
                    <Text variant="eventSubTitle" fontSize="14px">
                      {t('quote_preview.send_quote_as_contract')}
                    </Text>
                    <Tooltip
                      hasArrow
                      variant="business"
                      arrowShadowColor="#00000010"
                      label={t('quote_preview.tooltip_label')}
                      aria-label="Add or remove members to your account. manage account permissions">
                      <IconButton
                        aria-label="info"
                        bg="transparent"
                        icon={<MdInfoOutline />}
                        color="#7BA395"
                      />
                    </Tooltip>
                  </Flex>
                )}

                {myUser?.businessID === memoizedQuote?.businessId && (
                  <Button
                    w={{
                      base: forcedDesktopView.state ? '280px' : '171px',
                      md: '280px',
                    }}
                    variant="h1cta"
                    onClick={handleSendQuote}>
                    {t('quote_preview.send_to')}
                  </Button>
                )}
                <Flex
                  justifyContent="end"
                  gap="10px"
                  alignItems="center"
                  w="100%"
                  display={
                    forcedDesktopView?.state || memoizedIsDownloading
                      ? 'none'
                      : 'flex'
                  }>
                  {myUser?.businessID !== memoizedQuote?.businessId &&
                    memoizedQuote?.status === 'pendingApproval' && (
                      <>
                        <Button
                          variant="h1cta"
                          w="320px"
                          onClick={handleApprove}>
                          {t('quote_preview.approve')}
                        </Button>
                        <Button
                          variant="h2outlined"
                          w="320px"
                          onClick={onDeclineQuoteDialogOpen}>
                          {t('quote_preview.decline')}
                        </Button>
                      </>
                    )}
                </Flex>
              </Flex>
            </Flex>
          )}
          <DeclineQuoteDialog
            isOpen={isDeclineQuoteDialogOpen}
            onClose={onDeclineQuoteDialogClose}
            handleDecline={handleDecline}
            comment={comment}
            setComment={setComment}
          />
        </Card>
      </Flex>
    </Box>
  );
}
