import DropdownField from 'components/fields/DropdownField';
import { RootState } from 'contexts/redux/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GoogleCalendarImg from 'assets/img/googleCalendar/googleCalendar.png';
import { useSelector } from 'react-redux';
import { Image } from '@chakra-ui/react';
interface CalendarsDropDownProps {
  value: string;
  onChange: (value: string) => void;
  isMeeting?: boolean;
  isEvent?: boolean;
  [key: string]: any;
}
export default function CalendarsDropDown(props: CalendarsDropDownProps) {
  const { t } = useTranslation();
  const { calendars } = useSelector((state: RootState) => state.calendars);
  const {
    value,
    onChange,
    isEvent = false,
    isMeeting = false,
    ...rest
  } = props;

  const options = useMemo(() => {
    const result: { value: string | null; label: string }[] = [];

    for (const calendar of calendars) {
      if (
        (!isEvent && calendar.name === 'events') ||
        (!isMeeting && calendar.name === 'meetings') ||
        calendar.name === 'holidays'
      ) {
        continue;
      }
      result.push({
        value: calendar.id,
        label: calendar?.isSystem
          ? t(`calendar.general_calendars.${calendar.name}`)
          : calendar.name,
      });
    }

    return result;
  }, [calendars, t, isEvent, isMeeting]);

  const selectedOptions = useMemo(() => {
    const selected = options.find((o) => o.value === value);
    return selected ? [selected] : [];
  }, [options, value]);

  const showedOptions = useMemo(() => {
    return calendars.find((c) => c.id === value);
  }, [value, calendars]);

  const onSelected = (selected: { value: string; label: string }) => {
    onChange(selected.value);
  };

  // to do: remove on imrove for other icons it in future
  const isGoogleCalendar = useMemo(
    () => showedOptions?.provider === 'google',
    [showedOptions],
  );

  console.log('Showed options', showedOptions);
  return (
    <DropdownField
      options={options}
      onSelected={onSelected}
      selectedOptions={selectedOptions}
      showedOptions={
        showedOptions?.isSystem
          ? t(`calendar.general_calendars.${showedOptions?.name}`)
          : showedOptions?.name
      }
      showedOptionsIcon={
        isGoogleCalendar && (
          <Image src={GoogleCalendarImg} w="24px" h="24px" alt="" />
        )
      }
      placeholder={t('select_calendar')}
      closeOnSelect
      {...rest}
    />
  );
}
