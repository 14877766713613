import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { IMeeting, PaginatedResults } from 'services/@types';
import _meetingService from 'services/meeting.api';

// Query keys
const getMeetingQueryKey = (meetingId: string): QueryKey => [
  'meeting',
  meetingId,
];
const getMeetingsQueryKey = (params?: Record<string, any>): QueryKey => [
  'meetings',
  params,
];

// Query options
const getMeetingQueryOptions = (meetingId: string) => ({
  queryKey: getMeetingQueryKey(meetingId),
  queryFn: () => _meetingService.getMeeting(meetingId),
  staleTime: 0, // disable stale time
  gcTime: 0, // disable gc time
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const getMeetingsQueryOptions = <T = IMeeting>(
  params?: Record<string, any>,
) => ({
  queryKey: getMeetingsQueryKey(params),
  queryFn: () => _meetingService.getMeetings<T>(params),
  staleTime: 0, // disable stale time
  gcTime: 0, // disable gc time
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

// Interface for useGetMeeting options
interface UseGetMeetingOptions {
  enabled?: boolean;
}

/**
 * Hook for fetching a meeting by ID
 */
export const useGetMeeting = (
  meetingId: string,
  options: UseGetMeetingOptions = {},
): UseQueryResult<IMeeting, Error> => {
  return useQuery({
    ...getMeetingQueryOptions(meetingId),
    enabled: options.enabled !== false && !!meetingId,
  });
};

/**
 * Hook for fetching meetings with optional parameters
 */
export const useGetMeetings = <T = IMeeting>(params?: Record<string, any>) => {
  return useQuery<PaginatedResults<T>, Error>({
    ...getMeetingsQueryOptions<T>(params),
    enabled: true,
  });
};

/**
 * Hook for updating a meeting
 */
export const useUpdateMeeting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, updates }: { id: string; updates: Partial<IMeeting> }) =>
      _meetingService.updateMeeting(id, updates),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getMeetingQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['meetings'] });

      // If the meeting has an eventId, invalidate that event's meetings queries
      if (data.eventId) {
        queryClient.invalidateQueries({
          queryKey: [`getMeetings-${data.eventId}`],
        });
        queryClient.invalidateQueries({
          queryKey: ['meetings', data.eventId],
        });
      }
    },
  });
};

/**
 * Hook for deleting a meeting
 */
export const useDeleteMeeting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => _meetingService.deleteMeeting(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: getMeetingQueryKey(id) });
      queryClient.invalidateQueries({ queryKey: ['meetings'] });

      // Additional invalidations for related queries
      queryClient.invalidateQueries({ queryKey: ['todayMeetings'] });
      queryClient.invalidateQueries({ queryKey: ['anchorMeetings'] });
    },
  });
};

/**
 * Hook for creating a meeting
 */
export const useCreateMeeting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (meeting: IMeeting) => _meetingService.createMeeting(meeting),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getMeetingQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['meetings'] });

      // If the meeting has an eventId, invalidate that event's meetings queries
      if (data.eventId) {
        queryClient.invalidateQueries({
          queryKey: [`getMeetings-${data.eventId}`],
        });
        queryClient.invalidateQueries({
          queryKey: ['meetings', data.eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['todayMeetings', data.eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['anchorMeetings', data.eventId],
        });
      }
    },
  });
};

// Helper methods for prefetching and invalidating
useGetMeeting.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  meetingId: string,
) => {
  await queryClient.prefetchQuery(getMeetingQueryOptions(meetingId));
};

useGetMeeting.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  meetingId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getMeetingQueryKey(meetingId) });
};

useGetMeetings.prefetch = async <T = IMeeting>(
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  await queryClient.prefetchQuery(getMeetingsQueryOptions<T>(params));
};

useGetMeetings.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  queryClient.invalidateQueries({ queryKey: getMeetingsQueryKey(params) });
};

export default useGetMeeting;
