import {
  Box,
  Button,
  HStack,
  Image,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import InviteIcon from 'assets/svg/notification/invite.svg';
import axios from 'axios';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import { updateNotification } from 'contexts/redux/notifications/notificationsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import 'moment/locale/he';
import { useGetEntity } from 'queries/entity';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { INotification } from 'services/@types';
import { getNotificationMomentFormat } from './NotificationComponent';

const RequestNotification: React.FC<{
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}> = ({ notification, notificationRef }) => {
  const { appNavigate: navigate } = useAppNavigate();
  const toast = useToast();
  const { acceptButtonLink, declineButtonLink } = notification;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { data: invite, refetch } = useGetEntity<any>(
    'invite',
    notification?.entityId,
  );

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  const handleOpenQuoteNotice = useCallback(
    (quoteId: string) => {
      toast({
        position: 'top-right',
        duration: 5000,
        render: () => (
          <VStack
            p={2}
            w="100%"
            pe={4}
            maxW="500px"
            spacing={0}
            borderRadius="xl"
            align="flex-start"
            justify="flex-start"
            bg="brand.200"
            color="primary.100">
            <HStack spacing={2} justify="flex-start" align="center" w="100%">
              <VStack spacing={0} align="flex-start" justify="flex-start">
                <Text fontWeight="500" noOfLines={2} color="white">
                  {t('notifications_content.quote_request_accepted.title')}
                </Text>
              </VStack>
              <Spacer />

              <Box>
                <Button
                  aria-label="undo"
                  variant="h3white"
                  onClick={() => {
                    navigate(`/main/finance/quotes/${quoteId}`);
                  }}>
                  <Text fontWeight="500">
                    {t(
                      'notifications_content.quote_request_accepted.button_text',
                    )}
                  </Text>
                </Button>
              </Box>
            </HStack>
          </VStack>
        ),
      });
    },
    [navigate, t, toast],
  );

  const handleAccept = useCallback(async () => {
    if (!acceptButtonLink) return;
    const { data: response } = await axios.get(acceptButtonLink);
    console.log('request notification data response -> ', response);
    refetch();
    dispatch(fetchClients({ limit: 1000 }));
    dispatch(
      updateNotification({
        notificationId: notification.id,
        updates: { visible: false },
      }),
    );
    if (notification?.type === 'quote') {
      handleOpenQuoteNotice(response.data.id);
    }
  }, [
    acceptButtonLink,
    dispatch,
    notification.id,
    notification?.type,
    refetch,
    handleOpenQuoteNotice,
  ]);

  const handleDecline = useCallback(() => {
    if (!declineButtonLink) return;
    axios.get(declineButtonLink).then(() => {
      refetch();
    });
    dispatch(
      updateNotification({
        notificationId: notification.id,
        updates: { visible: false },
      }),
    );
  }, [declineButtonLink, dispatch, notification.id, refetch]);

  const isAnswered = useMemo(() => {
    return invite?.status !== 'pending';
  }, [invite?.status]);

  return (
    <Box
      p={4}
      ref={notificationRef}
      // bg="white"
      border="1px solid #F9FAFB"
      // sx={{ backgroundColor: '#93C3B31A' }}
      borderRadius="12px">
      <VStack align="stretch" spacing={4}>
        <HStack gap="12px">
          <Image w="23px" h="13px" src={InviteIcon} />
          <Text variant="cardSubTitle" color="#1B2037">
            {t(notification.message, {
              name: notification.variables?.name,
              date: moment(notification.variables?.date).format(
                'DD MMM,  HH:mm',
              ),
            })}
            {/* New meeting invite from{' '}
            <span style={{ fontWeight: 700 }}>Emily</span> on */}
          </Text>
        </HStack>
        {/* <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Text>12:00 - 13:00 Team meeting</Text>
          <Flex alignItems="center" gap="2.75px">
            <Icon as={IoLocationOutline} color="general.100" />
            <Text variant="cardSectionTitle">riding 36, tel-aviv</Text>
          </Flex>
        </Flex> */}
        {!isAnswered && (
          <HStack justify="space-between">
            <Button
              variant="h1cta"
              onClick={() => {
                // window.open(acceptButtonLink, '_blank');
                handleAccept();
              }}
              flex={1}>
              {/* Accept */}
              {t(notification.acceptButtonText)}
            </Button>
            <Button
              variant="h2outlined"
              onClick={() => {
                // window.open(declineButtonLink, '_blank');
                handleDecline();
              }}
              flex={1}>
              {/* Decline */}
              {t(notification.declineButtonText)}
            </Button>
          </HStack>
        )}
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Box>
  );
};

export default RequestNotification;
