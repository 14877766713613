import { useDisclosure, useToast } from '@chakra-ui/react';
import { closeSplashDialog } from 'contexts/redux/dialog/dialogsSlice';
import { shareQuote } from 'contexts/redux/quote/quoteSlice';
import { format } from 'date-fns';
import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import { useGetInviteTokenData } from 'queries/invite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppNavigate } from 'hooks/useAppNavigate';
import _userActionsService from 'services/userActions.api';

const blobToFile = (blob: Blob, fileName: string) => {
  return new File([blob], fileName, { type: blob.type });
};

export default function useApproveQuote() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const componentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<any>();
  const { appNavigate: navigate } = useAppNavigate();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const toast = useToast();
  const { data: inviteData } = useGetInviteTokenData(
    searchParams?.get('token'),
  );
  const quote = inviteData?.quote;
  const event = inviteData?.event;
  const business = inviteData?.business;
  const {
    isOpen: isDeclineQuoteDialogOpen,
    onOpen: onDeclineQuoteDialogOpen,
    onClose: onDeclineQuoteDialogClose,
  } = useDisclosure();

  // Desktop view handling when generating PDFs
  const [forcedDesktopView, setForcedDesktopView] = useState<{
    state: boolean;
    executor: null | any;
  }>({
    state: false,
    executor: null,
  });

  const [comment, setComment] = useState<string>('');

  const isContract = useMemo(() => quote?.isContract, [quote?.isContract]);

  const memoizedIsDownloading = useMemo(
    () =>
      !!searchParams.get('share') ||
      !!searchParams.get('download') ||
      isDownloading,
    [searchParams, isDownloading],
  );

  const memoDiscount = useMemo(() => {
    if (quote?.generalPaymentDetails?.discountType === 'fixed') {
      return quote?.generalPaymentDetails?.discount;
    }
    return (
      quote?.generalPaymentDetails?.total -
      quote?.generalPaymentDetails?.totalIncludingDiscount
    );
  }, [quote]);

  const quoteFileName = useMemo(() => {
    return `quote_${quote?.quoteNumber}_${format(
      new Date(),
      'dd-MM-yyyy',
    )}.pdf`;
  }, [quote?.quoteNumber]);

  const handleApprove = useCallback(async () => {
    await _userActionsService.getUserActions('quote-approval', quote?.id, {
      status: 'accepted',
      token: searchParams.get('token'),
    });
    navigate('/');
  }, [quote?.id, searchParams, navigate]);

  const handleDecline = useCallback(async () => {
    await _userActionsService.getUserActions('quote-approval', quote?.id, {
      status: 'rejected',
      token: searchParams.get('token'),
      comment,
    });
    navigate('/');
  }, [quote?.id, navigate, comment]);

  const handleDeclineQuoteDialogClose = useCallback(() => {
    onDeclineQuoteDialogClose();
    setComment('');
  }, [onDeclineQuoteDialogClose]);

  const handleSharePdf = useCallback(async () => {
    const canvas = await html2canvas(componentRef.current, {
      scale: 2,
    });
    const imgData = canvas.toDataURL('image/jpeg');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);

    // Calculate image dimensions to fit the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

    // return blob pdf to share
    return pdf.output('blob');
  }, []);

  const handleDownloadPdf = useCallback(async () => {
    // Capture the component as an image
    const canvas = await html2canvas(componentRef.current, {
      scale: 2,
    });

    const imgData = canvas.toDataURL('image/jpeg');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);

    // Calculate image dimensions to fit the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

    // Save the PDF
    pdf.save(quoteFileName);

    return true;
  }, [componentRef, quoteFileName]);

  const handleSetComment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    },
    [],
  );

  useEffect(() => {
    // check that the page is loaded
    if (!quote) return;

    if (searchParams.get('download')) {
      const downloadAndRedirect = async () => {
        const isDownloaded = await handleDownloadPdf();

        if (isDownloaded) {
          setTimeout(() => {
            dispatch(closeSplashDialog());
            toast({
              title: t('quote_preview.downloaded'),
              variant: 'main',
              position: 'top-right',
              status: 'success',
            });
          }, 1000);
        }
      };

      setForcedDesktopView({ state: true, executor: downloadAndRedirect });
    }
  }, [searchParams, handleDownloadPdf, navigate, dispatch, toast, t, quote]);

  useEffect(() => {
    if (!quote) return;
    if (searchParams.get('share')) {
      const sharePdf = async () => {
        const blob = await handleSharePdf();
        const file = blobToFile(blob, quoteFileName);

        if (file) {
          const res = await dispatch(
            shareQuote({
              file,
              eventId: quote?.eventId,
              quoteId: quote?.id,
            }),
          );
          if (res) {
            setTimeout(() => {
              dispatch(closeSplashDialog());
              toast({
                title: t('quote_sharing_dialog.sent'),
                variant: 'main',
                position: 'top-right',
                status: 'success',
              });
            }, 1000);
          }
        }
      };
      sharePdf();
    }
  }, [
    searchParams,
    handleSharePdf,
    navigate,
    dispatch,
    toast,
    t,
    quoteFileName,
    quote,
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (forcedDesktopView.state) {
      timeout = setTimeout(() => {
        forcedDesktopView?.executor();
        setForcedDesktopView({ state: false, executor: null });
      }, 300);
    }
    return () => timeout && clearTimeout(timeout);
  }, [forcedDesktopView]);

  return {
    quote,
    event,
    business,
    memoizedIsDownloading,
    forcedDesktopView,
    componentRef,
    setForcedDesktopView,
    setIsDownloading,
    handleDownloadPdf,
    isContract,
    memoDiscount,
    handleApprove,
    handleDecline,
    isDeclineQuoteDialogOpen,
    onDeclineQuoteDialogOpen,
    onDeclineQuoteDialogClose: handleDeclineQuoteDialogClose,
    comment,
    setComment: handleSetComment,
  };
}
