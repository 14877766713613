import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  AppDialogProps,
  closeQuoteSharingDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { setShareBody, updateQuote } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCreateInvite } from 'queries/invite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

export default function QuoteSharingDialog() {
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const { quoteSharingDialog } = useSelector((state: RootState) => ({
    quoteSharingDialog: state.dialogs.quoteSharingDialog,
  }));
  const dialog = quoteSharingDialog as AppDialogProps;
  const { appNavigate: navigate } = useAppNavigate();
  const [form, setForm] = useState<{
    to: string;
    cc: string[];
  }>({
    to: '',
    cc: [''],
  });
  const [isSendAsContract, setIsSendAsContract] = useState<boolean>(false);
  const { mutateAsync: createInvite } = useCreateInvite();
  const myUser = useMyUser();
  const toast = useToast();
  const handleClose = useCallback(() => {
    dispatch(closeQuoteSharingDialog());
    setForm({
      to: '',
      cc: [''],
    });
    setIsSendAsContract(false);
  }, [dispatch]);

  const handleSharePdf = useCallback(() => {
    dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));
    dispatch(
      setShareBody({
        ...form,
        cc: form?.cc?.filter((cc) => cc !== ''),
      }),
    );

    setTimeout(async () => {
      navigate(
        dialog?.item?.navigateUrl ||
          `/main/finance/quotes/preview/${dialog?.item?.quoteId}?share=true`,
      );
    }, 400);
  }, [
    dispatch,
    form,
    navigate,
    dialog?.item?.navigateUrl,
    dialog?.item?.quoteId,
  ]);

  const handleSend = useCallback(async () => {
    if (isSendAsContract) {
      await dispatch(
        updateQuote({
          id: dialog?.item?.quoteId,
          updates: { isContract: true },
        }),
      );
      const emails: string[] = [];
      if (form?.to) {
        emails.push(form.to);
      }
      if (form?.cc?.filter((cc) => cc !== '').length > 0) {
        emails.push(...form.cc.filter((cc) => cc !== ''));
      }
      try {
        for (const email of emails) {
          await createInvite({
            businessID: myUser?.businessID,
            quoteId: dialog?.item?.quoteId,
            type: 'quote',
            email,
          });
        }
        toast({
          title: t('quote_sharing_dialog.success_as_contract'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
      } catch (error) {
        console.error('handleSend: ', error);
      }
    } else {
      handleSharePdf();
    }
    handleClose();
  }, [
    handleClose,
    handleSharePdf,
    isSendAsContract,
    myUser?.businessID,
    dialog?.item?.quoteId,
    form,
    dispatch,
    createInvite,
    t,
    toast,
  ]);

  const handleShareBodyChange = useCallback((e: any) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleAddCc = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      cc: [...prev.cc, ''],
    }));
  }, []);

  const handleRemoveCc = useCallback((index: number) => {
    setForm((prev) => ({
      ...prev,
      cc: prev.cc.filter((_, i) => i !== index),
    }));
  }, []);

  const handleCcChange = useCallback((e: any, index: number) => {
    setForm((prev) => ({
      ...prev,
      cc: prev.cc.map((cc, i) => (i === index ? e.target.value : cc)),
    }));
  }, []);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle">{t('quote_sharing_dialog.title')}</Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <InputField
              placeholder={t('quote_sharing_dialog.to_placeholder')}
              label={t('quote_sharing_dialog.to')}
              value={form?.to}
              name="to"
              onChange={handleShareBodyChange}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
            />
            {form?.cc?.map((cc, index) => {
              return (
                <HStack alignItems="end" gap="12px" key={index}>
                  <InputField
                    w="100%"
                    flex="1"
                    value={cc}
                    name="cc"
                    onChange={(e) => handleCcChange(e, index)}
                    placeholder={t('quote_sharing_dialog.cc_placeholder')}
                    label={t('quote_sharing_dialog.cc')}
                    labelProps={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#00000099',
                      ps: '10px',
                    }}
                  />
                  {index !== 0 && (
                    <Icon
                      as={FiMinus}
                      bg="brand.400"
                      mb="8px !important"
                      w="32px"
                      h="32px"
                      cursor="pointer"
                      color="white"
                      borderRadius="8px"
                      onClick={() => handleRemoveCc(index)}
                    />
                  )}
                  {index === form?.cc?.length - 1 && (
                    <Icon
                      as={FiPlus}
                      bg="brand.400"
                      w="32px"
                      h="32px"
                      mb="8px !important"
                      cursor="pointer"
                      color="white"
                      borderRadius="8px"
                      onClick={handleAddCc}
                    />
                  )}
                </HStack>
              );
            })}
            <Flex alignItems="center" gap={2}>
              <Switch
                isChecked={isSendAsContract}
                onChange={(e) => setIsSendAsContract(e.target.checked)}
                variant="brand"
                dir={i18n.dir()}
              />
              <Text variant="addEventClientDocumentSignedDocument">
                {t('quote_sharing_dialog.send_quote_as_contract')}
              </Text>
            </Flex>
            <Button
              w="280px"
              variant="h1cta"
              alignSelf="center"
              onClick={handleSend}>
              {t('quote_sharing_dialog.send')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
