import { Button, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AppTooltip } from 'components/tooltip';
import { getLocalizedDir } from 'helpers/getLocalizedDir';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Engfonts, Hebrfonts } from 'theme/foundations/fonts';
import SelectClients from 'views/main/managment/events/createUpdateEvent/components/SelectClients';
import CreateQuoteHeader from './components/CreateQuoteHeader';
import PaymentDetails from './components/PaymentDetails';
import Products from './components/Products';
import QuoteInformation from './components/QuoteInformation';
import SaveAsTemplateModal from './components/SaveAsTemplateModal';
import useCreateQuote from './hooks/useCreateQuote';

export default function CreateQuote() {
  const {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    event,
    setNewQuote,
    isLoading,
    products,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    handleChangePaymentAmount,
    handleChangeNextAmount,
    handleChangePaymentDescription,
    currencyIcon,
    error,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    handleChangeProductVatIncluded,
    handleOpenClientDialog,
    setClient,
    isSaveButtonDisabled,
    isPaymentInInstallmentsCorrect,
    wrongAmount,
    wrongAmountText,
    newQuoteNumber,
    handleAddToLastPayment,
    handleRemovePaymentIndex,
    handleSaveAsTemplate,
    isOpenSaveAsTemplate,
    onCloseSaveAsTemplate,
    handleSaveAsTemplateModal,
    eventDateSwitch,
    setEventDateSwitch,
    setIsUserInteraction,
  } = useCreateQuote();

  const { t } = useTranslation('', {
    lng: newQuote?.language,
  });

  const dir = useMemo(() => {
    return getLocalizedDir(newQuote?.language || 'en');
  }, [newQuote?.language]);

  const fonts = newQuote?.language === 'he' ? Hebrfonts : Engfonts;

  console.log('#new quote', newQuote.clients);

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '100px' }}
      flexDirection="column"
      overflow="auto"
      gap={4}
      sx={{
        '& *': {
          direction: dir,
          fontFamily: fonts.body,
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          direction: dir,
          fontFamily: fonts.heading,
        },
        '& button': {
          direction: dir,
          fontFamily: fonts.button || fonts.body,
        },
      }}>
      <Card gap="36px" position="relative">
        <CreateQuoteHeader
          dir={dir}
          newQuoteNumber={newQuoteNumber}
          handleChange={handleChange}
          newQuote={newQuote}
          handleChangeEventType={handleChangeEventType}
          selectedEventType={selectedEventType}
          handleOpenEventDialog={handleOpenEventDialog}
          event={event}
        />
        <Divider
          orientation="horizontal"
          // my="36px"
          color="#E8E8E8"
          borderWidth={'1px'}
        />

        <SelectClients
          handleOpenClientDialog={handleOpenClientDialog}
          handleChange={handleChange}
          clients={newQuote?.clients}
          hasError={error?.clients}
          setClient={setClient}
          newQuoteLanguage={newQuote?.language}
        />
        <Divider
          orientation="horizontal"
          // my="36px"
          color="#E8E8E8"
          borderWidth={'1px'}
        />

        <QuoteInformation
          handleChange={handleChange}
          newQuote={newQuote}
          handleChangeEventType={handleChangeEventType}
          selectedEventType={selectedEventType}
          eventDateSwitch={eventDateSwitch}
          setEventDateSwitch={setEventDateSwitch}
        />

        <Flex w="100%" flexDirection={{ base: 'column' }} gap="16px">
          <Products
            dir={dir}
            handleAddProduct={handleAddProduct}
            newQuote={newQuote}
            handleChange={handleChange}
            setNewQuote={setNewQuote}
            products={products}
            handleSelectedProduct={handleSelectedProduct}
            handleChangeFile={handleChangeFile}
            clearFile={clearFile}
            currencyIcon={currencyIcon}
            error={error}
            handleChangeAllProductsVatIncluded={
              handleChangeAllProductsVatIncluded
            }
            isAllProductsVatIncluded={isAllProductsVatIncluded}
            handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            setIsUserInteraction={setIsUserInteraction}
          />

          <Divider
            orientation="horizontal"
            color="#E8E8E8"
            borderWidth={'1px'}
          />

          <PaymentDetails
            handleChangeAllProductsVatIncluded={
              handleChangeAllProductsVatIncluded
            }
            isAllProductsVatIncluded={isAllProductsVatIncluded}
            dir={dir}
            newQuote={newQuote}
            handleChangeFile={handleChangeFile}
            clearFile={clearFile}
            handleIncreasePaymentQuantity={handleIncreasePaymentQuantity}
            handleDecreasePaymentQuantity={handleDecreasePaymentQuantity}
            setNewQuote={setNewQuote}
            currencyIcon={currencyIcon}
            error={error}
            handleChangeNextAmount={handleChangeNextAmount}
            handleChangePaymentAmount={handleChangePaymentAmount}
            handleChangePaymentDescription={handleChangePaymentDescription}
            handleRemovePaymentIndex={handleRemovePaymentIndex}
            setIsUserInteraction={setIsUserInteraction}
          />
          <Flex
            justify={{ base: 'center', md: 'flex-end' }}
            align="center"
            gap="10px">
            {!isPaymentInInstallmentsCorrect && (
              <AppTooltip
                label={t('create_quote.total_payment_tooltip')}
                placement="top"
              />
            )}
            <Flex flexDirection="column" align="center">
              {!isPaymentInInstallmentsCorrect && (
                <Text display="inline" color="red" fontSize="12px">
                  {wrongAmountText}
                  {wrongAmount > 0 && (
                    <Text
                      mx="5px"
                      display="inline"
                      color="red"
                      fontSize="12px"
                      onClick={handleAddToLastPayment}
                      cursor="pointer"
                      textDecoration="underline">
                      {t('create_quote.add_to_last_payment')}
                    </Text>
                  )}
                </Text>
              )}
              <HStack>
                {/* <Buttonsave as template */}
                <Button
                  variant="h2outlined"
                  w="240px"
                  isLoading={isLoading}
                  onClick={handleSaveAsTemplateModal}>
                  {newQuote?.isTemplate
                    ? t('create_quote.edit_template')
                    : t('create_quote.save_as_template')}
                </Button>
                <Button
                  isLoading={isLoading}
                  disabled={isSaveButtonDisabled}
                  variant="h1cta"
                  w="240px"
                  onClick={handleCreateQuote}>
                  {t('create_quote.save')}
                </Button>
              </HStack>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      {/* save as template modal */}
      <SaveAsTemplateModal
        quote={newQuote}
        isOpen={isOpenSaveAsTemplate}
        onClose={onCloseSaveAsTemplate}
        handleChange={handleChange}
        handleSave={handleSaveAsTemplate}
      />
    </Flex>
  );
}
