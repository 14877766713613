import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  AppDialogProps,
  closeAlertDialog,
  openAlertDialog,
  setUploadQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { format } from 'date-fns';
import { formatCurrency } from 'helpers/formatNumber';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { getQuotesQueryKey, useDeleteQuote, useGetQuotes } from 'queries/quote';
import { useGetSupplier } from 'queries/supplier';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { MdAdd, MdOutlineMoreHoriz } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IEvent, IQuote, QuoteStatus, UserPreferences } from 'services/@types';
import { currency } from 'variables/currency';
import RenderQuoteStatus from 'views/main/finance/quote/quoteList/components/QuoteStatus';
import UploadQuoteModal from './UploadQuoteModal';
import useGetEvent from 'queries/event';

const Quote = memo(
  ({
    quote,
    isBlurred,
    onDelete,
    openUploadQuoteDialog,
  }: {
    quote: IQuote;
    isBlurred: boolean;
    onDelete: () => void;
    openUploadQuoteDialog: (quoteId: string) => void;
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const supplierData = useGetSupplier(quote?.supplierId);
    const { mutate: deleteQuote } = useDeleteQuote();
    const myBusiness = useMyBusiness();

    const name = useMemo(() => supplierData?.data?.name, [supplierData?.data]);

    const handleNavigateToUpdateQuote = useCallback(() => {
      navigate(`/main/finance/quotes/${quote?.id}`);
    }, [quote?.id, navigate]);

    const handleViewQuote = useCallback(() => {
      navigate(`/main/finance/quotes/preview/${quote?.id}`);
    }, [quote?.id, navigate]);

    const handleDeleteQuote = useCallback(() => {
      dispatch(
        openAlertDialog({
          title: t('event.delete_quote'),
          content: t('event.delete_quote_description'),
          onConfirm: async () => {
            deleteQuote(quote?.id);
            onDelete();
            dispatch(closeAlertDialog());
          },
        }),
      );
    }, [deleteQuote, quote?.id, dispatch, t, onDelete]);

    return (
      <Flex flexDirection="column" gap="10px">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" gap="10px">
            <Text variant="eventSupplierName">{name}</Text>
            <Text
              variant="clientDetailInfo"
              transition="all 0.3s ease"
              filter={isBlurred ? 'blur(8px)' : 'none'}>
              {quote?.totalAmount &&
                formatCurrency(
                  quote.totalAmount,
                  currency.find((c) => c?.value === quote?.currency)?.symbol,
                )}
            </Text>
          </Flex>
          <Menu>
            <MenuButton aria-label="more">
              <Icon as={MdOutlineMoreHoriz} color="#93C3B3" w="20px" h="20px" />
            </MenuButton>
            <MenuList
              w="95px"
              minW="95px"
              minH="55px"
              borderRadius="24px"
              p="0"
              overflow="hidden">
              {quote?.businessId === myBusiness?.id && (
                <MenuItem
                  gap="13px"
                  justifyContent="center"
                  alignItems="center"
                  h="55px"
                  onClick={() => {
                    if (quote?.isManuallyUploaded) {
                      openUploadQuoteDialog(quote?.id);
                    } else {
                      handleNavigateToUpdateQuote();
                    }
                  }}>
                  <Text variant="eventTabButtonTitle">{t('event.edit')}</Text>
                </MenuItem>
              )}
              {quote?.businessId === myBusiness?.id && (
                <MenuItem
                  gap="13px"
                  justifyContent="center"
                  alignItems="center"
                  h="55px"
                  onClick={handleDeleteQuote}>
                  <Text variant="eventTabButtonTitle">{t('event.delete')}</Text>
                </MenuItem>
              )}
              <MenuItem
                gap="13px"
                justifyContent="center"
                alignItems="center"
                h="55px"
                onClick={handleViewQuote}>
                <Text variant="eventTabButtonTitle">{t('event.view')}</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {quote?.createdAt && (
          <Text variant="addRowText">
            {format(new Date(quote?.createdAt), 'dd MMM, yyyy')}
          </Text>
        )}
        <Flex flexDirection="column" gap="10px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="addRowText">
              {quote?.description || quote?.comment || '-'}
            </Text>
            <RenderQuoteStatus
              status={quote.status as QuoteStatus}
              isSender={quote?.businessId === myBusiness?.id}
            />
          </Flex>
        </Flex>
        <Divider />
      </Flex>
    );
  },
);

export default function SupplierQuotes() {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('event_quotes');
  const business = useMyBusiness();
  const queryClient = useQueryClient();
  const params = useParams();

  const { id, blur } = useMyUserPreferences();
  const dispatch = useDispatch<AppDispatch>();

  const eventId = useMemo(() => params.id, [params.id]);

  const { data: eventData } = useGetEvent(eventId);

  const useGetQuotesParams = useMemo(
    () => ({
      supplierBusinessId: business?.id,
      eventId,
      limit: 1000,
      projectBy: '-updatedAt',
    }),
    [business?.id, eventId],
  );
  const { data: quotesData } = useGetQuotes(useGetQuotesParams);

  const onDelete = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: getQuotesQueryKey(useGetQuotesParams),
    });
  }, [useGetQuotesParams, queryClient]);

  const quotes = useMemo(() => quotesData?.results, [quotesData]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isBlurred = useMemo(() => {
    return blur?.eventQuotes;
  }, [blur]);

  const toggleDataVisibility = useCallback(() => {
    let updates: Partial<UserPreferences> = { blur: { ...blur } };
    updates.blur.eventQuotes = !blur.eventQuotes;

    dispatch(updateUserPreferences({ id, updates }));
  }, [blur, dispatch, id]);

  const openUploadQuoteDialog = useCallback(
    (quoteId?: string) => {
      dispatch(
        setUploadQuoteDialog({
          item: {
            event: eventData,
            quoteId,
            hideEventSection: true,
          },
          onConfirm: () => {
            queryClient.refetchQueries({
              queryKey: getQuotesQueryKey(useGetQuotesParams),
            });
          },
        } as AppDialogProps),
      );
    },
    [dispatch, eventData, queryClient, useGetQuotesParams],
  );

  return (
    <Card
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      style={{ gridColumn: 'span 2' }}
      maxHeight="535px"
      h="100%"
      gap="20px"
      minH="535px"
      overflowX="hidden"
      overflowY="auto"
      minW={{ base: '100%', lg: 'auto' }}
      {...style}
      ref={ref}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.quotes')}</Text>

        <Flex alignItems="center" gap="5px">
          <IconButton
            aria-label={isBlurred ? 'Hide quotes' : 'Show quotes'}
            icon={!isBlurred ? <FiEye /> : <FiEyeOff />}
            variant="ghost"
            color="black"
            onClick={toggleDataVisibility}
          />
          <IconButton
            margin={'0 !important'}
            borderRadius="8px"
            maxWidth="24px"
            height="24px"
            minWidth="24px"
            p="0 !important"
            order={{ base: 1, lg: 0 }}
            aria-label="add-event"
            variant={'actionIconBlue'}
            icon={<Icon as={MdAdd} fontSize="16px" />}
            onClick={() => openUploadQuoteDialog()}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="20px">
        {quotes?.map((quote) => (
          <Quote
            key={quote?.id}
            quote={quote}
            isBlurred={isBlurred}
            onDelete={onDelete}
            openUploadQuoteDialog={() => openUploadQuoteDialog(quote?.id)}
          />
        ))}
      </Flex>
      {/* <UploadQuoteModal isOpen={isOpen} onClose={onClose} /> */}
    </Card>
  );
}
