import {
  Button,
  Collapse,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { setAddSupplierDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { IEvent, Supplier } from 'services/@types';
import ChosenSupplier from '../ChosenSupplier';

interface CreateEventPlannerProps {
  event: Partial<IEvent>;
  handleChange: (key: string, value: any, errorKey?: string) => void;
  isFormVisible: boolean;
  setIsFormVisible: (value: boolean) => void;
  handleOpenSelectSupplierDialog: () => void;
  errorList: string[];
  readOnly?: boolean;
}

export default function CreateEventPlanner({
  event,
  handleChange,
  isFormVisible,
  setIsFormVisible,
  handleOpenSelectSupplierDialog,
  errorList,
  readOnly,
}: CreateEventPlannerProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const handleOpenNewEventPlannerDialog = useCallback(() => {
    dispatch(
      setAddSupplierDialog({
        onConfirm: (suppliers: Supplier[]) => {
          handleChange('eventPlanner', suppliers?.[0]?.id);
        },
      }),
    );
  }, [dispatch, handleChange]);

  const handleRemoveEventPlanner = useCallback(() => {
    handleChange('eventPlanner', undefined);
  }, [handleChange]);

  return (
    <Stack gap={3}>
      <HStack
        onClick={() => setIsFormVisible(!isFormVisible)}
        pointerEvents={readOnly ? 'none' : 'auto'}
        w="fit-content">
        <Icon
          as={FiCheck}
          bg={isFormVisible ? 'brand.900' : 'white'}
          border="3px solid"
          borderColor="brand.900"
          w="20px"
          h="20px"
          transition="all 0.3s ease"
          color="white"
          borderRadius="10px"
        />
        <Text variant="eventSubTitle">{t('create_event.event_planner')}</Text>
      </HStack>
      <Collapse in={isFormVisible} animateOpacity>
        <VStack spacing={0} w="full" py={2} px="5px">
          <HStack
            w="full"
            spacing={0}
            gap={3}
            flexDir={{ base: 'column', md: 'row' }}>
            <Button
              margin={'0 !important'}
              onClick={handleOpenSelectSupplierDialog}
              variant="h1cta"
              w="full"
              maxW={{ base: 'unset', md: '320px' }}
              isDisabled={readOnly}
              style={{
                outline: errorList.includes('eventPlanner.id')
                  ? '1px solid #FF554A'
                  : 'none',
                outlineOffset: '4px',
              }}>
              {t('create_event.label.select_event_planner')}
            </Button>
            <Button
              margin={'0 !important'}
              variant="h2outlined"
              w="full"
              onClick={handleOpenNewEventPlannerDialog}
              maxW={{ base: 'unset', md: '320px' }}
              isDisabled={readOnly}>
              {t('create_event.label.create_new_supplier')}
            </Button>
          </HStack>
        </VStack>
        {event?.eventPlanner?.id && (
          <ChosenSupplier
            supplier={event.eventPlanner}
            onRemove={readOnly ? undefined : handleRemoveEventPlanner}
          />
        )}
      </Collapse>
    </Stack>
  );
}
