import {
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import addNoteIcon from 'assets/svg/event/add-note-icon.svg';
import Card from 'components/card/Card';
import { setAddBriefNoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useGetBriefNotes } from 'queries/briefNote';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMoreHorizontal } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BriefItem } from './BriefItem';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import { useMyUser } from 'contexts/redux/auth/authSlice';
export const ClientBrief = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();
  const myUser = useMyUser();

  const eventId = useMemo(() => params?.id, [params?.id]);

  const { style, ref } = useHighlightAnimation('event_brief');
  // Get brief notes
  const { data: briefNotesData, isLoading } = useGetBriefNotes({
    eventId,
    businessId: myUser?.businessID,
    limit: 100,
  });

  // Add state for temporary edits
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const handleAddBriefNote = useCallback(() => {
    if (!eventId) return;

    dispatch(
      setAddBriefNoteDialog({
        item: { eventId, title: '', description: '' },
      }),
    );
    onClose();
  }, [eventId, dispatch, onClose]);

  const handleEditStart = useCallback(
    (item: { id: string; title: string; description: string }) => {
      dispatch(
        setAddBriefNoteDialog({
          item,
        }),
      );
    },
    [dispatch],
  );

  if (!eventId) {
    return (
      <Box
        px="4"
        py="6"
        bg="white"
        width="100%"
        rounded={{ base: '0', md: '30px' }}
        shadow="sm"
        w="full">
        <Text color="gray.500" textAlign="center">
          {t('event.brief.brief_not_found')}
        </Text>
      </Box>
    );
  }

  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      zIndex={1}
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px"
      minW={{ base: '100%', lg: '310px' }}
      maxH="620px"
      // minH={{ base: 'unset', lg: '620px' }}
    >
      <Flex justify="space-between" align="center">
        <Text variant="eventPageCardTitle">{t('event.brief.event_brief')}</Text>
        <Menu isOpen={isOpen} onClose={onClose} boundary="clippingParents">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<LuMoreHorizontal size={24} />}
            variant="ghost"
            onClick={onOpen}
          />
          <MenuList minW={140} borderRadius={'2xl'}>
            <MenuItem
              icon={<Image src={addNoteIcon} />}
              color="blackAlpha.600"
              onClick={handleAddBriefNote}>
              {t('event.brief.add_note')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" h="100px">
          <Spinner color="gray.500" />
        </Flex>
      ) : briefNotesData?.results?.length ? (
        <VStack
          spacing="5"
          align="stretch"
          minH="100px"
          overflow="auto"
          pb="120px">
          {briefNotesData.results.map((item) => (
            <BriefItem
              key={item.id}
              id={item.id}
              item={item}
              onEdit={() => handleEditStart(item)}
            />
          ))}
        </VStack>
      ) : (
        <VStack
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          gap="24px"
          fontWeight={500}
          textAlign="center">
          <Image src={NoEventsImg} w="180px" h="180px" alt="No Client Brief" />
          <Text color="#00000033" fontSize="24px" lineHeight="26px">
            {t('event.no_data_found')}
          </Text>
          <Text color="#00000033" fontSize="14px" lineHeight="18px">
            {t('event.no_data_message')}
          </Text>
        </VStack>
      )}
      {/* add linear gradient to the bottom of the card */}
      <Flex
        w="100%"
        borderRadius="30px"
        right="0"
        left="0"
        position="absolute"
        bottom="0"
        h="200px"
        bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
      />
    </Card>
  );
};
