import { useDisclosure, useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { fetchClient } from 'contexts/redux/client/clientSlice';
import { resetCurrentDocumentNumber } from 'contexts/redux/currentDocumentNumber/currentDocumentNumberSlice';
import {
  closeUploadQuoteDialog,
  setContactsPickerDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { fetchProducts } from 'contexts/redux/product/productSlice';
import {
  createQuote,
  fetchQuote,
  setNewQuoteProp,
  updateQuote,
} from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useMyUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { formatNumber } from 'helpers/formatNumber';
import { useAppNavigate } from 'hooks/useAppNavigate';
import {
  useGetBusinessPreferences,
  useUpdateBusinessPreferences,
} from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import {
  CreateQuoteError,
  IClient,
  IClientContact,
  IEvent,
  IQuote,
  Supplier,
  Upload,
  UrlItem,
} from 'services/@types';
import EventService from 'services/event.api';
import UploadService from 'services/upload.api';
import { currency } from 'variables/currency';
import {
  addSelectedProduct,
  calculateProductsVat,
  calculateProductsVatByIndex,
  calculateTotal,
  calculateTotalIncludingDiscount,
  calculateTotalPayment,
  calculateTotalVat,
} from '../helpers/calculations';
import { useGetEntity } from 'queries/entity';

const defaultQuote = (language?: 'en' | 'he'): IQuote => ({
  eventDate: new Date(),
  eventType: 'other', // default event type
  currency: 'ILS',
  status: 'draft',
  ...(language && { language }),
  products: [
    {
      description: '',
      amount: 1,
      quantity: 1,
      net: 0,
      vat: 0,
      gross: 0,
      isVatIncluded: false,
    },
  ],
  clients: [],
  generalPaymentDetails: {
    discountType: 'fixed',
  },
  paymentInInstallments: {
    quantity: 1,
    installments: [
      {
        amount: 0,
        dueDate: new Date(),
        isPaid: false,
      },
    ],
  },
});
const defaultUploadQuote = (language?: 'en' | 'he'): IQuote => ({
  eventDate: new Date(),
  eventType: 'other',
  currency: 'ILS',
  status: 'draft',
  // clients: [],
  totalAmount: 0,
  ...(language && { language }),
});

export default function useCreateQuote(
  isUploadQuote: boolean = false,
  uploadedQuoteId?: string,
) {
  const { language } = useMyUserPreferences();
  const [eventDateSwitch, setEventDateSwitch] = useState(false);
  const { mutate: updateBusinessPreferences } = useUpdateBusinessPreferences();
  const { loading, newQuoteProps } = useSelector(
    (state: RootState) => state.quote,
  );
  const { suppliers } = useSelector((state: RootState) => ({
    suppliers: state.suppliers.suppliers,
  }));
  const { data: businessPreferences } = useGetBusinessPreferences();

  const { products, currentDocumentNumber } = useSelector(
    (state: RootState) => ({
      products: state.product.products,
      currentDocumentNumber: state.currentDocumentNumber.currentDocumentNumber,
    }),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const params = useParams();

  const paramId = useMemo(
    () => uploadedQuoteId || params?.id,
    [uploadedQuoteId, params?.id],
  );
  const toast = useToast();
  const myUser = useMyUser();
  const navigate = useNavigate();
  const { preventChangesDetected, triggerChangesDetected } = useAppNavigate();
  const [event, setEvent] = useState<IEvent | null>(null);
  const [error, setError] = useState<CreateQuoteError>({
    clients: false,
  });
  const [newQuote, setNewQuote] = useState<IQuote>(
    isUploadQuote ? defaultUploadQuote() : defaultQuote(),
  );
  const queryClient = useQueryClient();

  const [quoteNumber, setQuoteNumber] = useState<number>(0);
  // should increment the current document number in the global finance preferences
  const [
    updateInGlobalPreferencesOnSuccess,
    setUpdateInGlobalPreferencesOnSuccess,
  ] = useState<number | false>(false);

  // new fileds
  const [isAllProductsVatIncluded, setIsAllProductsVatIncluded] =
    useState<boolean>(false);

  const {
    onOpen: onOpenSaveAsTemplate,
    onClose: onCloseSaveAsTemplate,
    isOpen: isOpenSaveAsTemplate,
  } = useDisclosure();

  const [isUserInteraction, setIsUserInteraction] = useState(true);

  // check if the payment in installments is equal to the total payment
  const isPaymentInInstallmentsCorrect = useMemo(() => {
    const totalPayment = newQuote?.generalPaymentDetails?.totalPayment || 0;
    const totalPaymentInInstallments =
      newQuote?.paymentInInstallments?.installments.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0,
      );

    return totalPaymentInInstallments === totalPayment;
  }, [newQuote?.paymentInInstallments, newQuote?.generalPaymentDetails]);

  // Remaining amount or Amount exceeded
  const wrongAmount = useMemo(() => {
    const totalPayment = newQuote?.generalPaymentDetails?.totalPayment || 0;
    const totalPaymentInInstallments =
      newQuote?.paymentInInstallments?.installments.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0,
      );
    return totalPayment - totalPaymentInInstallments;
  }, [
    newQuote?.paymentInInstallments?.installments,
    newQuote?.generalPaymentDetails?.totalPayment,
  ]);

  const isSaveButtonDisabled = useMemo(() => {
    return isUploadQuote
      ? error?.uploadedQuote || error?.totalAmount || loading
      : error?.clients ||
          error?.products ||
          error?.totalPayment ||
          !isPaymentInInstallmentsCorrect ||
          loading;
  }, [error, isPaymentInInstallmentsCorrect, isUploadQuote, loading]);

  const currentEventType = useMemo(
    () => newQuote?.eventType || '',
    [newQuote?.eventType],
  );

  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );

  const displayedProducts = useMemo(() => {
    return products
      .filter((p) => p.name !== 'your_first_product')
      .map((product) => ({
        value: product.id,
        label: product.name,
      }));
  }, [products]);

  const currencyIcon = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? selected.symbol : '';
  }, [newQuote?.currency]);

  const wrongAmountText = useMemo(() => {
    return wrongAmount > 0
      ? t('create_quote.remaining_amount', {
          amount: formatNumber(wrongAmount),
          currency: currencyIcon,
        })
      : t('create_quote.amount_exceeded', {
          amount: formatNumber(Math.abs(wrongAmount)),
          currency: currencyIcon,
        });
  }, [wrongAmount, t, currencyIcon]);

  const handleAddToLastPayment = useCallback(() => {
    console.log(
      '@@ handleAddToLastPayment: Initiating process to add remaining amount to last payment',
    );
    console.log();
    setNewQuote((prev) => {
      const currentQuote = { ...prev };
      const totalPayment =
        currentQuote?.generalPaymentDetails?.totalPayment || 0;

      const paymentInInstallments = [
        ...(currentQuote.paymentInInstallments?.installments || []),
      ];

      const totalPaymentInInstallments = paymentInInstallments.reduce(
        (acc, curr, index) => {
          if (index < paymentInInstallments.length - 1) {
            return acc + Number(curr.amount);
          }
          return acc;
        },
        0,
      );

      const remainingAmount = totalPayment - totalPaymentInInstallments;

      if (paymentInInstallments.length > 0) {
        paymentInInstallments[paymentInInstallments.length - 1] = {
          ...paymentInInstallments[paymentInInstallments.length - 1],
          amount: remainingAmount,
        };
      }

      return {
        ...prev,
        paymentInInstallments: {
          ...currentQuote.paymentInInstallments,
          installments: paymentInInstallments,
        },
      };
    });
  }, []);

  const handleOpenClientDialog = useCallback(() => {
    console.log('create quote handle open client dialog');
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: newQuote?.clients || [],
          type: 'clients',
          isSingleSelection: true,
        },

        onConfirm: (
          selection: { client: IClient; contacts: IClientContact[] }[],
        ) => {
          console.log('create quote on confirm', selection);
          if (error?.clients) {
            setError((prev) => ({ ...prev, clients: false }));
          }
          console.log('create quote new Quote clients', newQuote.clients);
          setNewQuote((prevstate) => {
            return {
              ...prevstate,
              clients: selection,
            };
          });
        },
      }),
    );
  }, [dispatch, error, newQuote?.clients]);

  const handleOpenSuppliersDialog = useCallback(() => {
    console.log('create quote handle open client dialog');
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: newQuote?.clients || [],
          isSingleSelection: true,
          type: 'suppliers',
        },

        onConfirm: (
          selection: { supplier: any; contacts: IClientContact[] }[],
        ) => {
          console.log('create quote on confirm', selection);
          if (error?.clients) {
            setError((prev) => ({ ...prev, clients: false }));
          }
          console.log('create quote new Quote clients', newQuote.clients);
          const clients: { client: IClient; contacts: IClientContact[] }[] =
            selection.map((el) => {
              const supplier = el.supplier as Supplier;
              return {
                client: {
                  ...el.supplier,
                  type: 'private',
                  firstName: supplier?.name,
                  lastName: '',
                },
                contacts: el.contacts,
              };
            });
          setNewQuote((prevstate) => {
            return {
              ...prevstate,
              clients: clients,
            };
          });
        },
      }),
    );
  }, [dispatch, error, newQuote?.clients]);

  const handleRemoveClient = useCallback(
    (client: IClient) => {
      setNewQuote((prev) => ({
        ...prev,
        clients: prev.clients.filter((c: any) => c?.id !== client.id),
      }));
    },
    [setNewQuote],
  );

  const handleChange = useCallback(
    (key: string, value: any) => {
      console.log('create quote handle change', key, value);
      setIsUserInteraction(true);
      triggerChangesDetected();
      if (key === 'location') {
        setNewQuote((prev) => ({ ...prev, location: value.label }));
      } else {
        setNewQuote((prev) => ({ ...prev, [key]: value }));
      }
    },
    [triggerChangesDetected],
  );

  const handleAddProduct = useCallback(() => {
    setIsUserInteraction(true);
    setNewQuote((prev) => ({
      ...prev,
      products: [
        ...(prev?.products || []),
        {
          description: '',
          amount: 1,
          quantity: 1,
          net: 1,
          vat: 1 * businessPreferences?.vatValue,
          gross: 1 + 1 * businessPreferences?.vatValue,
          isVatIncluded: true,
        },
      ],
    }));
  }, [businessPreferences?.vatValue]);

  const setClient = useCallback(
    (client: { client: IClient; contacts: IClientContact[] }) => {
      setNewQuote((prev) => ({
        ...prev,
        clients: [...(prev?.clients || []), client],
      }));
    },
    [],
  );

  const handleValidateQuote = useCallback(() => {
    const error = isUploadQuote
      ? {
          clients: newQuote?.clients.length === 0,
          totalAmount: newQuote?.totalAmount === 0,
          uploadedQuote: newQuote?.uploadedQuote?.length === 0,
        }
      : {
          clients: newQuote?.clients.length === 0,
          products: newQuote?.products.some((p) => p.amount === 0),
          totalPayment: newQuote?.generalPaymentDetails?.totalPayment === 0,
        };

    setError(error);

    return Object.values(error).every((value) => value === false);
  }, [
    isUploadQuote,
    newQuote?.clients,
    newQuote?.products,
    newQuote?.generalPaymentDetails?.totalPayment,
    newQuote?.totalAmount,
    newQuote?.uploadedQuote,
  ]);

  const handleResetQuote = useCallback(() => {
    setNewQuote((prev) =>
      isUploadQuote
        ? defaultUploadQuote(language || undefined)
        : defaultQuote(language || undefined),
    );
  }, [isUploadQuote, language]);

  const handleCloseUploadQuoteDialog = useCallback(() => {
    handleResetQuote();
    dispatch(closeUploadQuoteDialog());
  }, [dispatch, handleResetQuote]);

  const handleCreateQuote = useCallback(async () => {
    if (!handleValidateQuote()) return;
    preventChangesDetected();
    if (paramId) {
      const updatedQuote: any = {
        ...newQuote,
        clients: newQuote.clients.map(
          (c: { client: IClient; contacts: IClientContact[] | string[] }) => ({
            client: c.client.id,
            contacts: !c.contacts
              ? []
              : (c.contacts as IClientContact[]).map((contact) => contact.id),
          }),
        ),
      };
      delete updatedQuote.businessId;
      if (updatedQuote?.requestQuoteToken) {
        delete updatedQuote.requestQuoteToken;
      }

      if (!updatedQuote?.vatPercent) {
        updatedQuote.vatPercent = businessPreferences?.vatPercent;
      }
      if (updatedQuote?.id) {
        delete updatedQuote.id;
      }

      if (isUploadQuote) {
        updatedQuote.supplierId = updatedQuote.clients[0]?.client;
        updatedQuote.clients = [];
      }

      const response = await dispatch(
        updateQuote({ id: paramId, updates: updatedQuote }),
      );

      if (response.error) {
        toast({
          title: response?.payload?.response?.data?.message,
          position: 'top-right',
          status: 'error',
          variant: 'error',
        });
        return;
      }

      toast({
        title: t('create_quote.quote_updated'),
        position: 'top-right',
        status: 'success',
        variant: 'main',
      });
      queryClient.invalidateQueries({
        queryKey: [`quoteInEventPage-${newQuote.eventId}`],
      });
      queryClient.invalidateQueries({
        queryKey: ['quote', paramId],
      });

      if (!isUploadQuote) {
        navigate(`/main/finance/quotes/preview/${response.payload.id}`);
      } else {
        handleCloseUploadQuoteDialog();
      }
      return;
    }

    const newQuoteWithSenderId: any = { ...newQuote };
    newQuoteWithSenderId.quoteNumber = quoteNumber;

    // if the vatPercent is not set, use the business preferences
    if (!newQuoteWithSenderId.vatPercent) {
      newQuoteWithSenderId.vatPercent = businessPreferences?.vatPercent;
    }

    if (!newQuoteWithSenderId.quoteNumber) {
      newQuoteWithSenderId.quoteNumber = Number(businessPreferences?.quote);
    }

    if (isUploadQuote) {
      newQuoteWithSenderId.clients = [];
      newQuoteWithSenderId.supplierId = newQuote.clients[0]?.client.id;
      newQuoteWithSenderId.isManuallyUploaded = true;
    } else {
      newQuoteWithSenderId.clients = newQuote.clients.map(
        (c: { client: IClient; contacts: IClientContact[] | string[] }) => ({
          client: c.client.id,
          contacts: !c.contacts
            ? []
            : (c.contacts as IClientContact[]).map((contact) => contact.id),
        }),
      );
    }

    const response = await dispatch(createQuote(newQuoteWithSenderId));
    if (response.error) {
      toast({
        title: response?.payload?.response?.data?.message,
        status: 'error',
        variant: 'error',
        position: 'top-right',
      });
      return;
    }

    toast({
      title: t('create_quote.quote_created'),
      status: 'success',
      variant: 'main',
      position: 'top-right',
    });
    queryClient.invalidateQueries({
      queryKey: [`quoteInEventPage-${newQuote.eventId}`],
    });
    if (updateInGlobalPreferencesOnSuccess || !quoteNumber) {
      updateBusinessPreferences({
        quote: Number(businessPreferences?.quote) + 1,
      });
      setUpdateInGlobalPreferencesOnSuccess(false);
    }
    if (isUploadQuote) {
      handleCloseUploadQuoteDialog();
    } else {
      navigate(`/main/finance/quotes/preview/${response.payload.id}`);
    }
  }, [
    handleValidateQuote,
    preventChangesDetected,
    paramId,
    newQuote,
    quoteNumber,
    dispatch,
    toast,
    t,
    queryClient,
    updateInGlobalPreferencesOnSuccess,
    isUploadQuote,
    navigate,
    handleCloseUploadQuoteDialog,
    businessPreferences?.quote,
    updateBusinessPreferences,
    businessPreferences?.vatPercent,
  ]);

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('eventType', '');
        return;
      }
      handleChange('eventType', formattedSelected);
    },
    [selectedEventType, handleChange],
  );

  const handleDeselectEvent = useCallback(
    (e: React.MouseEvent) => {
      e?.stopPropagation();
      setEvent(null);
      handleChange('eventId', null);
    },
    [handleChange],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: IEvent) => {
          setEvent(e);
          setNewQuote((prev) => ({
            ...prev,
            eventId: e?.id,
            location: e?.location?.label,
            eventType: e?.type,
            eventDate: e?.dateAndTime,
            numberOfGuests: e?.numberOfGuests,
            eventName: e?.name,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleSelectEvent = useCallback((e: IEvent) => {
    setEvent(e);
    setNewQuote((prev) => ({
      ...prev,
      eventId: e?.id,
      location: e?.location?.label,
      eventType: e?.type,
      eventDate: e?.dateAndTime,
      numberOfGuests: e?.numberOfGuests,
      eventName: e?.name,
    }));
  }, []);

  const clearFile = useCallback(async () => {
    if (paramId) {
      dispatch(updateQuote({ id: paramId, updates: { media: [] } }));
    } else {
      try {
        const [target, targetId, access, fileId] =
          newQuote?.media[0]?.url.split('/');
        await UploadService.deleteUpload({ target, targetId, access, fileId });
      } catch (error) {
        console.error('use clear file error ->', error);
      }
    }

    setNewQuote((prev) => ({
      ...prev,
      media: [],
    }));
  }, [newQuote?.media, paramId, dispatch]);

  const handleChangeFile = useCallback(
    async (event: any) => {
      event?.stopPropagation();
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newImg: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        if (newImg) {
          setNewQuote((prev) => ({
            ...prev,
            media: [
              {
                url: newImg.filePath,
                type: 'document',
                displayName: newImg.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  // add quote file
  const handleAddQuoteFile = useCallback(async (urlItem: UrlItem) => {
    if (!urlItem) {
      console.error('use add quote file error -> urlItem is required');
      return;
    }
    setNewQuote((prev) => ({
      ...prev,
      uploadedQuote: [
        {
          url: urlItem.url,
          type: 'quote_file',
          displayName: urlItem.displayName,
        },
      ],
    }));
  }, []);

  // clear quote file
  const clearQuoteFile = useCallback(
    async (type: 'document' | 'quote_file') => {
      if (!type) {
        console.error('use clear file error -> type is required');
        return;
      }
      try {
        const [target, targetId, access, fileId] = newQuote?.uploadedQuote
          ?.find((m) => m.type === type)
          ?.url.split('/');
        await UploadService.deleteUpload({
          target,
          targetId,
          access,
          fileId,
        });
      } catch (error) {
        console.error('use clear file error ->', error);
      } finally {
        setNewQuote((prev) => ({
          ...prev,
          uploadedQuote: [],
        }));
      }
    },
    [newQuote?.uploadedQuote],
  );

  const handleRemovePayment = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev.paymentInInstallments.installments.slice(0, -1),
      },
    }));
  }, []);

  const handleRemovePaymentIndex = useCallback((index: number) => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev.paymentInInstallments.installments.slice(0, index),
        quantity: prev.paymentInInstallments.quantity - 1,
      },
    }));
  }, []);

  const handleAddPayment = useCallback(() => {
    const total = newQuote.generalPaymentDetails?.totalPayment;
    const rest = newQuote.paymentInInstallments.installments.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0,
    );
    const difference = total - rest;
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        quantity: prev.paymentInInstallments.quantity + 1,
        installments: [
          ...(prev?.paymentInInstallments?.installments || []),
          {
            amount: difference > 0 ? Number(difference.toFixed(2)) : 0,
            dueDate: new Date(),
            isPaid: false,
          },
        ],
      },
    }));
  }, [
    newQuote?.generalPaymentDetails?.totalPayment,
    newQuote?.paymentInInstallments?.installments,
  ]);

  const handleIncreasePaymentQuantity = useCallback(() => {
    handleAddPayment();
  }, [handleAddPayment]);

  const handleDecreasePaymentQuantity = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        quantity: prev.paymentInInstallments.quantity - 1,
      },
    }));
    handleRemovePayment();
  }, [handleRemovePayment]);

  const handleSelectedProduct = useCallback(
    (e: any, index: number) => {
      setIsUserInteraction(true);
      const selectedProduct = products.find((p) => p.id === e);
      setNewQuote((prev) => ({
        ...prev,
        products: addSelectedProduct(
          prev.products,
          selectedProduct,
          index,
          businessPreferences?.vatValue,
        ),
      }));
    },
    [businessPreferences?.vatValue, products],
  );

  const handleChangePaymentAmount = useCallback((e: any, index: number) => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev?.paymentInInstallments?.installments.map((p, key) =>
          key === index
            ? { ...p, amount: e.target.value }
            : {
                ...p,
              },
        ),
      },
    }));
  }, []);


  const handleChangeNextAmount = useCallback((e: any, index: number) => {
    const total = newQuote.generalPaymentDetails?.totalPayment;
    const rest = newQuote.paymentInInstallments?.installments.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0,
    );
    const difference = total - rest;
    if((newQuote?.paymentInInstallments?.installments?.length > (index+1)) && difference > 0){
      setNewQuote((prev) => ({
        ...prev,
        paymentInInstallments: {
          ...prev.paymentInInstallments,
          installments: prev?.paymentInInstallments?.installments.map((p, key) =>
            key === index+1
              ? { ...p, amount: (+p?.amount || 0) + +difference }
              : {
                  ...p,
                },
          ),
        },
      }));
    }
  }, [newQuote.generalPaymentDetails?.totalPayment, 
    newQuote.paymentInInstallments?.installments]);

  const handleChangePaymentDescription = useCallback(
    (e: any, index: number) => {
      setNewQuote((prev) => ({
        ...prev,
        paymentInInstallments: {
          ...prev.paymentInInstallments,
          installments: prev.paymentInInstallments.installments.map((p, key) =>
            key === index ? { ...p, description: e.target.value } : p,
          ),
        },
      }));
    },
    [],
  );
  const handleChangeAllProductsVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsUserInteraction(true);
      setIsAllProductsVatIncluded(e.target.checked);
      setNewQuote((prev) => {
        const products = calculateProductsVat(
          prev.products,
          e.target.checked,
          businessPreferences?.vatValue,
        );
        return {
          ...prev,
          products,
        };
      });
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeProductVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setIsUserInteraction(true);
      setNewQuote((prev) => ({
        ...prev,
        products: calculateProductsVatByIndex(
          prev.products,
          index,
          e.target.checked,
          businessPreferences?.vatValue,
        ),
      }));
    },
    [businessPreferences?.vatValue],
  );

  const handleEditTemplate = useCallback(async () => {
    const updates = {
      ...newQuote,
    };
    delete updates.businessId;
    if (updates?.requestQuoteToken) {
      delete updates.requestQuoteToken;
    }

    if (updates?.id) {
      delete updates.id;
    }
    const response = await dispatch(
      updateQuote({
        id: newQuote.id,
        updates,
      }),
    );

    if (response.error) {
      toast({
        title: response?.payload?.response?.data?.message,
        position: 'top-right',
        status: 'error',
        variant: 'error',
      });
      return;
    }

    toast({
      title: t('create_quote.quote_template_updated'),
      position: 'top-right',
      status: 'success',
      variant: 'main',
    });
    queryClient.invalidateQueries({
      queryKey: [`quoteInEventPage-${newQuote.eventId}`],
    });
    queryClient.invalidateQueries({
      queryKey: ['quote', paramId],
    });
    onCloseSaveAsTemplate();
    // navigate to templates tab
    navigate({
      pathname: '/main/finance/quotes',
      search: createSearchParams({
        tab: 'templates',
      }).toString(),
    });
  }, [
    newQuote,
    dispatch,
    t,
    queryClient,
    paramId,
    onCloseSaveAsTemplate,
    navigate,
    toast,
  ]);

  const handleSaveAsTemplate = useCallback(async () => {
    // if the quote is a template, edit the template
    if (newQuote.isTemplate) {
      await handleEditTemplate();
      return;
    }
    const response = await dispatch(
      createQuote({ ...newQuote, isTemplate: true }),
    );
    if (response.error) {
      toast({
        title: response?.payload?.response?.data?.message,
        status: 'error',
        variant: 'error',
        position: 'top-right',
      });
      return;
    }

    toast({
      title: t('create_quote.quote_created_as_template'),
      status: 'success',
      variant: 'main',
      position: 'top-right',
    });
    queryClient.invalidateQueries({
      queryKey: [`quoteInEventPage-${newQuote.eventId}`],
    });
    onCloseSaveAsTemplate();
    navigate({
      pathname: '/main/finance/quotes',
      search: createSearchParams({
        tab: 'templates',
      }).toString(),
    });
  }, [
    newQuote,
    dispatch,
    t,
    queryClient,
    onCloseSaveAsTemplate,
    navigate,
    handleEditTemplate,
    toast,
  ]);

  const handleSaveAsTemplateModal = useCallback(() => {
    onOpenSaveAsTemplate();
  }, [onOpenSaveAsTemplate]);

  const handleCloseSaveAsTemplateModal = useCallback(() => {
    onCloseSaveAsTemplate();
    // remove the template fields
    setNewQuote((prev) => {
      const updatedPrev = { ...prev };
      // if the quote is not a template, remove the template fields
      if (!updatedPrev.isTemplate) {
        delete updatedPrev.isTemplate;
        delete updatedPrev.templateDescription;
        delete updatedPrev.templateTitle;
      }
      return updatedPrev;
    });
  }, [onCloseSaveAsTemplate]);

  useEffect(() => {
    dispatch(fetchProducts({}));
  }, [dispatch]);

  const eventData = useGetEntity<any>('event', event?.id);

  useEffect(() => {
    if (
      event &&
      myUser?.businessID === eventData?.data?.businessID &&
      !newQuote?.clients?.length &&
      !isUploadQuote
    ) {
      setNewQuote((prev) => ({
        ...prev,
        clients: eventData?.data?.clients,
      }));
    }
  }, [event]);

  // handle total generalPaymentDetails
  useEffect(() => {
    if (!isUploadQuote) {
      setNewQuote((prev) => ({
        ...prev,
        generalPaymentDetails: {
          ...prev.generalPaymentDetails,
          total: calculateTotal(prev.products),
        },
      }));
    }
  }, [isUploadQuote, newQuote.products, newQuote.currency]);

  // handle total including discount
  useEffect(() => {
    if (!isUploadQuote) {
      setNewQuote((prev) => ({
        ...prev,
        generalPaymentDetails: {
          ...prev.generalPaymentDetails,
          totalIncludingDiscount: calculateTotalIncludingDiscount(
            prev.generalPaymentDetails.discountType,
            prev.generalPaymentDetails.discount || 0,
            prev.generalPaymentDetails.total,
          ),
        },
      }));
    }
  }, [
    newQuote?.generalPaymentDetails?.discountType,
    newQuote?.generalPaymentDetails?.discount,
    newQuote?.generalPaymentDetails?.total,
    isUploadQuote,
  ]);

  // handle total including vat
  useEffect(() => {
    if (!isUploadQuote) {
      let totalPayment = calculateTotalPayment(
        newQuote.products,
        newQuote?.generalPaymentDetails,
        businessPreferences?.vatValue,
      );

      if (newQuote.generalPaymentDetails.totalPayment) {
        totalPayment = newQuote.generalPaymentDetails.totalPayment;
      }

      setNewQuote((prev) => ({
        ...prev,
        generalPaymentDetails: {
          ...prev.generalPaymentDetails,
          vat: calculateTotalVat(
            prev.products,
            prev.generalPaymentDetails,
            businessPreferences?.vatValue,
          ),
          ...(isUserInteraction
            ? {
                totalPayment: calculateTotalPayment(
                  prev.products,
                  prev?.generalPaymentDetails,
                  businessPreferences?.vatValue,
                ),
              }
            : {}),
        },
      }));
    }
  }, [
    newQuote?.products,
    newQuote?.generalPaymentDetails?.total,
    newQuote?.generalPaymentDetails?.discount,
    newQuote?.generalPaymentDetails?.discountType,
    newQuote?.generalPaymentDetails?.totalIncludingDiscount,
    isUploadQuote,
    businessPreferences?.vatValue,
    isUserInteraction,
  ]);

  useEffect(() => {
    if (paramId) {
      const getQuote = async () => {
        setIsUserInteraction(false);
        const response = await dispatch(fetchQuote(paramId));
        let clients = response.payload?.clients;
        if (isUploadQuote) {
          const supplierId = response.payload?.supplierId;
          console.log(suppliers, supplierId);
          const supplier = suppliers.find(
            (el: Supplier) => el.id === supplierId,
          );
          clients = [
            {
              client: {
                ...supplier,
                type: 'private',
                firstName: supplier?.name,
                lastName: '',
              },
              contacts: supplier?.contacts,
            },
          ];
        }
        setNewQuote({
          ...(isUploadQuote
            ? defaultUploadQuote(language)
            : defaultQuote(language)),
          ...response.payload,
          clients,
        });

        if (response.payload?.eventId) {
          const event = await EventService.getEvent(response.payload.eventId);
          if (event) {
            setEvent(event);
            setNewQuote((prev) => ({
              ...prev,
              location: event.location?.label,
              eventType: event.type,
              eventDate: event.dateAndTime,
              numberOfGuests: event.numberOfGuests,
              eventName: event.name,
            }));
          }
        }
      };
      getQuote();
    } else if (newQuoteProps.eventId) {
      EventService.getEvent(newQuoteProps.eventId).then((event) => {
        dispatch(setNewQuoteProp({ key: 'eventId', value: null }));
        if (event) {
          triggerChangesDetected();
          setEvent(event);
          setNewQuote((prev) => ({
            ...prev,
            eventId: event.id,
            location: event.location?.label,
            eventType: event.type,
            eventDate: event.dateAndTime,
            numberOfGuests: event.numberOfGuests,
            eventName: event.name,
            vatPercent: businessPreferences?.vatPercent,
          }));
        }
      });
    }
    if (newQuoteProps.eventDate) {
      console.log('newQuoteDate', newQuoteProps.eventDate);
      setEventDateSwitch(true);
      setNewQuote((prev) => ({
        ...prev,
        eventDate: newQuoteProps.eventDate,
      }));
      dispatch(setNewQuoteProp({ key: 'eventDate', value: null }));
    }
    if (newQuoteProps.clientId) {
      const fetchedClientId = newQuoteProps.clientId;
      dispatch(setNewQuoteProp({ key: 'clientId', value: null }));
      dispatch(fetchClient(fetchedClientId)).then(({ payload }: any) => {
        console.log('client payload', payload);
        setNewQuote((prev) => ({
          ...prev,
          clients: [
            {
              client: payload as any,
              contacts: [],
            },
          ],
        }));
      });
    }
    if (newQuoteProps.description) {
      setNewQuote((prev) => ({
        ...prev,
        description: newQuoteProps.description,
      }));
      dispatch(setNewQuoteProp({ key: 'description', value: null }));
    }

    if (!paramId) {
      if (currentDocumentNumber?.value) {
        setQuoteNumber(currentDocumentNumber?.value);
        if (!currentDocumentNumber?.onlyCurrent) {
          // if onlyCurrent is false, set it to global finance preferences
          if (businessPreferences?.quote !== currentDocumentNumber?.value) {
            updateBusinessPreferences({
              quote: currentDocumentNumber?.value,
            });
          }
          setUpdateInGlobalPreferencesOnSuccess(
            currentDocumentNumber?.value + 1,
          );
        }
        dispatch(resetCurrentDocumentNumber());
      } else {
        setQuoteNumber(businessPreferences?.quote);
        if (!currentDocumentNumber?.onlyCurrent) {
          setUpdateInGlobalPreferencesOnSuccess(businessPreferences?.quote + 1);
        }
      }
    }
  }, [
    paramId,
    dispatch,
    businessPreferences?.vatPercent,
    currentDocumentNumber,
    updateBusinessPreferences,
    updateInGlobalPreferencesOnSuccess,
    businessPreferences?.quote,
    isUploadQuote,
    language,
    newQuoteProps,
    triggerChangesDetected,
  ]);

  useEffect(() => {
    if (language && !newQuote?.language) {
      setNewQuote((prev) => ({
        ...prev,
        language,
      }));
    }
  }, [language, newQuote?.language]);

  // observe error and clear error
  useEffect(() => {
    if (error.clients && newQuote.clients.length > 0) {
      setError((prev) => ({ ...prev, clients: false }));
    }

    if (!isUploadQuote) {
      if (error.products && newQuote.products.every((p) => p.amount !== 0)) {
        setError((prev) => ({ ...prev, products: false }));
      }

      if (
        error.totalPayment &&
        newQuote.generalPaymentDetails?.totalPayment !== 0
      ) {
        setError((prev) => ({ ...prev, totalPayment: false }));
      }
    } else {
      if (error.totalAmount && newQuote.totalAmount !== 0) {
        setError((prev) => ({ ...prev, totalAmount: false }));
      }

      if (error.uploadedQuote && newQuote?.uploadedQuote?.length > 0) {
        setError((prev) => ({ ...prev, uploadedQuote: false }));
      }
    }
  }, [
    newQuote.clients,
    newQuote.products,
    newQuote.generalPaymentDetails?.totalPayment,
    error,
    isUploadQuote,
    newQuote.totalAmount,
    newQuote.uploadedQuote,
  ]);

  useEffect(() => {
    if (!isUploadQuote && newQuote?.products?.length > 0) {
      setIsAllProductsVatIncluded(
        newQuote?.products?.every((p) => p?.isVatIncluded),
      );
    }
  }, [newQuote?.products, isUploadQuote]);

  return {
    newQuote,
    newQuoteNumber: quoteNumber,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    event,
    handleAddPayment,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    setNewQuote,
    isLoading: loading,
    products: displayedProducts,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    currencyIcon,
    error,
    handleChangePaymentAmount,
    handleChangeNextAmount,
    handleChangePaymentDescription,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    handleChangeProductVatIncluded,
    handleOpenClientDialog,
    handleOpenSuppliersDialog,
    handleRemoveClient,
    setClient,
    isSaveButtonDisabled,
    isPaymentInInstallmentsCorrect,
    wrongAmount,
    wrongAmountText,
    handleAddToLastPayment,
    handleDeselectEvent,
    handleAddQuoteFile,
    clearQuoteFile,
    handleResetQuote,
    handleRemovePaymentIndex,
    handleSaveAsTemplate,
    isOpenSaveAsTemplate,
    onCloseSaveAsTemplate: handleCloseSaveAsTemplateModal,
    handleSaveAsTemplateModal,
    eventDateSwitch,
    setEventDateSwitch,
    handleSelectEvent,
    setIsUserInteraction,
  };
}
