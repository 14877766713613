import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import Card from 'components/card/Card';
import { setAddDesignDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useGetDesigns } from 'queries/design';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BriefItem } from './BriefItem';
import { useMyUser } from 'contexts/redux/auth/authSlice';

export const Designs = () => {
  const params = useParams();
  const eventId = useMemo(() => params?.id, [params?.id]);
  const myUser = useMyUser();
  const { style, ref } = useHighlightAnimation('design');
  // Get brief notes
  const { data: designsData, isLoading } = useGetDesigns({
    eventId,
    businessId: myUser?.businessID,
    limit: 100,
  });

  // Add state for temporary edits
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const handleDesignAdd = useCallback(() => {
    if (!eventId) return;

    dispatch(
      setAddDesignDialog({
        item: { eventId, title: '', description: '' },
      }),
    );
  }, [eventId, dispatch]);

  const handleEditStart = useCallback(
    (item: { id: string; title: string; description: string }) => {
      dispatch(
        setAddDesignDialog({
          item,
        }),
      );
    },
    [dispatch],
  );

  if (!eventId) {
    return (
      <Box
        px="4"
        py="6"
        bg="white"
        width="100%"
        rounded={{ base: '0', md: '30px' }}
        shadow="sm"
        w="full">
        <Text color="gray.500" textAlign="center">
          {t('event.brief.brief_not_found')}
        </Text>
      </Box>
    );
  }

  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      zIndex={1}
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px"
      minW={{ base: '100%' }}
      maxH="620px">
      <Flex justify="space-between" align="center">
        <Text variant="eventPageCardTitle">{t('event.designs.title')}</Text>
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={handleDesignAdd}
        />
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" h="100px">
          <Spinner color="gray.500" />
        </Flex>
      ) : designsData?.results?.length > 0 ? (
        <VStack
          spacing="5"
          align="stretch"
          minH="100px"
          overflow="auto"
          pb="120px">
          {designsData.results.map((item) => (
            <BriefItem
              key={item.id}
              id={item.id}
              item={item}
              onEdit={() => handleEditStart(item)}
            />
          ))}
        </VStack>
      ) : (
        <VStack
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          gap="24px"
          fontWeight={500}
          textAlign="center">
          <Image src={NoEventsImg} w="180px" h="180px" alt="No Client Brief" />
        </VStack>
      )}
    </Card>
  );
};
