import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
import { CreateQuoteError, IInvoice } from 'services/@types';
import { discountTypes } from 'variables/discountTypes';

interface TotalInvoicePaymentProps {
  invoice: Partial<IInvoice>;
  handleChangeInvoice: (key: string, value: any) => void;
  currencyIcon: string;
  error: CreateQuoteError;
  setInvoice: (invoice: any) => void;
  dir: string;
  handleChangeDiscountType: (type: 'percentage' | 'fixed') => void;
}
export default function TotalInvoicePayment(props: TotalInvoicePaymentProps) {
  const {
    invoice,
    handleChangeInvoice,
    currencyIcon,
    error,
    setInvoice,
    dir,
    handleChangeDiscountType,
  } = props;
  const { t } = useTranslation();
  const [totalManual, setTotalManual] = useState(false);
  const { data: businessPreferences } = useGetBusinessPreferences();

  return (
    <Flex direction="column" gap="12px">
      <InputField
        label={t('create_quote.total')}
        placeholder={t('create_quote.total')}
        value={invoice.generalPaymentDetails?.total}
        type="number"
        dir={dir}
        isDisabled={!totalManual}
        onChange={(e: any) =>
          setInvoice((prev: any) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              total: parseFloat(e.target.value),
            },
          }))
        }
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
      <SwitchField
        dir={dir}
        id="total-manual"
        label={t('create_quote.total_manual')}
        checked={totalManual}
        labelColor={'brand.900'}
        onChange={(e: any) => setTotalManual(e.target.checked)}
      />

      <InputField
        dir={dir}
        label={t('create_quote.discount')}
        placeholder={t('create_quote.discount')}
        value={invoice.generalPaymentDetails?.discount}
        type="number"
        lang={invoice?.language}
        onChange={(e) =>
          setInvoice((prev: any) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              discount: parseFloat(e.target.value || '0'),
            },
          }))
        }
        iconPointerEvents="all"
        iconRight={
          <Flex alignItems="center" w="auto">
            <Divider w="1px" h="36px" bg="#EBEDF0" />
            <Menu closeOnSelect placement="left-start">
              <MenuButton
                as={Button}
                ps="5px"
                rightIcon={<ChevronDownIcon />}
                borderColor="transparent"
                bg="transparent"
                _active={{ borderColor: 'transparent' }}
                _hover={{ borderColor: 'transparent' }}>
                {/* {showedOptions} */}
              </MenuButton>
              <MenuList
                zIndex={999}
                w="100px"
                minW="100px"
                rootProps={{
                  transform: `translateX(${dir === 'rtl' ? '-57px' : '57px'})`,
                }}>
                {discountTypes.map((type) => (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    onClick={() => {
                      handleChangeDiscountType(
                        type.value as 'percentage' | 'fixed',
                      );
                    }}>
                    {t(type.label)}
                    <Icon
                      as={MdCheck}
                      color="brand.400"
                      ms="5px"
                      fontSize="xl"
                      display={
                        invoice.generalPaymentDetails?.discountType ===
                        type.value
                          ? 'block'
                          : 'none'
                      }
                    />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        }
      />

      <InputField
        label={t('create_quote.total_including')}
        placeholder={t('create_quote.total_including')}
        value={invoice.generalPaymentDetails?.totalIncludingDiscount}
        type="number"
        onChange={(e: any) =>
          setInvoice((prev: any) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              totalIncludingDiscount: parseFloat(e.target.value),
            },
          }))
        }
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />

      <InputField
        label={t('quote_preview.vat', {
          percentage: businessPreferences?.vatPercent,
        })}
        placeholder={t('quote_preview.vat', {
          percentage: businessPreferences?.vatPercent,
        })}
        value={invoice.generalPaymentDetails?.vat}
        isDisabled={true}
        type="number"
        lang={invoice?.language}
        dir={dir}
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
      <InputField
        label={t('create_quote.total_payment')}
        placeholder={t('create_quote.total_payment')}
        value={invoice.generalPaymentDetails?.totalPayment}
        type="number"
        lang={invoice?.language}
        dir={dir}
        onChange={(e: any) =>
          setInvoice((prev: any) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              totalPayment: parseFloat(e.target.value),
            },
          }))
        }
        borderColor={error?.totalPayment ? 'error.100' : 'gray.200'}
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
    </Flex>
  );
}
