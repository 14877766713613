import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useTranslation } from 'react-i18next';
import { IQuote } from 'services/@types';

export default function SaveAsTemplateModal(props: {
  quote: IQuote;
  isOpen: boolean;
  onClose: () => void;
  handleChange: (key: string, value: string) => void;
  handleSave: () => void;
}) {
  const { isOpen, onClose, quote, handleChange, handleSave } = props;
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px"
        minW={{ base: '100%', md: '660px' }}
        borderRadius="30px"
        gap="24px">
        <Text variant="createQuoteTitle">
          {t('create_quote.save_as_template')}
        </Text>
        <InputField
          label={t('create_quote.document_title')}
          placeholder={t('create_quote.document_title_placeholder')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange('templateTitle', e.target.value)
          }
          name="templateTitle"
          value={quote.templateTitle}
        />
        <TextField
          label={t('create_quote.template_description')}
          placeholder={t('create_quote.template_description_placeholder')}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange('templateDescription', e.target.value)
          }
          name="templateDescription"
          value={quote.templateDescription}
          minH="157px"
        />
        <Button
          w="320px"
          variant="h1cta"
          alignSelf="center"
          onClick={handleSave}>
          {t('create_quote.save')}
        </Button>
      </ModalContent>
    </Modal>
  );
}
