import {
  Flex,
  Hide,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useInvoiceMutations } from 'hooks/useInvoice';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IInvoice } from 'services/@types';
import { currency } from 'variables/currency';

function InvoiceRow(props: { invoice: IInvoice }) {
  const { invoice } = props;
  const { t } = useTranslation();
  const { deleteInvoice } = useInvoiceMutations();
  const toast = useToast();
  const navigate = useNavigate();

  const customers = useMemo(() => {
    const customers = invoice?.clients
      .map(
        (c) =>
          `${c?.client?.firstName || ''} ${
            c?.client?.lastName || c?.client?.company || ''
          }`,
      )
      .join(', ');
    return customers;
  }, [invoice?.clients]);

  const amount = useMemo(() => {
    if (invoice?.generalPaymentDetails?.totalPayment) {
      const currencySymbol = currency?.find(
        (c) => c.value === invoice?.currency,
      )?.symbol;
      return `${currencySymbol} ${invoice?.generalPaymentDetails?.totalPayment}`;
    }
    return '-';
  }, [invoice?.generalPaymentDetails?.totalPayment, invoice?.currency]);

  const handleDeleteInvoice = useCallback(async () => {
    await deleteInvoice.mutateAsync(invoice.id);

    toast({
      title: t('invoices_overview.deleted'),
      description: t('invoices_overview.deleted_description'),
      status: 'success',
      variant: 'main',
      position: 'top-right',
    });
  }, [deleteInvoice, invoice.id, t, toast]);

  const handleUpdateInvoice = useCallback(() => {
    if (!invoice?.id) return;
    navigate(`/main/finance/invoices/${invoice?.id}`);
  }, [invoice?.id, navigate]);

  const handleIssueTaxInvoiceCredit = useCallback(() => {
    if (!invoice?.id) {
      console.error('handleIssueTaxInvoiceCredit: no invoice id');
      return;
    }
    navigate({
      pathname: `/main/finance/invoices/create`,
      search: createSearchParams({
        invoiceId: invoice?.id,
        documentType: 'tax_invoice_credit',
      }).toString(),
    });
  }, [invoice?.id, navigate]);

  return (
    <Flex
      key={invoice.id}
      justify="space-between"
      py={4}
      px={2}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      fontSize="14px">
      <Flex flex={1} fontWeight={{ base: '700', md: '500' }} align="center">
        {t(`invoicesAndReceipts.document_type.${invoice.type}`)}
      </Flex>
      <Flex flex={1} align="center">
        {customers || '-'}
      </Flex>
      <Flex flex={1} align="center">
        {amount}
      </Flex>
      <Hide below="md">
        <Flex flex={1} align="center">
          {invoice.contractDate
            ? format(invoice.contractDate, 'dd.MM.yy')
            : '-'}
        </Flex>
      </Hide>
      <Flex flex="0 0 60px">
        <Menu>
          <MenuButton onClick={(e: any) => e.stopPropagation()}>
            <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
          </MenuButton>
          <MenuList>
            <MenuItem h="44px" onClick={handleDeleteInvoice}>
              {t('invoices_overview.delete')}
            </MenuItem>
            <MenuItem h="44px" onClick={handleUpdateInvoice}>
              {t('invoices_overview.update')}
            </MenuItem>
            <MenuItem h="44px" onClick={handleIssueTaxInvoiceCredit}>
              {t('invoices_overview.issue_tax_invoice_credit')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}

export default memo(InvoiceRow);
