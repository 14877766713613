import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Slide,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import InputField from 'components/fields/InputField';
import { useEffect } from 'react';
import { LuChevronLeft } from 'react-icons/lu';
import { IClientContact } from 'services/@types';
import useNewClientContact from '../hooks/useNewClientContact';

const contactFields = [
  { key: 'jobTitle', type: 'text' },
  { key: 'firstName', type: 'text', isRequired: true },
  { key: 'lastName', type: 'text', isRequired: true },
  { key: 'phone', type: 'text', isRequired: true },
  { key: 'email', type: 'email', isRequired: true },
  { key: 'address', type: 'text' },
  { key: 'idNumber', type: 'text' },
  { key: 'note', type: 'text' },
];

type AddNewClientContactProps = {
  isCreateContactOpen: boolean;
  onCloseCreateContact: () => void;
  onOpenCreateContact: () => void;
  handleCreateContact: (contact: IClientContact) => void;
};

const AddNewClientContact = ({
  isCreateContactOpen,
  onCloseCreateContact,
  onOpenCreateContact,
  handleCreateContact,
}: AddNewClientContactProps) => {
  const { t } = useTranslation();
  const { contact, errors, handleChange, handleSubmit, handleClearContact } =
    useNewClientContact({
      handleCreateContact,
    });

  useEffect(() => {
    if (!isCreateContactOpen) {
      handleClearContact();
    }
  }, [isCreateContactOpen, handleClearContact]);

  return (
    <Slide
      direction="right"
      in={isCreateContactOpen}
      unmountOnExit
      style={{
        position: 'absolute',
        zIndex: 1000,
        width: '100%',
        height: '100%',
        marginTop: 0,
      }}>
      {/* wrapper */}
      <Flex
        flexDir="column"
        p="24px"
        gap="50px"
        borderRadius={{ base: '0px', md: '30px' }}
        position="relative"
        overflowY="auto"
        bg="white"
        w="100%"
        h="100%">
        {/* iner add contact tab content */}
        {/* header */}
        <Flex justifyContent="space-between">
          <Flex flexDir="column" gap={1}>
            <HStack spacing={0} gap={1}>
              <IconButton
                aria-label="close"
                icon={
                  <Icon as={LuChevronLeft} w="16px" h="16px" color="#939393" />
                }
                size="xs"
                variant="solid"
                bg={'transparent'}
                onClick={onCloseCreateContact}
              />
              <Text
                variant="previewQuoteContactTitle"
                onClick={onOpenCreateContact}>
                {t('client_contact.title')}
              </Text>
            </HStack>
          </Flex>
        </Flex>
        {/* fields */}
        <Flex flexDir="column" gap="24px" flex={1}>
          <Flex flexWrap="wrap" gap="24px 12px">
            {contactFields.map((field) =>
              field.key === 'jobTitle' ? (
                <Flex w="full"   key={field.key}>
                  <InputField 
                    name={field.key}
                    value={contact[field.key as keyof IClientContact]}
                    onChange={handleChange}
                    flex={{ base: '1', md: '1 1 30%' }}
                    w={{
                      base: '100%',
                      md: `calc((100% - 12px) / 2)`,
                      lg: `calc((100% - 24px) / 3)`,
                    }}
                    label={
                      t(`clients.${field.key}`) + (field.isRequired ? ' *' : '')
                    }
                    borderColor={
                      errors.includes(field.key) ? 'red.500' : 'gray.300'
                    }
                    placeholder={t(`clients.${field.key}`)}
                    type={field.type}
                  />
                </Flex>
              ) : (
                <InputField
                  key={field.key}
                  name={field.key}
                  value={contact[field.key as keyof IClientContact]}
                  onChange={handleChange}
                  flex={{ base: '1', md: '1 1 30%' }}
                  w={{
                    base: '100%',
                    md: `calc((100% - 12px) / 2)`,
                    lg: `calc((100% - 24px) / 3)`,
                  }}
                  label={
                    t(`clients.${field.key}`) + (field.isRequired ? ' *' : '')
                  }
                  borderColor={
                    errors.includes(field.key) ? 'red.500' : 'gray.300'
                  }
                  placeholder={t(`clients.${field.key}`)}
                  type={field.type}
                />
              ),
            )}
          </Flex>
        </Flex>
        {/* footer */}
        <Flex justifyContent="center" w="100%">
          <Button
            variant="h1cta"
            isDisabled={errors.length > 0}
            w={{ base: '100%', md: '320px' }}
            onClick={handleSubmit}>
            {t('clients.save')}
          </Button>
        </Flex>
      </Flex>
    </Slide>
  );
};

export default AddNewClientContact;
