import { useCallback, useEffect } from 'react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import invoiceService from 'services/invoice.api';
import { IInvoice } from 'services/@types';
import { useParams } from 'react-router-dom';

export const INVOICE_KEYS = {
  all: ['invoices'] as const,
  list: (filters: Record<string, any>) =>
    [...INVOICE_KEYS.all, filters] as const,
};

export const useInvoices = (
  queryParams?: Partial<IInvoice> & {
    limit?: number;
    populate?: string;
  },
) => {
  if (!queryParams?.limit) {
    queryParams.limit = 1000;
  }
  return useQuery({
    queryKey: INVOICE_KEYS.list(queryParams || {}),
    queryFn: () => invoiceService.getInvoices(queryParams),
  });
};

export const useInvoiceMutations = () => {
  const queryClient = useQueryClient();

  const invalidateInvoices = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: INVOICE_KEYS.all });
  }, [queryClient]);

  const createInvoice = useMutation({
    mutationFn: async (invoice: Partial<IInvoice>) =>
      await invoiceService.createInvoice(invoice as IInvoice),
    onSuccess: () => invalidateInvoices(),
  });

  const updateInvoice = useMutation({
    mutationFn: ({
      invoiceId,
      updates,
    }: {
      invoiceId: string;
      updates: Partial<IInvoice>;
    }) => invoiceService.updateInvoice(invoiceId, updates),
    onSuccess: () => invalidateInvoices(),
  });

  const deleteInvoice = useMutation({
    mutationFn: (invoiceId: string) => invoiceService.deleteInvoice(invoiceId),
    onSuccess: () => invalidateInvoices(),
  });

  return {
    createInvoice,
    updateInvoice,
    deleteInvoice,
  };
};
