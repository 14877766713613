import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Hide,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
import { CreateQuoteError, IQuote } from 'services/@types';
import { currency } from 'variables/currency';
import { getLocalizedDir } from 'helpers/getLocalizedDir';
import { discountTypes } from 'variables/discountTypes';
import { ProductVatCheckbox } from './ProductVatCheckbox';

interface TotalQuotePaymentProps {
  newQuote: Partial<IQuote>;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  currencyIcon: string;
  error: CreateQuoteError;
  handleChangeAllProductsVatIncluded: (e: any) => void;
  isAllProductsVatIncluded: boolean;
  setIsUserInteraction?: (isUserInteraction: boolean) => void;
}

const TotalQuotePayment = memo(
  ({
    newQuote,
    setNewQuote,
    currencyIcon,
    error,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    setIsUserInteraction,
  }: TotalQuotePaymentProps) => {
    const { data: businessPreferences } = useGetBusinessPreferences();
    const [totalManual, setTotalManual] = useState(false);
    const [isTotalRounded, setIsTotalRounded1] = useState(false);
    const { t, i18n } = useTranslation('', {
      lng: newQuote?.language,
    });
    const textColorPrimary = useColorModeValue('primary.100', 'white');
    const isRtl = useMemo(() => {
      return i18n.dir() === 'rtl';
    }, [i18n.dir()]);

    const setIsTotalRounded = (isRounded: boolean) => {
      if (isRounded) {
        setNewQuote((prev) => ({
          ...prev,
          generalPaymentDetails: {
            ...prev.generalPaymentDetails,
            totalPayment: parseFloat(
              prev.generalPaymentDetails?.totalPayment.toFixed(0),
            ),
          },
        }));
      }
      setIsTotalRounded1(isRounded);
    };

    const showedOptions = useMemo(() => {
      if (newQuote.generalPaymentDetails?.discountType === 'percentage') {
        return '%';
      }

      if (newQuote.generalPaymentDetails?.discountType === 'fixed') {
        return currency.find((c) => c.value === newQuote?.currency)?.symbol;
      }

      return '';
    }, [newQuote.generalPaymentDetails?.discountType, newQuote?.currency]);

    const selectedOptions = useMemo(() => {
      const option = discountTypes.find(
        (t) => t.value === newQuote.generalPaymentDetails?.discountType,
      );

      return option ? [option] : [];
    }, [newQuote.generalPaymentDetails?.discountType]);

    const handleChangeDiscountType = useCallback(
      (type: 'percentage' | 'fixed') => {
        setNewQuote((prev) => ({
          ...prev,
          generalPaymentDetails: {
            ...prev.generalPaymentDetails,
            discountType: type,
          },
        }));
      },
      [setNewQuote],
    );
    return (
      <Flex direction="column" flexGrow={1} gap="12px">
        <InputField
          label={t('create_quote.total')}
          placeholder={t('create_quote.total')}
          value={newQuote.generalPaymentDetails?.total}
          type="number"
          dir={getLocalizedDir(newQuote?.language)}
          isDisabled={!totalManual}
          onChange={(e: any) => {
            setIsUserInteraction?.(true);
            setNewQuote((prev) => ({
              ...prev,
              generalPaymentDetails: {
                ...prev.generalPaymentDetails,
                total: parseFloat(e.target.value),
              },
            }));
          }}
          iconLeft={<Text variant="currency">{currencyIcon}</Text>}
        />
        <SwitchField
          reversed
          justify="flex-start"
          wrapperProps={{
            gap: 2,
            alignSelf: 'flex-start',
          }}
          dir={getLocalizedDir(newQuote?.language)}
          id="total-manual"
          label={t('create_quote.total_manual')}
          checked={totalManual}
          labelColor={'brand.900'}
          onChange={(e: any) => setTotalManual(e.target.checked)}
        />

        <InputField
          dir={getLocalizedDir(newQuote?.language)}
          label={t('create_quote.discount')}
          placeholder={t('create_quote.discount')}
          value={newQuote.generalPaymentDetails?.discount}
          type="number"
          lang={newQuote?.language}
          onChange={(e) =>
            setNewQuote((prev) => ({
              ...prev,
              generalPaymentDetails: {
                ...prev.generalPaymentDetails,
                discount: parseFloat(e.target.value || '0'),
              },
            }))
          }
          iconPointerEvents="all"
          iconRight={
            <Flex alignItems="center" w="auto">
              <Divider w="1px" h="36px" bg="#EBEDF0" />
              <Menu closeOnSelect placement="left-start">
                <MenuButton
                  as={Button}
                  ps="5px"
                  rightIcon={<ChevronDownIcon />}
                  borderColor="transparent"
                  bg="transparent"
                  _active={{ borderColor: 'transparent' }}
                  _hover={{ borderColor: 'transparent' }}>
                  {showedOptions}
                </MenuButton>
                <MenuList
                  zIndex={999}
                  w="100px"
                  minW="100px"
                  rootProps={{
                    transform: `translateX(${!isRtl ? '-57px' : '57px'})`,
                  }}>
                  {discountTypes.map((type) => (
                    <MenuItem
                      key={type.value}
                      value={type.value}
                      onClick={() => {
                        handleChangeDiscountType(
                          type.value as 'percentage' | 'fixed',
                        );
                      }}>
                      {t(type.label)}
                      <Icon
                        as={MdCheck}
                        color="brand.400"
                        ms="5px"
                        fontSize="xl"
                        display={
                          newQuote.generalPaymentDetails?.discountType ===
                          type.value
                            ? 'block'
                            : 'none'
                        }
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          }
        />
        <InputField
          w={'100%'}
          disabled={true}
          label={t('create_quote.after_discount')}
          placeholder={t('create_quote.after_discount')}
          value={newQuote.generalPaymentDetails?.totalIncludingDiscount}
          type="number"
          lang={newQuote?.language}
          dir={getLocalizedDir(newQuote?.language)}
          // onChange={(e: any) =>
          //   setNewQuote((prev) => ({
          //     ...prev,
          //     generalPaymentDetails: {
          //       ...prev.generalPaymentDetails,
          //       totalIncludingDiscount: parseFloat(e.target.value),
          //     },
          //   }))
          // }
          borderColor={error?.totalPayment ? 'error.100' : 'gray.200'}
          iconLeft={<Text variant="currency">{currencyIcon}</Text>}
        />
        {/* <InputField
          label={t('create_quote.total_including')}
          placeholder={t('create_quote.total_including')}
          value={newQuote.generalPaymentDetails?.totalIncludingDiscount}
          type="number"
          onChange={(e: any) =>
            setNewQuote((prev) => ({
              ...prev,
              generalPaymentDetails: {
                ...prev.generalPaymentDetails,
                totalIncludingDiscount: parseFloat(e.target.value),
              },
            }))
          }
          iconLeft={<Text variant="currency">{currencyIcon}</Text>}
        /> */}
        <HStack w="100%">
          <HStack flexGrow={1}>
            <InputField
              w={'100%'}
              label={t('quote_preview.vat', {
                percentage: businessPreferences?.vatPercent,
              })}
              placeholder={t('quote_preview.vat', {
                percentage: businessPreferences?.vatPercent,
              })}
              value={newQuote.generalPaymentDetails?.vat}
              isDisabled={true}
              type="number"
              lang={newQuote?.language}
              dir={getLocalizedDir(newQuote?.language)}
              iconLeft={<Text variant="currency">{currencyIcon}</Text>}
            />
          </HStack>
          <Checkbox
            alignSelf={'end'}
            variant="roundBrand"
            isChecked={isAllProductsVatIncluded}
            onChange={handleChangeAllProductsVatIncluded}
            h="50px"
            dir={getLocalizedDir(newQuote?.language)}
            alignItems="center">
            <Text variant="createQuoteCheckboxText">
              {t('quote_preview.vat', {
                percentage: businessPreferences?.vatPercent,
              })}
            </Text>
          </Checkbox>
        </HStack>
        <VStack w="100%">
          <HStack justify={'start'} align="center" w="100%">
            <FormLabel
              display={'flex'}
              ml={3}
              mb={0}
              flexGrow={1}
              w="100%"
              maxW={'100px'}
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="medium">
              {t('create_quote.total_payment')}
            </FormLabel>
            <SwitchField
              reversed
              justify="flex-start"
              wrapperProps={{
                gap: 2,
                alignSelf: 'flex-start',
              }}
              dir={getLocalizedDir(newQuote?.language)}
              id="total-manual"
              label={t('create_quote.round_sum')}
              checked={isTotalRounded}
              labelColor={'brand.900'}
              onChange={(e: any) => setIsTotalRounded(e.target.checked)}
            />
          </HStack>
          <InputField
            w={'100%'}
            placeholder={t('create_quote.total_payment')}
            isDisabled={true}
            value={newQuote.generalPaymentDetails?.totalPayment}
            type="number"
            lang={newQuote?.language}
            dir={getLocalizedDir(newQuote?.language)}
            onChange={(e: any) =>
              setNewQuote((prev) => ({
                ...prev,
                generalPaymentDetails: {
                  ...prev.generalPaymentDetails,
                  totalPayment: isTotalRounded
                    ? parseFloat(parseFloat(e.target.value).toFixed(0))
                    : parseFloat(e.target.value),
                },
              }))
            }
            borderColor={error?.totalPayment ? 'error.100' : 'gray.200'}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
        </VStack>
      </Flex>
    );
  },
);

export default TotalQuotePayment;
