import { Divider, Flex, Icon, Image, Text, Tooltip } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import invitedIcon from 'assets/svg/event/top_bar/invited-icon.svg';
import VatIcon from 'assets/svg/supplierInfo/vat-icon.svg';
import Card from 'components/card/Card';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { formatDate } from 'date-fns';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import useGetEvent from 'queries/event';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FiEdit3, FiPhone, FiUser } from 'react-icons/fi';
import { HiOutlineLocationMarker, HiOutlineMailOpen } from 'react-icons/hi';
import { useAppNavigate } from 'hooks/useAppNavigate';
import _businessEventPlanService from 'services/businessEventPlan.api';
import useGetLatestQuote from '../hooks/useGetLatestQuote';
import { useGetBusinessEventPlans } from 'queries/businessEventPlan';
import { Business } from 'services/@types';
import { Supplier } from 'services/@types';
import { useGetEntity } from 'queries/entity';

export default function ClientDetails({ eventId }: { eventId: string }) {
  const params = useParams();
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('details');
  const { appNavigate: navigate } = useAppNavigate();
  const { id: businessId } = useMyBusiness();
  const { quote, isLoading } = useGetLatestQuote(eventId);
  const { data: businessEventPlans } = useGetBusinessEventPlans({
    eventId: params?.id,
    businessId,
  });
  const { data: eventData } = useGetEvent(params?.id);
  const eventName = useMemo(() => {
    return businessEventPlans?.results[0]?.eventName || eventData?.name;
  }, [businessEventPlans?.results, eventData?.name]);

  const handleEditEventClientDetails = useCallback(() => {
    navigate(`/main/events/update/${params?.id}`);
  }, [params?.id, navigate]);

  const eventDate = useMemo(() => {
    return eventData?.dateAndTime
      ? formatDate(eventData?.dateAndTime, 'MMM dd, yyyy  hh:mm a')
      : '';
  }, [eventData?.dateAndTime]);

  const quoteClients = useMemo(() => {
    const clients = quote?.clients?.filter(
      (c) => c.client.businessID !== businessId,
    );
    if (clients?.length === 0) {
      return eventData?.clients;
    }
    return clients;
  }, [quote?.clients, businessId, eventData?.clients]);
  const { data: supplier } = useGetEntity<Supplier>(
    'supplier',
    quote?.supplierId,
  );
  const { data: business } = useGetEntity<Business>(
    'business',
    eventData?.businessID,
  );

  useEffect(() => {
    console.log(`businesss`, business);
    console.log(`supplierr`, supplier);
  }, [business, supplier]);
  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      overflow="auto"
      minW={{ base: '100%', lg: '310px' }}
      maxH="620px"
      flex={1}
      height="unset"
      display="flex"
      flexDirection="column"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px">
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.client_details')}</Text>
        <Icon
          as={FiEdit3}
          color="#999EA9"
          w="24px"
          h="24px"
          cursor="pointer"
          display={eventData?.businessID === businessId ? 'flex' : 'none'}
          onClick={handleEditEventClientDetails}
        />
      </Flex>
      <Flex flexDirection="column" gap="16px">
        <Flex flexDirection="column" gap="4px">
          <Text
            variant="eventGridCardTitle"
            display={eventName ? 'flex' : 'none'}>
            {eventName}
          </Text>
          <Text
            variant="taskHistoryType"
            display={eventData?.type ? 'flex' : 'none'}>
            {t(`event_types.${eventData?.type}`)}
          </Text>
        </Flex>
        <Flex flexDirection="column" gap="4px">
          <Text
            variant="clientDetailInfo"
            display={eventDate ? 'flex' : 'none'}>
            {eventDate}
          </Text>
          <Flex alignItems="start" flexDirection="column" gap="4px">
            <Flex alignItems="center" gap="2px">
              <Image src={invitedIcon} />
              <Text variant="eventGridCardDesc" minW="60px">
                {t('event.top_bar_tabs.invited_adults_and_children', {
                  count: eventData?.numberOfGuests || 100,
                  childrenCount: eventData?.numberOfGuestsChildren || 0,
                })}
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="2px"
              display={eventData?.location?.label ? 'flex' : 'none'}>
              <Icon as={HiOutlineLocationMarker} />
              <Text variant="eventGridCardDesc">
                {eventData?.location?.label}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {eventData?.businessID === businessId ? (
        quoteClients?.map(({ client, contacts }, key) => (
          <React.Fragment key={key}>
            <Divider />
            {key > 0 && (
              <Text variant="clientDetailInfo">
                {t('event.contact', { count: key + 1 })}
              </Text>
            )}
            <Flex flexDirection="column" gap="14px">
              <Flex alignItems="center" gap="8px">
                <Icon as={FiUser} color="#00000099" />
                <Text variant="eventTabButtonTitle">
                  {client?.type === 'business'
                    ? client?.company
                    : `${client?.firstName} ${client?.lastName}`}
                </Text>
              </Flex>
              <Flex
                alignItems="center"
                gap="8px"
                display={client?.phone ? 'flex' : 'none'}>
                <Icon as={FiPhone} color="#00000099" />
                <Text
                  variant="eventTabButtonTitle"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  transition="all 0.3s ease"
                  onClick={() => {
                    window.open(`tel:${client?.phone}`, '_blank');
                  }}>
                  {client?.phone}
                </Text>
              </Flex>
              <Tooltip label={client?.address}>
                <Flex
                  alignItems="center"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                      `https://maps.google.com/?q=${client?.address}`,
                      '_blank',
                    );
                  }}
                  display={client?.address ? 'flex' : 'none'}>
                  <Icon as={HiOutlineLocationMarker} color="#00000099" />
                  <Text variant="eventTabButtonTitle">{client?.address}</Text>
                </Flex>
              </Tooltip>
              <Flex
                alignItems="center"
                gap="8px"
                display={client?.idNumber ? 'flex' : 'none'}>
                <Image src={VatIcon} />
                <Text variant="eventTabButtonTitle">{client?.idNumber}</Text>
              </Flex>
              <Flex
                alignItems="center"
                gap="8px"
                display={client?.email ? 'flex' : 'none'}>
                <Icon as={HiOutlineMailOpen} color="#00000099" />
                <Text
                  variant="eventTabButtonTitle"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  transition="all 0.3s ease"
                  onClick={() => {
                    window.open(`mailto:${client?.email}`, '_blank');
                  }}>
                  {client?.email}
                </Text>
              </Flex>
            </Flex>
          </React.Fragment>
        ))
      ) : (
        <Flex flexDirection="column" gap="14px">
          <Flex alignItems="center" gap="8px">
            <Icon as={FiUser} color="#00000099" />
            <Text variant="eventTabButtonTitle">
              {supplier?.name || business?.businessName}
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            gap="8px"
            display={supplier?.phone || business?.phone ? 'flex' : 'none'}>
            <Icon as={FiPhone} color="#00000099" />
            <Text
              variant="eventTabButtonTitle"
              cursor="pointer"
              _hover={{
                textDecoration: 'underline',
              }}
              transition="all 0.3s ease"
              onClick={() => {
                window.open(
                  `tel:${supplier?.phone || business?.phone}`,
                  '_blank',
                );
              }}>
              {supplier?.phone || business?.phone}
            </Text>
          </Flex>
          <Tooltip label={supplier?.address || business?.address}>
            <Flex
              alignItems="center"
              gap="8px"
              cursor="pointer"
              onClick={() => {
                window.open(
                  `https://maps.google.com/?q=${
                    supplier?.address || business?.address
                  }`,
                  '_blank',
                );
              }}
              display={
                supplier?.address || business?.address ? 'flex' : 'none'
              }>
              <Icon as={HiOutlineLocationMarker} color="#00000099" />
              <Text variant="eventTabButtonTitle">
                {supplier?.address || business?.address}
              </Text>
            </Flex>
          </Tooltip>
          <Flex
            alignItems="center"
            gap="8px"
            display={
              supplier?.vatId || business?.businessVATId ? 'flex' : 'none'
            }>
            <Image src={VatIcon} />
            <Text variant="eventTabButtonTitle">
              {supplier?.vatId || business?.businessVATId}
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            gap="8px"
            display={supplier?.email || business?.email ? 'flex' : 'none'}>
            <Icon as={HiOutlineMailOpen} color="#00000099" />
            <Text
              variant="eventTabButtonTitle"
              cursor="pointer"
              _hover={{
                textDecoration: 'underline',
              }}
              transition="all 0.3s ease"
              onClick={() => {
                window.open(
                  `mailto:${supplier?.email || business?.email}`,
                  '_blank',
                );
              }}>
              {supplier?.email || business?.email}
            </Text>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
