// Others
// import OthersError from 'views/admin/main/others/404';

// Auth Imports
// import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
// import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
// import LockCentered from 'views/auth/lock/LockCentered';
// import LockDefault from 'views/auth/lock/LockDefault';
// import VerificationCentered from 'views/auth/verification/VerificationCentered';
// import VerificationDefault from 'views/auth/verification/VerificationDefault';

import GetStarted from 'views/auth/getStarted';
import SignIn from 'views/auth/signIn/index';
import SignUp from 'views/auth/signUp/SignUpCentered';

// icons
import calendarIcon from 'assets/svg/sidebar/calendar.svg';
import dashboardsIcon from 'assets/svg/sidebar/dashboards.svg';
import financeIcon from 'assets/svg/sidebar/finance.svg';
import managmentIcon from 'assets/svg/sidebar/managment.svg';
import eventsIcon from 'assets/svg/sidebar/events2.svg';
import operationsIcon from 'assets/svg/sidebar/operations.svg';
import settingsIcon from 'assets/svg/sidebar/settings.svg';

import { Image } from '@chakra-ui/react';
import InviteSeat from 'views/auth/inviteSeat';
import InviteSupplier from 'views/auth/inviteSupplier';
import QuoteReqest from 'views/auth/quoteRequest';
import BusinesSettings from 'views/main/businessSettings';
import Dashboard from 'views/main/dashboard';
import { EventPage } from 'views/main/event';
import Gallery from 'views/main/gallery';
import CalendarFull from 'views/main/managment/calendar';
import CreateUpdateEvent from 'views/main/managment/events/createUpdateEvent';
import EventsOverview from 'views/main/managment/events/eventsOverview';
import CreateMeeting from 'views/main/managment/meeting/createMeeting';
import Messenger from 'views/main/managment/messenger';
import Products from 'views/main/managment/products';
import Suppliers from 'views/main/managment/suppliers';
import NotificationSettings from 'views/main/notificationSettings';
import ProfileSettings from 'views/main/profileSettings';
import CreateTask from 'views/main/tasks/createTask';
import TaskHistory from 'views/main/tasks/taskHistory';
import ForgotPassword from 'views/auth/forgotPassword';
import ResetPassword from 'views/auth/resetPassword';
import CreateQuote from 'views/main/finance/quote/createQuote';
import Quotes from 'views/main/finance/quote/quoteList';
import PreviewQuote from 'views/main/finance/quote/previewQuote';
import Clients from 'views/main/managment/clients';
import CreateContract from 'views/main/finance/contract/createContract';
import Contracts from 'views/main/finance/contract/contracts';
import FinanceOverview from 'views/main/finance/financeOverview';
import Incomes from 'views/main/finance/incomes';
import Outcomes from 'views/main/finance/outcomes';
import PreviewContract from 'views/main/finance/contract/previewContract';
import TaskHistoryView from 'views/main/taskHistory';
import ApproveQuote from 'views/auth/approveQuote';
import FilesOverview from 'views/main/operations/files';
import FinanceSettings from 'views/main/financeSettings';
import InvoicesOverview from 'views/main/finance/invoice/invoicesOverview';
import InvoiceForm from 'views/main/finance/invoice/invoiceForm';
import RequestingSupplierDetails from 'views/token/requesting-supplier-details';

const routes: RoutesType[] = [
  // --- Main routes ---
  {
    isSidebarVisible: false,
    name: 'Authentication',
    path: '/auth',
    collapse: true,
    items: [
      {
        isSidebarVisible: false,
        name: 'Approve Quote',
        path: '/invite-quote',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/invite-quote',
            component: <ApproveQuote />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Request Supplier Details',
        path: '/requesting-supplier-details',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/requesting-supplier-details',
            component: <RequestingSupplierDetails />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in/',
            component: <SignIn />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/',
            component: <ForgotPassword />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Reset Password',
        path: '/reset-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/reset-password/',
            component: <ResetPassword />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Get Started',
        path: '/get-started',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/get-started/',
            component: <GetStarted />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/',
            component: <SignUp />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.suppliers',
            layout: '/main',
            path: '/managment/suppliers',
            component: <Suppliers />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.clients',
            layout: '/main',
            path: '/managment/clients',
            component: <Clients />,
          },
          {
            isSidebarVisible: false,
            name: 'route_name.client_events_history',
            layout: '/main',
            path: '/managment/clients/:clientId/events-history',
            component: <EventsOverview />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.products',
            layout: '/main',
            path: '/managment/products',
            component: <Products />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.calendar',
            layout: '/main',
            path: '/managment/calendar',
            component: <></>,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Invited Seat',
        path: '/invite-seat',
        collapse: true,
        items: [
          {
            name: 'Landing',
            layout: '/auth',
            path: '/invite-seat/',
            component: <InviteSeat />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Invited Supplier',
        path: '/invite-supplier',
        collapse: true,
        items: [
          {
            name: 'Landing',
            layout: '/auth',
            path: '/invite-supplier/',
            component: <InviteSupplier />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Quote Request',
        path: '/quote-request',
        collapse: true,
        items: [
          {
            name: 'Landing',
            layout: '/auth',
            path: '/quote-request/',
            component: <QuoteReqest />,
          },
        ],
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.dashboard',
    path: '/dashboard',
    icon: <Image src={dashboardsIcon} alt="" />,
    collapse: false,
    layout: '/main',
    component: <Dashboard />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.task-history',
    path: '/task-history',
    collapse: false,
    layout: '/main',
    component: <TaskHistoryView />,
  },
  {
    isSidebarVisible: true,
    name: 'route_name.calendar',
    path: '/calendar',
    icon: <Image src={calendarIcon} alt="" />,
    collapse: false,
    layout: '/main',
    component: <CalendarFull />,
  },

  {
    isSidebarVisible: false,
    name: 'route_name.event_messenges',
    path: 'event/:eventId/messenger/:id',
    layout: '/main',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: false,
    component: <Messenger />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.messenger',
    path: '/messenger/:id',
    layout: '/main',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: false,
    component: <Messenger />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.event',
    path: '/event',
    layout: '/main',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: false,
    component: <EventPage />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.event',
    path: '/event/:id',
    layout: '/main',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: false,
    component: <EventPage />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.tasks_history',
    path: '/event/:id/tasks-history',
    layout: '/main',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: false,
    component: <TaskHistory />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.tasks_history',
    path: '/tasks-history',
    layout: '/main',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: false,
    component: <TaskHistory />,
  },
  {
    isSidebarVisible: false,
    name: 'Approve Quote',
    path: '/invite-quote',
    collapse: false,
    layout: '/main',
    component: <ApproveQuote />,
  },
  {
    isSidebarVisible: true,
    name: 'route_name.events_overview',
    path: '/events',
    collapse: false,
    icon: <Image src={eventsIcon} alt="" />,
    layout: '/main',
    component: <EventsOverview />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.create_event',
    path: '/events/create',
    layout: '/main',
    icon: <Image src={eventsIcon} alt="" />,
    collapse: false,
    isEditable: true,
    component: <CreateUpdateEvent />,
  },
  {
    isSidebarVisible: false,
    name: 'route_name.update_event',
    path: '/events/update/:id',
    layout: '/main',
    collapse: false,
    icon: <Image src={eventsIcon} alt="" />,
    isEditable: true,
    component: <CreateUpdateEvent />,
  },
  {
    isSidebarVisible: true,
    name: 'route_name.managment',
    path: '/managment',
    icon: <Image src={managmentIcon} alt="" />,
    collapse: true,
    items: [
      // {
      //   isSidebarVisible: true,
      //   name: 'route_name.events',
      //   layout: '/main',
      //   path: '/managment/events',
      //   component: <EventInfo />,
      // },

      {
        isSidebarVisible: true,
        name: 'route_name.contacts',
        layout: '/main',
        collapse: true,
        path: '/managment/contacts',
        items: [
          {
            isSidebarVisible: true,
            name: 'route_name.clients',
            layout: '/main',
            path: '/managment/clients',
            component: <></>,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.suppliers',
            layout: '/main',
            path: '/managment/suppliers',
            component: <Suppliers />,
          },
        ],
      },
      // {
      //   isSidebarVisible: true,
      //   name: 'route_name.suppliers',
      //   layout: '/main',
      //   path: '/managment/suppliers',
      //   component: <Suppliers />,
      // },

      {
        isSidebarVisible: true,
        name: 'route_name.products',
        layout: '/main',
        path: '/managment/products',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.messenger',
        path: '/messenger',
        layout: '/main',
        component: <Messenger />,
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.finance',
    path: '/finance',
    icon: <Image src={financeIcon} alt="" />,
    collapse: true,
    roles: ['admin'],
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.overview',
        layout: '/main',
        path: '/finance/overview',
        component: <FinanceOverview />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.incomes',
        layout: '/main',
        path: '/finance/incomes',
        component: <Incomes />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.expenses',
        layout: '/main',
        path: '/finance/expenses',
        component: <Outcomes />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.quotes',
        layout: '/main',
        path: '/finance/quotes',
        component: <Quotes />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.create_quote',
        layout: '/main',
        path: '/finance/quotes/create',
        isEditable: true,
        component: <CreateQuote />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.quote',
        layout: '/main',
        path: '/finance/quotes/preview/:id',
        component: <PreviewQuote />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.update_quote',
        layout: '/main',
        path: '/finance/quotes/:id',
        component: <CreateQuote />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.contracts',
        layout: '/main',
        path: '/finance/contracts',
        component: <Contracts />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.document_preview',
        layout: '/main',
        path: '/finance/contracts/preview',
        component: <PreviewContract />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.create_contract',
        layout: '/main',
        path: '/finance/contracts/create',
        isEditable: true,
        component: <CreateContract />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.update_contract',
        layout: '/main',
        path: '/finance/contracts/:contractId',
        isEditable: true,
        component: <CreateContract />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.invoices',
        layout: '/main',
        path: '/finance/invoices',
        component: <InvoicesOverview />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.create_invoice',
        layout: '/main',
        path: '/finance/invoices/create',
        component: <InvoiceForm />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.update_invoice',
        layout: '/main',
        path: '/finance/invoices/:invoiceId',
        component: <InvoiceForm />,
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.operations',
    path: '/operations',
    icon: <Image src={operationsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.files',
        layout: '/main',
        path: '/operations/files',
        component: <FilesOverview />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.events_archive',
        layout: '/main',
        path: '/operations/events-archive',
        component: <EventsOverview isArchive={true} />,
      },
    ],
  },
  // --- Account settings ---
  {
    isSidebarVisible: true,
    name: 'route_name.settings',
    path: '/settings',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.business_settings',
        layout: '/main',
        path: '/settings/business',
        roles: ['admin'],
        component: <BusinesSettings />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.profile_settings',
        layout: '/main',
        path: '/settings/profile',
        component: <ProfileSettings />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.billing',
        layout: '/main',
        path: '/settings/billing',
        roles: ['admin'],
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.gallery',
        layout: '/main',
        path: '/settings/gallery',
        component: <Gallery />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.notifications',
        layout: '/main',
        path: '/settings/notifications',
        component: <NotificationSettings />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.finance',
        layout: '/main',
        path: '/settings/finance',
        component: <FinanceSettings />,
      },
    ],
  },

  // --- Tasks ---
  {
    isSidebarVisible: false,
    name: 'route_name.tasks',
    path: '/tasks',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: false,
        name: 'route_name.create_task',
        layout: '/main',
        path: '/tasks/create',
        isEditable: true,
        component: <CreateTask />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.update_task',
        layout: '/main',
        path: '/tasks/update/:id',
        isEditable: true,
        component: <CreateTask />,
      },
    ],
  },
  {
    isSidebarVisible: false,
    name: 'route_name.meetings',
    path: '/meetings',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: false,
        name: 'route_name.create_meeting',
        layout: '/main',
        path: '/meetings/create',
        isEditable: true,
        component: <CreateMeeting />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.update_meeting',
        layout: '/main',
        path: '/meetings/update/:id',
        isEditable: true,
        component: <CreateMeeting />,
      },
    ],
  },
];

export default routes;
