// src/features/users/userSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'config/i18n/config';
import { User, PaginatedResults } from 'services/@types';
import UserService from 'services/user.api';
import { clearCredentials } from '../auth/authSlice';
import {
  closeAlertDialog,
  closeSplashDialog,
  showSplashDialog,
} from '../dialog/dialogsSlice';
import store from '../store';
export interface UserState {
  users: User[];
  user: User | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: UserState = {
  users: [],
  user: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchUsers = createAsyncThunk<
  PaginatedResults<User>,
  Record<string, any>,
  { rejectValue: string }
>(
  'users/fetchUsers',
  async (params: Record<string, Record<string, any>>, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(params);
      return response;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  },
);

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await UserService.getUser(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (user: User, { rejectWithValue }) => {
    try {
      const response = await UserService.createUser(user);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (
    { userId, updates }: { userId: string; updates: Partial<User> },
    { rejectWithValue },
  ) => {
    try {
      const response = await UserService.updateUser(userId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      await UserService.deleteUser(userId);
      return userId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMe = createAsyncThunk(
  'users/deleteMe',
  async ({ user }: { user: User }, { rejectWithValue }) => {
    try {
      const splashMessage = i18n.t('dialogs.splash.deleting_account', {
        name: user.firstName,
      });
      store.dispatch(showSplashDialog({ title: splashMessage }));
      store.dispatch(closeAlertDialog());
      await UserService.deleteMe();
      store.dispatch(clearCredentials());
      localStorage?.removeItem('inviteSupplierToken');
      setTimeout(() => {
        store.dispatch(closeSplashDialog());
      }, 2000);
      return true;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const getUserMedia = createAsyncThunk(
  'users/getUserMedia',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await UserService.getUser(userId);
      return response.media;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch all users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUsers.fulfilled,
        (state, action: PayloadAction<PaginatedResults<User>>) => {
          state.users = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchUsers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch a single user
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a user
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.users.push(action.payload);
        state.loading = false;
      })
      .addCase(createUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id,
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<string>) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete my user
      .addCase(deleteMe.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMe.fulfilled, (state, action: PayloadAction<{}>) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteMe.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
