import {
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import InputDateField from 'components/fields/InputDateField';
import SwitchField from 'components/fields/SwitchField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { AnimatePresence, motion } from 'framer-motion';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPaperclip, FiPlus, FiTrash, FiX } from 'react-icons/fi';
import { CreateQuoteError, IInvoice } from 'services/@types';

const contentVariants = {
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.2 },
    },
  },
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.2, delay: 0.1 },
    },
  },
};

export default function PaymentDetails({
  invoice,
  handleChangeInvoice,
  currencyIcon,
  error,
  handleChangePaymentAmount,
  handleChangeFile,
  handleChangePaymentDescription,
  handleRemovePaymentIndex,
  clearFile,
  dir,
  setInvoice,
  handleIncreasePaymentQuantity,
}: {
  invoice: Partial<IInvoice>;
  handleChangeInvoice: (path: string, value: any) => void;
  currencyIcon: string;
  error: CreateQuoteError;
  handleChangeFile: (e: any) => void;
  handleChangePaymentAmount: (e: any, index: number) => void;
  handleChangePaymentDescription: (e: any, index: number) => void;
  clearFile: () => void;
  handleRemovePaymentIndex: (index: number) => void;
  dir: string;
  setInvoice: (invoice: any) => void;
  handleIncreasePaymentQuantity: () => void;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const myBusiness = useMyBusiness();
  const { t } = useTranslation('', {
    lng: invoice?.language,
  });

  const [isEnabled, setIsEnabled] = useState(true);

  return (
    <Flex direction="column" gap="12px">
      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
        style={{ display: 'none' }}
        onChange={handleChangeFile}
      />
      <SwitchField
        dir={dir}
        align="start"
        reversed
        justify="flex-start"
        wrapperProps={{
          gap: 2,
          alignSelf: 'flex-start',
        }}
        id="general-payment-details"
        label={t('create_quote.general_payment_details')}
        isChecked={isEnabled}
        onChange={(e) => {
          setIsEnabled(e.target.checked);
        }}
      />

      <AnimatePresence>
        {isEnabled && (
          <motion.div
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden">
            <VStack align="stretch" spacing={3}>
              {invoice.paymentInInstallments?.installments?.map(
                (payment, index) => (
                  <HStack key={index} alignItems="start">
                    <InputField
                      dir={dir}
                      w="100%"
                      type="text"
                      name="description"
                      min={0}
                      label={
                        index === 0 &&
                        invoice.paymentInInstallments?.installments?.length > 1
                          ? t('create_quote.payment_description_advance')
                          : t('create_quote.payment_description')
                      }
                      placeholder={t(
                        'create_quote.payment_description_placeholder',
                      )}
                      value={payment.description}
                      onChange={(e) => handleChangePaymentDescription(e, index)}
                    />
                    <InputField
                      dir={dir}
                      w="100%"
                      type="number"
                      name="amount"
                      min={0}
                      label={t('create_quote.amount')}
                      placeholder={t('create_quote.amount')}
                      value={payment.amount}
                      onChange={(e) => handleChangePaymentAmount(e, index)}
                      iconLeft={<Text variant="currency">{currencyIcon}</Text>}
                    />
                    <InputDateField
                      lang={invoice?.language}
                      containerStyles={{ flex: '1', w: { base: '100%' } }}
                      label={t('create_quote.date')}
                      dir={dir}
                      buttonStyles={{
                        w: { base: '100%' },
                        maxW: { base: '100%' },
                        borderColor: payment.dueDate
                          ? '#0000001A'
                          : 'error.100',
                        justifyContent: 'space-between',
                      }}
                      value={payment.dueDate}
                      imgSrc={CalendarIcon}
                      onChange={(date: Date) =>
                        setInvoice((prev: any) => ({
                          ...prev,
                          paymentInInstallments: {
                            ...prev.paymentInInstallments,
                            installments:
                              prev.paymentInInstallments.installments.map(
                                (p: any, i: number) =>
                                  i === index ? { ...p, dueDate: date } : p,
                              ),
                          },
                        }))
                      }
                      imgStyles={{
                        bg: 'transparent',
                      }}
                    />
                    <Flex alignSelf="flex-end" py={3}>
                      <IconButton
                        icon={<Icon as={FiTrash} color="white" />}
                        aria-label="delete"
                        alignSelf="flex-end"
                        onClick={() => handleRemovePaymentIndex(index)}
                        variant="actionIconRed"
                      />
                    </Flex>
                  </HStack>
                ),
              )}
              <HStack>
                <IconButton
                  borderRadius="10px"
                  icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
                  onClick={handleIncreasePaymentQuantity}
                  aria-label="add"
                  w="33px"
                  h="33px"
                  minW="33px"
                  bg="#93C3B3"
                />
                <Text variant="addRowText">
                  {t('create_quote.add_payment')}
                </Text>
              </HStack>
              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />
              <InputField
                label={t('create_quote.cancellation_terms')}
                value={invoice?.cancellationTerms}
                minW={{ base: '100%', md: '510px' }}
                onChange={(e) =>
                  handleChangeInvoice('cancellationTerms', e.target.value)
                }
                placeholder={t('create_quote.cancellation_terms_placeholder')}
              />
              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />
              <HStack justify="flex-start">
                {/* attach file... */}
                <IconButton
                  icon={<Icon as={FiPaperclip} p={'1px'} />}
                  aria-label="add"
                  display={invoice?.media?.length > 0 ? 'none' : 'flex'}
                  onClick={() => fileInputRef.current?.click()}
                  variant="actionIconGrey"
                />
                <Text
                  variant="addRowText"
                  display={invoice?.media?.length > 0 ? 'none' : 'block'}>
                  {t('create_quote.attach_file')}
                </Text>
                {invoice?.media?.length > 0 && (
                  <Flex alignItems="center" gap="4px">
                    <Text
                      variant="eventGridCardDesc"
                      onClick={() => {
                        handleOpenPdf(
                          invoice?.media?.[0]?.url,
                          invoice?.media?.[0]?.displayName,
                        );
                      }}>
                      {invoice?.media?.[0]?.displayName}
                    </Text>
                    <Icon
                      aria-label=""
                      as={FiX}
                      cursor="pointer"
                      onClick={() => clearFile()}
                    />
                  </Flex>
                )}
              </HStack>
              <Divider
                orientation="horizontal"
                my="36px"
                color="#E8E8E8"
                borderWidth={'1px'}
              />

              <VStack align="flex-start">
                {/* business details here */}
                <Text fontSize="14px" fontWeight="500" color="primary.100">
                  {t('create_quote.business_details')}
                </Text>
                <HStack bg="#efefef" p={4} borderRadius="16px" w="100%">
                  <Text fontSize="14px" fontWeight="500">
                    {myBusiness?.businessName}
                    {myBusiness?.address && `, ${myBusiness.address}`}
                    {myBusiness?.phone && ` ${myBusiness.phone}`}
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
}
