import { SearchIcon } from '@chakra-ui/icons';
import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  StackProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
type SearchBarProps = InputGroupProps & {
  variant?: string;
  background?: string;
  placeholder?: string;
  borderRadius?: string | number;
  inputProps?: InputProps;
  containerProps?: StackProps;
  [x: string]: any;
};
export function SearchBar(props: SearchBarProps) {
  // Pass the computed styles into the `__css` prop
  const {
    variant,
    background,
    children,
    placeholder,
    borderRadius,
    inputProps,
    containerProps,
    ...rest
  } = props;
  // Chakra Color Mode
  const searchIconColor = useColorModeValue('brand.400', 'white');
  const inputBg = useColorModeValue('warmWhite.100', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const { t } = useTranslation();
  return (
    <HStack
      w="100%"
      bg={background ? background : inputBg}
      borderRadius={borderRadius ? borderRadius : '30px'}
      {...containerProps}>
      <IconButton
        aria-label="search"
        bg="transparent"
        borderRadius="inherit"
        _active={{
          bg: 'inherit',
          transform: 'none',
          borderColor: 'transparent',
        }}
        _hover={{
          background: 'none',
        }}
        _focus={{
          background: 'none',
          boxShadow: 'none',
        }}
        icon={<SearchIcon color={searchIconColor} w="12px" h="12px" />}
      />
      <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
        <Input
          variant="search"
          fontSize={{ base: '16px', md: '14px' }}
          color={inputText}
          h="44px"
          bg="transparent"
          ps={0}
          fontWeight="400"
          _placeholder={{ color: 'gray.400', fontSize: 'sm' }}
          borderRadius={borderRadius ? borderRadius : '30px'}
          placeholder={
            placeholder ? placeholder : t('dialogs.select_supplier.search')
          }
          {...inputProps}
        />
      </InputGroup>
    </HStack>
  );
}
