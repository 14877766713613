import {
  Collapse,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { AppDispatch, RootState } from 'contexts/redux/store';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { CiImport } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { eventTypes } from 'variables/eventTypes';
import useEventFilter from '../hooks/useEventFilter';
import LayoutSelector from './LayoutSelector';
import MobileFilterBurger from './MobileFilterBurger';
import RefreshFiltersButton from './RefreshFiltersButton';
const dropDownStyles = {
  flex: { base: 'none', md: '1' },
  width: { base: '100%', md: 'auto' },
  menuButton: {
    borderColor: '#0000001A',
    bg: 'transparent',
    color: '#999999',
    fontWeight: '500',
  },
  downIconProps: { color: '#939393' },
};
export const eventStatuses = [
  'new',
  'active',
  'post',
  'completed',
  'cancelled',
];

interface EventsOverviewHeaderProps {
  isArchive: boolean;
  onOpenImportDialog: () => void;
}
export default function EventsOverviewHeader(props: EventsOverviewHeaderProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch<AppDispatch>();
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: isMobile ? false : true,
  });
  const {
    eventName,
    selectedEventType,
    selectedMonth,
    options,
    showedMonth,
    handleEventNameChange,
    handleChangeEventType,
    handleChangeEventStatus,
    handleChangeMonth,
    handleRefreshFilters,
  } = useEventFilter(props.isArchive);
  const userPreferences = useMyUserPreferences();
  const filter = useSelector(
    (state: RootState) => state.businessEventPlan.filter,
  );
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="16px" w="100%">
      <MobileFilterBurger onToggle={onToggle} isOpen={isOpen} />
      <Collapse in={isOpen} animateOpacity>
        <Flex
          w="100%"
          alignItems="center"
          gap="16px"
          wrap={{ base: 'wrap', md: 'nowrap' }}>
          {isMobile && (
            <InputField
              placeholder={t('events_overview.header.search')}
              iconRight={
                <Icon
                  as={FiSearch}
                  color={dropDownStyles.downIconProps.color}
                />
              }
              value={eventName}
              onChange={handleEventNameChange}
              flex={dropDownStyles.flex}
              w={dropDownStyles.width}
              minW={dropDownStyles.width}
              borderColor={dropDownStyles.menuButton.borderColor}
              _placeholder={{ color: dropDownStyles.menuButton.color }}
            />
          )}
          <DropdownField
            placeholder={t('events_overview.header.status')}
            options={
              props.isArchive
                ? eventStatuses
                    .map((type) => ({
                      id: type,
                      label: t(`event.status.${type}`),
                    }))
                    .slice(3, 5)
                : eventStatuses
                    .map((type) => ({
                      id: type,
                      label: t(`event.status.${type}`),
                    }))
                    .slice(0, 3)
            }
            selectedOptions={filter.status}
            onSelected={handleChangeEventStatus}
            // showedOptions={t(filter.status[0])}
            {...dropDownStyles}
          />
          <DropdownField
            placeholder={t('events_overview.header.event_type')}
            options={eventTypes.map((type) => `event_types.${type}`)}
            selectedOptions={selectedEventType}
            onSelected={handleChangeEventType}
            showedOptions={t(selectedEventType[0])}
            closeOnSelect
            {...dropDownStyles}
          />
          <DropdownField
            placeholder={t('events_overview.header.month')}
            options={options}
            selectedOptions={selectedMonth}
            showedOptions={showedMonth}
            onSelected={handleChangeMonth}
            {...dropDownStyles}
          />
          {!isMobile && (
            <InputField
              placeholder={t('events_overview.header.search')}
              iconRight={
                <Icon
                  as={FiSearch}
                  color={dropDownStyles.downIconProps.color}
                />
              }
              value={eventName}
              onChange={handleEventNameChange}
              flex={dropDownStyles.flex}
              w={dropDownStyles.width}
              minW={dropDownStyles.width}
              borderColor={dropDownStyles.menuButton.borderColor}
              _placeholder={{ color: dropDownStyles.menuButton.color }}
            />
          )}
          <RefreshFiltersButton onClick={handleRefreshFilters} />
          <Tooltip
            label={t('events_overview.import_events')}
            placement="top"
            variant="business">
            <IconButton
              aria-label="import"
              size="md"
              icon={<Icon as={CiImport} />}
              onClick={props.onOpenImportDialog}
              variant="actionIconBlue"
            />
          </Tooltip>
          {!isMobile && (
            <LayoutSelector
              activeLayout={userPreferences.eventsOverviewLayout || 'grid'}
              onLayoutChange={(layout: 'grid' | 'list') => {
                dispatch(
                  updateUserPreferences({
                    id: userPreferences.id,
                    updates: {
                      eventsOverviewLayout: layout,
                    },
                  }),
                );
              }}
            />
          )}
        </Flex>
      </Collapse>
    </Flex>
  );
}
