import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { IClient, PaginatedResults } from 'services/@types';
import ClientService from 'services/client.api';
import { RootState } from '../store';
import { createSupplier, updateSupplier } from '../supplier/supplierSlice';
export interface ClientState {
  clients: IClient[];
  currentClient: IClient | null;
  client: IClient | null;
  editClient: IClient | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: ClientState = {
  clients: [],
  client: null,
  loading: false,
  error: null,
  currentClient: null,
  editClient: null,
  pagination: {
    page: 1,
    limit: 1000,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await ClientService.getClients(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchClient = createAsyncThunk(
  'client/fetchClient',
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await ClientService.getClient(clientId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createClient = createAsyncThunk(
  'client/createClient',
  async ({ client }: { client: Partial<IClient> }, { rejectWithValue }) => {
    try {
      // delete empty values
      Object.keys(client).forEach((key) => {
        if (client[key as keyof IClient] === '') {
          delete client[key as keyof IClient];
        }
      });

      const newClient = await ClientService.createClient(client);
      console.log('#Redux create client ->', newClient);

      return newClient;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateClient = createAsyncThunk(
  'bussiness/updateClient',
  async (
    { clientId, updates }: { clientId: string; updates: Partial<IClient> },
    { rejectWithValue },
  ) => {
    try {
      // Remove fields that are not allowed to be updated
      delete updates?.id;
      delete updates?.createdAt;
      delete updates?.updatedAt;
      delete updates?.creatorBusinessID;
      delete updates?.creatorUserID;

      // delete empty values
      Object.keys(updates).forEach((key) => {
        if (updates[key as keyof IClient] === '') {
          delete updates[key as keyof IClient];
        }
      });

      const response = await ClientService.updateClient(clientId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteClient = createAsyncThunk(
  'client/deleteClient',
  async (clientId: string, { rejectWithValue }) => {
    try {
      await ClientService.deleteClient(clientId);
      return clientId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setCurrentClient: (state, action: PayloadAction<IClient | null>) => {
      state.currentClient = action.payload;
    },
    setEditClient: (state, action: PayloadAction<IClient | null>) => {
      state.editClient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchClients.fulfilled,
        (state, action: PayloadAction<PaginatedResults<IClient>>) => {
          state.clients = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchClients.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchClient.fulfilled,
        (state, action: PayloadAction<IClient>) => {
          state.client = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createClient.fulfilled,
        (state, action: PayloadAction<IClient>) => {
          state.client = action.payload;
          state.clients.push(action.payload);
          state.loading = false;
        },
      )
      .addCase(createSupplier.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateClient.fulfilled,
        (state, action: PayloadAction<IClient>) => {
          const index = state.clients.findIndex(
            (user) => user.id === action.payload.id,
          );
          if (index !== -1) {
            state.clients[index] = action.payload;
          }
          state.client = action.payload;
          state.currentClient = action.payload;
          state.loading = false;
        },
      )
      .addCase(updateClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteClient.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.clients = state.clients.filter(
            (user) => user.id !== action.payload,
          );
          state.currentClient = null;
          state.loading = false;
        },
      )
      .addCase(deleteClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCurrentClient, setEditClient } = clientSlice.actions;

export const useSelectedClient = () => {
  const client = useSelector((state: RootState) => state.clients.currentClient);
  return { client };
};

export default clientSlice.reducer;
