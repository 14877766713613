import * as XLSX from 'xlsx';

/**
 * Generic function to import data from Excel files
 * @param file The Excel file to import
 * @param transformFn Function to transform raw data to the desired format
 * @param callbacks Optional callbacks for state management
 * @returns Promise with the transformed data
 */
export const importFromExcel = async <RawDataType, TransformedType>(
  file: File,
  transformFn: (data: RawDataType) => TransformedType,
  callbacks?: {
    setIsLoading?: (isLoading: boolean) => void;
    setError?: (error: Error | null) => void;
    setFileName?: (fileName: string) => void;
    setImportedData?: (data: TransformedType[]) => void;
  }
): Promise<TransformedType[]> => {
  const { setIsLoading, setError, setFileName, setImportedData } = callbacks || {};
  
  if (setIsLoading) setIsLoading(true);
  if (setError) setError(null);
  if (setFileName) setFileName(file.name);

  try {
    const result = await new Promise<TransformedType[]>(
      (resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet) as RawDataType[];
            const transformedData = jsonData.map(transformFn);
            console.log('Converted data:', transformedData);
            
            if (setImportedData) setImportedData(transformedData);
            resolve(transformedData);
          } catch (error) {
            if (setFileName) setFileName('');
            reject(
              error instanceof Error
                ? error
                : new Error('Failed to process Excel file')
            );
          }
        };

        reader.onerror = (error) => {
          if (setFileName) setFileName('');
          reject(
            error instanceof Error ? error : new Error('Failed to read file')
          );
        };
        
        reader.readAsBinaryString(file);
      }
    );

    return result;
  } catch (err) {
    const error =
      err instanceof Error
        ? err
        : new Error('Failed to process Excel file');
    
    if (setError) setError(error);
    if (setFileName) setFileName('');
    throw error;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}; 