import { AxiosInstance, AxiosResponse } from 'axios';
import {
  IDesign,
  ICreateDesign,
  UpdateDesign,
  GetDesignsParams,
  PaginatedResults,
} from './@types';
import { createAxiosInstance } from './axios.util';

class DesignService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createDesign(designRequest: ICreateDesign): Promise<IDesign> {
    const response: AxiosResponse<IDesign> = await this.api.post(
      '/designs',
      designRequest,
    );
    return response.data;
  }

  public async getDesigns(
    params?: GetDesignsParams,
  ): Promise<PaginatedResults<IDesign>> {
    const response: AxiosResponse<PaginatedResults<IDesign>> =
      await this.api.get('/designs', {
        params,
      });
    return response.data;
  }

  public async getDesign(designId: string): Promise<IDesign> {
    const response: AxiosResponse<IDesign> = await this.api.get(
      `/designs/${designId}`,
    );
    return response.data;
  }

  public async updateDesign(
    designId: string,
    updates: UpdateDesign,
  ): Promise<IDesign> {
    const response: AxiosResponse<IDesign> = await this.api.patch(
      `/designs/${designId}`,
      updates,
    );
    return response.data;
  }

  public async deleteDesign(designId: string): Promise<void> {
    await this.api.delete(`/designs/${designId}`);
  }
}

const _designService = new DesignService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _designService;
