import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { IClient, Supplier } from 'services/@types';
import ClientCard from './components/ClientCard';
import { Contact } from './components/Contact';
import useContactsPickerDialog from './hooks/useContactsPickerDialog';
import { FiPlus } from 'react-icons/fi';

function ContactsPickerDialog() {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const {
    isOpen,
    handleClose,
    handleSelectClient,
    handleSubmitContactsSelection,
    filteredContacts,
    type,
    search,
    handleSearch,
    selectedClients,
    handleSelectSupplier,
    selectedSuppliers,
    isSingleSelection,
    handleCreateSupplier,
    isSupplierSelection,
    buttonText,
  } = useContactsPickerDialog();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'sm'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        minH={{ base: '100%', md: '50dvh' }}>
        <ModalBody
          p="24px 16px"
          w="full"
          h="100%"
          display="flex"
          flexDir="column">
          <VStack w="100%" gap={4} spacing={0} h={'full'} pb={4}>
            <HStack w="100%" justifyContent="space-between" spacing={0}>
              <Text
                variant="cardSectionTitle"
                fontSize={'base'}
                fontWeight={700}
                color="#141718">
                {isSingleSelection
                  ? t(`dialogs.contacts.select_${type}`)
                  : t(`dialogs.contacts.${type}`)}
              </Text>
              <ModalCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                justifyContent={'center'}
                alignItems="center"
                display={'flex'}
                boxShadow="none !important"
              />
            </HStack>
            {/* Search */}
            <Flex w="100%" alignItems="center" gap={2}>
              <SearchBar
                value={search}
                margin={'0 !important'}
                inputProps={{
                  onChange: handleSearch,
                }}
              />
              {isSingleSelection && isSupplierSelection && (
                <Icon
                  as={FiPlus}
                  bg="brand.400"
                  w="32px"
                  h="32px"
                  cursor="pointer"
                  color="white"
                  borderRadius="8px"
                  onClick={handleCreateSupplier}
                />
              )}
            </Flex>
            <VStack
              w="100%"
              maxH={{ base: '100%', md: '400px' }}
              flexShrink={0}
              overflowY="auto"
              gap={2}
              display="flex"
              flexDirection="column"
              h="100%"
              flex={1}>
              {/* TODO: add Recents and divider  */}
              {filteredContacts.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.contacts.no_elements_found')}
                </Text>
              )}
              {filteredContacts.map((contact) =>
                contact.contactType === 'client' ? (
                  <ClientCard
                    key={contact.id}
                    client={contact as IClient}
                    hideCheckbox={isSingleSelection}
                    onSelect={handleSelectClient}
                    onClick={handleSelectClient}
                    isSelected={selectedClients?.has(contact.id)}
                    selectedContacts={selectedClients?.get(contact.id) ?? null}
                  />
                ) : (
                  <Contact
                    color="#FFAF36"
                    hideCheckbox={isSingleSelection}
                    address={contact?.address}
                    clientId={contact?.id}
                    key={contact?.id}
                    email={contact?.email}
                    isSelected={selectedSuppliers?.has(contact.id)}
                    onSelect={handleSelectSupplier}
                    onClick={handleSelectSupplier}
                    title={(contact as Supplier)?.name}
                  />
                ),
              )}
            </VStack>
          </VStack>
          <Spacer flexShrink={0} />
          {!isSingleSelection && (
            <Button
              variant="h1cta"
              w="full"
              flexShrink={0}
              onClick={handleSubmitContactsSelection}>
              {t(buttonText)}
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ContactsPickerDialog;
