import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  BusinessEventPlan,
  IBusinessEventPlan,
  PaginatedResults,
} from 'services/@types';
import businessEventPlanService from 'services/businessEventPlan.api';

// Query key factories
const getBusinessEventPlanQueryKey = (planId: string): QueryKey => [
  'businessEventPlan',
  planId,
];
const getBusinessEventPlansQueryKey = (
  params?: Record<string, any>,
): QueryKey => ['businessEventPlans', params];

// Query options factories
const getBusinessEventPlanQueryOptions = (planId: string) => ({
  queryKey: getBusinessEventPlanQueryKey(planId),
  queryFn: () => businessEventPlanService.getBusinessEventPlan(planId),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const getBusinessEventPlansQueryOptions = (params?: Record<string, any>) => ({
  queryKey: getBusinessEventPlansQueryKey(params),
  queryFn: () => businessEventPlanService.getBusinessEventPlans(params),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
});

// Hooks
interface UseGetBusinessEventPlanOptions {
  enabled?: boolean;
}

export const useGetBusinessEventPlan = <T = IBusinessEventPlan>(
  planId: string,
  options: UseGetBusinessEventPlanOptions = {},
): UseQueryResult<T, Error> => {
  return useQuery({
    ...getBusinessEventPlanQueryOptions(planId),
    enabled: options.enabled !== false && !!planId,
  });
};

export const useGetBusinessEventPlans = (params?: Record<string, any>) => {
  return useQuery<PaginatedResults<BusinessEventPlan>>({
    ...getBusinessEventPlansQueryOptions(params),
    enabled: true,
  });
};

// Mutation hooks
export const useCreateBusinessEventPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      planRequest: Partial<IBusinessEventPlan>;
      notify: boolean;
    }) =>
      businessEventPlanService.createBusinessEventPlan(
        params.planRequest,
        params.notify,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['businessEventPlans'] });
    },
  });
};

export const useUpdateBusinessEventPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      updates,
    }: {
      id: string;
      updates: Partial<IBusinessEventPlan>;
    }) => businessEventPlanService.updateBusinessEventPlan(id, updates),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: getBusinessEventPlanQueryKey(data.id),
      });
      queryClient.invalidateQueries({ queryKey: ['businessEventPlans'] });
    },
  });
};

export const useDeleteBusinessEventPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (planId: string) =>
      businessEventPlanService.deleteBusinessEventPlan(planId),
    onSuccess: (_, planId) => {
      queryClient.invalidateQueries({
        queryKey: getBusinessEventPlanQueryKey(planId),
      });
      queryClient.invalidateQueries({ queryKey: ['businessEventPlans'] });
    },
  });
};

// Helper methods
useGetBusinessEventPlan.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  planId: string,
) => {
  await queryClient.prefetchQuery(getBusinessEventPlanQueryOptions(planId));
};

useGetBusinessEventPlan.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  planId: string,
) => {
  queryClient.invalidateQueries({
    queryKey: getBusinessEventPlanQueryKey(planId),
  });
};
