import {
  MenuButton,
  Menu,
  Flex,
  Text,
  IconButton,
  Icon,
  MenuList,
  MenuItem,
  Checkbox,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import { useTranslation } from 'react-i18next';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IInvoice } from 'services/@types';
import { currency } from 'variables/currency';
import { useMemo } from 'react';
import { paymentMethods } from 'variables/paymentMethods';

interface IDetailsOfReceiptsProps {
  receipientDetails: IInvoice['receipientDetails'];
  handleChangeDetailsOfReceipts: (key: string, value: any) => void;
}

export default function DetailsOfReceipts(props: IDetailsOfReceiptsProps) {
  const { receipientDetails, handleChangeDetailsOfReceipts } = props;
  const { t } = useTranslation();
  const { data: businessPreferences } = useGetBusinessPreferences();

  const selectedCurrency = useMemo(() => {
    const selected = currency.find(
      (c) => c.value === receipientDetails?.currency,
    );
    return selected ? [selected] : [''];
  }, [receipientDetails?.currency]);

  const showedOptions = useMemo(() => {
    const selected = currency.find(
      (c) => c.value === receipientDetails?.currency,
    );
    return selected ? t(selected.label) + ' - ' + selected.symbol : '';
  }, [receipientDetails?.currency, t]);
  return (
    <Flex flexDirection="column" gap="16px">
      <Text variant="lineUpFormDialogFileName">
        {t('create_invoice.details_of_receipts')}
      </Text>
      <Flex wrap="wrap" gap="12px" alignItems="end">
        <DropdownField
          closeOnSelect
          label={t('create_invoice.method')}
          w={{ base: '100%', md: '289px' }}
          placeholder={
            receipientDetails?.method
              ? t(
                  `event_finance.payment_details_modal.${receipientDetails?.method}`,
                )
              : t('create_invoice.method')
          }
          options={paymentMethods?.map((pm) => ({
            id: pm.id,
            label: t(pm.label),
          }))}
          selectedOptions={
            receipientDetails?.method ? [receipientDetails?.method] : []
          }
          onSelected={({ id }: any) => {
            handleChangeDetailsOfReceipts('method', id);
          }}
        />
        <DropdownField
          closeOnSelect
          label={t('create_invoice.currency')}
          placeholder={t('create_invoice.currency')}
          w={{ base: '100%', md: '289px' }}
          options={currency}
          selectedOptions={selectedCurrency}
          showedOptions={showedOptions}
          onSelected={(e: any) => {
            handleChangeDetailsOfReceipts('currency', e.value);
          }}
        />
        <InputDateField
          label={t('create_invoice.date')}
          placeholder={t('create_invoice.contract_date')}
          value={
            receipientDetails?.date
              ? new Date(receipientDetails?.date)
              : new Date()
          }
          onChange={(e: any) => handleChangeDetailsOfReceipts('date', e)}
          buttonStyles={{
            borderColor: '#0000001A',
            justifyContent: 'space-between',
            w: { base: '100%', md: '174px' },
          }}
          imgStyles={{
            bg: 'transparent',
          }}
          imgSrc={CalendarIcon}
        />
        <InputField
          label={t('create_invoice.amount')}
          placeholder={t('create_invoice.amount')}
          w={{ base: '100%', md: '110px' }}
          value={receipientDetails?.amount}
          onChange={(e: any) =>
            handleChangeDetailsOfReceipts('amount', e.target.value)
          }
        />
        <Checkbox
          variant="roundBrand"
          isChecked={receipientDetails?.vat}
          onChange={(e: any) =>
            handleChangeDetailsOfReceipts('vat', e.target.checked)
          }
          w={{ base: '100%', md: 'auto' }}
          h="50px"
          alignItems="center">
          <Text variant="createQuoteCheckboxText">
            {t('create_invoice.vat', {
              percentage: businessPreferences?.vatPercent,
            })}
          </Text>
        </Checkbox>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label=""
            icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
            variant="ghost"
            h="50px"
          />
          <MenuList>
            <MenuItem onClick={() => {}}></MenuItem>
          </MenuList>
        </Menu>
        <InputField
          label={t('create_invoice.bank')}
          placeholder={t('create_invoice.bank')}
          w={{ base: '100%', md: '288px' }}
          value={receipientDetails?.bank}
          onChange={(e: any) =>
            handleChangeDetailsOfReceipts('bank', e.target.value)
          }
        />
        <InputField
          label={t('create_invoice.branch')}
          placeholder={t('create_invoice.branch')}
          w={{ base: '100%', md: '288px' }}
          value={receipientDetails?.branch}
          onChange={(e: any) =>
            handleChangeDetailsOfReceipts('branch', e.target.value)
          }
        />
        <InputField
          label={t('create_invoice.bank_account_number')}
          placeholder={t('create_invoice.bank_account_number')}
          w={{ base: '100%', md: '288px' }}
          value={receipientDetails?.bankAccountNumber}
          onChange={(e: any) =>
            handleChangeDetailsOfReceipts('bankAccountNumber', e.target.value)
          }
        />
      </Flex>
    </Flex>
  );
}
