import { Button, Divider, Flex, Hide, Image, Text } from '@chakra-ui/react';
import TrashIcon from 'assets/img/quote/QuotePreview.png';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import TextField from 'components/fields/TextField';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { languages } from 'variables/languages';
import AssignEvent from './components/AssignEvent';
import BusinessDetails from './components/BusinessDetails';
import Clients from './components/Clients';
import DetailsOfReceipts from './components/DetailsOfReceipts';
import EventDetails from './components/EventDetails';
import FormHeader from './components/FormHeader';
import PaymentDetails from './components/PaymentDetails';
import Products from './components/Products';
import TotalInvoicePayment from './components/TotalInvoicePayment';
import useInvoiceForm from './hooks/useInvoiceForm';

export default function InvoiceForm() {
  const { t, i18n } = useTranslation();
  const params = useParams();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    documentType,
    handleOpenEventDialog,
    invoice,
    event,
    handleRemoveEvent,
    clientsList,
    handleOpenClientDialog,
    setClient,
    removeChosenClient,
    handleChangeInvoice,
    handleChangeFile,
    removeFile,
    handleChangeDetailsOfReceipts,
    handleSelectedProduct,
    currencyIcon,
    handleChangeProductVatIncluded,
    handleUploadCompleted,
    error,
    isAllProductsVatIncluded,
    handleChangeAllProductsVatIncluded,
    handleChangeProductDescription,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    handleAddProduct,
    updateProductByCurrencyChange,
    saveAsDraft,
    sendInvoice,
    handleOpenQuoteDialog,
    selectedLanguage,
    showedLanguage,
    handleChangePaymentAmount,
    handleChangePaymentDescription,
    clearFile,
    dir,
    handleRemovePaymentIndex,
    setInvoice,
    handleIncreasePaymentQuantity,
    handleChangeDiscountType,
    saveAsTemplate,
  } = useInvoiceForm();

  return (
    <Flex
      w={'100%'}
      pt={{
        base: '210px',
        md: '80px',
        xl: '80px',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w="100%"
        gap="36px"
        p="24px"
        borderRadius={{ base: 0, md: '30px' }}
        maxW="1097px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="cardTitle">
            {params.invoiceId
              ? t('update_invoice.update', {
                  documentType: t(
                    `invoicesAndReceipts.document_type.${documentType}`,
                  )?.toLowerCase(),
                })
              : t('create_invoice.create', {
                  documentType: t(
                    `invoicesAndReceipts.document_type.${documentType}`,
                  )?.toLowerCase(),
                })}
          </Text>
          <Flex alignItems="end" gap="12px">
            <DropdownField
              closeOnSelect
              label={t('create_invoice.language')}
              placeholder={t('create_invoice.language')}
              options={languages}
              selectedOptions={selectedLanguage}
              onSelected={(e: any) => {
                handleChangeInvoice('language', e.value);
              }}
              showedOptions={showedLanguage}
              w={{ base: '100%', md: '239px' }}
            />
            <Flex alignItems="center" gap="12px">
              {documentType !== 'tax_invoice' && (
                <Hide below="md">
                  <AssignEvent
                    handleOpenEventDialog={handleOpenEventDialog}
                    event={event}
                    handleRemoveEvent={handleRemoveEvent}
                    w={{ base: '100%', md: '200px' }}
                    maxW={{ base: '100%', md: '200px' }}
                  />
                </Hide>
              )}
              <Flex
                cursor="pointer"
                bg="#0000004D"
                borderRadius="10px"
                w="30px"
                h="30px"
                onClick={() => {}}
                alignItems="center"
                justifyContent="center">
                <Image src={TrashIcon} alt="trash" w="20px" h="20px" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {documentType !== 'tax_invoice' && (
          <Hide above="md">
            <AssignEvent
              handleOpenEventDialog={handleOpenEventDialog}
              event={event}
              handleRemoveEvent={handleRemoveEvent}
              w={{ base: '100%', md: '200px' }}
              maxW={{ base: '100%', md: '200px' }}
            />
          </Hide>
        )}
        <BusinessDetails senderBusinessID={invoice?.senderBusinessID} />
        <FormHeader
          handleOpenEventDialog={handleOpenEventDialog}
          event={event}
          documentType={documentType}
          handleRemoveEvent={handleRemoveEvent}
          handleChange={handleChangeInvoice}
          invoice={invoice}
          removeFile={removeFile}
          handleUploadCompleted={handleUploadCompleted}
          isAllProductsVatIncluded={isAllProductsVatIncluded}
          handleChangeAllProductsVatIncluded={
            handleChangeAllProductsVatIncluded
          }
          handleOpenQuoteDialog={handleOpenQuoteDialog}
        />
        <Divider borderColor="#00000033" />
        <Clients
          clientsList={clientsList}
          handleOpenClientDialog={handleOpenClientDialog}
          setClient={setClient}
          removeChosenClient={removeChosenClient}
        />
        <Divider borderColor="#00000033" />
        <EventDetails invoice={invoice} handleChange={handleChangeInvoice} />
        <Divider borderColor="#00000033" />
        {/* fields */}
        <TextField
          label={t('create_invoice.quote_included')}
          placeholder={t('create_invoice.comment')}
          w="100%"
          minHeight={{ base: '233px', md: '203px' }}
          value={invoice?.comments}
          onChange={(e: any) => {
            handleChangeInvoice('comments', e.target.value);
          }}
        />
        <Flex w="100%" flexDirection={{ base: 'column', md: 'row' }} gap="16px">
          <Products
            products={invoice?.products}
            handleSelectedProduct={handleSelectedProduct}
            currencyIcon={currencyIcon}
            handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            handleChangeProductDescription={handleChangeProductDescription}
            handleChangeProductAmount={handleChangeProductAmount}
            handleChangeProductPrice={handleChangeProductPrice}
            error={error}
            handleRemoveProduct={handleRemoveProduct}
            handleAddProduct={handleAddProduct}
            updateProductByCurrencyChange={updateProductByCurrencyChange}
            invoice={invoice}
            handleChange={handleChangeInvoice}
            isAllProductsVatIncluded={isAllProductsVatIncluded}
            handleChangeAllProductsVatIncluded={
              handleChangeAllProductsVatIncluded
            }
          />
          <Divider
            orientation="vertical"
            borderColor="#EBEDF0"
            h="100%"
            borderWidth={'1px'}
            display={{ base: 'none', md: 'block' }}
          />
          <TotalInvoicePayment
            invoice={invoice}
            handleChangeInvoice={handleChangeInvoice}
            currencyIcon={currencyIcon}
            error={error}
            setInvoice={setInvoice}
            dir={dir}
            handleChangeDiscountType={handleChangeDiscountType}
          />
        </Flex>

        <Divider borderColor="#00000033" />

        {/* details of receipts */}
        {documentType !== 'tax_invoice' &&
          documentType !== 'tax_invoice_credit' &&
          documentType !== 'pro_forma_invoice' && (
            <DetailsOfReceipts
              receipientDetails={invoice?.receipientDetails}
              handleChangeDetailsOfReceipts={handleChangeDetailsOfReceipts}
            />
          )}
        <PaymentDetails
          invoice={invoice}
          handleChangeInvoice={handleChangeInvoice}
          currencyIcon={currencyIcon}
          error={error}
          handleChangeFile={handleChangeFile}
          handleChangePaymentAmount={handleChangePaymentAmount}
          handleChangePaymentDescription={handleChangePaymentDescription}
          clearFile={clearFile}
          handleRemovePaymentIndex={handleRemovePaymentIndex}
          handleIncreasePaymentQuantity={handleIncreasePaymentQuantity}
          dir={dir}
          setInvoice={setInvoice}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          gap="24px"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Button variant="h4" w="auto" onClick={saveAsDraft}>
            {t('create_invoice.save_as_draft')}
          </Button>
          <Flex gap="24px">
            <Button variant="h2outlined" w="240px" onClick={saveAsTemplate}>
              {t('create_invoice.save_as_template')}
            </Button>
            <Button variant="h1cta" w="240px" onClick={sendInvoice} disabled>
              {t('create_invoice.preview')}
            </Button>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
