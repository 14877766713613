import { AxiosInstance, AxiosResponse } from 'axios';

import {
  BusinessEventPlan,
  IBusinessEventPlan,
  PaginatedResults,
} from './@types';

import { createAxiosInstance } from './axios.util';

class BusinessEventPlanService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createBusinessEventPlan(
    planRequest: Partial<IBusinessEventPlan>,
    notify: boolean = false,
  ): Promise<IBusinessEventPlan> {
    const response: AxiosResponse<IBusinessEventPlan> = await this.api.post(
      `/business-event-plans?notify=${notify}`,
      planRequest,
    );
    return response.data;
  }

  public async getBusinessEventPlans(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<BusinessEventPlan>> {
    const response: AxiosResponse<PaginatedResults<BusinessEventPlan>> =
      await this.api.get('/business-event-plans', {
        params: queryParams,
      });
    return response.data;
  }

  public async getBusinessEventPlan(
    planId: string,
  ): Promise<IBusinessEventPlan> {
    const response: AxiosResponse<IBusinessEventPlan> = await this.api.get(
      `/business-event-plans/${planId}`,
    );
    return response.data;
  }

  public async updateBusinessEventPlan(
    planId: string,
    updates: Partial<IBusinessEventPlan>,
  ): Promise<IBusinessEventPlan> {
    const response: AxiosResponse<IBusinessEventPlan> = await this.api.patch(
      `/business-event-plans/${planId}`,
      updates,
    );
    return response.data;
  }

  public async deleteBusinessEventPlan(planId: string): Promise<void> {
    await this.api.delete(`/business-event-plans/${planId}`);
  }
}

const _businessEventPlanService = new BusinessEventPlanService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _businessEventPlanService;
