import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import NoQuotesCreatedImg from 'assets/img/quote/NoQuotesCreated.png';
import { useAppNavigate } from 'hooks/useAppNavigate';

export default function NoQuotesCreated(props: {
  activeTab: 'sent' | 'received' | 'templates';
  onUploadQuote: () => void;
}) {
  const { t } = useTranslation();
  const { activeTab, onUploadQuote } = props;
  const { appNavigate: navigate } = useAppNavigate();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="320px" src={NoQuotesCreatedImg} alt="" />
      <Box gap="16px" textAlign="center">
        <Text variant="noEventsTitle">{t('quote_list.no_quotes_created')}</Text>
        <Text variant="noEventsSubTitle">
          {t('quote_list.no_quotes_created_description')}
        </Text>
      </Box>
      {activeTab === 'received' && (
        <Button variant="h1cta" onClick={onUploadQuote} w="355px">
          {t('quote_list.upload_quote')}
        </Button>
      )}
      {activeTab !== 'received' && (
        <Button
          w="355px"
          variant="h1cta"
          onClick={() => navigate('/main/finance/quotes/create')}>
          {t('quote_list.create_quote')}
        </Button>
      )}
    </Flex>
  );
}
