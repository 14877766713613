import { Button, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import { IoIosAlert } from 'react-icons/io';
import { Language } from 'types/languages';
import CreateEventDetails from './components/CreateEventDetails';
import CreateEventHeader from './components/CreateEventHeader';
import CreateEventPlanner from './components/CreateEventPlanner';
import EventFound from './components/EventFound';
import { EventFoundModal } from './components/EventFoundModal';
import SelectClients from './components/SelectClients';
import useCreateEvent from './hooks/useCreateEvent';

export default function Events() {
  const {
    handleChange,
    handleCreateUpdateEvent,
    event,
    errorList,
    isEventPlannerFormVisible,
    setIsEventPlannerFormVisible,
    handleOpenClientDialog,
    handleOpenSelectSupplierDialog,
    setClient,
    handleOpenQuoteDialog,
    selectedQuote,
    isMyBusinessEvent,
  } = useCreateEvent();

  const { t, i18n } = useTranslation();

  return (
    <Flex w="full" pb={{ base: '40px', md: '60px' }}>
      <Flex
        w="100%"
        pt={{ base: '190px', md: '80px', xl: '80px' }}
        flexDirection="column"
        gap={4}>
        <Card gap={5} maxW="6xl" transition="all 0.2s ease">
          <CreateEventHeader
            handleChange={handleChange}
            event={event}
            errorList={errorList}
            handleOpenQuoteDialog={handleOpenQuoteDialog}
            selectedQuote={selectedQuote}
            readOnly={!isMyBusinessEvent}
          />
          <SelectClients
            handleOpenClientDialog={handleOpenClientDialog}
            handleChange={handleChange}
            clients={event?.clients}
            newQuoteLanguage={i18n.language as Language}
            hasError={errorList.includes('clients')}
            setClient={setClient}
            readOnly={!isMyBusinessEvent}
          />
          <CreateEventPlanner
            event={event}
            handleChange={handleChange}
            isFormVisible={isEventPlannerFormVisible}
            setIsFormVisible={setIsEventPlannerFormVisible}
            handleOpenSelectSupplierDialog={handleOpenSelectSupplierDialog}
            errorList={errorList}
            readOnly={!isMyBusinessEvent}
          />
          <CreateEventDetails
            handleChange={handleChange}
            event={event}
            errorList={errorList}
            readOnly={!isMyBusinessEvent}
          />
          <EventFound event={event} />
          <VStack
            w="100%"
            align="center"
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            justifyContent={{ base: 'center', md: 'end' }}
            gap="20px"
            h="auto">
            {errorList.length > 0 && (
              <HStack>
                <Icon
                  as={IoIosAlert}
                  w="16px"
                  h="16px"
                  bg="white"
                  color="error.100"
                  borderRadius="50%"
                />
                <Text variant="errorTitle">{t('create_event.error')}</Text>
              </HStack>
            )}
            <Button
              variant="h1cta"
              // px={{ base: '16px', sm: '24px' }}
              w={{ base: '100%', md: '171px' }}
              disabled={errorList.length > 0}
              mt="0px !important"
              onClick={handleCreateUpdateEvent}>
              {t('create_event.save_and_close')}
            </Button>
          </VStack>
        </Card>
        <EventFoundModal onClose={() => {}} />
      </Flex>
    </Flex>
  );
}
