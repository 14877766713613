import { useToast } from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  setContactsPickerDialog,
  setEventsDialog,
  setQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { getLocalizedDir } from 'helpers/getLocalizedDir';
import { useInvoiceMutations } from 'hooks/useInvoice';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  IClient,
  IClientContact,
  IEvent,
  IInvoice,
  IQuote,
  Upload,
  UrlItem,
} from 'services/@types';
import clientService from 'services/client.api';
import eventService from 'services/event.api';
import invoiceService from 'services/invoice.api';
import UploadService from 'services/upload.api';
import { currency } from 'variables/currency';
import { languages } from 'variables/languages';
import {
  addSelectedProduct,
  calculateProductsVat,
  calculateProductsVatByIndex,
  calculateTotal,
  calculateTotalIncludingDiscount,
  calculateTotalPayment,
  calculateTotalVat,
  getUpdatedProductByAmount,
  getUpdatedProductByIls,
  getUpdatedProductByPrice,
  getUpdatedProductByUsd,
} from '../helpers/calculations';
export default function useInvoiceForm() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const myUser = useMyUser();
  const { products } = useSelector((state: RootState) => ({
    products: state.product.products,
    currentDocumentNumber: state.currentDocumentNumber.currentDocumentNumber,
  }));
  const [invoice, setInvoice] = useState<Partial<IInvoice>>({
    currency: 'ILS',
    products: [
      {
        description: '',
        quantity: 0,
        amount: 0,
        net: 0,
        vat: 0,
        gross: 0,
        isVatIncluded: false,
      },
    ],
  });

  const [isAllProductsVatIncluded, setIsAllProductsVatIncluded] =
    useState<boolean>(false);
  const [event, setEvent] = useState<IEvent | null>(null);
  const [error, setError] = useState<{
    clients: boolean;
    products: boolean;
    totalPayment: boolean;
  }>({
    clients: false,
    products: false,
    totalPayment: false,
  });
  const [searchParams] = useSearchParams();
  const { data: businessPreferences } = useGetBusinessPreferences();

  const documentType = useMemo(
    () =>
      (searchParams.get('documentType') as IInvoice['type']) || invoice?.type,
    [searchParams, invoice?.type],
  );

  const currencyIcon = useMemo(() => {
    const selected = currency.find((c) => c.value === invoice?.currency);
    return selected ? selected.symbol : '';
  }, [invoice?.currency]);

  const clientsList = useMemo(
    () => [
      ...(invoice?.clients
        ?.filter((c) => c.client?.type === 'private')
        ?.map((c) => c.client) ?? []),
      ...(invoice?.clients
        ?.filter((c) => c.client?.type === 'business')
        ?.flatMap(
          (c: { contacts: IClientContact[] | string[] }) =>
            c.contacts as IClientContact[],
        ) ?? []),
    ],
    [invoice?.clients],
  );

  const selectedLanguage = useMemo(() => {
    const selected = languages.find((l) => l.value === invoice?.language);
    return selected ? [selected] : [];
  }, [invoice?.language]);

  const showedLanguage = useMemo(() => {
    const selected = languages.find((l) => l.value === invoice?.language);
    return selected ? t(selected.label) : '';
  }, [invoice?.language, t]);

  const dir = useMemo(() => {
    return getLocalizedDir((invoice?.language as 'he' | 'en') || 'en');
  }, [invoice?.language]);

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: async (e: IEvent) => {
          setEvent(e);

          const clients: { client: IClient; contacts: IClientContact[] }[] = [];
          for (const client of e?.clients) {
            try {
              console.log('clientData', client?.client);
              const clientData = await clientService.getClient(
                client?.client as unknown as string,
              );
              if (clientData) {
                clients.push({ client: clientData, contacts: [] });
              }
            } catch (error) {
              console.error('clientData error', error);
            }
          }
          setInvoice((prev) => ({
            ...prev,
            eventID: e?.id,
            clients: clients,
            eventType: e?.type,
            eventDate: e?.dateAndTime,
            numberOfGuests: e?.numberOfGuests,
            location: e?.location?.label,
            media: e?.media,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleRemoveEvent = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      setEvent(null);
      setInvoice((prev) => ({
        ...prev,
        eventID: null,
      }));
    },
    [],
  );

  const handleOpenQuoteDialog = useCallback(() => {
    dispatch(
      setQuoteDialog({
        onConfirm: async (quote: IQuote) => {
          setInvoice((prev) => {
            const newInvoice = { ...prev };
            newInvoice.quoteID = quote.id;

            // set clients
            if (quote?.clients?.length > 0) {
              newInvoice.clients = quote.clients;
            }

            // set language
            if (quote?.language) {
              newInvoice.language = quote.language;
            }

            // set event
            if (quote?.eventId) {
              newInvoice.eventID = quote.eventId;
            }

            // set products
            if (quote?.products?.length > 0) {
              newInvoice.products = quote.products.map((p) => ({
                productId: p.productId,
                description: p.description,
                isVatIncluded: p.isVatIncluded,
                quantity: p.quantity,
                amount: p.amount,
                net: p.net,
                vat: p.vat,
                gross: p.gross,
              }));
            }

            // set event date
            if (quote?.eventDate) {
              newInvoice.eventDate = quote.eventDate;
            }

            // set event type
            if (quote?.eventType) {
              newInvoice.eventType = quote.eventType;
            }

            // number of guests
            if (quote?.numberOfGuests) {
              newInvoice.numberOfGuests = quote.numberOfGuests;
            }

            // set payment in installments
            if (quote?.paymentInInstallments) {
              newInvoice.paymentInInstallments = quote.paymentInInstallments;
            }

            // set payment in installments
            if (quote?.paymentInInstallments) {
              newInvoice.paymentInInstallments = quote.paymentInInstallments;
            }

            // set cancellation terms
            if (quote?.cancellationTerms) {
              newInvoice.cancellationTerms = quote.cancellationTerms;
            }

            // set location
            if (quote?.location) {
              newInvoice.location = quote.location;
            }

            // set currency
            if (quote?.currency) {
              newInvoice.currency = quote.currency;
            }

            // legal text
            if (quote?.legalText) {
              newInvoice.legalText = quote.legalText;
            }

            // description
            if (quote?.description) {
              newInvoice.description = quote.description;
            }

            // set comment
            if (quote?.comment) {
              newInvoice.comments = quote.comment;
            }

            // set media
            if (quote?.media?.length > 0) {
              newInvoice.media = quote.media.map((m) => ({
                url: m.url,
                type: m.type,
                displayName: m.displayName,
              }));
            }

            return newInvoice;
          });

          if (quote.eventId) {
            const eventData = await eventService.getEvent(quote.eventId);
            if (eventData) {
              setEvent(eventData as IEvent);
            }
          }
        },
      }),
    );
  }, [dispatch]);

  const handleOpenClientDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: invoice?.clients || [],
          type: 'clients',
        },
        onConfirm: (
          selection: { client: IClient; contacts: IClientContact[] }[],
        ) => {
          setInvoice((prevstate) => {
            return {
              ...prevstate,
              clients: selection,
            };
          });
        },
      }),
    );
  }, [dispatch, invoice?.clients, invoice?.id]);

  const setClient = useCallback(
    (client: { client: IClient; contacts: IClientContact[] }) => {
      setInvoice((prev) => ({
        ...prev,
        clients: [...(prev?.clients || []), client],
      }));
    },
    [],
  );

  const removeChosenClient = useCallback((clientId, contactId) => {
    if (contactId) {
      setInvoice((prev) => {
        const contactClient = prev?.clients?.find((ic) =>
          ic.contacts?.some((c) => (c as IClientContact).id === contactId),
        );
        const newClients =
          contactClient.contacts.length === 1
            ? prev?.clients?.filter(
                (ic) => ic.client.id !== contactClient?.client.id,
              )
            : prev?.clients?.map((ic) => {
                if (ic.client.id === contactClient?.client.id) {
                  return {
                    ...ic,
                    contacts: (ic.contacts as IClientContact[]).filter(
                      (c) => c.id !== contactId,
                    ),
                  };
                }
                return ic;
              });
        return {
          ...prev,
          clients: newClients,
        };
      });
    } else {
      setInvoice((prev) => ({
        ...prev,
        clients: prev?.clients?.filter((ic) => ic.client.id !== clientId),
      }));
    }
  }, []);

  const handleChangeInvoice = useCallback((key: string, value: any) => {
    setInvoice((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleChangeDetailsOfReceipts = useCallback(
    (key: string, value: any) => {
      setInvoice((prev) => ({
        ...prev,
        receipientDetails: { ...prev.receipientDetails, [key]: value },
      }));
    },
    [],
  );

  const handleUploadCompleted = useCallback((file: UrlItem) => {
    console.log('handleUploadCompleted file', file);
    setInvoice((prev) => ({
      ...prev,
      media: [
        ...(prev.media || []),
        {
          url: file.url,
          type: file.type,
          displayName: file.displayName,
        },
      ],
    }));
  }, []);

  const handleChangeFile = useCallback(
    async (event: any) => {
      event.stopPropagation();
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newImg: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        if (newImg) {
          setInvoice((prev) => ({
            ...prev,
            media: [
              ...(prev.media || []),
              {
                url: newImg.filePath,
                type: 'document',
                displayName: newImg.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  const removeFile = useCallback(
    async (type: 'invoiceCommentAttachment' | 'invoiceAttachment') => {
      try {
        const file = invoice?.media?.find((m) => m.type === type);
        if (file) {
          const [target, targetId, access, fileId] = file.url.split('/');
          await UploadService.deleteUpload({
            target,
            targetId,
            access,
            fileId,
          });
        } else {
          console.error('file not found');
          return;
        }
      } catch (error) {
        console.error('use clear file error ->', error);
        return;
      }

      setInvoice((prev) => ({
        ...prev,
        media: prev?.media?.filter((m) => m.type !== type),
      }));
    },
    [invoice?.media],
  );

  const handleSelectedProduct = useCallback(
    (e: any, index: number) => {
      const selectedProduct = products.find((p) => p.id === e);
      if (!selectedProduct) return;
      setInvoice((prev) => ({
        ...prev,
        products: addSelectedProduct({
          vatValue: businessPreferences?.vatValue,
          products: prev.products,
          selectedProduct,
          index,
        }),
      }));
    },
    [businessPreferences?.vatValue, products],
  );

  const handleChangeProductVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setInvoice((prev) => ({
        ...prev,
        products: calculateProductsVatByIndex(
          prev.products,
          index,
          e.target.checked,
          businessPreferences?.vatValue,
        ),
      }));
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeAllProductsVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsAllProductsVatIncluded(e.target.checked);
      setInvoice((prev) => {
        const products = calculateProductsVat(
          prev.products,
          e.target.checked,
          businessPreferences?.vatValue,
        );
        return {
          ...prev,
          products,
        };
      });
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeProductAmount = useCallback(
    (e: any, index: number) => {
      setInvoice((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index
            ? getUpdatedProductByAmount(
                p,
                e.target.value,
                businessPreferences?.vatValue,
              )
            : p,
        ),
      }));
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeProductDescription = useCallback(
    (e: any, index: number) => {
      setInvoice((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index ? { ...p, description: e.target.value } : p,
        ),
      }));
    },
    [],
  );

  const handleChangeProductPrice = useCallback((e: any, index: number) => {
    setInvoice((prev: any) => ({
      ...prev,
      products: prev.products.map((p: any, key: number) =>
        key === index
          ? getUpdatedProductByPrice(
              p,
              e.target.value,
              businessPreferences?.vatValue,
            )
          : p,
      ),
    }));
  }, []);

  const handleRemoveProduct = useCallback((index: number) => {
    setInvoice((prev) => ({
      ...prev,
      products: prev.products.filter((p, key) => key !== index),
    }));
  }, []);

  const handleAddProduct = useCallback(() => {
    setInvoice((prev) => ({
      ...prev,
      products: [
        ...(prev?.products || []),
        {
          description: '',
          quantity: 1,
          amount: 1,
          net: 1,
          vat: 1,
          gross: 1 + 1 * businessPreferences?.vatValue,
          isVatIncluded: true,
        },
      ],
    }));
  }, [businessPreferences?.vatValue]);

  const updateProductByCurrencyChange = useCallback(
    (productData, productIndex) => {
      setInvoice((prev) => {
        const products = prev.products;
        // update product by currency change
        let updatedProduct = products[productIndex];
        if (invoice?.currency === 'ILS') {
          updatedProduct = getUpdatedProductByIls(
            updatedProduct,
            productData?.price,
            businessPreferences?.vatValue,
          );
        }
        if (invoice?.currency === 'USD') {
          updatedProduct = getUpdatedProductByUsd(
            updatedProduct,
            productData?.price,
            businessPreferences?.vatValue,
          );
        }
        products[productIndex] = updatedProduct;
        return { ...prev, products: products };
      });
    },
    [invoice?.currency, businessPreferences?.vatValue],
  );

  // create invoice
  const { createInvoice, updateInvoice } = useInvoiceMutations();

  const saveAsTemplate = useCallback(async () => {
    // TODO: save as template
  }, []);

  const saveAsDraft = useCallback(async () => {
    try {
      const newInvoice: Partial<IInvoice> = {
        ...invoice,
        type: documentType as IInvoice['type'],
        clients: invoice?.clients?.map((c) => ({
          ...c,
          client: c.client.id || '',
          contacts: c?.contacts?.map((contact) => contact?.id) || [],
        })),
      };
      let response;
      if (params?.invoiceId) {
        response = await updateInvoice.mutateAsync({
          invoiceId: params.invoiceId,
          updates: newInvoice as IInvoice,
        });
      } else {
        response = await createInvoice.mutateAsync(newInvoice);
      }

      if (response) {
        navigate('/main/finance/invoices/');
        toast({
          title: params?.invoiceId
            ? t('update_invoice.invoice_updated_successfully')
            : t('create_invoice.save_as_draft'),
          description: params?.invoiceId
            ? ''
            : t('create_invoice.invoice_saved_as_draft_description'),
          status: 'success',
          position: 'top-right',
          variant: 'main',
        });
      }
    } catch (error: any) {
      console.error('saveAsDraft error', error);
      toast({
        title: error?.response?.data?.message || error?.message,
        status: 'error',
        position: 'top-right',
      });
    }
  }, [invoice, navigate, toast, t, params?.invoiceId]);

  const sendInvoice = useCallback(async () => {
    // TODO: send invoice
  }, []);

  const handleRemovePaymentIndex = useCallback((index: number) => {
    setInvoice((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev.paymentInInstallments.installments.slice(0, index),
        quantity: prev.paymentInInstallments.quantity - 1,
      },
    }));
  }, []);

  const handleChangePaymentAmount = useCallback((e: any, index: number) => {
    setInvoice((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev?.paymentInInstallments?.installments.map((p, key) =>
          key === index
            ? { ...p, amount: e.target.value }
            : {
                ...p,
              },
        ),
      },
    }));
  }, []);

  const handleChangePaymentDescription = useCallback(
    (e: any, index: number) => {
      setInvoice((prev) => ({
        ...prev,
        paymentInInstallments: {
          ...prev.paymentInInstallments,
          installments: prev.paymentInInstallments.installments.map((p, key) =>
            key === index ? { ...p, description: e.target.value } : p,
          ),
        },
      }));
    },
    [],
  );

  const clearFile = useCallback(async () => {
    if (params?.invoiceId) {
      await updateInvoice.mutateAsync({
        invoiceId: params.invoiceId,
        updates: { media: [] },
      });
    } else {
      try {
        const [target, targetId, access, fileId] =
          invoice?.media[0]?.url.split('/');
        await UploadService.deleteUpload({ target, targetId, access, fileId });
      } catch (error) {
        console.error('use clear file error ->', error);
      }
    }

    setInvoice((prev) => ({
      ...prev,
      media: [],
    }));
  }, [invoice?.media, params?.invoiceId, dispatch]);

  const handleIncreasePaymentQuantity = useCallback(() => {
    const total = invoice?.generalPaymentDetails?.totalPayment || 0;
    const rest =
      invoice?.paymentInInstallments?.installments?.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0,
      ) || 0;
    const difference = total - rest;
    setInvoice((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev?.paymentInInstallments,
        quantity: (prev?.paymentInInstallments?.quantity || 0) + 1,
        installments: [
          ...(prev?.paymentInInstallments?.installments || []),
          {
            amount: difference > 0 ? difference : 0,
            dueDate: new Date(),
            isPaid: false,
          },
        ],
      },
    }));
  }, [
    invoice?.generalPaymentDetails?.totalPayment,
    invoice?.paymentInInstallments?.installments,
  ]);

  const handleChangeDiscountType = useCallback(
    (type: 'percentage' | 'fixed') => {
      setInvoice((prev) => ({
        ...prev,
        generalPaymentDetails: {
          ...prev.generalPaymentDetails,
          discountType: type,
        },
      }));
    },
    [],
  );

  useEffect(() => {
    if (invoice?.products?.length > 0) {
      setIsAllProductsVatIncluded(
        invoice?.products?.every((p) => p?.isVatIncluded),
      );
    }
  }, [invoice?.products]);

  // get invoice if invoiceId is in the url
  useEffect(() => {
    if (params?.invoiceId) {
      const fetchInvoice = async () => {
        try {
          const invoiceData = await invoiceService.getInvoice(params.invoiceId);
          console.log('invoiceData', invoiceData);
          setInvoice({ ...invoiceData, eventID: invoiceData.eventID?.id });
          setEvent(invoiceData.eventID);
        } catch (error) {
          console.error('fetchInvoice error', error);
        }
      };
      fetchInvoice();
    }
  }, [params?.invoiceId]);

  // handle total generalPaymentDetails
  useEffect(() => {
    setInvoice((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        total: calculateTotal(prev.products),
      },
    }));
  }, [invoice.products, invoice.currency]);

  // handle total including discount
  useEffect(() => {
    setInvoice((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        totalIncludingDiscount: calculateTotalIncludingDiscount(
          prev.generalPaymentDetails.discountType,
          prev.generalPaymentDetails.discount || 0,
          prev.generalPaymentDetails.total,
        ),
      },
    }));
  }, [
    invoice?.generalPaymentDetails?.discountType,
    invoice?.generalPaymentDetails?.discount,
    invoice?.generalPaymentDetails?.total,
  ]);

  // handle total including vat
  useEffect(() => {
    setInvoice((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        vat: calculateTotalVat(
          prev.products,
          prev.generalPaymentDetails,
          businessPreferences?.vatValue,
        ),
        totalPayment: calculateTotalPayment(
          prev.products,
          prev?.generalPaymentDetails,
          businessPreferences?.vatValue,
        ),
      },
    }));
  }, [
    invoice?.products,
    invoice?.generalPaymentDetails?.total,
    invoice?.generalPaymentDetails?.discount,
    invoice?.generalPaymentDetails?.discountType,
    invoice?.generalPaymentDetails?.totalIncludingDiscount,

    businessPreferences?.vatValue,
  ]);

  // observe invoiceId query params for apply invoice data to new invoice
  useEffect(() => {
    if (searchParams.get('invoiceId')) {
      const fetchInvoice = async () => {
        const invoiceId = searchParams.get('invoiceId') as string;
        if (!invoiceId) {
          return;
        }
        try {
          const invoiceData = await invoiceService.getInvoice(invoiceId);
          if (invoiceData) {
            setInvoice((prev: any) => {
              // set type
              prev.type = searchParams.get('documentType') as IInvoice['type'];
              // fields to merge
              const fields: (
                | keyof IInvoice
                | [keyof IInvoice, keyof IInvoice]
              )[] = [
                'clients',
                'products',
                ['eventID', 'id'],
                'quoteID',
                'media',
                'generalPaymentDetails',
                'paymentInInstallments',
                'cancellationTerms',
                'language',
                'eventDate',
                'eventType',
                'numberOfGuests',
                'location',
              ];

              // merge fields
              fields.forEach((field) => {
                if (Array.isArray(field)) {
                  const [key, nestedKey] = field;
                  if (invoiceData?.[key]) {
                    prev[key] = invoiceData[key][nestedKey];
                  }
                } else {
                  if (invoiceData?.[field]) {
                    prev[field] = invoiceData[field];
                  }
                }
              });

              if (invoiceData.eventID) {
                setEvent(invoiceData.eventID);
              }

              return {
                ...prev,
              };
            });
          }
        } catch (error) {
          console.error('fetchInvoice error', error);
        }
      };
      fetchInvoice();
    }
  }, [searchParams.get('invoiceId')]);

  return {
    documentType,
    handleOpenEventDialog,
    invoice,
    event,
    handleRemoveEvent,
    clientsList,
    handleOpenClientDialog,
    setClient,
    removeChosenClient,
    handleChangeInvoice,
    handleChangeFile,
    removeFile,
    handleChangeDetailsOfReceipts,
    handleSelectedProduct,
    currencyIcon,
    handleChangeProductVatIncluded,
    error,
    handleUploadCompleted,
    isAllProductsVatIncluded,
    handleChangeAllProductsVatIncluded,
    handleChangeProductDescription,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    handleAddProduct,
    updateProductByCurrencyChange,
    saveAsDraft,
    sendInvoice,
    handleOpenQuoteDialog,
    selectedLanguage,
    showedLanguage,
    handleChangePaymentAmount,
    handleChangePaymentDescription,
    clearFile,
    dir,
    handleRemovePaymentIndex,
    setInvoice,
    handleIncreasePaymentQuantity,
    handleChangeDiscountType,
    saveAsTemplate,
  };
}
