import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DropdownField from 'components/fields/DropdownField';
import LocationSearchInput from 'components/placesAutocomplete';
import InputDateField from 'components/fields/InputDateField';
import { eventTypes } from 'variables/eventTypes';
import { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { getLocalizedDir } from 'helpers/getLocalizedDir';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import InputField from 'components/fields/InputField';

interface IEventDetailsProps {
  invoice: any;
  handleChange: (key: string, value: any) => void;
}

export default function EventDetails(props: IEventDetailsProps) {
  const { invoice, handleChange } = props;
  const { t } = useTranslation();
  const [eventDateSwitch, setEventDateSwitch] = useState<boolean>(true);

  const selectedEventType = useMemo(
    () => (invoice?.eventType ? [`event_types.${invoice?.eventType}`] : []),
    [invoice?.eventType],
  );

  const showedEventType = useMemo(
    () => (invoice?.eventType ? t(`event_types.${invoice?.eventType}`) : ''),
    [invoice?.eventType, t],
  );

  const handleSelectEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');
      handleChange('eventType', formattedSelected);
    },
    [handleChange],
  );

  const handleEventDateSwitch = useCallback(
    (event: any) => {
      setEventDateSwitch(event.target.checked);
    },
    [handleChange],
  );

  const handleEventDateChange = useCallback(
    (e: Date) => {
      handleChange('eventDate', e.toString());
    },
    [handleChange],
  );

  return (
    <Flex gap="12px">
      <DropdownField
        closeOnSelect
        label={t('create_invoice.event_type')}
        placeholder={t('create_invoice.event_type')}
        options={eventTypes.map((type) => `event_types.${type}`)}
        selectedOptions={selectedEventType}
        onSelected={handleSelectEventType}
        showedOptions={showedEventType}
        w={{ base: '100%', md: '239px' }}
      />
      <LocationSearchInput
        sxWidth="100%"
        label={t('create_meeting.location')}
        placeholder={t('create_meeting.location')}
        value={invoice?.location}
        onChange={(e) => {
          handleChange('location', e);
        }}
        onSelect={(e) => {
          handleChange('location', e);
        }}
      />
      <InputDateField
        label={t(`create_quote.event_date`)}
        justifyContent="end"
        dir={getLocalizedDir(invoice?.language)}
        isDisabled={!eventDateSwitch}
        containerStyles={{ flex: '1', w: { base: '100%', md: '260px' } }}
        buttonStyles={{
          w: { base: '100%', md: '260px' },
          maxW: { base: '100%', md: '280px' },
          borderColor: '#0000001A',
          justifyContent: 'space-between',
        }}
        switchProps={{
          id: 'eventDateSwitch',
          size: 'sm',
          isChecked: eventDateSwitch,
          onChange: handleEventDateSwitch,
          label: '',
          dir: getLocalizedDir(invoice?.language),
        }}
        value={invoice?.eventDate || new Date()}
        imgSrc={CalendarIcon}
        onChange={handleEventDateChange}
        imgStyles={{
          bg: 'transparent',
        }}
        lang={invoice?.language}
      />
      <InputField
        label={t(`create_quote.number_of_guests`)}
        value={invoice?.numberOfGuests}
        onChange={(e) => handleChange('numberOfGuests', e.target.value)}
        flex="1"
        lang={invoice?.language}
        dir={getLocalizedDir(invoice?.language)}
        minWidth={{ base: '100%', md: '260px' }}
        w={{ base: '100%', md: '280px' }}
        placeholder={t(`create_quote.number_of_guests`)}
      />
    </Flex>
  );
}
