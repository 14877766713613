import { useToast } from '@chakra-ui/react';
import { createClient } from 'contexts/redux/client/clientSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IClient } from 'services/@types';
import { importFromExcel } from 'utils/excelImport';

interface ContactData {
  // English fields
  active?: string;
  name?: string;
  client_type?: string;
  business_id?: string;
  total_orders?: number;
  total_receipts?: number;
  total_invoices?: number;
  balance?: number;
  software?: string;
  accounting_system_code?: number;

  // Hebrew fields
  פעיל?: string;
  שם?: string;
  'סוג לקוח'?: string;
  'מספר עוסק'?: string;
  'סה"כ הזמנות'?: number;
  'סה"כ קבלות'?: number;
  'סה"כ חשבוניות'?: number;
  יתרה?: number;
  תוכנה?: string;
  'קוד במערכת הנה"ח'?: number;
}

type UseContactImportProps = {
  onClose: () => void;
};

const useContactImport = ({ onClose }: UseContactImportProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [importedClients, setImportedClients] = useState<Partial<IClient>[]>(
    [],
  );
  const [fileName, setFileName] = useState<string>('');

  const transformToClient = (data: ContactData): Partial<IClient> => {
    // Get name from either English or Hebrew field
    const clientName = data.name || data['שם'] || '';

    // Get client type from either English or Hebrew field
    const clientType = data.client_type || data['סוג לקוח'] || 'business';

    // Get business ID from either English or Hebrew field
    const businessId = data.business_id || data['מספר עוסק']?.toString() || '';

    // Check if client_type includes relevant keywords in any language
    const isPrivateType =
      clientType.toLowerCase().includes('private') ||
      clientType.toLowerCase().includes('פרטי') ||
      clientType.toLowerCase().includes('חברה פרטית');

    // For business clients, use the full name as company name
    if (!isPrivateType) {
      return {
        id: '', // This will be assigned by the backend
        firstName: '',
        lastName: '',
        email: 'contact@example.com',
        phone: '-',
        address: '-',
        company: clientName,
        type: 'business',
        idNumber: businessId.toString() || '-',
      };
    }

    // For private clients, split the name
    const nameParts = clientName.split(' ');
    return {
      id: '', // This will be assigned by the backend
      firstName: nameParts[0] || 'Unknown',
      lastName: nameParts.slice(1).join(' ') || 'Unknown',
      email: 'contact@example.com',
      phone: '-',
      address: '-',
      company: undefined, // No company name for private clients
      type: 'private',
      idNumber: businessId.toString() || '-',
    };
  };

  const exportJsonFromExcel = async (
    file: File,
  ): Promise<Partial<IClient>[]> => {
    return importFromExcel<ContactData, Partial<IClient>>(
      file,
      transformToClient,
      {
        setIsLoading,
        setError,
        setFileName,
        setImportedData: setImportedClients,
      }
    );
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    try {
      await exportJsonFromExcel(file);
    } catch (error) {
      console.error('Error processing Excel file:', error);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls',
    multiple: false,
  });

  const createMultipleClients = useCallback(async () => {
    console.log('Importing clients:', importedClients);
    try {
      const results = await Promise.all(
        importedClients.map(async (client) => {
          try {
            return await dispatch(
              createClient({
                client: {
                  address: client.address,
                  company: client.company,
                  email: client.email,
                  firstName: client.firstName,
                  lastName: client.lastName,
                  phone: client.phone,
                  type: client.type,
                  idNumber: client.idNumber,
                },
              }),
            );
          } catch (error) {
            console.error(`Failed to create client: ${client.email}`, error);
            return { error };
          }
        }),
      );

      const errors = results.filter((result) => 'error' in result);
      if (errors.length > 0) {
        console.error(`Failed to create ${errors.length} clients`);
        throw new Error(`Failed to create ${errors.length} clients`);
      }
      toast({
        title: t('dialogs.import_data.contacts_import_success'),
        status: 'success',
        variant: 'main',
      });
      setFileName('');
      setImportedClients([]);
      setError(null);
      onClose();
    } catch (error) {
      console.error('Error creating clients:', error);
      toast({
        title: t('dialogs.import_data.contacts_import_error'),
        status: 'error',
        variant: 'error',
      });
    }
  }, [importedClients, dispatch, onClose, toast, t]);

  return {
    exportJsonFromExcel,
    isLoading,
    error,
    getRootProps,
    getInputProps,
    isDragActive,
    importedClients,
    fileName,
    handleImport: createMultipleClients,
  };
};

export default useContactImport;
