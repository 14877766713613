import { Button, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import PreviewIcon from 'assets/img/quote/QuotePreview.png';
import EventCard from 'components/eventsPickerDialog/eventCard';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useGetBusinessEventPlans } from 'queries/businessEventPlan';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IEvent, IQuote } from 'services/@types';
import { languages } from 'variables/languages';
import _uploadService from 'services/upload.api';
import { AxiosError } from 'axios';

export default function CreateQuoteHeader({
  handleChange,
  newQuote,
  handleChangeEventType,
  selectedEventType,
  handleOpenEventDialog,
  event,
  dir,
  newQuoteNumber,
}: {
  handleChange: (key: string, value: any) => void;
  newQuote: IQuote;
  handleChangeEventType: (selected: string) => void;
  selectedEventType: string[];
  handleOpenEventDialog: () => void;
  event: IEvent | null;
  newQuoteNumber: number;
  dir: string;
}) {
  const { t } = useTranslation('', {
    lng: newQuote?.language,
  });
  const params = useParams();
  const { appNavigate: navigate } = useAppNavigate();

  const currentLanguage = useMemo(() => {
    const lang = newQuote?.language;
    switch (lang) {
      case 'he':
        return { label: t('language.hebrew', { lng: lang }), value: 'he' };
      case 'en':
        return { label: t('language.english', { lng: lang }), value: 'en' };
      default:
        return { label: t('language.english', { lng: lang }), value: lang };
    }
  }, [newQuote?.language, t]);

  const business = useMyBusiness();
  const { data: businessEventPlans } = useGetBusinessEventPlans({
    eventId: event?.id,
    businessId: business?.id,
  });

  const [imageData, setImageData] = useState<string>('');

  useMemo(() => {
    const fetchImage = async () => {
      try {
        const path = business?.media?.find((i) => i.type === 'profileImage')?.url
        if (!path) {
          setImageData('');
        }
        const response = await _uploadService.getUpload(path);
        const imageData = URL.createObjectURL(response);
        setImageData(imageData);
      } catch (error) {
      }
    };

    fetchImage();
  }, [business?.media]);

  const eventName = useMemo(() => {
    return businessEventPlans?.results[0]?.eventName || event?.name;
  }, [businessEventPlans?.results, event?.name]);

  return (
    <VStack alignItems="start" gap="36px">
      <HStack justify="space-between" w="100%" spacing={0}>
        <VStack alignItems="start">
          <Text variant="cardTitle">
            {params.id
              ? t('create_quote.update_quote')
              : t('create_quote.title')}
          </Text>
          <Text variant="cardSubTitle">
            {`#${newQuote.quoteNumber || newQuoteNumber}`}
          </Text>
        </VStack>
        <HStack alignItems="flex-end" spacing={0}>
          <HStack alignItems="end" w="max-content" gap={1}>
            <DropdownField
              closeOnSelect
              label={t('header.language')}
              placeholder={t('header.language')}
              options={languages}
              selectedOptions={[currentLanguage]}
              onSelected={(e: any) => {
                handleChange('language', e.value);
              }}
              dir={dir}
              showedOptions={t(currentLanguage.label)}
              w={{ base: '100%', md: '320px' }}
            />
            <HStack alignItems="center">
              {event && (
                <EventCard
                  event={event}
                  eventName={eventName}
                  onClick={handleOpenEventDialog}
                  isQuoteEvent
                  maxW={{ base: '100%', md: '320px' }}
                  flex="1"
                />
              )}
              {!event && (
                <Button mb={{base: '0', md: "3px"}} variant="h1cta" onClick={handleOpenEventDialog}>
                  {t('create_quote.assign_event')}
                </Button>
              )}
              {params?.id && (
                <Flex
                  cursor="pointer"
                  bg="#0000004D"
                  borderRadius="10px"
                  w="32px"
                  h="32px"
                  onClick={() =>
                    navigate(`/main/finance/quotes/preview/${params?.id}`)
                  }
                  alignItems="center"
                  justifyContent="center">
                  <Image src={PreviewIcon} alt="preview" />
                </Flex>
              )}
            </HStack>
          </HStack>
        </HStack>
      </HStack>
      <HStack
         w="full"
         spacing={0}
         gap={9}
         flexDir={{ base: 'column', md: 'row' }}
          justify={"space-between"} alignItems={{ base: 'start', md: "center" }}>
        {imageData && <Image src={imageData} alt="Business" maxH="80px" />}
        <Text
          fontSize={'18'}
          lineHeight={'28px'}
          fontWeight={600}
          color="black">
          {business?.businessName?.toUpperCase()}
        </Text>
       
      </HStack>
      <InputField
        label={t('create_quote.document_title')}
        value={newQuote?.documentTitle}
        minW={{ base: '100%', md: '510px' }}
        onChange={(e) => handleChange('documentTitle', e.target.value)}
        placeholder={t('create_quote.document_title_placeholder')}
      />
    </VStack>
  );
}
