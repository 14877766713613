import { setImportDataDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import AddClientModal from './components/AddClientModal';
import ClientsView from './components/ClientsView';
import NoClients from './components/NoClients';
import useClients from './hooks/useClients';

export default function Clients() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    clients,
    isAddClientOpen,
    onOpenAddClient,
    onAddClientClosed,
    search,
    handleSearch,
    loading,
  } = useClients({});

  const handleOpenImportDialog = useCallback(() => {
    dispatch(setImportDataDialog({ item: { activeTab: 1 } }));
  }, [dispatch]);

  return (
    <>
      {(clients.length > 0 || search.length > 0) && (
        <ClientsView
          clients={clients}
          onOpenAddClient={onOpenAddClient}
          search={search}
          handleSearch={handleSearch}
          loading={loading}
          onOpenImportDialog={handleOpenImportDialog}
        />
      )}
      {clients.length === 0 && search.length === 0 && (
        <NoClients
          onOpenAddClient={onOpenAddClient}
          onOpenImportDialog={handleOpenImportDialog}
        />
      )}
      <AddClientModal isOpen={isAddClientOpen} onClose={onAddClientClosed} />
    </>
  );
}
