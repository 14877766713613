import {
  Button,
  Checkbox,
  Flex,
  Hide,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import TextField from 'components/fields/TextField';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPaperclip, FiPlus, FiX } from 'react-icons/fi';
import { CreateQuoteError, IQuote } from 'services/@types';
import { currency } from 'variables/currency';
import ProductItem from './ProductItem';

export default function Products({
  handleAddProduct,
  newQuote,
  handleChange,
  setNewQuote,
  products,
  handleSelectedProduct,
  handleChangeFile,
  clearFile,
  currencyIcon,
  error,
  handleChangeAllProductsVatIncluded,
  isAllProductsVatIncluded,
  handleChangeProductVatIncluded,
  dir,
  setIsUserInteraction,
}: {
  handleAddProduct: () => void;
  newQuote: IQuote;
  handleChange: (path: string, value: any) => void;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  products: { value: string; label: string }[];
  handleSelectedProduct: (e: any, index: number) => void;
  handleChangeFile: (e: any) => void;
  clearFile: () => void;
  currencyIcon: string;
  error: CreateQuoteError;
  handleChangeAllProductsVatIncluded: (e: any) => void;
  isAllProductsVatIncluded: boolean;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
  dir: string;
  setIsUserInteraction: (value: boolean) => void;
}) {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const { t } = useTranslation('', {
    lng: newQuote?.language,
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectedCurrency = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? [selected] : [''];
  }, [newQuote?.currency]);

  const showedOptions = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? t(selected.label) + ' - ' + selected.symbol : '';
  }, [newQuote?.currency, t]);

  return (
    <VStack alignItems="start" gap="16px" flex="1">
      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
        style={{ display: 'none' }}
        onChange={handleChangeFile}
      />
      <Text variant="createQuoteTitle">{t('create_quote.products')}</Text>
      <Hide below="md">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          gap={4}
          width="100%">
          <DropdownField
            closeOnSelect
            showedOptions={showedOptions}
            label={t('create_quote.currency')}
            options={currency}
            placeholder={t('create_quote.currency')}
            selectedOptions={selectedCurrency}
            onSelected={(e: any) => {
              handleChange('currency', e.value);
            }}
            dir={dir}
            menuButton={{
              w: {
                base: '100%',
                md: '239px',
              },
              bg: '#FFFFFF',
            }}
          />
          <VStack alignItems="start" width="144px">
            <Text color={"transparent"} variant="createQuoteCheckboxText">
              {t('create_quote.vat')}
            </Text>
            <Checkbox
              marginTop={'0px !important'}
              variant="roundBrand"
              isChecked={isAllProductsVatIncluded}
              onChange={handleChangeAllProductsVatIncluded}
              h="50px"
              dir={dir}
              alignItems="center">
              <Text variant="createQuoteCheckboxText">
                {t('quote_preview.vat', {
                  percentage: businessPreferences?.vatPercent,
                })}
              </Text>
            </Checkbox>
          </VStack>
        </Flex>
      </Hide>
      {newQuote?.products?.map((product, index) => (
        <ProductItem
          newQuoteLanguage={newQuote?.language}
          product={product}
          index={index}
          setNewQuote={setNewQuote}
          products={products}
          handleSelectedProduct={handleSelectedProduct}
          currencyIcon={currencyIcon}
          key={product.productId || index}
          error={error}
          dir={dir}
          currency={newQuote?.currency}
          handleChangeProductVatIncluded={handleChangeProductVatIncluded}
          setIsUserInteraction={setIsUserInteraction}
        />
      ))}
      <HStack>
        <IconButton
          borderRadius="8px"
          icon={<Icon as={FiPlus} color="white" w="15px" h="15px" />}
          onClick={handleAddProduct}
          aria-label="add"
          w="24px"
          h="24px"
          minW="24px"
          bg="rgba(112, 142, 133, 1)"
        />
        <Text variant="addRowText">{t('create_quote.add_row')}</Text>
      </HStack>
      {/* <TextField
        label={t('create_quote.comment')}
        placeholder={t('create_quote.comment')}
        w="100%"
        minHeight={{ base: '233px', md: '203px' }}
        value={newQuote.comment}
        onChange={(e: any) => {
          setNewQuote((prev) => ({ ...prev, comment: e.target.value }));
        }}
        dir={dir}
        iconPointerEvents="auto"
        iconProps={{
          top: 'unset',
          bottom: '0',
          insetInlineStart: 'unset',
          insetInlineEnd: 'unset',
          w: 'unset',
          h: 'auto',
        }}
        iconLeft={
          <Flex
            // marginLeft={i18n.dir() === 'rtl' ? '0px' : '20px'}
            // marginRight={i18n.dir() === 'rtl' ? '20px' : '0px'}
            alignItems="center"
            height={'auto'}>
            {(!newQuote?.media || newQuote?.media?.length === 0) && (
              <Button
                variant="ghost"
                alignItems="center"
                justifyContent={'start'}
                gap={1}
                onClick={() => {
                  fileInputRef.current?.click();
                }}>
                <Flex
                  bg="#93C3B3"
                  justify="center"
                  align="center"
                  borderRadius="10px"
                  w="33px"
                  h="33px"
                  minW="33px">
                  <Icon as={FiPaperclip} w="20px" h="20px" color="white" />
                </Flex>
                <Text variant="addRowText" noOfLines={1}>
                  {t('create_quote.attach_file')}
                </Text>
                <Input
                  type="file"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                  display="none"
                  onChange={handleChangeFile}
                  ref={fileInputRef}
                />
              </Button>
            )}
          </Flex>
        }
      /> */}
      {newQuote?.media?.length > 0 && (
        <Flex alignItems="center" gap="4px">
          <Text
            variant="eventGridCardDesc"
            onClick={() => {
              handleOpenPdf(
                newQuote?.media?.[0]?.url,
                newQuote?.media?.[0]?.displayName,
              );
            }}>
            {newQuote.media[0]?.displayName}
          </Text>
          <Icon
            aria-label=""
            as={FiX}
            cursor="pointer"
            onClick={() => clearFile()}
          />
        </Flex>
      )}
    </VStack>
  );
}
